import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useContext } from 'react'
import UniversalButton from '../../../../ui/Buttons/UniversalButton'
import { UploadEvidenceContext } from '../../../../../contexts/UploadEvidenceContext/uploadEvidence-context'
import UploadFileSharedContent from './UploadFileSharedContent'
import Radio from '@mui/material/Radio'

const ChooseTypeOfEvidenceContent: React.FC = () => {
  const {
    actions: { onSubmitAddEvidenceForm, onClickContinueInChooseEvidenceType },
  } = useContext(UploadEvidenceContext)

  const theme = useTheme()

  const [value, setValue] = React.useState('CHOOSE')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }

  return (
    <Box sx={{ px: theme.spacing(4), py: theme.spacing(3) }}>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          {' '}
          <Typography variant={'h6'}>
            What type of evidence would you like to upload?
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="PUBLIC" control={<Radio />} label="Public" />
          <FormControlLabel
            value="PRIVATE"
            control={<Radio />}
            label="Private"
          />
        </RadioGroup>
      </FormControl>
      <Box sx={{ marginTop: theme.spacing(2) }}>
        <UniversalButton
          label="Continue"
          disabled={value === 'CHOOSE'}
          onClick={() => {
            onClickContinueInChooseEvidenceType(value as 'PUBLIC' | 'PRIVATE')
          }}
        />
      </Box>
    </Box>
  )
}

export default ChooseTypeOfEvidenceContent
