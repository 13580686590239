import { createContext } from 'react'
import { UploadEvidenceContextSchema } from './uploadEvidenceInterfaces'
import initialUploadEvidenceState from './initialUploadEvidenceState'
import initialUploadEvidenceActions from './initialUploadEvidenceActions'

const UploadEvidenceInitialContext: UploadEvidenceContextSchema = {
  state: initialUploadEvidenceState,
  actions: initialUploadEvidenceActions,
}

export const UploadEvidenceContext = createContext(UploadEvidenceInitialContext)
