import { AnswerPrevievState } from './answerPreviewContextInterfaces'

export const initialAnswerPreviewState: AnswerPrevievState = {
  responseId: '',
  isEditMode: false,
  responseInput: {
    isValid: false,
    value: '',
  },
  selectReviewerValue: 'unassigned',
  reviewers: null,
  choosenMissingEvidenceId: null,
  approvedByName: null,
  isAddMissingEvidenceModalOpen: false,
  isRejectModalOpen: false,
  addingMissingEvidenceStep: 0,
  errorInPageRangesValidation: '',
  missingEvidenceSentenceInputValue: '',
  pageRangesInputValue: '',
  areAnyChangesCompareToOriginal: null,
  isEditorAssignedAsContributor: false,
  requestId: '',
}
