import React from 'react'
import { Box, SxProps, TextField, Typography, useTheme } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select'
import { TimesOneMobiledata } from '@mui/icons-material'

interface Props {
  id: string
  title: string
  explanation: string
  value?: '' | string
  onChange: (event: SelectChangeEvent<string>) => void
  options: { label: string; value: string }[]
  sx?: SxProps
}

const SelectInput: React.FC<Props> = ({
  id,
  title,
  explanation,
  value,
  onChange,
  options,
  sx,
}) => {
  const jsxOptionsElements = options.map(item => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    )
  })

  const theme = useTheme()

  return (
    <Box id={id} sx={{ my: theme.spacing(2), ...sx }}>
      <Typography component={'h3'} variant="h6" sx={{ m: 0 }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ m: 0 }}>
        {explanation}
      </Typography>
      <FormControl sx={{ minWidth: '300px' }} size="small">
        <InputLabel id={`${title}-label`}>{title}</InputLabel>
        <Select
          labelId={`${title}-label`}
          id={`${title}-select`}
          label={title}
          onChange={onChange}
          value={value}
        >
          {jsxOptionsElements}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectInput
