import React from 'react'
import { Box, Dialog, IconButton, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'

interface Props {
  children: React.ReactNode
  isDialogOpen: boolean
  onCloseDialog: () => void
}

const AppDialog: React.FC<Props> = ({
  children,
  isDialogOpen,
  onCloseDialog,
}) => {
  const theme = useTheme()
  return (
    <Dialog open={isDialogOpen}>
      <Box
        sx={{
          paddingX: theme.spacing(2),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(4),
          zIndex: 1,
        }}
      >
        <Box textAlign={'right'}>
          <IconButton onClick={onCloseDialog}>
            <Close />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Dialog>
  )
}

export default AppDialog
