import React, { useContext, useEffect } from 'react'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import { useGetEvidencesQuery } from '../services/evidences'
import ErrorView from './ErrorView'
import { Evidence } from '../types/interfaces'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import UniversalTabs from '../components/ui/UniversalTabs/UniversalTabs'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import { appActions } from '../store/App/app-slice'
import { UploadEvidenceContext } from '../contexts/UploadEvidenceContext/uploadEvidence-context'
import EvidenceTabel from '../components/evidenceLibrary/EvidenceTabel/EvidenceTabel'
import UniversalButton from '../components/ui/Buttons/UniversalButton'
import AddIcon from '@mui/icons-material/Add'
import DialogAddEvidence from '../components/evidenceLibrary/AddPublicOrPrivateEvidenceSection/DialogAddEvidence/DialogAddEvidence'
import EvidenceFilters from '../components/evidenceLibrary/EvidenceFilters/EvidenceFilters'
import { EvidenceLibraryContext } from '../contexts/EvidenceLibraryContext/evidence-library-context'
import LoadingInsideComponent from '../components/ui/LoadingInsideComponent/LoadingInsideComponent'
import EngineeringIcon from '@mui/icons-material/Engineering'

const EvidenceLibrary: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { role } = useAppSelector(state => state.user)
  const {
    state: { isAddEvidenceModalOpen },
    actions: {
      onClickAddOrUploadDocumentButton,
      onClickParseAllCompaniesEvidence,
    },
  } = useContext(UploadEvidenceContext)

  const {
    state: { isLoadingEvidenceData },
    actions: { onClickOpenUploadMode },
  } = useContext(EvidenceLibraryContext)

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Evidence'))
  }, [])

  const isViewer = role === 'VIEWER'

  const {
    eng: {
      views: {
        evidenceLibrary: { heading },
      },
    },
  } = content

  let mainContent: JSX.Element | null = null

  if (isLoadingEvidenceData) {
    return <LoadingInsideComponent />
  }

  return (
    <MainContentContainer
      maxWidth="xl"
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(5),
        }}
      >
        <EvidenceFilters />
        <Box>
          {role === 'STUFF' && (
            <UniversalButton
              onClick={onClickParseAllCompaniesEvidence}
              startIcon={<EngineeringIcon />}
              label="Parse All Evidence"
            />
          )}
          {!isViewer && (
            <UniversalButton
              onClick={onClickOpenUploadMode}
              startIcon={<AddIcon />}
              label="Add Evidence"
              sx={{ ml: theme.spacing(4) }}
            />
          )}
        </Box>
      </Box>
      <EvidenceTabel />
      <DialogAddEvidence />
    </MainContentContainer>
  )
}

export default EvidenceLibrary
