import React, { useEffect, useReducer } from 'react'
import { Box } from '@mui/material'
import { SupportiveEvidenceContext } from './supportive-evidence-contex'
import {
  SupportiveEvidenceContextSchema,
  SupportiveEvidenceState,
} from './supportiveEvidenceInterfaces'
import { initialSupportiveEvidenceState } from './initialSupportiveEvidenceState'
import { useGetAccessToFileMutation } from '../../services/file'
import { FileDetails, SupportiveEvidence } from '../../types/interfaces'
import { useDeleteSupportiveEvidencesMutation } from '../../services/supportiveEvidences'
import { useAppDispatch } from '../../hooks/hooks'
import { appActions } from '../../store/App/app-slice'

enum SupportiveEvidenceActionKind {
  TOGGLE_EXTENDED = 'TOGGLE_EXTENDED',
  TOGGLE_IS_REMOVE_DIALOG_OPEN = 'TOGGLE_IS_REMOVE_DIALOG_OPEN',
  SET_PDF_PREVIEW_IS_SHOWN = 'SET_PDF_PREVIEW_IS_SHOWN',
  SET_IS_ERROR_IN_PREVIEW_OF_PDF_FROM_COMPANYS_SERVER = 'SET_IS_ERROR_IN_PREVIEW_OF_PDF_FROM_COMPANYS_SERVER',
  SET_IS_ERROR_IN_DISPLAYING_PDF_SCREENSHOT = 'SET_IS_ERROR_IN_DISPLAYING_PDF_SCREENSHOT',
}

interface SupportiveEvidenceAction {
  type: SupportiveEvidenceActionKind
  payload: string | boolean
}

const reducerFunction = (
  state: SupportiveEvidenceState,
  action: SupportiveEvidenceAction
): SupportiveEvidenceState => {
  const { type, payload } = action
  switch (type) {
    case SupportiveEvidenceActionKind.TOGGLE_EXTENDED:
      return {
        ...state,
        isExtended: !state.isExtended,
      }
    case SupportiveEvidenceActionKind.TOGGLE_IS_REMOVE_DIALOG_OPEN:
      return {
        ...state,
        isRemoveEvidenceDialogOpen: !state.isRemoveEvidenceDialogOpen,
      }
    case SupportiveEvidenceActionKind.SET_IS_ERROR_IN_PREVIEW_OF_PDF_FROM_COMPANYS_SERVER:
      return {
        ...state,
        isErrorInPreviewPdfFromCompanysServer: payload as boolean,
      }
    case SupportiveEvidenceActionKind.SET_PDF_PREVIEW_IS_SHOWN:
      return {
        ...state,
        isPdfPreviewShown: payload as boolean,
      }
    case SupportiveEvidenceActionKind.SET_IS_ERROR_IN_DISPLAYING_PDF_SCREENSHOT:
      return {
        ...state,
        isErrorInDisplayingPdfScreenshot: payload as boolean,
      }
    default:
      return state
  }
}

const SupportiveEvidenceContextProvider: React.FC<
  SupportiveEvidence & { children?: React.ReactNode }
> = ({ children, evidence_chunk, id, evidence, image }) => {
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialSupportiveEvidenceState
  )

  const [
    getAccessToEvidenceFile,
    {
      // isError: isErrorInGetAccessToEvidenceFile,
      isSuccess: getAccessToEvidenceFileIsSuccess,
      // isLoading: isGetAccessToEvidenceFileLoading,
      data: dataToAccesFile,
      // reset: resetGetAccessToEvidence,
    },
  ] = useGetAccessToFileMutation()

  const [
    deleteSupportiveEvidence,
    {
      isError: isErrorDeleteSuppEvi,
      isSuccess: isSuccessDeleteSuppEvi,
      isLoading: isLoadingDeleteSuppEvi,
    },
  ] = useDeleteSupportiveEvidencesMutation()

  const dispatchStore = useAppDispatch()

  let fileDetails: FileDetails | null = null

  if (evidence) {
    const { file } = evidence
    fileDetails = file
  }

  useEffect(() => {
    if (isErrorDeleteSuppEvi) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't delete supportive evidence. Please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
      return () => {
        dispatchStore(appActions.hideSnackbarNotification())
      }
    }
  }, [isErrorDeleteSuppEvi])

  useEffect(() => {
    if (isSuccessDeleteSuppEvi) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Supportive evidence succesful deleted`,
          type: 'success',
        })
      )
    }
  }, [isSuccessDeleteSuppEvi])

  useEffect(() => {
    if (getAccessToEvidenceFileIsSuccess && dataToAccesFile) {
      const { url: urlWithAccess, path } = dataToAccesFile

      let isPdfFile: boolean = false
      if (fileDetails && path.lastIndexOf('.pdf') !== -1) {
        isPdfFile = true
      }
      window.open(urlWithAccess, isPdfFile ? '_blank' : '_self', 'noreferrer')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToEvidenceFileIsSuccess, dataToAccesFile])

  useEffect(() => {
    if (state.isErrorInPreviewPdfFromCompanysServer && image) {
      dispatch({
        type: SupportiveEvidenceActionKind.SET_PDF_PREVIEW_IS_SHOWN,
        payload: true,
      })
    } else {
      dispatch({
        type: SupportiveEvidenceActionKind.SET_PDF_PREVIEW_IS_SHOWN,
        payload: false,
      })
    }
  }, [state.isErrorInPreviewPdfFromCompanysServer, image])

  const onToogleExtendedHandler = () => {
    dispatch({
      type: SupportiveEvidenceActionKind.TOGGLE_EXTENDED,
      payload: '',
    })
  }

  const onClickEvidenceHeadingHandler = () => {
    if (fileDetails) {
      const { id } = fileDetails
      getAccessToEvidenceFile(id)
    }
  }

  const onClickRemoveSupportiveEvidenceAprovalButtonHandler = () => {
    deleteSupportiveEvidence(id)
  }

  const onClickRemoveEvidenceCheckboxHandler = () => {
    dispatch({
      type: SupportiveEvidenceActionKind.TOGGLE_IS_REMOVE_DIALOG_OPEN,
      payload: '',
    })
  }

  const onErrorInPreviewPdfFromCompanysServerHandler = () => {
    dispatch({
      type: SupportiveEvidenceActionKind.SET_IS_ERROR_IN_PREVIEW_OF_PDF_FROM_COMPANYS_SERVER,
      payload: true,
    })
    dispatch({
      type: SupportiveEvidenceActionKind.SET_PDF_PREVIEW_IS_SHOWN,
      payload: true,
    })
  }

  const onErrorInDisplayingPdfScreenshotHandler = () => {
    dispatch({
      type: SupportiveEvidenceActionKind.SET_IS_ERROR_IN_DISPLAYING_PDF_SCREENSHOT,
      payload: true,
    })
    dispatch({
      type: SupportiveEvidenceActionKind.SET_PDF_PREVIEW_IS_SHOWN,
      payload: false,
    })
  }

  const onShowPdfPreviewHandler = () => {
    dispatch({
      type: SupportiveEvidenceActionKind.SET_PDF_PREVIEW_IS_SHOWN,
      payload: true,
    })
  }

  const ctxObject: SupportiveEvidenceContextSchema = {
    state: state,
    results: {
      deleteSupportiveEvidence: {
        isError: isErrorDeleteSuppEvi,
        isLoading: isLoadingDeleteSuppEvi,
        isSuccess: isSuccessDeleteSuppEvi,
      },
    },
    actions: {
      onToggleExtedned: onToogleExtendedHandler,
      onClickEvidenceHeading: onClickEvidenceHeadingHandler,
      onClickRemoveEvidenceCheckbox: onClickRemoveEvidenceCheckboxHandler,
      onClickRemoveSupportiveEvidenceAprovalButton:
        onClickRemoveSupportiveEvidenceAprovalButtonHandler,
      onErrorInPreviewPdfFromCompanysServer:
        onErrorInPreviewPdfFromCompanysServerHandler,
      onShowPdfPreview: onShowPdfPreviewHandler,
      onErrorInDisplayingPdfScreenshot: onErrorInDisplayingPdfScreenshotHandler,
    },
  }
  return (
    <SupportiveEvidenceContext.Provider value={ctxObject}>
      {children}
    </SupportiveEvidenceContext.Provider>
  )
}

export default SupportiveEvidenceContextProvider
