import React, { useContext, useEffect, useState } from 'react'
import { Box, Pagination, Stack, Typography, useTheme } from '@mui/material'
import { Evidence } from '../../../types/interfaces'
import content from '../../../assets/content/content'
import EvidenceLibraryItem from './EvidenceLibraryItem/EvidenceLibraryItem'
import UniversalTable from '../../ui/tables/UniversalTable/UniversalTable'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CellInTable from '../../ui/tables/CellInTable/CellInTable'
import NewDesignUniversalTable from '../../ui/tables/NewDesignUniversalTable/NewDesignUniversalTable'
import HeadTableCell from '../../ui/tables/NewDesignUniversalTable/HeadTableCell/HeadTableCell'
import { EvidenceLibraryContext } from '../../../contexts/EvidenceLibraryContext/evidence-library-context'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/hooks'

const EvidenceTabel: React.FC = () => {
  const theme = useTheme()
  const [isDeleteSuccessShown, setIsDeleteSuccessShown] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [sortBy, setSortBy] = useState<keyof Evidence>('updated_at')
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DES'>('DES')

  const {
    state: { filteredEvidence, filterByTypeOfEvidence, filterBySearch },
  } = useContext(EvidenceLibraryContext)

  const { role } = useAppSelector(state => state.user)

  const {
    eng: {
      components: {
        evidenceTable: { tableHeaderCells },
      },
    },
  } = content

  const navigate = useNavigate()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  useEffect(() => {
    if (isDeleteSuccessShown) {
      const timer = setTimeout(() => {
        setIsDeleteSuccessShown(false)
      }, 6000)

      return () => clearTimeout(timer)
    }
  }, [isDeleteSuccessShown])

  let mainContent = <span></span>
  let isAnyFilterSelected = false

  if (filterByTypeOfEvidence !== null || filterBySearch !== null) {
    isAnyFilterSelected = true
  }
  const showSuccesNotificationHandler = () => {
    setIsDeleteSuccessShown(true)
  }

  const changePageHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPageIndex(value - 1)
  }

  const onClickHeaderCellHandler = (key: string) => {
    setSortBy(key as keyof Evidence)
    if (key === sortBy) {
      setSortOrder(prevState => {
        if (prevState === 'ASC') {
          return 'DES'
        } else {
          return 'ASC'
        }
      })
    }
  }

  const numberOfItemOnOnePage = 10
  const quantityOfPages = Math.ceil(
    filteredEvidence.length / numberOfItemOnOnePage
  )

  // if (filteredEvidence.length === 0) {
  //   return null
  // }

  let sortingIcon: JSX.Element

  if (sortOrder === 'ASC') {
    sortingIcon = <ArrowDropUpIcon />
  } else {
    sortingIcon = <ArrowDropDownIcon />
  }

  let tableHeaderCellsIncludingRole: {
    label: string
    id: string
    keyToSort: string | null
  }[] = []

  if (role !== 'STUFF') {
    tableHeaderCellsIncludingRole = tableHeaderCells.filter(headerItem => {
      const { id } = headerItem
      return id !== 'parsed'
    })
  } else {
    tableHeaderCellsIncludingRole = [...tableHeaderCells]
  }

  const tableHeadCellsJsx = tableHeaderCellsIncludingRole.map(
    (headerCell, index) => {
      const { id, label, keyToSort } = headerCell
      return (
        <HeadTableCell
          key={`${id}_${index}`}
          align={index === 0 ? 'left' : 'center'}
          sx={{
            width: index === 0 ? '50%' : 'default',
            cursor: keyToSort ? 'pointer' : 'text',
            userSelect: 'none',
            py: theme.spacing(1),
          }}
          onClick={
            keyToSort ? () => onClickHeaderCellHandler(keyToSort) : () => {}
          }
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: index === 0 ? 'flex-start' : 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2" sx={{ display: 'flex', m: 0 }}>
              {label}
            </Typography>
            {keyToSort === sortBy && sortingIcon}
          </Box>
        </HeadTableCell>
      )
    }
  )

  function sortData(data: Evidence[]): Evidence[] {
    if (sortBy === 'year_applicable_to') {
      return data.sort((a, b) => {
        const yearA = a.year_applicable_from || 0
        const yearB = b.year_applicable_from || 0

        if (yearA > yearB) {
          return sortOrder === 'ASC' ? 1 : -1
        } else if (yearA < yearB) {
          return sortOrder === 'ASC' ? -1 : 1
        } else {
          return 0
        }
      })
    }

    if (sortBy === 'category') {
      return data.sort((a, b) => {
        if (a.category && b.category) {
          return a.category.localeCompare(b.category)
        } else if (a.category) {
          return sortOrder === 'ASC' ? 1 : -1
        } else if (b.category) {
          return sortOrder === 'ASC' ? -1 : 1
        }

        // If evidence category doesn't exist, sort by URL
        if (a.url && b.url) {
          return a.url.localeCompare(b.url) * (sortOrder === 'ASC' ? 1 : -1)
        } else if (a.url) {
          return sortOrder === 'ASC' ? 1 : -1 // a comes first
        } else if (b.url) {
          return sortOrder === 'ASC' ? -1 : 1 // b comes first
        }

        // If URL doesn't exist, sort by file
        if (a.file && b.file) {
          return a.file.path.localeCompare(b.file.path)
        } else if (a.file) {
          return -1 // a comes first
        } else if (b.file) {
          return 1 // b comes first
        }
        return 0
      })
    }

    return data.sort((a, b) => {
      const orderFactor = sortOrder === 'ASC' ? 1 : -1

      // Check if the property is a number and use numeric comparison
      if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
        return (a[sortBy]! as number) - (b[sortBy]! as number) * orderFactor
      }

      // If the property is not a number, use string comparison
      return a[sortBy] && b[sortBy]
        ? a[sortBy]!.toString().localeCompare(b[sortBy]! as string) *
            orderFactor
        : 0
    })
  }

  const dataAfterSorting = [...sortData(filteredEvidence)]

  const evidenceJsxElements = dataAfterSorting.map(eviItem => {
    return (
      <EvidenceLibraryItem
        key={eviItem.id}
        {...eviItem}
        showDelEviSuccess={showSuccesNotificationHandler}
      />
    )
  })

  const dateToDisplay = evidenceJsxElements.slice(
    currentPageIndex * numberOfItemOnOnePage,
    (currentPageIndex + 1) * numberOfItemOnOnePage
  )

  if (isAnyFilterSelected && filteredEvidence.length === 0) {
    mainContent = (
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          py: theme.spacing(2.5),
          px: theme.spacing(3.5),
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Typography sx={{ m: 0, marginBottom: theme.spacing(1) }}>
          There are no evidence that match your filters. Please try filtering
          again.
        </Typography>
        <Stack direction={'row'}>
          <UniversalButton
            size="large"
            stylingType={6}
            onClick={() => navigate('/evidence-library')}
            sx={{ m: 0 }}
          >
            <Typography sx={{ m: 0, color: theme.palette.primary.main }}>
              Reset filters
            </Typography>
          </UniversalButton>
        </Stack>
      </Box>
    )
  } else {
    mainContent = (
      <>
        <NewDesignUniversalTable
          headRowBgColor={theme.palette.grey[100]}
          tableHeaders={tableHeadCellsJsx}
          tableBodyRows={dateToDisplay}
        />
        {quantityOfPages > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: theme.spacing(3),
            }}
          >
            <Pagination
              count={quantityOfPages}
              size="large"
              onChange={changePageHandler}
            />
          </Box>
        )}
      </>
    )
  }

  return mainContent
}

export default EvidenceTabel
