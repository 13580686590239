import { UploadSurveyActions } from './uploadSurveyInterfaces'

const initialUploadSurveyActions: UploadSurveyActions = {
  onClickBackButton: () => {},
  onClickContinueButton: () => {},
  onChangeFileTitleInput: () => {},
  onChangeUploadSurveyType: () => {},
  onChangeAudienceSelectInput: () => {},
  onChangeCommentInput: () => {},
  onChangeCustomInternalInput: () => {},
  onChangeEvidenceDepthSelectInput: () => {},
  onChangeAnswerLengthSelectInput: () => {},
  onChangeEvidenceTypeSelectInput: () => {},
  onChangeSurveyDeadline: () => {},
  onChangeSurveyFocusInput: () => {},
  onChangeSurveySourceInput: () => {},
  onClickSubmitForm: () => {},
}

export default initialUploadSurveyActions
