import React from 'react'
import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import tilteBackgroundPath from '../../../assets/images/titleBackground_cropped.png'
import NavLinks from '../../NavLinks/NavLinks'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import TextOnDark from '../Typographies/TextOnDark'

const DecorationBar: React.FC = () => {
  const theme = useTheme()

  const isSmallViewDevices = useMediaQuery(theme.breakpoints.down(250))

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        minHeight: '172px',
      }}
    >
      <img
        src={tilteBackgroundPath}
        alt="title background"
        style={{
          position: 'absolute',
          zIndex: '-2',
          maxWidth: '100%',
          height: isSmallViewDevices ? '100%' : 'auto',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          zIndex: '-1',
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.primary.dark,
          opacity: 0.8,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextOnDark component={'h1'} variant={'h2'}>
          Ask Any ESG Question
        </TextOnDark>
      </Box>
    </Box>
  )
}

export default DecorationBar
