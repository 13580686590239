import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Box, CircularProgress } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { useMediaQuery } from 'react-responsive'
import NoSupportForMobileDevices from '../views/NoSupportForMobileDevices'
import UnauthorisedHeader from '../components/UnauthorisedHeader/UnauthorisedHeader'

const Root: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth0()
  const { pathname } = useLocation()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 765px)',
  })

  if (
    !isAuthenticated &&
    pathname !== '/' &&
    pathname !== '/terms-and-conditions' &&
    pathname !== '/sign-up' &&
    pathname !== '/log-in'
  ) {
    return <Navigate to={'/'} />
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'space-between',
        }}
      >
        <UnauthorisedHeader />
        {isLoading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : isDesktopOrLaptop ? (
          <Outlet />
        ) : (
          <NoSupportForMobileDevices />
        )}
        <Footer />
      </Box>
    </>
  )
}

export default Root
