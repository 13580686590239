import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Evidence } from '../types/interfaces'
import type { RootState } from '../store/store'

export const evidencesApi = createApi({
  reducerPath: 'evidencesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/evidences/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['Evidences'],
  endpoints: build => ({
    getEvidences: build.query<Evidence[], void>({
      query: () => ({
        url: `/`,
      }),
      providesTags: ['Evidences'],
    }),
    addEvidenceLinkOrFileToEviLibrary: build.mutation<
      Evidence,
      { evidence: Partial<Evidence>; fileData?: File; friendlyName?: string }
    >({
      query: evidenceData => {
        const form = new FormData()
        const { evidence, fileData, friendlyName } = evidenceData
        const { type, url } = evidence
        if (type) {
          form.append('evidence_type', type)
        }
        if (url) {
          form.append('url', url)
        }
        if (fileData) {
          form.append('file', fileData)
          form.append('original_file_name', fileData.name)
        }
        if (friendlyName) {
          form.append('friendly_name', friendlyName)
        }

        const body = form
        return {
          url: `/`,
          method: 'POST',
          body: body,
        }
      },
      invalidatesTags: ['Evidences'],
    }),
    deleteEvidenceLink: build.mutation<
      { message: string; unique_com_evidence_id: string },
      Evidence['id']
    >({
      query: evidenceId => ({
        url: `/${evidenceId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Evidences'],
    }),
  }),
})

export const {
  useGetEvidencesQuery,
  useAddEvidenceLinkOrFileToEviLibraryMutation,
  useDeleteEvidenceLinkMutation,
} = evidencesApi
