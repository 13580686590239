import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserRole } from '../../types/interfaces'

interface UserState {
  token: string | undefined
  hasProfileDetailsColected: boolean | undefined
  userEmail: string | null
  username: string | null
  companyId: string | null
  userId: string | null
  companyName: string | null
  areEvidenceProvided: null | boolean
  role: UserRole | null
  isAskQuestionModeBlocked: boolean
  isSuperUser: boolean
}

const initialState: UserState = {
  token: undefined,
  hasProfileDetailsColected: undefined,
  userEmail: null,
  username: null,
  userId: null,
  companyId: null,
  companyName: null,
  areEvidenceProvided: null,
  role: null,
  isAskQuestionModeBlocked: true,
  isSuperUser: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | UserRole>) => {
      state.token = action.payload
    },
    setProfileDetailsCollected: (state, action: PayloadAction<boolean>) => {
      state.hasProfileDetailsColected = action.payload
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload
    },
    setUserRole: (state, action: PayloadAction<UserRole>) => {
      state.role = action.payload
    },
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload
    },
    setEvidenceProvided: state => {
      state.areEvidenceProvided = true
    },
    setEvidenceNotProvided: state => {
      state.areEvidenceProvided = false
    },
    unblockAskQuestionMode: state => {
      state.isAskQuestionModeBlocked = false
    },
    setSuperUser: state => {
      state.isSuperUser = true
    },
  },
})

export const userActions = userSlice.actions

export default userSlice.reducer
