import React, { useContext, useEffect } from 'react'
import {
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  formHelperTextClasses,
  useTheme,
  Link,
  CircularProgress,
} from '@mui/material'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import ResponseRecomendationSection from '../ResponseRecomendation/ResponseRecomendationSection'
import Close from '@mui/icons-material/Close'
import { GeneratedResponseContext } from '../../../contexts/GeneratedResponseContext/generated-response-context'
import { processFileNameFromUrl } from '../../../utils/utils'
import { OpenInNew } from '@mui/icons-material'
import '@mdxeditor/editor/style.css'
import {
  MDXEditor,
  headingsPlugin,
  MDXEditorMethods,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  tablePlugin,
  linkPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  InsertTable,
  ListsToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  diffSourcePlugin,
} from '@mdxeditor/editor'

interface Props {}

const GeneratedAnswerSection: React.FC<Props> = () => {
  const theme = useTheme()
  const ref = React.useRef<MDXEditorMethods>(null)
  const {
    state: {
      supportiveEvidences,
      responseInputValue,
      isInfoRegardingTypeOfEvidenceDisplayed,
      isEditResponseMode,
      isResIncludePrivateEvi,
      isLoadingInRegenerateResponse,
      responseBeforeEdit,
    },
    actions: {
      onResponseInputChange,
      onResponseMarkdownInputChange,
      onClickSaveToMyAnswersButton,
      onClickCloseInfoRegardingTypeOfEvidence,
      switchOnEditMode,
      switchOffEditMode,
      onClickCancelEditResponse,
      onClickOpenPrivateEvidence,
      onClickRegenerateResponse,
    },
  } = useContext(GeneratedResponseContext)

  const processedMarkdown = markDown => {
    return markDown.replace(/\[(.*?)\]\((.*?)\)/g, (match, text, href) => {
      return `<a style="display:inline; color:black;"href="${href}" target="_blank">${text}</a>`
    })
  }

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const supportiveEvidenceFilteredBySource = supportiveEvidences.filter(
    supEviItem => supEviItem.evidence_chunk.source === 'EVIDENCE'
  )

  const supportiveEvidenceFilteredByPrivateType =
    supportiveEvidenceFilteredBySource.filter(
      supEviItem =>
        supEviItem.evidence_chunk.type === 'PRIVATE' &&
        supEviItem.evidence_chunk.source === 'EVIDENCE'
    )

  let typeOfEvidenceContent: JSX.Element = (
    <Box
      sx={{
        borderBottom: `1px solid ${borderColor}`,
        px: theme.spacing(3),
        py: theme.spacing(2),
        backgroundColor: theme.palette.secondary.contrastText,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography>
        This answer was generated using only publicly available documents.
      </Typography>
      <IconButton onClick={onClickCloseInfoRegardingTypeOfEvidence}>
        <Close />
      </IconButton>
    </Box>
  )
  if (isResIncludePrivateEvi) {
    typeOfEvidenceContent = (
      <Box
        sx={{
          borderBottom: `1px solid ${borderColor}`,
          px: theme.spacing(3),
          py: theme.spacing(2),
          backgroundColor: theme.palette.secondary.contrastText,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexGrow: 1,
        }}
      >
        <Typography>
          This answer was generated using public and private documents.
        </Typography>
        <IconButton onClick={onClickCloseInfoRegardingTypeOfEvidence}>
          <Close />
        </IconButton>
      </Box>
    )
  } else if (isLoadingInRegenerateResponse) {
    typeOfEvidenceContent = (
      <Box
        sx={{
          ':not(:last-child)': {
            borderBottom: `1px solid ${borderColor}`,
          },
          px: theme.spacing(3),
          py: theme.spacing(2),
          backgroundColor: theme.palette.secondary.contrastText,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexGrow: 1,
        }}
      >
        <Box>
          <Typography>
            This answer is being refined with private evidence.
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        </Box>
        <IconButton onClick={onClickCloseInfoRegardingTypeOfEvidence}>
          <Close />
        </IconButton>
      </Box>
    )
  } else if (supportiveEvidenceFilteredByPrivateType.length > 0) {
    let privateEvidenceListWhichCanBeUsedInNewResponseJSX =
      supportiveEvidenceFilteredByPrivateType.map(supEviItem => {
        const { evidence_chunk, page_number } = supEviItem
        const { evidence, id } = evidence_chunk

        let headingContent = ''
        let evidenceHeading: JSX.Element = <></>

        if (evidence) {
          const { category, file, url, id } = evidence

          if (category) {
            headingContent = category
          } else if (file && file.friendly_name) {
            headingContent = file.friendly_name
          } else if (file && file.original_file_name) {
            headingContent = file.original_file_name
          } else if (file && file.path) {
            headingContent = processFileNameFromUrl(file.path)
          } else if (url) {
            headingContent = url
          } else {
            headingContent = id
          }

          if (url) {
            evidenceHeading = (
              <Link
                href={page_number ? `${url}#page=${page_number}` : url}
                target="_blank"
                key={supEviItem.id}
                sx={{
                  fontSize: '20px',
                  lineHeight: '25px',
                  wordBreak: 'break-all',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {headingContent} <OpenInNew sx={{ ml: '10px' }} />
              </Link>
            )
          } else if (file) {
            evidenceHeading = (
              <Typography
                key={supEviItem.id}
                onClick={() => onClickOpenPrivateEvidence(file.id)}
                component={'h3'}
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: 400,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {headingContent} <OpenInNew sx={{ ml: '10px' }} />
              </Typography>
            )
          } else {
            evidenceHeading = (
              <Typography key={supEviItem.id}>{headingContent}</Typography>
            )
          }
        } else {
          headingContent = id
          evidenceHeading = (
            <Typography key={supEviItem.id}>{headingContent}</Typography>
          )
        }
        return evidenceHeading
      })
    typeOfEvidenceContent = (
      <Box
        sx={{
          borderBottom: `1px solid ${borderColor}`,
          px: theme.spacing(3),
          py: theme.spacing(2),
          backgroundColor: theme.palette.secondary.contrastText,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexGrow: 1,
        }}
      >
        <Box>
          <Typography>
            This answer was generated using only publicly available documents.
            We've identified the following private evidence relevant to your
            question.
          </Typography>
          <Box>
            {privateEvidenceListWhichCanBeUsedInNewResponseJSX}
            {/* {JSON.stringify(supportiveEvidenceFilteredByPrivateType)} */}
          </Box>

          <Typography>
            Would you like to regenerate the answer by applying this evidence?
          </Typography>
          <Stack
            direction={'row'}
            spacing={theme.spacing(2)}
            alignItems={'center'}
          >
            <UniversalButton
              label="Apply"
              stylingType={1}
              loading={isLoadingInRegenerateResponse}
              onClick={onClickRegenerateResponse}
            />
            {!isLoadingInRegenerateResponse && (
              <UniversalButton
                stylingType={1}
                label="Cancel"
                onClick={onClickCloseInfoRegardingTypeOfEvidence}
              />
            )}
          </Stack>
        </Box>
        <IconButton>
          <Close />
        </IconButton>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 5fr',
        border: `1px solid ${borderColor}`,
        borderTop: 0,
      }}
    >
      <Box
        id="leftColumnInFirstRow"
        sx={{
          px: theme.spacing(3),
          py: theme.spacing(2),
          borderRight: `1px solid ${borderColor}`,
        }}
      >
        <Typography textAlign={'center'} variant="h6">
          Answer
        </Typography>
        {!isEditResponseMode && (
          <Box textAlign={'center'}>
            <UniversalButton
              label="Modify"
              stylingType={2}
              onClick={switchOnEditMode}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!isLoadingInRegenerateResponse && (
          <Box
            sx={{
              px: isEditResponseMode ? theme.spacing(4) : theme.spacing(1),
              py: isEditResponseMode ? theme.spacing(4) : theme.spacing(0.5),
              backgroundColor: isEditResponseMode
                ? theme.palette.secondary.main
                : 'inherit',
            }}
          >
            {isEditResponseMode ? (
              <Box id="edit">
                <span></span>
                <MDXEditor
                  ref={ref}
                  markdown={processedMarkdown(responseInputValue)}
                  readOnly={false}
                  onChange={onResponseMarkdownInputChange}
                  contentEditableClassName={'edit'}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    tablePlugin(),
                    linkPlugin(),
                    diffSourcePlugin({
                      diffMarkdown: responseBeforeEdit,
                      viewMode: 'rich-text',
                      readOnlyDiff: true,
                    }),
                    toolbarPlugin({
                      toolbarContents: () => (
                        <>
                          <UndoRedo />
                          <BoldItalicUnderlineToggles />
                          <BlockTypeSelect />
                          <InsertTable />
                          <ListsToggle />
                          <CreateLink />
                          <DiffSourceToggleWrapper>
                            <UndoRedo />
                          </DiffSourceToggleWrapper>
                        </>
                      ),
                    }),
                  ]}
                />
              </Box>
            ) : (
              <Box>
                <MDXEditor
                  ref={ref}
                  markdown={processedMarkdown(responseInputValue)}
                  readOnly={true}
                  onChange={onResponseMarkdownInputChange}
                  contentEditableClassName={'prose'}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    tablePlugin(),
                    linkPlugin(),
                  ]}
                />
              </Box>
            )}

            {isEditResponseMode && (
              <Stack
                direction={'row'}
                spacing={theme.spacing(4)}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ marginTop: theme.spacing(2) }}
              >
                <UniversalButton
                  label="Save"
                  // loading={isSaveAnswerLoading}
                  onClick={switchOffEditMode}
                  // disabled={isSaveAnswerButtonDisabled}
                />
                <UniversalButton
                  label="Cancel"
                  stylingType={4}
                  onClick={onClickCancelEditResponse}
                />
              </Stack>
            )}

            {/* <Box
              sx={{
                flexGrow: 1,
                px: theme.spacing(3),
                py: theme.spacing(2),
                bgcolor: isEditResponseMode
                  ? theme.palette.secondary.main
                  : 'inherit',
              }}
            >
              {isEditResponseMode ? (
                <Box>
                  <TextField
                    fullWidth
                    multiline
                    value={responseInputValue}
                    onChange={onResponseInputChange}
                    minRows={4}
                    sx={{
                      div: {
                        backgroundColor: 'white',
                        color: theme.palette.primary.dark,
                      },
                    }}
                  />
                  <Stack
                    direction={'row'}
                    spacing={theme.spacing(4)}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <UniversalButton
                      label="Save"
                      // loading={isSaveAnswerLoading}
                      onClick={switchOffEditMode}
                      // disabled={isSaveAnswerButtonDisabled}
                    />
                    <UniversalButton
                      label="Cancel"
                      stylingType={4}
                      onClick={onClickCancelEditResponse}
                    />
                  </Stack>
                </Box>
              ) : (
                <Typography>{responseInputValue}</Typography>
              )}
            </Box> */}
          </Box>
        )}
        {!isLoadingInRegenerateResponse && <ResponseRecomendationSection />}
      </Box>
    </Box>
  )
}

export default GeneratedAnswerSection
