import { createContext } from 'react'
import { CdpSmeSeasonalSurveyDetailsContextSchema } from './cdpSmeSeasonalSurveyDetailsInterfaces'
import initialCdpSmeSeasonalSurveyDetailsState from './initialCdpSmeSeasonalSurveyDetailsState'
import initialCdpSmeSeasonalSurveyDetailsActions from './initialCdpSmeSeasonalSurveyDetailsActions'

const CdpSmeSeasonalSurveyDetailsInitialContext: CdpSmeSeasonalSurveyDetailsContextSchema =
  {
    state: initialCdpSmeSeasonalSurveyDetailsState,
    actions: initialCdpSmeSeasonalSurveyDetailsActions,
  }

export const CdpSmeSeasonalSurveyDetailsContext = createContext(
  CdpSmeSeasonalSurveyDetailsInitialContext
)
