import { createContext } from 'react'
import { UploadSurveyContextSchema } from './uploadSurveyInterfaces'
import initialUploadSurveyState from './initialUploadSurveyState'
import initialUploadSurveyActions from './initialUploadSurveyActions'

const UploadSurveyInitialContext: UploadSurveyContextSchema = {
  state: initialUploadSurveyState,
  actions: initialUploadSurveyActions,
}

export const UploadSurveyContext = createContext(UploadSurveyInitialContext)
