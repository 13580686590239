import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  FormHelperText,
  TextField,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
interface Props {
  label: string
  options: { value: string; label: string }[]
  helper?: string
  sx?: SxProps
  onChange?: (newValue: string) => void
  currentValue?: string
  isRequired?: boolean
}

const SelectTargetAudienceInput: React.FC<Props> = ({
  label,
  options,
  sx,
  helper,
  onChange,
  currentValue,
  isRequired,
}) => {
  const isCustomFieldDisplied = currentValue === 'INTERNAL_DEPARTMENTS'

  const optionsJsxElements = options.map((option, index) => {
    const { label, value } = option
    return (
      <MenuItem key={`${index}_${label}`} value={value}>
        {label}
      </MenuItem>
    )
  })

  const handleChange = (event: SelectChangeEvent) => {
    if (onChange) {
      onChange(event.target.value as string)
    }
  }
  return (
    <>
      <FormControl sx={{ ...sx, div: { marginBottom: 0 } }}>
        <InputLabel size="small" id={`uni-select-label-${label}`}>
          {label}
        </InputLabel>
        <Select
          labelId={`uni-select-label-${label}`}
          id={`uni-select-label-${label}`}
          value={currentValue}
          label={label}
          onChange={handleChange}
          size="small"
          required={isRequired ? true : false}
        >
          {optionsJsxElements}
        </Select>
        {isCustomFieldDisplied && (
          <TextField
            id="customInternal"
            type="text"
            size="small"
            margin="none"
            label="Name of internal department"
          />
        )}
        {helper && (
          <FormHelperText sx={{ m: 0, marginBottom: 0 }}>
            {helper}
          </FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default SelectTargetAudienceInput
