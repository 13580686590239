import React from 'react'
import { Box, Typography } from '@mui/material'
import ApprovedLabel from '../../../../ui/Labels/ApprovedLabel'
import ReviewItem from './ReviewItem.tsx/ReviewItem'
import { useAppSelector } from '../../../../../hooks/hooks'

interface Props {
  borderStyle: string
  sourceId: string
  responses_unassigned: number
  responses_unreviewed: number
  responses_reviewed: number
  responses_approved: number
  responses_rejected_by_contributor: number
  responses_rejected_by_editor: number
}

const ReviewItems: React.FC<Props> = ({
  borderStyle,
  sourceId,
  responses_approved,
  responses_unreviewed,
  responses_reviewed,
  responses_unassigned,
  responses_rejected_by_contributor,
  responses_rejected_by_editor,
}) => {
  const { role } = useAppSelector(state => state.user)

  const isViewer = role === 'VIEWER'

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        div: {
          flexGrow: 1,
        },
        '>div:not(:last-child)': {
          borderBottom: borderStyle,
        },
      }}
    >
      {/* <Box>
        <Typography>item1</Typography>
      </Box>
      <Box>
        <Typography>item2</Typography>
      </Box>
      <Box>
        <Typography>item3</Typography>
      </Box> */}
      <ReviewItem
        borderStyle={borderStyle}
        quantity={responses_approved}
        type={'APPROVED'}
        link={`/answers-library/null/null/${encodeURI(
          sourceId
        )}/APPROVED/null/null/null/null`}
      />

      {!isViewer && (
        <ReviewItem
          borderStyle={borderStyle}
          quantity={responses_reviewed}
          type="REVIEWED"
          link={`/answers-library/null/null/${encodeURI(
            sourceId
          )}/REVIEWED/null/null/null/null`}
        />
      )}
      {!isViewer && (
        <ReviewItem
          borderStyle={borderStyle}
          quantity={responses_unreviewed}
          type="CHECKED_ASSIGNED"
          link={`/answers-library/null/null/${encodeURI(
            sourceId
          )}/CHECKED_ASSIGNED/null/null/null/null`}
        />
      )}
      {!isViewer && role !== 'CONTRIBUTOR' && (
        <ReviewItem
          borderStyle={borderStyle}
          quantity={responses_unassigned}
          type="CHECKED"
          link={`/answers-library/null/null/${encodeURI(
            sourceId
          )}/CHECKED/null/null/null/null`}
        />
      )}
      {!isViewer && (
        <ReviewItem
          borderStyle={borderStyle}
          quantity={responses_rejected_by_editor}
          type="REJECTED_REVIEWED"
          link={`/answers-library/null/null/${encodeURI(
            sourceId
          )}/REJECTED_REVIEWED/null/null/null/null`}
        />
      )}
      {!isViewer && (
        <ReviewItem
          borderStyle={borderStyle}
          quantity={responses_rejected_by_contributor}
          type="REJECTED_CHECKED"
          link={`/answers-library/null/null/${encodeURI(
            sourceId
          )}/REJECTED_CHECKED/null/null/null/null`}
        />
      )}
    </Box>
  )
}

export default ReviewItems
