import React from 'react'
import { CdpSmeSeasonalSurveyDetailsActions } from './cdpSmeSeasonalSurveyDetailsInterfaces'

const initialCdpSmeSeasonalSurveyDetailsActions: CdpSmeSeasonalSurveyDetailsActions =
  {
    onChangeCheckedAnswer: () => {},
    onSetCurrentQuestion: () => {},
  }

export default initialCdpSmeSeasonalSurveyDetailsActions
