import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  AnswerItem,
  IAnswerComment,
  IGeneratedResponse,
  IMyAnswer,
  SupportiveEvidence,
} from '../types/interfaces'
import type { RootState } from '../store/store'

export const supportiveEvidencesApi = createApi({
  reducerPath: 'supportiveEvidencesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/supportive_evidences`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['Supportive Evidences'],
  endpoints: build => ({
    getSupportiveEvidences: build.query<SupportiveEvidence[], AnswerItem['id']>(
      {
        query: reponseId => ({
          url: `/`,
          params: { response_id: reponseId },
        }),
        providesTags: ['Supportive Evidences'],
      }
    ),
    createSupportiveEvidences: build.mutation<
      string,
      {
        sentence: string
        page_number: string
        evidence_id: string
        response_id: string
      }
    >({
      query: body => ({
        url: `/`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Supportive Evidences'],
    }),
    deleteSupportiveEvidences: build.mutation<string, SupportiveEvidence['id']>(
      {
        query: supportiveEvidenceId => ({
          url: `/${supportiveEvidenceId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Supportive Evidences'],
      }
    ),
  }),
})

export const {
  useGetSupportiveEvidencesQuery,
  useCreateSupportiveEvidencesMutation,
  useDeleteSupportiveEvidencesMutation,
} = supportiveEvidencesApi
