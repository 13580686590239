import React, { ChangeEvent, useContext } from 'react'
import { useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { Box, Typography } from '@mui/material'
import './DateRangePicker.css'
import './Calendar.css'
import { AnswersLibraryContext } from '../../../../contexts/AnswersLibraryContext/answers-library-context'
import { useNavigate, useParams } from 'react-router-dom'
import { DateRangeValue } from '../../../../types/types'

interface Props {
  onInputChange?: (newValue: DateRangeValue) => void
  value?: DateRangeValue
}

const RangeDateInput: React.FC<Props> = ({ onInputChange, value }) => {
  const onChangeHandler = (newValue: DateRangeValue) => {
    if (onInputChange) {
      onInputChange(newValue)
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <DateRangePicker locale="en" onChange={onChangeHandler} value={value} />
      <Typography
        component={'span'}
        sx={{
          position: 'absolute',
          backgroundColor: 'white',
          top: '2px',
          left: '7px',
          paddingLeft: '5px',
          paddingRight: '5px',
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: '15px',
          lineHeight: '15px',
          m: 0,
        }}
      >
        Date of update range
      </Typography>
    </Box>
  )
}

export default RangeDateInput
