import { Navigate, useNavigate } from 'react-router-dom'
import Logo from '../../../assets/svg/Logos/EthicsAnswerLogoOnDark.svg'
import { useAuth0 } from '@auth0/auth0-react'

const LogoOnDark = () => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()
  const clickHandler = () => {
    if (isAuthenticated) {
      navigate('/dashboard')
    } else {
      window.location.replace('https://www.ethicsanswer.com')
    }
  }
  return (
    <img
      style={{
        maxWidth: '250px',
        marginTop: '18px',
        marginBottom: '18px',
        cursor: 'pointer',
      }}
      src={Logo}
      alt="EthicsAnswer Logo"
      onClick={clickHandler}
    />
  )
}

export default LogoOnDark
