import React from 'react'
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  SxProps,
  TextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

interface Props {
  onChangeTextField: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchInputValue: string
  sx: SxProps
}

const SearchInput: React.FC<Props> = ({
  onChangeTextField,
  searchInputValue,
  sx,
}) => {
  return (
    <TextField
      size="small"
      id="search"
      variant="outlined"
      sx={{ ...sx }}
      value={searchInputValue}
      onChange={onChangeTextField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchInput
