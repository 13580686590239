import React, { useContext, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
  Typography,
  useTheme,
  Link,
  Chip,
  IconButton,
} from '@mui/material'
import { AnswerPreviewContext } from '../../../../contexts/AnswerPreview/answer-preview-contex'
import UniversalDialog from '../../../ui/UniversalDialog/UniversalDialog'
import { useGetEvidencesQuery } from '../../../../services/evidences'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import UniversalButton from '../../../ui/Buttons/UniversalButton'
import { processFileNameFromUrl } from '../../../../utils/utils'
import { useGetAccessToFileMutation } from '../../../../services/file'
import { ArrowForwardIos, OpenInNew } from '@mui/icons-material'
import { Evidence } from '../../../../types/interfaces'

const MissingEvidenceModal: React.FC = () => {
  const {
    state: {
      isAddMissingEvidenceModalOpen,
      choosenMissingEvidenceId,
      addingMissingEvidenceStep,
      pageRangesInputValue,
      errorInPageRangesValidation,
      missingEvidenceSentenceInputValue,
    },
    createMissingEvidenceData: { isLoadingCreateMissingEvidence },
    actions: {
      onClickCloseAddMissingEvidenceModal,
      onSelectMissingEvidence,
      onClickCancelButtonInAddMissingEvidenceDialog,
      onChangeAddingMissingEvidenceStep,
      onSubmitCreateMissingEvidence,
      onChangePageRangeInput,
      onChangeMissingEvidenceSentenceInput,
    },
  } = useContext(AnswerPreviewContext)

  const {
    data: evidenceData,
    isSuccess: isSuccessEvidenceData,
    isLoading: isLoadingEvidenceData,
    isError: isErrorEvidenceData,
    error: errorEvidenceData,
  } = useGetEvidencesQuery()

  const [
    getAccessToEvidenceFile,
    {
      isError: isErrorInGetAccessToEvidenceFile,
      isSuccess: getAccessToEvidenceFileIsSuccess,
      isLoading,
      data,
      reset: resetGetAccessToEvidence,
    },
  ] = useGetAccessToFileMutation()

  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  useEffect(() => {
    if (getAccessToEvidenceFileIsSuccess && data) {
      const { url, path } = data

      let isPdfFile: boolean = false
      if (path && path.lastIndexOf('.pdf') !== -1) {
        isPdfFile = true
      }
      window.open(url, isPdfFile ? '_blank' : '_self', 'noreferrer')
    }
  }, [getAccessToEvidenceFileIsSuccess, data])

  const getEvidenceHeading = (evidence: Evidence): JSX.Element => {
    const { category, file, url, id } = evidence
    let evidenceLabel = ''
    let downloadButton = <></>

    if (category) {
      evidenceLabel = category
    } else if (file) {
      const { friendly_name, original_file_name, path } = file
      if (friendly_name) {
        evidenceLabel = friendly_name
      } else if (original_file_name) {
        evidenceLabel = original_file_name
      } else {
        evidenceLabel = processFileNameFromUrl(path)
      }
    } else if (url) {
      evidenceLabel = url
    } else {
      evidenceLabel = id
    }

    if (file) {
      downloadButton = (
        <Box
          onClick={() => getAccessToEvidenceFile(file.id)}
          sx={{
            m: 0,
            p: 0,
            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              lineHeight: '24px',
              fontWeight: 400,
              textDecoration: 'underline',
              color: theme.palette.secondary.light,
            }}
          >
            {evidenceLabel}
          </Typography>
          <OpenInNew
            sx={{ ml: '10px', color: theme.palette.secondary.light }}
          />
        </Box>
      )
    } else if (url) {
      downloadButton = (
        <Link
          href={url}
          target="_blank"
          sx={{
            fontSize: '20px',
            lineHeight: '24px',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              lineHeight: '24px',
              fontWeight: 400,
              textDecoration: 'underline',
              color: theme.palette.secondary.light,
            }}
          >
            {evidenceLabel}
          </Typography>
          <OpenInNew
            sx={{ ml: '10px', color: theme.palette.secondary.light }}
          />
        </Link>
      )
    }
    return downloadButton
  }

  let MainContent: JSX.Element = (
    <Box>
      <Typography>Choose evidence</Typography>
      <CircularProgress />
    </Box>
  )

  if (isSuccessEvidenceData) {
    const JSXEviItems = evidenceData.map(eviItem => {
      const { id, category, file, url, type } = eviItem

      let downloadButton = getEvidenceHeading(eviItem)

      return (
        <Box
          key={id}
          id="evidenceRow"
          sx={{
            display: 'grid',
            gridTemplateColumns: '9fr 1.5fr 0.6fr',
            borderBottom: `1px solid ${borderColor}`,
            '>div': {
              px: theme.spacing(3),
              borderRight: `1px solid ${borderColor}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ':last-of-type': { border: 'none' },
              ':first-of-type': { justifyContent: 'flex-start' },
            },
          }}
        >
          <Box>
            {/* <Typography>{evidenceLabel}</Typography> */}
            {downloadButton}
          </Box>
          <Box sx={{ borderRight: `1px solid ${borderColor}` }}>
            <Chip
              size="small"
              label={type}
              sx={{
                bgcolor:
                  type === 'PRIVATE'
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
              }}
            />
          </Box>
          <Box>
            <IconButton onClick={() => onSelectMissingEvidence(id)}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Box>
      )
    })

    let evidenceHeadingJsxItem = <></>

    if (choosenMissingEvidenceId !== null) {
      const selectedEvidenceData = evidenceData.find(
        eviItem => eviItem.id === choosenMissingEvidenceId
      )
      evidenceHeadingJsxItem = getEvidenceHeading(selectedEvidenceData!)
    }
    if (choosenMissingEvidenceId !== null && addingMissingEvidenceStep === 0) {
      MainContent = (
        <>
          <Box
            sx={{
              px: theme.spacing(3),
              borderBottom: `1px solid ${borderColor}`,
            }}
          >
            <Typography variant="h6" component={'h3'}>
              Enter evidence information
            </Typography>
          </Box>
          <Box
            component={'form'}
            id="createMissingEvidence"
            onSubmit={onSubmitCreateMissingEvidence}
            sx={{
              p: theme.spacing(3),
              pb: 0,
              '>div': { my: theme.spacing(2) },
            }}
          >
            <Box>
              <Typography sx={{ mb: 0 }}>Evidence document:</Typography>
              {evidenceHeadingJsxItem}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ m: 0 }}>Page:</Typography>
                <TextField
                  onChange={onChangePageRangeInput}
                  type="text"
                  id="eviSentencePage"
                  required
                  value={pageRangesInputValue}
                  sx={{
                    ml: theme.spacing(2),
                    maxWidth: '125px',
                    '>div': { '>input': { px: 1, py: 0.5 } },
                  }}
                />
              </Box>
              {errorInPageRangesValidation && (
                <Typography
                  variant="body1"
                  color={theme.palette.secondary.light}
                >
                  {errorInPageRangesValidation}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography sx={{ mb: 0 }}>Evidence sentence:</Typography>
              <TextField
                type="text"
                id="eviSentence"
                fullWidth
                required
                multiline
                minRows={4}
                value={missingEvidenceSentenceInputValue}
                onChange={onChangeMissingEvidenceSentenceInput}
              />
            </Box>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent={'center'}
              spacing={theme.spacing(2)}
              sx={{ m: 0 }}
            >
              <UniversalButton
                disabled={errorInPageRangesValidation !== ''}
                label="Next"
                onClick={() => onChangeAddingMissingEvidenceStep(1)}
              />
              <UniversalButton
                label="Cancel"
                onClick={onClickCancelButtonInAddMissingEvidenceDialog}
              />
            </Stack>
          </Box>
        </>
      )
    } else if (
      choosenMissingEvidenceId !== null &&
      addingMissingEvidenceStep === 1
    ) {
      MainContent = (
        <>
          <Box
            sx={{
              px: theme.spacing(3),
              borderBottom: `1px solid ${borderColor}`,
            }}
          >
            <Typography variant="h6" component={'h3'}>
              Confirm evidence
            </Typography>
          </Box>
          <Box
            component={'form'}
            id="createMissingEvidence"
            onSubmit={onSubmitCreateMissingEvidence}
            sx={{
              p: theme.spacing(3),
              pb: 0,
              '>div': { my: theme.spacing(2) },
            }}
          >
            <Box>
              <Typography sx={{ mb: 0 }}>Evidence document:</Typography>
              {evidenceHeadingJsxItem}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ m: 0 }}>Page:</Typography>
                <Typography sx={{ ml: theme.spacing(1) }}>
                  {pageRangesInputValue}
                </Typography>
              </Box>
              {errorInPageRangesValidation && (
                <Typography
                  variant="body1"
                  color={theme.palette.secondary.light}
                >
                  {errorInPageRangesValidation}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography sx={{ mb: 0 }}>Evidence sentence:</Typography>
              <Typography sx={{ mb: 0 }}>
                {missingEvidenceSentenceInputValue}
              </Typography>
            </Box>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent={'center'}
              spacing={theme.spacing(2)}
              sx={{ m: 0 }}
            >
              <UniversalButton
                disabled={errorInPageRangesValidation !== ''}
                label="Add Evidence"
                type="submit"
                loading={isLoadingCreateMissingEvidence}
              />
              <UniversalButton
                label="Cancel"
                onClick={onClickCancelButtonInAddMissingEvidenceDialog}
              />
            </Stack>
          </Box>
        </>
      )
    } else {
      MainContent = (
        <>
          <Box
            sx={{
              px: theme.spacing(3),
              borderBottom: `1px solid ${borderColor}`,
            }}
          >
            <Typography variant="h6" component={'h3'}>
              Select evidence:
            </Typography>
          </Box>
          {JSXEviItems}
        </>
      )
    }
  }

  if (isAddMissingEvidenceModalOpen) {
    return (
      <UniversalDialog
        isDialogOpen={isAddMissingEvidenceModalOpen}
        onCloseDialog={onClickCancelButtonInAddMissingEvidenceDialog}
        maxWidth="md"
        mainContentStyling={{ p: 0 }}
      >
        <Box>{MainContent}</Box>
      </UniversalDialog>
    )
  } else {
    return null
  }
}

export default MissingEvidenceModal
