import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  List,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'

import { useLocation } from 'react-router-dom'
import content from '../../../assets/content/content'
import FormIsBlockedDialog from './FormIsBlockedDialog/FormIsBlockedDialog'
import BulletItem from '../../ui/BulletItem/BulletItem'
import { AskQuestionContext } from '../../../contexts/AskQuestionContext/ask-question-context'
import { useAppSelector } from '../../../hooks/hooks'
import SelectUnitSection from './SelectUnitSection/SelectUnitSection'
import AskQuestionError from '../../AskQuestionError/AskQuestionError'

const GenerateResponseForm: React.FC = () => {
  const theme = useTheme()
  const location = useLocation()
  const [selectedFormat, setSelectedFormat] = useState<number | null>(null)
  const isMainSubPagaLocation = location.pathname === '/ask-question'
  const { companyName, role } = useAppSelector(state => state.user)
  const { state, actions } = useContext(AskQuestionContext)
  const {
    askQuestionInputValue,
    isBlockedDialogOpen,
    isInputValid,
    isMetric,
    promptInputValue,
  } = state
  const {
    onInputChange,
    onCloseBlockedDialog,
    onSetIsMetric,
    onAskQuestionFormSubmit,
    onPromptChange,
  } = actions

  const {
    eng: {
      views: {
        askQuestion: { questionExamplesTitle, questionExamplesItems },
      },
    },
  } = content

  let bulletItems: string[] = []

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  switch (companyName) {
    case 'Apple':
      bulletItems = [
        'Provide the highest management-level position(s) or committee(s) with responsibility for climate-related issues.',
        `Provide details of your organization's use of climate-related scenario analysis.`,
        `How much has the company's waste diversion rate improved in recent years?`,
        `Provide a breakdown of the company's gender pay gap in the United Kingdom in 2022.`,
        `What progress has the company made towards its emissions reduction goals?`,
      ]
      break
    case 'Amazon':
      bulletItems = [
        `What was the company's Lost Time Incident Rate (LTIR) in 2022?`,
        `What is the company's concrete plan to achieve its water goals?`,
        `How does the company identify its material ESG topics?`,
        `Provide a breakdown of the sources of the company's Scope 3 emissions.`,
        `Provide a breakdown of board composition by gender.`,
      ]
      break
    case 'Vestas':
      bulletItems = [
        `Provide a breakdown of board composition by gender.`,
        `Provide the highest management-level position(s) or committee(s) with responsibility for climate-related issues.`,
        `Provide details of your organization's use of climate-related scenario analysis.`,
        `How much has the company's waste diversion rate improved in recent years?`,
        `How does the company identify its material ESG topics?`,
      ]
      break
    default:
      bulletItems = [...questionExamplesItems]
  }

  const listItemsJsx = bulletItems.map((questionExample, index) => (
    <BulletItem key={`${questionExample}_${index}`} label={questionExample} />
  ))

  const formats = [
    {
      name: 'Short Answer',
      prompt: 'Provide the shortest possible answer without extra details.',
    },
    {
      name: 'Long Answer',
      prompt:
        'Provide a comprehensive and detailed answer to the question posed, not only answering it thoroughly ' +
        'but also expanding on the underlying concepts and context. Offer additional insights, explanations, ' +
        'and relevant background information to deepen understanding and enhance the depth of the discussion.',
    },
    {
      name: 'Binary Answer',
      prompt:
        'Provide an answer that is strictly "YES" or "NO," with no further elaboration or additional information.',
    },
    {
      name: 'Table Answer',
      prompt:
        'Provide a well-structured table that clearly presents the answer to the question, with labeled columns ' +
        'and rows for easy interpretation. The answer sholud be in markdown table format.',
    },
  ]

  return (
    <Box sx={{ border: `1px solid ${borderColor}` }}>
      <Box
        component={'form'}
        onSubmit={onAskQuestionFormSubmit}
        sx={{
          bgcolor: theme.palette.secondary.main,
          py: theme.spacing(6),
          px: theme.spacing(6),
          justifyContent: 'center',
          alignItems: 'center',
          mt: 0,
          zIndex: -1,
        }}
      >
        <FormIsBlockedDialog
          isDialogOpen={isBlockedDialogOpen}
          onCloseDialog={onCloseBlockedDialog}
        />
        <AskQuestionError />
        <Stack
          direction={'row'}
          spacing={theme.spacing(4)}
          sx={{ marginBottom: theme.spacing(2) }}
          alignItems={'center'}
        >
          <TextField
            id="GenerateQuestionField"
            placeholder="Type or copy/paste survey question"
            fullWidth
            multiline
            minRows={isMainSubPagaLocation ? 2 : 1}
            maxRows={Infinity}
            sx={{ div: { margin: 0, backgroundColor: 'white' } }}
            onChange={onInputChange}
            value={askQuestionInputValue}
            onKeyDown={ev => {
              if (ev.key === 'Enter' && !ev.shiftKey) {
                onAskQuestionFormSubmit(ev)
              }
            }}
          />

          <Button
            style={{ zIndex: 10 }}
            type="submit"
            variant="contained"
            disabled={!isInputValid}
          >
            Submit
          </Button>
        </Stack>
        {/* <Box sx={{ ml: theme.spacing(1) }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: theme.spacing(1) }}
                    checked={isMetric}
                    onChange={e => onSetIsMetric(e.target.checked)}
                  />
                }
                label=" I would like a quantitative response"
              />
            </FormGroup>
          </Box>
          {isMetric && <SelectUnitSection />}
         
        </Box> */}
        {role === 'STUFF' && (
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <Typography>Additional Prompt:</Typography>
            <TextField
              id="prompt"
              fullWidth
              multiline
              minRows={isMainSubPagaLocation ? 2 : 1}
              maxRows={Infinity}
              sx={{ div: { margin: 0, backgroundColor: 'white' } }}
              onChange={onPromptChange}
              value={promptInputValue}
            />
          </Box>
        )}
        <Box>
          <Typography>Expected format:</Typography>
          <Box sx={{ display: 'flex', gap: theme.spacing(3) }}>
            {formats.map(({ name, prompt }, index) => (
              <Button
                key={index}
                variant={selectedFormat === index ? 'outlined' : 'contained'}
                onClick={() => {
                  setSelectedFormat(selectedFormat === index ? null : index)
                  onPromptChange(selectedFormat === index ? '' : prompt)
                }}
              >
                {name}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ px: theme.spacing(5), py: theme.spacing(3) }}>
        <Typography sx={{ m: 0, mb: theme.spacing(4), fontWeight: 700 }}>
          {questionExamplesTitle}
        </Typography>
        <List disablePadding>{listItemsJsx}</List>
      </Box>
    </Box>
  )
}

export default GenerateResponseForm
