import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import React, { useContext, useState } from 'react'
import { SurveysContext } from '../../../../contexts/SurveysContext/surveys-context'
import { Close } from '@mui/icons-material'
import UniversalDialog from '../../../ui/UniversalDialog/UniversalDialog'
import UniversalButton from '../../../ui/Buttons/UniversalButton'
import TextInput from '../UploadSurveyInputs/TextInput'
import SelectInput from '../UploadSurveyInputs/SelectInput'
import DateInput from '../../../ui/inputs/DateInput'
import { UploadSurveyContext } from '../../../../contexts/UploadSurveyContext/uploadSurvey-context'
import {
  answerLengthOptions,
  audienceOptions,
  evidenceDepthOptions,
  evidenceTypeOptions,
} from './optionsForSelectInouts'

const DialogUploadSurvey: React.FC = () => {
  const {
    state: { isDialogAddSurveyOpen, surveyToUploadFileData },
    actions: { renderUploadSurveyForm, onSubmitSurveyCancel },
  } = useContext(SurveysContext)
  const {
    state: {
      dialogCurrentPage,
      fileTitle,
      surveyType,
      surveySource,
      surveyAudience,
      customInternalDepartment,
      surveyFocus,
      surveyDeadline,
      evidenceDepth,
      evidenceType,
      answerLength,
      comment,
    },
    queryResult: {
      isErrorInCreateQuestionnaire,
      isLoadingInCreateQuestionnaire,
      isSuccessInCreateQuestionnaire,
    },
    actions: {
      onClickBackButton,
      onClickContinueButton,
      onChangeFileTitleInput,
      onChangeUploadSurveyType,
      onChangeSurveySourceInput,
      onChangeAudienceSelectInput,
      onChangeCustomInternalInput,
      onChangeSurveyFocusInput,
      onChangeAnswerLengthSelectInput,
      onChangeCommentInput,
      onChangeEvidenceDepthSelectInput,
      onChangeEvidenceTypeSelectInput,
      onChangeSurveyDeadline,
      onClickSubmitForm,
    },
  } = useContext(UploadSurveyContext)

  const theme = useTheme()
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let currentPageContent = <Box></Box>

  let headingTitleSection = (
    <Box>
      <Typography
        component={'h2'}
        variant="h6"
        sx={{ mt: 0, mb: theme.spacing(0.5) }}
      >
        Uploaded file
      </Typography>
      <Typography sx={{ mt: theme.spacing(0.5), mb: 0, color: 'black' }}>
        {surveyToUploadFileData?.name}
      </Typography>
    </Box>
  )

  let headingFileTitleSection = fileTitle ? (
    <Box sx={{ mt: theme.spacing(3) }}>
      <Typography
        component={'h2'}
        variant="h6"
        sx={{ mt: 0, mb: theme.spacing(0.5) }}
      >
        Survey title
      </Typography>

      <Typography sx={{ mt: theme.spacing(0.5), mb: 0, color: 'black' }}>
        {fileTitle}
      </Typography>
    </Box>
  ) : (
    <span></span>
  )

  let simpleHeading = <>{headingTitleSection}</>

  let complexHeading = (
    <>
      {headingTitleSection}
      {headingFileTitleSection}
    </>
  )

  if (isSuccessInCreateQuestionnaire) {
    currentPageContent = (
      <>
        <Box id="contentContainer" sx={{ p: theme.spacing(3) }}>
          <Typography variant="h6">Your survey has been added</Typography>
          <Typography>
            You can now access all your approved answers from the Answers
            Library or Surveys section.
          </Typography>
        </Box>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          direction={'row'}
          spacing={theme.spacing(2)}
          sx={{ mt: theme.spacing(2) }}
        >
          <UniversalButton label="Close" onClick={onSubmitSurveyCancel} />
        </Stack>
      </>
    )
  } else if (isLoadingInCreateQuestionnaire) {
    currentPageContent = (
      <>
        <Box
          sx={{
            backgroundColor: theme.palette.grey[200],
            p: theme.spacing(3),
            pb: theme.spacing(2),
            borderBottom: `1px solid ${borderColor}`,
          }}
        >
          {complexHeading}
        </Box>
        <Box
          id="contentContainer"
          sx={{
            p: theme.spacing(3),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </>
    )
  } else if (dialogCurrentPage === 0) {
    currentPageContent = (
      <>
        <Box
          sx={{
            backgroundColor: theme.palette.grey[200],
            p: theme.spacing(3),
            pb: theme.spacing(2),
            borderBottom: `1px solid ${borderColor}`,
          }}
        >
          {simpleHeading}
        </Box>
        <Box id="contentContainer" sx={{ p: theme.spacing(3) }}>
          <Box id="textFieldTitle">
            <Typography component={'h3'} variant="h6" sx={{ m: 0 }}>
              Survey title
            </Typography>
            <Typography variant="body2" sx={{ m: 0 }}>
              Enter a descriptive title that will differentiate it from others
              in a list. Add the same of the source, year, etc
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Survey title"
              value={fileTitle}
              onChange={onChangeFileTitleInput}
            />
          </Box>
          <Box sx={{ mt: theme.spacing(3) }}>
            <Typography component={'h3'} variant="h6" sx={{ m: 0 }}>
              What would you like to do with this survey?
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={surveyType}
              sx={{ pl: theme.spacing(1) }}
              onChange={onChangeUploadSurveyType}
            >
              <FormControlLabel
                value="TO_GENERATE"
                control={<Radio />}
                label={
                  <>
                    <Typography sx={{ m: 0, color: 'black' }}>
                      Generate new answers
                    </Typography>
                    <Typography sx={{ m: 0, color: 'black' }}>
                      This is a new unanswered survey
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                value="COMPLETED"
                control={<Radio />}
                label={
                  <Box>
                    <Typography sx={{ m: 0, color: 'black' }}>
                      Save to Answers Library
                    </Typography>
                    <Typography sx={{ m: 0, color: 'black' }}>
                      This is a completed survey with approved answers
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </Box>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ mt: theme.spacing(2) }}
          >
            <UniversalButton
              disabled={surveyType === ''}
              label="Continue"
              onClick={onClickContinueButton}
            />
          </Stack>
        </Box>
      </>
    )
  } else if (dialogCurrentPage === 1) {
    currentPageContent = (
      <>
        <Box
          sx={{
            backgroundColor: theme.palette.grey[200],
            p: theme.spacing(3),
            pb: theme.spacing(2),
            borderBottom: `1px solid ${borderColor}`,
          }}
        >
          {complexHeading}
        </Box>
        <Box id="contentContainer" sx={{ p: theme.spacing(3) }}>
          <TextInput
            id="textFieldSource"
            title={'Survey source'}
            explanation={`  Who the survey source? e.g. ESG Rating Agency, internal department
              in your company, customer etc.`}
            value={surveySource}
            onChange={onChangeSurveySourceInput}
          />
          <SelectInput
            id="audience"
            explanation={'Who is the intended audience of the survey answers?'}
            options={audienceOptions}
            title="Survey audience"
            onChange={onChangeAudienceSelectInput}
            value={surveyAudience}
          />
          {surveyAudience === 'INTERNAL_DEPARTMENTS' && (
            <>
              <TextField
                id={`internalDepartment`}
                variant="outlined"
                fullWidth
                size="small"
                label={'Name of internal department'}
                value={customInternalDepartment}
                onChange={onChangeCustomInternalInput}
                sx={{ mt: 0, transform: `translateY(-20px)` }}
              />
            </>
          )}
          <TextInput
            id="surveyFocus"
            title={'Survey focus'}
            explanation={`Is the survey limited to a specific project, product, service, or aspect of your company's operations?`}
            onChange={onChangeSurveyFocusInput}
            value={surveyFocus}
          />
          <Box sx={{ my: theme.spacing(2) }}>
            <Typography component={'h3'} variant="h6" sx={{ m: 0 }}>
              Survey deadline
            </Typography>
            <DateInput
              label=""
              onChange={onChangeSurveyDeadline}
              value={surveyDeadline}
              id="Survey deadline"
            />
          </Box>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            direction={'row'}
            spacing={theme.spacing(2)}
            sx={{ mt: theme.spacing(2) }}
          >
            <UniversalButton
              stylingType={3}
              label="Back"
              onClick={onClickBackButton}
            />
            {surveyType === 'COMPLETED' ? (
              <UniversalButton label="Submit" type="submit" />
            ) : (
              <UniversalButton
                label="Continue"
                onClick={onClickContinueButton}
              />
            )}
          </Stack>
        </Box>
      </>
    )
  } else {
    currentPageContent = (
      <>
        <Box
          sx={{
            backgroundColor: theme.palette.grey[200],
            p: theme.spacing(3),
            pb: theme.spacing(2),
            borderBottom: `1px solid ${borderColor}`,
          }}
        >
          {complexHeading}
        </Box>
        <Box id="contentContainer" sx={{ p: theme.spacing(3) }}>
          <SelectInput
            sx={{ mt: 0 }}
            id="Evidence depth"
            explanation={
              'What is the earliest year’s evidence you wish to apply to generating answers?'
            }
            options={evidenceDepthOptions}
            title="Evidence depth"
            onChange={onChangeEvidenceDepthSelectInput}
            value={evidenceDepth}
          />
          <SelectInput
            id="Evidence type"
            explanation={
              'How do you wish to apply public and private evidence to your answers?'
            }
            options={evidenceTypeOptions}
            title="Evidence type"
            onChange={onChangeEvidenceTypeSelectInput}
            value={evidenceType}
          />
          <SelectInput
            id="Answer length"
            explanation={'How long do you want your answers to be?'}
            options={answerLengthOptions}
            title="Answer length"
            onChange={onChangeAnswerLengthSelectInput}
            value={answerLength}
          />
          <TextInput
            id="comments"
            title={'Comments'}
            explanation={`Do you have any additional comments or instructions that will help us better generate answers?`}
            onChange={onChangeCommentInput}
            value={comment}
            textFildProps={{ multiline: true, minRows: 5 }}
          />

          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            direction={'row'}
            spacing={theme.spacing(2)}
            sx={{ mt: theme.spacing(2) }}
          >
            <UniversalButton
              stylingType={3}
              label="Back"
              onClick={onClickBackButton}
            />
            <UniversalButton label="Submit" type="submit" />
          </Stack>
        </Box>
      </>
    )
  }

  return (
    <UniversalDialog
      isDialogOpen={isDialogAddSurveyOpen}
      onCloseDialog={onSubmitSurveyCancel}
    >
      <Box component={'form'} onSubmit={onClickSubmitForm}>
        {currentPageContent}
      </Box>
    </UniversalDialog>
  )
}

export default DialogUploadSurvey
