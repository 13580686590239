import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import SignUpButton from '../components/ui/Buttons/SignUpButton'
import LoginButton from '../components/ui/Buttons/LoginButton'
import DecorationBar from '../components/ui/DecorationBar/DecorationBar'

const Welcome: React.FC = () => {
  const theme = useTheme()
  const {
    eng: {
      views: {
        welcome: { heading, introduction },
      },
    },
  } = content

  // @ts-ignore: Unreachable code error
  var _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', '/welcome'])
  _hsq.push(['trackPageView'])

  return (
    <>
      {/* <DecorationBar /> */}
      <MainContentContainer
        maxWidth="md"
        sx={{ alignItems: 'center', display: 'flex' }}
      >
        <Box component={'article'}>
          <Typography component={'h2'} variant={'h4'}>
            {heading}
          </Typography>
          <Typography sx={{ marginTop: theme.spacing(6) }}>
            {introduction}
          </Typography>
        </Box>
        <Stack
          direction={'row'}
          spacing={theme.spacing(5)}
          justifyContent="center"
          marginTop={theme.spacing(7.5)}
          alignItems={'center'}
        >
          <SignUpButton />
          <LoginButton />
        </Stack>
      </MainContentContainer>
    </>
  )
}

export default Welcome
