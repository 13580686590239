import React from 'react'
import { Box, useTheme } from '@mui/material'
import EaLogoOnBright from '../ui/Logo/EaLogoOnBright'
import NavLinks from '../NavLinks/NavLinks'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppSelector } from '../../hooks/hooks'
import GetHelpSection from './GetHelpSection/GetHelpSection'

interface Props {}

const LeftSideBar: React.FC<Props> = () => {
  const { isAuthenticated, logout, user } = useAuth0()
  const { companyId } = useAppSelector(state => state.user)

  const theme = useTheme()
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  return (
    <Box
      sx={{
        borderRight: `1px solid ${borderColor}`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        id="topLogoSection"
        sx={{
          borderBottom: `1px solid ${borderColor}`,
          minHeight: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <EaLogoOnBright />
      </Box>
      {isAuthenticated && companyId && (
        <Box
          id="leftMenuArea"
          sx={{
            borderBottom: `1px solid ${borderColor}`,
            px: theme.spacing(2),
            py: theme.spacing(3),
          }}
        >
          <NavLinks />
        </Box>
      )}
      <Box>
        <GetHelpSection />
      </Box>
    </Box>
  )
}

export default LeftSideBar
