import React, { useContext } from 'react'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { SimilarQuestionsContext } from '../../../contexts/SimilarQuestionsContext/similar-questions-context'
import content from '../../../assets/content/content'
import NewDesignUniversalTable from '../../ui/tables/NewDesignUniversalTable/NewDesignUniversalTable'

import HeadTableCell from '../../ui/tables/NewDesignUniversalTable/HeadTableCell/HeadTableCell'
import { CheckCircle } from '@mui/icons-material'
import SimilarQuestionRow from './SimilarQuestionRow/SimilarQuestionRow'
import SimilarQuestionRightDrawer from './SimilarQuestionRightDrawer/SimilarQuestionRightDrawer'
import { useAppSelector } from '../../../hooks/hooks'

interface Props {}

const SimilarNotApprovedQuestionsTable: React.FC<Props> = () => {
  const {
    state: { similarRequests },
  } = useContext(SimilarQuestionsContext)

  const theme = useTheme()

  const { role } = useAppSelector(state => state.user)

  const {
    eng: {
      views: {
        similarQuestions: { tableHeaderCells },
      },
    },
  } = content

  let tableHeaderCellIncludingRole = [...tableHeaderCells]

  // if (role === 'VIEWER') {
  //   const copyOfTableHeaderCells = [...tableHeaderCells]
  //   const foundIdexToDelete = copyOfTableHeaderCells.findIndex(
  //     cellItem => cellItem.id === 'status'
  //   )
  //   if (foundIdexToDelete) {
  //     tableHeaderCellIncludingRole = copyOfTableHeaderCells.splice(
  //       foundIdexToDelete,
  //       1
  //     )
  //   }
  //   tableHeaderCellIncludingRole = [...copyOfTableHeaderCells]
  // }

  const jsxElementsTableHead = tableHeaderCellIncludingRole.map(
    (tableHeaderCell, index) => {
      const { id, label, keyToSort } = tableHeaderCell
      if (id === 'status') {
        return (
          <HeadTableCell key={`${id}_${index}`}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: index === 0 ? 'flex-start' : 'center',
                alignItems: 'center',
              }}
            >
              <IconButton disabled={true}>
                <CheckCircle />
              </IconButton>
            </Box>
          </HeadTableCell>
        )
      }
      return (
        <HeadTableCell
          key={`${id}_${index}`}
          align={index === 0 ? 'left' : 'center'}
          sx={{
            width: index === 0 ? '50%' : 'default',
            userSelect: 'none',
            py: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: index === 0 ? 'flex-start' : 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2" sx={{ display: 'flex', m: 0 }}>
              {label}
            </Typography>
          </Box>
        </HeadTableCell>
      )
    }
  )

  const jsxTableNotApprovedRowsContent = similarRequests
    .filter(anwerItem => {
      const {
        response: { status },
      } = anwerItem
      return status !== 'APPROVED'
    })
    .map((answerItem, index) => {
      return (
        <SimilarQuestionRow key={`${index}_${answerItem.id}`} {...answerItem} />
      )
    })

  if (jsxTableNotApprovedRowsContent.length > 0) {
    return (
      <Box>
        <Typography variant="h4" component={'h3'} sx={{ my: theme.spacing(5) }}>
          Similar questions with unapproved answers
        </Typography>
        <NewDesignUniversalTable
          headRowBgColor={theme.palette.grey[100]}
          tableHeaders={jsxElementsTableHead}
          tableBodyRows={jsxTableNotApprovedRowsContent}
        />
      </Box>
    )
  } else return null
}

export default SimilarNotApprovedQuestionsTable
