import React, { useContext, useEffect } from 'react'
import {
  AnswerItem,
  GenerateSimilarQuestionsRequestItem,
} from '../../../../../types/interfaces'
import { Box, IconButton, TableRow, Typography, useTheme } from '@mui/material'
import CellInNewTable from '../../../../ui/tables/NewDesignUniversalTable/CellInNewTable/CellInNewTable'
import { paragraphGenerator } from '../../../../../utils/paragraphGenerator'
import StatusIcon from '../../../../ui/StatusIcon/StatusIcon'
import { convertDateToNiceFormat } from '../../../../../utils/utils'
import UniversalButton from '../../../../ui/Buttons/UniversalButton'
import { ArrowForwardIos } from '@mui/icons-material'
import { SimilarQuestionsContext } from '../../../../../contexts/SimilarQuestionsContext/similar-questions-context'
import { useDuplicateAnswerMutation } from '../../../../../services/responses'
import AnswerPreview from '../../../../AnswerPreview/AnswerPreview'
import { AnswerPreviewContext } from '../../../../../contexts/AnswerPreview/answer-preview-contex'
import { AnswersLibraryContext } from '../../../../../contexts/AnswersLibraryContext/answers-library-context'

interface Props extends GenerateSimilarQuestionsRequestItem {
  onSetCurrenOpenSimilarQuestion: (id: string) => void
}

const SimilarQuestionRowInUncheckedAnswer: React.FC<Props> = ({
  question,
  id,
  response: {
    status,
    assigned_user,
    request: { survey_name },
    updated_at,
    id: responseId,
  },
  onSetCurrenOpenSimilarQuestion,
}) => {
  const theme = useTheme()
  const [
    duplicateAnswer,
    {
      data: duplicatedAnswerData,
      isSuccess: isSuccessInDuplicateAnswer,
      isLoading: isLoadingInDuplicateAnswer,
      isError: isErrorInDuplicateAnswer,
    },
  ] = useDuplicateAnswerMutation()

  const {
    answerData: { id: currentPreviewedResponseId },
  } = useContext(AnswerPreviewContext)

  const {
    actions: { onOpenAnswer },
  } = useContext(AnswersLibraryContext)

  useEffect(() => {
    if (duplicatedAnswerData) {
      onOpenAnswer(duplicatedAnswerData?.id, true)
    }
  }, [isSuccessInDuplicateAnswer])

  let statusIcon: JSX.Element = <></>

  if (status === 'CHECKED' && assigned_user !== null) {
    statusIcon = <StatusIcon status="CHECKED_ASSIGNED" />
  } else {
    statusIcon = <StatusIcon status={status} />
  }

  let sourceLabel = 'Requested answers'

  if (survey_name) {
    sourceLabel = survey_name
  }

  return (
    <TableRow>
      <CellInNewTable align="left">
        {paragraphGenerator({
          text: question,
          variant: 'h6',
          component: 'h3',
          sx: { m: 0, lineHeight: '24px' },
        })}
      </CellInNewTable>
      <CellInNewTable label={sourceLabel}>
        <Typography sx={{ m: 0, lineHeight: '24px' }}>{sourceLabel}</Typography>
      </CellInNewTable>
      <CellInNewTable>
        <Box
          sx={{
            p: 0,
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {statusIcon}
        </Box>
        {/* <IconButton>{statusIcon}</IconButton> */}
      </CellInNewTable>
      <CellInNewTable>
        <Typography sx={{ m: 0, lineHeight: '24px' }}>
          {convertDateToNiceFormat(updated_at)}
        </Typography>
      </CellInNewTable>
      <CellInNewTable label={'actions'}>
        <Box
          sx={{
            p: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <UniversalButton
            size="small"
            label="Use answer"
            onClick={() =>
              duplicateAnswer({
                resId: currentPreviewedResponseId,
                targetedId: responseId,
              })
            }
            loading={isLoadingInDuplicateAnswer}
            sx={{ m: 0 }}
          />
        </Box>
      </CellInNewTable>
      <CellInNewTable label={'open'} sx={{}}>
        <Box
          sx={{
            p: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => onSetCurrenOpenSimilarQuestion(id)}
            sx={{ height: '27px', width: '27px' }}
          >
            <ArrowForwardIos sx={{ height: '25px', width: '25px' }} />
          </IconButton>
        </Box>
      </CellInNewTable>
    </TableRow>
  )
}

export default SimilarQuestionRowInUncheckedAnswer
