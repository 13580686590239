import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import DecorationBar from '../components/ui/DecorationBar/DecorationBar'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'

const NoSupportForMobileDevices: React.FC = () => {
  const theme = useTheme()
  return (
    <>
      <DecorationBar />
      <MainContentContainer maxWidth="md">
        <Box component={'article'}>
          <Typography component={'h2'} variant={'h4'}>
            No mobile devices support
          </Typography>
          <Typography sx={{ marginTop: theme.spacing(6) }}>
            Sorry, our app doesn't support mobile devices. Please switch to
            desktop, refresh page.
          </Typography>
        </Box>
      </MainContentContainer>
    </>
  )
}

export default NoSupportForMobileDevices
