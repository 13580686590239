import React, { useEffect, useReducer } from 'react'
import { CompanyMembersContext } from './companyMembers-context'
import {
  CompanyMembersContextSchema,
  CompanyMembersState,
} from './companyMembersInterfaces'
import { initialCompanyMembersState } from './initialCompanyMembersState'
import {
  useCreateCompanyUserMutation,
  useDeleteCompanyUserRoleMutation,
  useGetCompanyUsersQuery,
  useUpdateCompanyUserRoleMutation,
} from '../../services/users'
import LoadingInsideComponent from '../../components/ui/LoadingInsideComponent/LoadingInsideComponent'
import ErrorView from '../../views/ErrorView'
import { CompanyUser, CompanyUserState, UserRole } from '../../types/interfaces'
import { useAppDispatch, useAppSelector, useForm } from '../../hooks/hooks'
import { appActions } from '../../store/App/app-slice'
import { isFetchBaseQueryErrorType } from '../../utils/utils'

interface Props {
  children: React.ReactNode
}

enum CompanyMembersActionKind {
  SET_MEMBERS_DATA = 'SET_MEMBERS_DATA',
  SET_IS_MODAL_OPEN = 'SET_IS_MODAL_OPEN',
  SET_SELECT_IN_MODAL = 'SET_SELECT_IN_MODAL',
  SET_MEMBER_IS_LOADING_DELETE = 'SET_MEMBER_IS_LOADING_DELETE',
}

interface CompanyMembersAction {
  type: CompanyMembersActionKind
  payload:
    | string
    | CompanyMembersState['membersData']
    | CompanyMembersState['isModalOpen']
    | CompanyMembersState['selectRoleValueInModal']
    | {
        id: CompanyUserState['id']
        newValue: CompanyUserState['isLoadingDelete']
      }
}

const reducerFunction = (
  state: CompanyMembersState,
  action: CompanyMembersAction
): CompanyMembersState => {
  const { type, payload } = action
  switch (type) {
    case CompanyMembersActionKind.SET_MEMBERS_DATA:
      return {
        ...state,
        membersData: payload as CompanyMembersState['membersData'],
      }
    case CompanyMembersActionKind.SET_IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: payload as CompanyMembersState['isModalOpen'],
      }
    case CompanyMembersActionKind.SET_SELECT_IN_MODAL:
      return {
        ...state,
        selectRoleValueInModal:
          payload as CompanyMembersState['selectRoleValueInModal'],
      }
    case CompanyMembersActionKind.SET_MEMBER_IS_LOADING_DELETE:
      const { id, newValue } = payload as {
        id: CompanyUserState['id']
        newValue: CompanyUserState['isLoadingDelete']
      }
      let foundIndex: number = -1
      const membersData = state.membersData
      if (membersData.length > 0) {
        foundIndex = membersData.findIndex(member => {
          return member.id === id
        })

        if (foundIndex !== -1) {
          const newMembersData = [...membersData]
          newMembersData[foundIndex] = {
            ...newMembersData[foundIndex],
            isLoadingDelete: newValue,
          }
          return {
            ...state,
            membersData: [...newMembersData],
          }
        }
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

const CompanyMembersContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialCompanyMembersState
  )
  const { companyId, userEmail } = useAppSelector(state => state.user)
  const {
    data: companyMembersData,
    isLoading: isLoadingMembersData,
    isError: isErrorMembersData,
    error: errorMembersData,
  } = useGetCompanyUsersQuery()

  const [
    updateRole,
    { isSuccess: isSuccessUpdateRole, isError: isErrorUpdateRole },
  ] = useUpdateCompanyUserRoleMutation()

  const [
    createCompanyUser,
    {
      isSuccess: isSuccessCreateUser,
      isError: isErrorCreateUser,
      isLoading: isLoadingCreateUser,
      error: errorDataCreateUser,
    },
  ] = useCreateCompanyUserMutation()

  const [
    deleteUserRole,
    {
      isSuccess: isSuccessDeleteRole,
      isError: isErrorDeleteRole,
      isLoading: isLoadingDeleteRole,
      originalArgs: deleteUserArgs,
    },
  ] = useDeleteCompanyUserRoleMutation()

  const [renderForm] = useForm()

  const dispatchStore = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatchStore(appActions.hideSnackbarNotification())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companyMembersData) {
      const sortedData = [...companyMembersData]
      sortedData.sort((a, b) => {
        if (a.email === userEmail && b.email !== userEmail) {
          return -1
        } else if (a.email !== userEmail && b.email === userEmail) {
          return 1
        } else {
          return 0
        }
      })
      const adjustedMembersDataToState: CompanyUserState[] = sortedData.map(
        companyMember => {
          return { ...companyMember, isLoadingDelete: false }
        }
      )
      dispatch({
        type: CompanyMembersActionKind.SET_MEMBERS_DATA,
        payload: adjustedMembersDataToState,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyMembersData])

  useEffect(() => {
    if (isSuccessUpdateRole) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `User's role successfully updated`,
          type: 'success',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdateRole])

  useEffect(() => {
    if (isSuccessCreateUser) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `User's role successfully created`,
          type: 'success',
        })
      )
      dispatch({
        type: CompanyMembersActionKind.SET_IS_MODAL_OPEN,
        payload: false,
      })
      dispatch({
        type: CompanyMembersActionKind.SET_SELECT_IN_MODAL,
        payload: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreateUser])

  useEffect(() => {
    if (isSuccessDeleteRole) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `User's role successfully deleted`,
          type: 'success',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleteRole])

  useEffect(() => {
    dispatch({
      type: CompanyMembersActionKind.SET_MEMBER_IS_LOADING_DELETE,
      payload: {
        id: deleteUserArgs as CompanyUserState['id'],
        newValue: isLoadingDeleteRole,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDeleteRole])

  useEffect(() => {
    if (isErrorUpdateRole) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't update user's role. Please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorUpdateRole])

  useEffect(() => {
    if (isErrorCreateUser) {
      if (
        isFetchBaseQueryErrorType(errorDataCreateUser) &&
        errorDataCreateUser.status === 400
      ) {
        const { data } = errorDataCreateUser
        const { detail } = data as { detail: string }

        dispatchStore(
          appActions.showSnackbarNotification({
            label: detail,
            type: 'error',
          })
        )
      } else {
        dispatchStore(
          appActions.showSnackbarNotification({
            label: `Sorry, we couldn't create new user role. Please try again later or contact support for assistance.`,
            type: 'error',
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorCreateUser])

  useEffect(() => {
    if (isErrorDeleteRole) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't delete user's role. Please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorDeleteRole])

  const onUserRoleChangeHandler = (
    newValue: UserRole,
    id: CompanyUser['id']
  ) => {
    updateRole({
      id: id,
      body: { role: newValue },
    })
  }

  const onChangeSelectHandler = (newValue: string) => {
    dispatch({
      type: CompanyMembersActionKind.SET_SELECT_IN_MODAL,
      payload: newValue,
    })
  }

  const onSubmitAddMemberFormHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      first_name: { value: string }
      last_name: { value: string }
      email: { value: string }
    }
    if (state.selectRoleValueInModal && companyId) {
      createCompanyUser({
        first_name: target.first_name.value,
        last_name: target.last_name.value,
        email: target.email.value,
        role: state.selectRoleValueInModal,
        id: companyId,
      })
    }
  }

  const onCancelAddNewMemberHandler = () => {
    dispatch({
      type: CompanyMembersActionKind.SET_IS_MODAL_OPEN,
      payload: false,
    })
    dispatch({
      type: CompanyMembersActionKind.SET_SELECT_IN_MODAL,
      payload: '',
    })
  }

  const onOpenModalHander = () => {
    dispatch({
      type: CompanyMembersActionKind.SET_IS_MODAL_OPEN,
      payload: true,
    })
    dispatch({
      type: CompanyMembersActionKind.SET_SELECT_IN_MODAL,
      payload: '',
    })
  }

  const onClickDeleteUserRoleHandler = (id: CompanyUser['id']) => {
    deleteUserRole(id)
  }

  const renderAddMemberFormHandler = () => {
    return renderForm({
      onSubmit: onSubmitAddMemberFormHandler,
      onCancel: onCancelAddNewMemberHandler,
      formElements: [
        {
          id: 'first_name',
          label: 'First Name',
          type: 'text',
          isRequired: true,
        },
        {
          id: 'last_name',
          label: 'Last Name',
          type: 'text',
          isRequired: true,
        },
        {
          id: 'email',
          label: 'Email',
          type: 'text',
          isRequired: true,
        },
        {
          id: 'role',
          label: 'Role',
          type: 'select',
          isRequired: true,
          options: [
            { label: 'Contributor', value: 'CONTRIBUTOR' },
            { label: 'Editor', value: 'EDITOR' },
            { label: 'Viewer', value: 'VIEWER' },
          ],
          currentValue: state.selectRoleValueInModal,
          onChange: onChangeSelectHandler as (newValue: string) => void,
        },
      ],
      isUploadSurveyLoading: isLoadingCreateUser,
      submitButtonLabel: 'Add',
    })
  }

  const ctxObject: CompanyMembersContextSchema = {
    state: {
      membersData: state.membersData,
      isModalOpen: state.isModalOpen,
      selectRoleValueInModal: state.selectRoleValueInModal,
    },
    queryResults: { isErrorInUpdateRole: isErrorUpdateRole },
    actions: {
      onUserRoleChange: onUserRoleChangeHandler,
      renderAddMemberForm: renderAddMemberFormHandler,
      onOpenModal: onOpenModalHander,
      onCloseModal: onCancelAddNewMemberHandler,
      onClickDeleteUserRole: onClickDeleteUserRoleHandler,
    },
  }

  let content = children

  if (isErrorMembersData) {
    return <ErrorView errorData={errorMembersData} />
  }
  if (isLoadingMembersData) {
    content = <LoadingInsideComponent />
  }

  return (
    <CompanyMembersContext.Provider value={ctxObject}>
      {content}
    </CompanyMembersContext.Provider>
  )
}

export default CompanyMembersContextProvider
