import React, { ReactElement, useContext } from 'react'
import {
  Box,
  SxProps,
  Typography,
  useTheme,
  SvgIconTypeMap,
} from '@mui/material'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import {
  CheckCircle,
  CheckCircleOutline,
  HelpOutline,
  HighlightOff,
  History,
  ModeEditOutline,
} from '@mui/icons-material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { AnswerPreviewContext } from '../../../contexts/AnswerPreview/answer-preview-contex'
import { convertDateToNiceFormat } from '../../../utils/utils'

interface Props {
  borderColor: string
  boxTitleStyling: SxProps
}

const StatusSection: React.FC<Props> = ({ borderColor, boxTitleStyling }) => {
  const theme = useTheme()
  const {
    state: { approvedByName },
    actions: { onClickEditMode, onClickAproveAnswer, onClickOpenRejectModal },
    answerData: { dateOfApproval, status },
  } = useContext(AnswerPreviewContext)

  const aproveBoxStyling: SxProps = {
    borderRight: `1px solid ${borderColor}`,
    px: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  }

  let JsxBoxElements: JSX.Element[] = []
  let statusIcon: JSX.Element = <></>

  if (status === 'APPROVED') {
    JsxBoxElements = [
      <Box sx={aproveBoxStyling} key={'approve_by_name'}>
        <CheckCircle
          sx={{ color: theme.palette.primary.main, mr: theme.spacing(1) }}
        />
        <Typography>
          {approvedByName ? `Approved by: ${approvedByName}` : `Approved`}
        </Typography>
      </Box>,
      <Box
        sx={{ ...aproveBoxStyling, borderRight: 'none' }}
        key={'approve_date'}
      >
        <Typography>
          Date approved:{' '}
          {dateOfApproval ? convertDateToNiceFormat(dateOfApproval) : 'unknown'}
        </Typography>
      </Box>,
    ]
  } else if (status === 'REJECTED_CHECKED') {
    JsxBoxElements = [
      <Box
        sx={{ ...aproveBoxStyling, borderRight: 'none' }}
        key={'approve_date'}
      >
        <History
          sx={{ fill: theme.palette.primary.main, mr: theme.spacing(1) }}
        />
        <Typography>Answer is regenerating</Typography>
      </Box>,
    ]
  } else {
    JsxBoxElements = [
      <Box
        sx={{ ...aproveBoxStyling, borderRight: 'none' }}
        key={'approve_date'}
      >
        <HelpOutline
          sx={{ fill: theme.palette.primary.main, mr: theme.spacing(1) }}
        />
        <Typography>Answer requires approval</Typography>
      </Box>,
    ]
  }

  return (
    <Box
      id="approvalRow"
      sx={{
        borderBottom: `1px solid ${borderColor}`,
        display: 'grid',
        gridTemplateColumns: '130px 1fr',
      }}
    >
      <Box
        id="leftColumn"
        sx={{ ...boxTitleStyling, justifyContent: 'center' }}
      >
        <Typography variant="h6" component={'h4'}>
          Status
        </Typography>
      </Box>
      <Box
        id="rightColumn"
        sx={{
          // backgroundColor: isUserApproved
          //   ? 'inherit'
          //   : theme.palette.secondary.contrastText,
          display: 'grid',
          gridTemplateColumns:
            status === 'APPROVED' ? '2fr 1fr' : '1fr 1fr 1fr',
        }}
      >
        {JsxBoxElements}
      </Box>
    </Box>
  )
}

export default StatusSection
