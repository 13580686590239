import React, { useEffect, useReducer } from 'react'
import { AskQuestionContext } from './ask-question-context'
import {
  AskQuestionContextSchema,
  AskQuestionState,
} from './askQuestionInterfaces'
import { initialAskQuestionState } from './initialAskQuestionState'
import { SelectChangeEvent } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  useCreateRequestMutation,
  useGenerateNewResponseMutation,
} from '../../services/generate'
import { useNavigate } from 'react-router-dom'
import LoadingViewForGenerateQuestion from '../../components/LoadingViewForGenerateQuestion/LoadingViewForGenerateQuestion'
import { StateSentFromAskQuestionView } from '../SimilarQuestionsContext/SimilarQuestionsContextProvider'
import { Unit } from '../../types/interfaces'
import { userActions } from '../../store/User/user-slice'

interface Props {
  children: React.ReactNode
}

enum AskQuestionActionKind {
  CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE',
  CHANGE_PROMT_INPUT_VALUE = 'CHANGE_PROMT_INPUT_VALUE',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
  OPEN_DIALOG = 'OPEN_DIALOG',
  SET_IS_METRIC = 'SET_IS_METRIC',
  SET_UNIT = 'SET_UNIT',
  SET_IS_VALID = 'SET_IS_VALID',
  SET_IS_BLOCK = 'SET_IS_BLOCK',
  SET_IS_ERROR_IN_GENERATE_RESPONSE = 'SET_IS_ERROR_IN_GENERATE_RESPONSE',
  SET_IS_LOADING_IN_GENERATE_RESPONSE = 'SET_IS_LOADING_IN_GENERATE_RESPONSE',
}

interface AskQuestionAction {
  type: AskQuestionActionKind
  payload: string | boolean | AskQuestionState['unit']
}

const reducerFunction = (
  state: AskQuestionState,
  action: AskQuestionAction
): AskQuestionState => {
  const { type, payload } = action
  switch (type) {
    case AskQuestionActionKind.CHANGE_INPUT_VALUE:
      return {
        ...state,
        askQuestionInputValue: payload as string,
      }
    case AskQuestionActionKind.CHANGE_PROMT_INPUT_VALUE:
      return {
        ...state,
        promptInputValue: payload as string,
      }
    case AskQuestionActionKind.CLOSE_DIALOG:
      return {
        ...state,
        isBlockedDialogOpen: false,
      }
    case AskQuestionActionKind.OPEN_DIALOG:
      return {
        ...state,
        isBlockedDialogOpen: true,
      }
    case AskQuestionActionKind.SET_IS_METRIC:
      return {
        ...state,
        isMetric: payload as boolean,
      }
    case AskQuestionActionKind.SET_UNIT:
      return {
        ...state,
        unit: payload as AskQuestionState['unit'],
      }
    case AskQuestionActionKind.SET_IS_VALID:
      return {
        ...state,
        isInputValid: payload as boolean,
      }
    case AskQuestionActionKind.SET_IS_BLOCK:
      return {
        ...state,
        isAskQuestionModeBlocked: payload as boolean,
      }
    case AskQuestionActionKind.SET_IS_LOADING_IN_GENERATE_RESPONSE:
      return {
        ...state,
        isLoadingInGenerateResponse: payload as boolean,
      }
    case AskQuestionActionKind.SET_IS_ERROR_IN_GENERATE_RESPONSE:
      return {
        ...state,
        isErrorInGenerateResponse: payload as boolean,
      }
    default:
      return state
  }
}

const AskQuestionContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducerFunction, initialAskQuestionState)
  const { companyName, role, isAskQuestionModeBlocked } = useAppSelector(
    state => state.user
  )

  const [
    createRequest,
    {
      data: requestData,
      isLoading: isLoadingInCreateRequest,
      isError: isErrorInCreateRequest,
    },
  ] = useCreateRequestMutation()
  const [
    generateResponse,
    {
      data: responseData,
      isLoading: isLoadingInGenerateResponse,
      isError: isErrorInGenerateResponse,
    },
  ] = useGenerateNewResponseMutation()

  const navigate = useNavigate()
  // const storeDispatch = useAppDispatch()

  // const isCompanyInUnBlockList = companiesNamesToUnBlockOneQuestionMode.find(
  //   companyNameFromList => companyName === companyNameFromList
  // )

  const isViewer = role === 'VIEWER'

  // useEffect(() => {
  //   if (isCompanyInUnBlockList) {
  //     storeDispatch(userActions.unblockAskQuestionMode())
  //   }
  // }, [companyName, isCompanyInUnBlockList])

  useEffect(() => {
    if (isLoadingInCreateRequest || isLoadingInGenerateResponse) {
      dispatch({
        type: AskQuestionActionKind.SET_IS_LOADING_IN_GENERATE_RESPONSE,
        payload: true,
      })
    }
  }, [isLoadingInCreateRequest, isLoadingInGenerateResponse])

  useEffect(() => {
    if (requestData) {
      const { similar_requests, request_id } = requestData

      if (
        similar_requests.length > 0 ||
        role === 'VIEWER' ||
        isAskQuestionModeBlocked
      ) {
        dispatch({
          type: AskQuestionActionKind.SET_IS_LOADING_IN_GENERATE_RESPONSE,
          payload: false,
        })
        navigate('/similar-questions', {
          state: {
            questionHeading: state.askQuestionInputValue,
            is_metric: state.isMetric,
            unit: state.unit,
            similarRequests: similar_requests,
            requestId: request_id,
          } as StateSentFromAskQuestionView,
        })
      } else {
        generateResponse(requestData['request_id'])
      }
    }
    // eslint-disable-next-line
  }, [requestData])

  useEffect(() => {
    if (responseData) {
      dispatch({
        type: AskQuestionActionKind.SET_IS_LOADING_IN_GENERATE_RESPONSE,
        payload: false,
      })
      navigate('/generated-response', {
        state: {
          questionHeading: state.askQuestionInputValue,
          responseText: responseData['text'],
          responseId: responseData['id'],
          requestId: responseData['request_id'],
          supportiveEvidences: responseData['supportive_evidences'],
        },
      })
    }
    // eslint-disable-next-line
  }, [responseData])

  useEffect(() => {
    if (isErrorInCreateRequest || isErrorInGenerateResponse) {
      dispatch({
        type: AskQuestionActionKind.SET_IS_LOADING_IN_GENERATE_RESPONSE,
        payload: false,
      })
      dispatch({
        type: AskQuestionActionKind.SET_IS_ERROR_IN_GENERATE_RESPONSE,
        payload: true,
      })
      const timer = setTimeout(() => {
        dispatch({
          type: AskQuestionActionKind.SET_IS_ERROR_IN_GENERATE_RESPONSE,
          payload: false,
        })
      }, 6000)
      return () => clearTimeout(timer)
    } else {
      dispatch({
        type: AskQuestionActionKind.SET_IS_ERROR_IN_GENERATE_RESPONSE,
        payload: false,
      })
    }
  }, [isErrorInCreateRequest, isErrorInGenerateResponse])

  const { askQuestionInputValue, isMetric, unit, promptInputValue } = state

  const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as string
    const isValid = newValue.length > 9
    dispatch({
      type: AskQuestionActionKind.CHANGE_INPUT_VALUE,
      payload: newValue,
    })
    dispatch({
      type: AskQuestionActionKind.SET_IS_VALID,
      payload: isValid,
    })
    dispatch({
      type: AskQuestionActionKind.SET_IS_BLOCK,
      payload: false,
    })
  }

  const onChangePromptHandler = (
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    const newValue = typeof e === 'string' ? e : (e.target.value as string)
    dispatch({
      type: AskQuestionActionKind.CHANGE_PROMT_INPUT_VALUE,
      payload: newValue,
    })
  }

  const onCloseBlockedDialogHandler = () => {
    dispatch({
      type: AskQuestionActionKind.CLOSE_DIALOG,
      payload: '',
    })
  }

  const onOpenBlockedDialogHandler = () => {
    dispatch({
      type: AskQuestionActionKind.OPEN_DIALOG,
      payload: '',
    })
  }

  const onSetIsMetricHandler = (value: boolean) => {
    dispatch({
      type: AskQuestionActionKind.SET_IS_METRIC,
      payload: value,
    })
  }

  const onSetUnitHandler = (e: SelectChangeEvent) => {
    const unit = (
      e.target.value === '' ? null : e.target.value
    ) as AskQuestionState['unit']

    dispatch({
      type: AskQuestionActionKind.SET_UNIT,
      payload: unit,
    })
  }

  const onAskQuestionFormSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()

    createRequest({
      question: askQuestionInputValue,
      is_metric: isMetric,
      unit: unit as Unit,
      prompt: promptInputValue,
    })
  }

  const onCloseErrorNotificationHandler = () => {
    dispatch({
      type: AskQuestionActionKind.SET_IS_ERROR_IN_GENERATE_RESPONSE,
      payload: false,
    })
  }

  const ctxObject: AskQuestionContextSchema = {
    state: state,
    actions: {
      onInputChange: onChangeInputHandler,
      onPromptChange: onChangePromptHandler,
      onAskQuestionFormSubmit: onAskQuestionFormSubmitHandler,
      onCloseBlockedDialog: onCloseBlockedDialogHandler,
      onOpenBlockedDialog: onOpenBlockedDialogHandler,
      onSetIsMetric: onSetIsMetricHandler,
      onSetUnit: onSetUnitHandler,
      onCloseErrorNotification: onCloseErrorNotificationHandler,
    },
  }

  let content = children

  if (state.isLoadingInGenerateResponse) {
    content = <LoadingViewForGenerateQuestion />
  }
  return (
    <AskQuestionContext.Provider value={ctxObject}>
      {content}
    </AskQuestionContext.Provider>
  )
}

export default AskQuestionContextProvider
