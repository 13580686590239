import React from 'react'
import { Box, SxProps, Typography, useTheme } from '@mui/material'

interface Props {
  label: string
  sx?: SxProps
}

const ViewTitle: React.FC<Props> = ({ label, sx }) => {
  const theme = useTheme()
  return (
    <Typography component={'h1'} variant={'h3'} sx={{ ...sx }}>
      {label}
    </Typography>
  )
}

export default ViewTitle
