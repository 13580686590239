import React from 'react'
import content, { emailSwap } from '../assets/content/content'
import { Link, Typography } from '@mui/material'

export const processEmailInStringAndReturnJsx = (textWithEmail: string) => {
  const {
    config: { supportEmail },
  } = content

  const stringToSwap = emailSwap

  const indexOfEmailToSwap = textWithEmail.indexOf(stringToSwap)

  if (indexOfEmailToSwap !== -1) {
    const partBeforeEmail = textWithEmail.slice(0, indexOfEmailToSwap)
    const partAfterEmail = textWithEmail.slice(
      indexOfEmailToSwap + stringToSwap.length,
      textWithEmail.length
    )
    return (
      <>
        <Typography>
          {partBeforeEmail}
          <Link href={`mailto:${supportEmail}`}>{`${supportEmail}`}</Link>.
        </Typography>
        <Typography>{partAfterEmail}</Typography>
      </>
    )
  }
  return <Typography>{textWithEmail}</Typography>
}
