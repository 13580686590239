import { createContext } from 'react'
import { CdpFullSeasonalSurveyDetailsContextSchema } from './cdpFullSeasonalSurveyDetailsInterfaces'
import initialCdpFullSeasonalSurveyDetailsState from './initialCdpFullSeasonalSurveyDetailsState'
import initialCdpFullSeasonalSurveyDetailsActions from './initialCdpFullSeasonalSurveyDetailsActions'

const CdpFullSeasonalSurveyDetailsInitialContext: CdpFullSeasonalSurveyDetailsContextSchema =
  {
    state: initialCdpFullSeasonalSurveyDetailsState,
    actions: initialCdpFullSeasonalSurveyDetailsActions,
  }

export const CdpFullSeasonalSurveyDetailsContext = createContext(
  CdpFullSeasonalSurveyDetailsInitialContext
)
