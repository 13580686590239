import { AskQuestionState } from './askQuestionInterfaces'

export const initialAskQuestionState: AskQuestionState = {
  askQuestionInputValue: '',
  promptInputValue: '',
  isInputValid: false,
  isMetric: false,
  unit: null,
  isAskQuestionModeBlocked: true,
  isBlockedDialogOpen: false,
  isErrorInGenerateResponse: false,
  isLoadingInGenerateResponse: false,
}
