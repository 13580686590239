import React from 'react'
import { Box, Link, useTheme, SvgIconTypeMap } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { responsesViewActions } from '../../store/ResponsesView/ResponsesView-slice'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'

interface Props {}

interface NavLinkData {
  label: string
  path: string
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
}

const createNavLinkObj = (
  label: string,
  path: string,
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
) => {
  return { label, path, icon }
}

const NavLinks: React.FC<Props> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const userState = useAppSelector(state => state.user)
  const { areEvidenceProvided, role } = userState

  let navLinksData: NavLinkData[] = [
    createNavLinkObj('Home', '/dashboard', HomeOutlinedIcon),
    createNavLinkObj(
      'Ask Question',
      '/ask-question',
      QuestionAnswerOutlinedIcon
    ),

    createNavLinkObj(
      'Answers',
      '/answers-library',
      AssignmentTurnedInOutlinedIcon
    ),
    createNavLinkObj('Surveys', '/surveys', FactCheckOutlinedIcon),
    createNavLinkObj('Evidence', '/evidence-library', PushPinOutlinedIcon),
  ]

  if (role === 'VIEWER') {
    navLinksData = [
      createNavLinkObj('Home', '/dashboard', HomeOutlinedIcon),
      createNavLinkObj(
        'Ask Question',
        '/ask-question',
        QuestionAnswerOutlinedIcon
      ),
      createNavLinkObj(
        'Answers',
        '/answers-library',
        AssignmentTurnedInOutlinedIcon
      ),
      createNavLinkObj('Surveys', '/surveys', FactCheckOutlinedIcon),
      createNavLinkObj('Evidence', '/evidence-library', PushPinOutlinedIcon),
    ]
  }

  if (role === 'CONTRIBUTOR') {
    navLinksData = [
      createNavLinkObj('Home', '/dashboard', HomeOutlinedIcon),
      createNavLinkObj(
        'Answers',
        '/answers-library',
        AssignmentTurnedInOutlinedIcon
      ),
      createNavLinkObj('Surveys', '/surveys', FactCheckOutlinedIcon),
      createNavLinkObj('Evidence', '/evidence-library', PushPinOutlinedIcon),
    ]
  }
  if (areEvidenceProvided === false) {
    navLinksData = [
      createNavLinkObj('Home', '/dashboard', HomeOutlinedIcon),
      createNavLinkObj('Surveys', '/surveys', FactCheckOutlinedIcon),
      createNavLinkObj('Evidence', '/evidence-library', PushPinOutlinedIcon),
    ]
  }

  const navLinkJsxElements = navLinksData.map((navLinkData, index) => {
    const { label, path, icon } = navLinkData

    const ItemIcon = icon

    return (
      <Link
        key={path + index}
        to={path}
        component={NavLink}
        variant="h6"
        underline="none"
        onClick={
          index === 0
            ? () => dispatch(responsesViewActions.resetResponses())
            : () => {}
        }
        sx={{
          minHeight: '40px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.primary.dark,
          my: theme.spacing(1),
          boxSizing: 'border-box',
          '&.active': {
            backgroundColor: `${theme.palette.secondary.contrastText}`,
          },
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }}
      >
        <ItemIcon sx={{ mr: theme.spacing(2), ml: theme.spacing(1) }} /> {label}
      </Link>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      {navLinkJsxElements}
    </Box>
  )
}

export default NavLinks
