import React, { useContext } from 'react'
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  useTheme,
  SelectChangeEvent,
} from '@mui/material'
import { useGetCompanyUsersQuery } from '../../../services/users'
import { useUpdateAnswerMutation } from '../../../services/responses'

interface Props {
  selectReviewerValue: string
  responseId: string
}

const SelectReviewer: React.FC<Props> = ({
  selectReviewerValue,
  responseId,
}) => {
  const theme = useTheme()
  const { data: companyUsers, isLoading: isLoadingCompanyUser } =
    useGetCompanyUsersQuery()

  const [
    updateResponse,
    {
      isSuccess: isSuccessInUpdateResponse,
      isLoading: isLoadingInSaveResponse,
      isError: isErrorInUpdateResponse,
      data,
    },
  ] = useUpdateAnswerMutation()

  const onChangeReviewer = (event: SelectChangeEvent<string>): void => {
    const newValue = event.target.value
    updateResponse({
      responseId,
      body: { assigned_user_id: newValue, status: 'CHECKED' },
    })
  }

  let selectReviewerJSXitem = <CircularProgress />

  if (companyUsers) {
    let selectReviewersOptions = companyUsers
      .filter(user => {
        const { role } = user

        return role !== 'VIEWER' && role !== 'STUFF'
      })
      .map(user => {
        const { id, first_name, last_name, role } = user
        let lastName = last_name !== null ? last_name : ''
        return (
          <MenuItem key={id} value={id}>{`${first_name} ${lastName}`}</MenuItem>
        )
      })

    selectReviewerJSXitem = (
      <Select
        sx={{ bgcolor: 'white', maxWidth: '200px' }}
        labelId="bulk_reviewer"
        id="select-reviewer"
        size="small"
        defaultValue={''}
        value={selectReviewerValue}
        onChange={onChangeReviewer}
      >
        <MenuItem value={'unassigned'} key={'select'}>
          Unassigned
        </MenuItem>
        {selectReviewersOptions}
      </Select>
    )
  }

  return (
    <FormControl size="small" sx={{ ml: theme.spacing(2) }}>
      {selectReviewerJSXitem}
    </FormControl>
  )
}

export default SelectReviewer
