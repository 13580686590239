import { createContext } from 'react'
import { initialAskQuestionState } from './initialAskQuestionState'
import { AskQuestionContextSchema } from './askQuestionInterfaces'
import { initialAskQuestionActions } from './initialAskQuestionActions'

const askQuestionInitialContext: AskQuestionContextSchema = {
  state: initialAskQuestionState,
  actions: initialAskQuestionActions,
}

export const AskQuestionContext = createContext(askQuestionInitialContext)
