import React, { useContext } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Typography,
  useTheme,
} from '@mui/material'
import { CompanyMembersContext } from '../../../contexts/CompanyMembersContext/companyMembers-context'
import NewDesignUniversalTable from '../../ui/tables/NewDesignUniversalTable/NewDesignUniversalTable'
import CellInNewTable from '../../ui/tables/NewDesignUniversalTable/CellInNewTable/CellInNewTable'
import content from '../../../assets/content/content'
import RowInNewTable from '../../ui/tables/NewDesignUniversalTable/RowInNewTable/RowInNewTable'
import RoleSelectInput from '../RoleSelectInput/RoleSelectInput'
import { useAppSelector } from '../../../hooks/hooks'
import AddMemberDialog from '../AddMemberDialog/AddMemberDialog'
import { Delete } from '@mui/icons-material'

const MembersTable: React.FC = () => {
  const {
    state,
    actions: { onClickDeleteUserRole },
  } = useContext(CompanyMembersContext)
  const { userEmail: currentUserEmail, role } = useAppSelector(
    state => state.user
  )

  const theme = useTheme()

  const { membersData } = state
  const {
    eng: {
      components: {
        membersTable: { tableHeaderCells },
      },
    },
  } = content

  const isViewer = role === 'VIEWER'

  let headersItems = tableHeaderCells

  if (isViewer) {
    headersItems = tableHeaderCells.slice(0, 3)
  }
  const jsxHeadersElements = headersItems.map((headerItem, index) => {
    const { id, label } = headerItem

    return (
      <CellInNewTable
        key={`${id}_${index}`}
        align={id === 'name' ? `left` : 'center'}
        sx={{ py: theme.spacing(0) }}
      >
        <Typography variant="body2">{label}</Typography>
      </CellInNewTable>
    )
  })

  const jsxRowsElements = membersData.map((rowItem, index) => {
    const {
      email,
      first_name,
      last_name,
      isLoadingDelete,
      id: userId,
      role,
    } = rowItem

    const isAdmin = email === currentUserEmail

    const jsxCellsInRow = headersItems.map(cell => {
      const { id } = cell
      if (id === 'name') {
        let nameLabel = `${first_name} ${last_name}`
        if (last_name === null) {
          nameLabel = `${first_name}`
        }
        return (
          <CellInNewTable
            component="th"
            scope="row"
            align="left"
            key={id}
            label={nameLabel}
            sx={{ width: '50%', verticalAlign: 'middle' }}
          />
        )
      } else if (id === 'email') {
        return (
          <CellInNewTable key={id} sx={{ verticalAlign: 'middle' }}>
            {rowItem[id] ? (
              <Link href={`mailto:${rowItem[id]}`} sx={{ m: 0 }}>
                {rowItem[id]}
              </Link>
            ) : (
              <Typography></Typography>
            )}
          </CellInNewTable>
        )
      } else if (id === 'role') {
        return (
          <CellInNewTable
            key={id}
            sx={{
              py: theme.spacing(0),
              px: theme.spacing(2),
              verticalAlign: 'middle',
            }}
          >
            {isViewer ? (
              <Typography sx={{ m: 0 }}>{rowItem[id]}</Typography>
            ) : !isAdmin ? (
              <RoleSelectInput
                memberId={userId}
                currentValue={userId === null ? 'VIEWER' : role}
              />
            ) : (
              <Typography sx={{ m: 0 }}>{rowItem[id]}</Typography>
            )}
          </CellInNewTable>
        )
      } else if (id === 'actions') {
        return (
          <CellInNewTable
            key={id}
            sx={{ py: theme.spacing(1.5), verticalAlign: 'center' }}
          >
            {!isAdmin ? (
              isLoadingDelete ? (
                <CircularProgress />
              ) : (
                <IconButton
                  sx={{ m: 0, p: 0 }}
                  onClick={() => onClickDeleteUserRole(userId)}
                >
                  <Delete></Delete>
                </IconButton>
              )
            ) : (
              <Typography></Typography>
            )}
          </CellInNewTable>
        )
      } else {
        return (
          <CellInNewTable key={id}>
            {rowItem[id] ? (
              <Typography>{rowItem[id]}</Typography>
            ) : (
              <Typography></Typography>
            )}
          </CellInNewTable>
        )
      }
    })

    return (
      <RowInNewTable key={`${email}_${index}`}>{jsxCellsInRow}</RowInNewTable>
    )
  })

  return (
    <Box>
      <AddMemberDialog />
      <NewDesignUniversalTable
        headRowBgColor={theme.palette.grey[100]}
        tableHeaders={jsxHeadersElements}
        tableBodyRows={jsxRowsElements}
      />
    </Box>
  )
}

export default MembersTable
