import React, { useContext, useEffect, useReducer } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AnswerItem, Evidence } from '../../types/interfaces'
import { useGetAnswersQuery } from '../../services/responses'
import { groupEvidenceFuse, groupResponsesFuse } from '../../utils/utils'
import Fuse from 'fuse.js'
import ErrorView from '../../views/ErrorView'
import {
  EvidenceLibraryContextSchema,
  EvidenceLibraryState,
} from './evidenceLibraryInterfaces'
import { initialEvidenceLibraryState } from './initialEvidenceLibraryState'
import { EvidenceLibraryContext } from './evidence-library-context'
import { FilterEvidenceUrlParamNames } from '../../types/enums'
import {
  DateRangeValue,
  DateRangeValuePiece,
  EvidenceLibraryFilterType,
} from '../../types/types'
import { useAppSelector } from '../../hooks/hooks'
import { useGetEvidencesQuery } from '../../services/evidences'
import { UploadEvidenceContext } from '../UploadEvidenceContext/uploadEvidence-context'

interface Props {
  children: React.ReactNode
}

enum EvidenceLibraryActionKind {
  SET_SEARCH_INPUT_VALUE = 'SET_SEARCH_INPUT_VALUE',
  SET_EVIDENCE_DATA_IS_LOADING = 'SET_EVIDENCE_DATA_IS_LOADING',
  SET_EVIDENCE_TYPE_FILTER = 'SET_EVIDENCE_TYPE_FILTER',
  SET_SEARCH_FILTER = 'SET_SEARCH_FILTER',
  SET_EVIDENCE_DATA = 'SET_EVIDENCE_DATA',
  SET_FILTERED_EVIDENCE = 'SET_FILTERED_EVIDENCE',
}

interface EvidenceLibraryAction {
  type: EvidenceLibraryActionKind
  payload: string | boolean | Evidence[] | null
}

const reducerFunction = (
  state: EvidenceLibraryState,
  action: EvidenceLibraryAction
): EvidenceLibraryState => {
  const { type, payload } = action
  switch (type) {
    case EvidenceLibraryActionKind.SET_SEARCH_INPUT_VALUE:
      return {
        ...state,
        searchInputValue: payload as string,
      }
    case EvidenceLibraryActionKind.SET_EVIDENCE_DATA:
      return {
        ...state,
        evidenceData: payload as Evidence[],
      }
    case EvidenceLibraryActionKind.SET_EVIDENCE_DATA_IS_LOADING:
      return {
        ...state,
        isLoadingEvidenceData: payload as boolean,
      }
    case EvidenceLibraryActionKind.SET_SEARCH_FILTER:
      return {
        ...state,
        filterBySearch: payload as string | null,
      }
    case EvidenceLibraryActionKind.SET_EVIDENCE_TYPE_FILTER:
      return {
        ...state,
        filterByTypeOfEvidence: payload as 'private' | 'public' | null,
      }

    case EvidenceLibraryActionKind.SET_FILTERED_EVIDENCE:
      return {
        ...state,
        filteredEvidence: payload as Evidence[],
      }
    default:
      return state
  }
}

const EvidenceLibraryContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialEvidenceLibraryState
  )
  const { role } = useAppSelector(state => state.user)
  const {
    data: evidenceData,
    isSuccess: isSuccessEvidenceData,
    isLoading: isLoadingEvidenceData,
    isError: isErrorEvidenceData,
    error: errorEvidenceData,
  } = useGetEvidencesQuery()

  const {
    actions: {
      onClickAddOrUploadDocumentButton,
      onClickCloseAddOrUploadModalButton,
    },
  } = useContext(UploadEvidenceContext)

  const {
    [FilterEvidenceUrlParamNames.typeParam]: typeParam,
    [FilterEvidenceUrlParamNames.searchParam]: searchParam,
    [FilterEvidenceUrlParamNames.isUploadModeParam]: isUploadModeParam,
  } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    dispatch({
      type: EvidenceLibraryActionKind.SET_EVIDENCE_DATA_IS_LOADING,
      payload: isLoadingEvidenceData,
    })
  }, [isLoadingEvidenceData])

  // useEffect(() => {
  //   if (!searchParam || searchParam === 'null') {
  //     dispatch({
  //       type: EvidenceLibraryActionKind.SET_SEARCH_INPUT_VALUE,
  //       payload: '',
  //     })
  //     dispatch({
  //       type: EvidenceLibraryActionKind.SET_SEARCH_FILTER,
  //       payload: null,
  //     })
  //   } else {
  //     dispatch({
  //       type: EvidenceLibraryActionKind.SET_SEARCH_INPUT_VALUE,
  //       payload: decodeURIComponent(searchParam),
  //     })
  //     dispatch({
  //       type: EvidenceLibraryActionKind.SET_SEARCH_FILTER,
  //       payload: decodeURIComponent(searchParam),
  //     })
  //   }
  // }, [searchParam])
  useEffect(() => {
    if (
      !isUploadModeParam ||
      isUploadModeParam === 'null' ||
      isUploadModeParam === 'false'
    ) {
      onClickCloseAddOrUploadModalButton()
    } else {
      onClickAddOrUploadDocumentButton()
    }
  }, [isUploadModeParam])

  useEffect(() => {
    if (!searchParam || searchParam === 'null') {
      dispatch({
        type: EvidenceLibraryActionKind.SET_SEARCH_INPUT_VALUE,
        payload: '',
      })
      dispatch({
        type: EvidenceLibraryActionKind.SET_SEARCH_FILTER,
        payload: null,
      })
    } else {
      dispatch({
        type: EvidenceLibraryActionKind.SET_SEARCH_INPUT_VALUE,
        payload: decodeURIComponent(searchParam),
      })
      dispatch({
        type: EvidenceLibraryActionKind.SET_SEARCH_FILTER,
        payload: decodeURIComponent(searchParam),
      })
    }
  }, [searchParam])

  useEffect(() => {
    if (!typeParam || typeParam === 'null') {
      dispatch({
        type: EvidenceLibraryActionKind.SET_EVIDENCE_TYPE_FILTER,
        payload: null,
      })
    } else {
      dispatch({
        type: EvidenceLibraryActionKind.SET_EVIDENCE_TYPE_FILTER,
        payload: typeParam as string,
      })
    }
  }, [typeParam])

  useEffect(() => {
    if (isSuccessEvidenceData && evidenceData) {
      const sortedSavedAnswersData = [...evidenceData].sort((a, b) => {
        const dateA = new Date(a.updated_at)
        const dateB = new Date(b.updated_at)

        return dateB.getTime() - dateA.getTime()
      })

      dispatch({
        type: EvidenceLibraryActionKind.SET_EVIDENCE_DATA,
        payload: sortedSavedAnswersData,
      })
      dispatch({
        type: EvidenceLibraryActionKind.SET_FILTERED_EVIDENCE,
        payload: sortedSavedAnswersData,
      })
    }
  }, [isSuccessEvidenceData, evidenceData, searchParam])

  useEffect(() => {
    if (state.filterBySearch || state.filterByTypeOfEvidence) {
      let filteredEvidence = [...state.evidenceData]
      if (state.filterBySearch) {
        const options = {
          isCaseSensitive: false,
          location: 20,
          threshold: 0.4,
          distance: 100,
          minMatchCharLength: 2,
          keys: ['category', 'file.friendly_name'],
        }
        const fuse = new Fuse(filteredEvidence, options)
        filteredEvidence = groupEvidenceFuse(fuse.search(state.filterBySearch))
      }
      if (state.filterByTypeOfEvidence) {
        filteredEvidence = filteredEvidence.filter(evidenceItem => {
          const { type } = evidenceItem
          if (state.filterByTypeOfEvidence === 'private') {
            return type === 'PRIVATE'
          }
          if (state.filterByTypeOfEvidence === 'public') {
            return type === 'PUBLIC'
          }
          return false
        })
      }

      dispatch({
        type: EvidenceLibraryActionKind.SET_FILTERED_EVIDENCE,
        payload: [...filteredEvidence],
      })
    } else {
      dispatch({
        type: EvidenceLibraryActionKind.SET_FILTERED_EVIDENCE,
        payload: state.evidenceData,
      })
    }
  }, [state.filterBySearch, state.filterByTypeOfEvidence, state.evidenceData])

  const onSearchInputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value as string
    dispatch({
      type: EvidenceLibraryActionKind.SET_SEARCH_INPUT_VALUE,
      payload: newValue as string,
    })
  }

  const onFilterChangeHandler = ({
    filterType,
    newValue,
  }: {
    filterType: EvidenceLibraryFilterType
    newValue: string
  }) => {
    let currentSearchUrl = 'null'
    let currentTypeUrl = 'null'
    let currentIsUploadModeUrl = 'null'

    if (state.filterBySearch) {
      currentSearchUrl = state.filterBySearch
    }

    if (state.filterByTypeOfEvidence) {
      currentTypeUrl = state.filterByTypeOfEvidence.toLowerCase()
    }

    if (filterType === 'search') {
      currentSearchUrl = newValue as string
    }

    if (filterType === 'type') {
      currentTypeUrl = newValue.toLowerCase() as string
    }

    navigate(
      `/evidence-library/${encodeURIComponent(
        currentTypeUrl
      )}/${encodeURIComponent(currentSearchUrl)}/${encodeURIComponent(
        currentIsUploadModeUrl
      )}/`
    )
  }

  const onClickOpenUploadModelHandler = () => {
    let currentSearchUrl = 'null'
    let currentTypeUrl = 'null'
    let currentIsUploadModeUrl = 'null'

    if (state.filterBySearch) {
      currentSearchUrl = state.filterBySearch
    }

    if (state.filterByTypeOfEvidence) {
      currentTypeUrl = state.filterByTypeOfEvidence.toLowerCase()
    }

    navigate(
      `/evidence-library/${encodeURIComponent(
        currentTypeUrl
      )}/${encodeURIComponent(currentSearchUrl)}/true/`
    )
  }

  const onClickCloseUploadModelHandler = () => {
    let currentSearchUrl = 'null'
    let currentTypeUrl = 'null'
    let currentIsUploadModeUrl = 'null'

    if (state.filterBySearch) {
      currentSearchUrl = state.filterBySearch
    }

    if (state.filterByTypeOfEvidence) {
      currentTypeUrl = state.filterByTypeOfEvidence.toLowerCase()
    }

    navigate(
      `/evidence-library/${encodeURIComponent(
        currentTypeUrl
      )}/${encodeURIComponent(currentSearchUrl)}/null/`
    )
  }

  const ctxObject: EvidenceLibraryContextSchema = {
    state: state,
    actions: {
      onSearchInputChange: onSearchInputChangeHandler,
      onFilterChange: onFilterChangeHandler,
      onClickOpenUploadMode: onClickOpenUploadModelHandler,
      onClickCloseUploadMode: onClickCloseUploadModelHandler,
    },
  }

  let content = children

  if (isErrorEvidenceData) {
    return <ErrorView errorData={errorEvidenceData} />
  }

  return (
    <EvidenceLibraryContext.Provider value={ctxObject}>
      {content}
    </EvidenceLibraryContext.Provider>
  )
}

export default EvidenceLibraryContextProvider
