import React from 'react'
import {
  SxProps,
  TableCell,
  TableCellProps,
  Typography,
  useTheme,
} from '@mui/material'

interface Props extends TableCellProps {
  children?: React.ReactNode
  label?: string
  sx?: SxProps
}

const CellInNewTable: React.FC<Props> = ({
  children,
  sx,
  label = 'Universal cell in table',
  ...restOfTableCellProps
}) => {
  const theme = useTheme()

  const cellSxProps: SxProps = {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.components?.MuiTableCell?.styleOverrides?.root![
      'borderColor'
    ]!}`,
    px: theme.spacing(2),
    py: theme.spacing(1.5),
    verticalAlign: 'top',
    ...sx,
  }

  const tableCellProps: TableCellProps = {
    align: 'center',
    ...restOfTableCellProps,
  }

  if (children) {
    return (
      <TableCell sx={{ ...cellSxProps }} {...tableCellProps}>
        {children}
      </TableCell>
    )
  }
  return (
    <TableCell sx={{ ...cellSxProps }} {...tableCellProps}>
      <Typography sx={{ m: 0 }}>{label}</Typography>
    </TableCell>
  )
}

export default CellInNewTable
