import { createContext } from 'react'

import { EvidenceLibraryContextSchema } from './evidenceLibraryInterfaces'
import { initialEvidenceLibraryState } from './initialEvidenceLibraryState'
import { initialEvidenceLibraryActions } from './initialEvidenceLibraryActions'

const EvidenceLibraryInitialContext: EvidenceLibraryContextSchema = {
  state: initialEvidenceLibraryState,
  actions: initialEvidenceLibraryActions,
}

export const EvidenceLibraryContext = createContext(
  EvidenceLibraryInitialContext
)
