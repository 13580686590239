import { Box, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import SeasonalSurveysTabel from './SeasonalSurveysTabel/SeasonalSurveysTabel'

interface Props {}

const SeasonalSurveysSection: React.FC<Props> = () => {
  const theme = useTheme()

  return (
    <Box sx={{ marginTop: theme.spacing(7.5) }}>
      <Typography
        sx={{ marginBottom: theme.spacing(5) }}
        component={'h2'}
        variant="h5"
      >
        Seasonal Surveys
      </Typography>
      <SeasonalSurveysTabel />
    </Box>
  )
}

export default SeasonalSurveysSection
