import React from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store/store'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material'
import UniversalButton from '../components/ui/Buttons/UniversalButton'
import theme from '../themeData/themeData'
import SelectUniInput from '../components/ui/inputs/SelectUniInput'
import SelectYearInput from '../components/ui/inputs/SelectYearInput'
import DateInput from '../components/ui/inputs/DateInput'
import SelectTargetAudienceInput from '../components/surveys/DropFileZone/SelectTargetAudienceInput'
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const widthOfElements = '48%'

export const useForm = () => {
  const renderForm = ({
    onSubmit,
    onCancel,
    formElements,
    isUploadSurveyLoading,
    submitButtonLabel,
    cancelButtonLabel,
  }: {
    onSubmit: (e: React.SyntheticEvent) => void
    onCancel: () => void
    formElements: {
      id: string
      type: TextFieldProps['type'] | 'multiline' | 'year' | 'select' | 'date'
      label: string
      isRequired?: boolean
      helper?: string
      options?: { value: string; label: string }[]
      currentValue?: string | 'DETAILED' | 'DETAILED' | null
      onChange?:
        | ((e: SelectChangeEvent<string>) => void)
        | ((newValue: string) => void)
    }[]
    isUploadSurveyLoading: boolean
    submitButtonLabel?: string
    cancelButtonLabel?: string
  }): JSX.Element => {
    const formItemsJsx = formElements.map((formElement, index) => {
      const {
        id,
        label,
        type,
        options,
        helper,
        onChange,
        currentValue,
        isRequired,
      } = formElement
      switch (type) {
        case 'text':
          return (
            <TextField
              key={`${id}_${index}`}
              sx={{ div: { marginBottom: 0 }, width: widthOfElements }}
              type={type}
              id={id}
              label={label}
              size="small"
              margin="none"
              helperText={helper}
              required={isRequired ? true : false}
              FormHelperTextProps={{ sx: { m: 0 } }}
            />
          )
        case 'multiline':
          return (
            <TextField
              key={`${id}_${index}`}
              sx={{
                marginTop: theme.spacing(2),
                div: { marginBottom: 0 },
              }}
              type={type}
              id={id}
              label={label}
              size="small"
              margin="none"
              multiline
              fullWidth
              minRows={2}
              FormHelperTextProps={{ sx: { m: 0, marginBottom: 1 } }}
              helperText={helper}
              required={isRequired ? true : false}
            />
          )
        case 'year':
          if (onChange && currentValue !== undefined) {
            return (
              <SelectYearInput
                id={id}
                key={`${id}_${index}`}
                label={label}
                sx={{ width: widthOfElements }}
                helper={helper}
                onChangeSelect={
                  onChange as (e: SelectChangeEvent<string>) => void
                }
                valueSelect={currentValue ? currentValue : ''}
                isRequired={isRequired ? true : false}
              />
            )
          } else
            return (
              <Typography key={`${id}_${index}`}>
                This Field can not be render pleas provide select value and
                select change function
              </Typography>
            )

        case 'select':
          if (onChange && currentValue !== undefined) {
            return (
              <SelectUniInput
                currentValue={currentValue ? currentValue : ''}
                onChange={onChange as (newValue: string) => void}
                key={`${id}_${index}`}
                label={label}
                options={options ? options : []}
                sx={{ width: widthOfElements }}
                helper={helper}
                isRequired={isRequired ? true : false}
              />
            )
          } else
            return (
              <Typography key={`${id}_${index}`}>
                This Select Field can not be render please provide "current
                value" and "onChange" function
              </Typography>
            )
        case 'selectAudience':
          if (onChange && currentValue !== undefined) {
            return (
              <SelectTargetAudienceInput
                currentValue={currentValue ? currentValue : ''}
                onChange={onChange as (newValue: string) => void}
                key={`${id}_${index}`}
                label={label}
                options={options ? options : []}
                sx={{ width: widthOfElements }}
                helper={helper}
                isRequired={isRequired ? true : false}
              />
            )
          } else
            return (
              <Typography key={`${id}_${index}`}>
                This Select Field can not be render please provide "current
                value" and "onChange" function
              </Typography>
            )
        case 'date':
          if (onChange && currentValue !== undefined) {
            return (
              <DateInput
                key={`${id}_${index}`}
                label={label}
                value={currentValue ? currentValue : ''}
                onChange={onChange as (newValue: string) => void}
              />
            )
          } else
            return (
              <Typography key={`${id}_${index}`}>
                This Date Field can not be render please provide "current value"
                and "onChange" function
              </Typography>
            )

        default:
          return (
            <TextField
              key={`${id}_${index}`}
              type={type}
              id={id}
              label={label}
              size="small"
              margin="none"
              required={isRequired ? true : false}
            />
          )
      }
    })

    return (
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{ display: 'flex', flexDirection: 'column' }}
        autoComplete="off"
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: theme.spacing(4),
          }}
        >
          {formItemsJsx}
        </Box>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          sx={{ m: theme.spacing(2) }}
          spacing={theme.spacing(4)}
          alignItems={'center'}
        >
          <UniversalButton
            loading={isUploadSurveyLoading}
            type="submit"
            label={submitButtonLabel ? submitButtonLabel : 'Upload'}
          />
          <UniversalButton
            label={cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
            onClick={onCancel}
          />
        </Stack>
      </Box>
    )
  }
  return [renderForm]
}
