import React, { useContext } from 'react'
import {
  Box,
  Checkbox,
  IconButton,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import CellInNewTable from '../../../ui/tables/NewDesignUniversalTable/CellInNewTable/CellInNewTable'
import { AnswerItem } from '../../../../types/interfaces'
import { convertDateToNiceFormat } from '../../../../utils/utils'
import { ArrowForwardIos } from '@mui/icons-material'
import { AnswersLibraryContext } from '../../../../contexts/AnswersLibraryContext/answers-library-context'
import { useAppSelector } from '../../../../hooks/hooks'
import { paragraphGenerator } from '../../../../utils/paragraphGenerator'
import StatusIcon from '../../../ui/StatusIcon/StatusIcon'
import SelectReviewer from '../../../shared/SelectReviewer/SelectReviewer'

const AnswerRow: React.FC<AnswerItem> = ({
  request: {
    question,
    survey_name,
    survey_question_number,
    survey_question_order,
    survey_id,
  },
  assigned_user,
  updated_at,
  id,
  status,
}) => {
  const theme = useTheme()
  const {
    state: { isAssignReviewerMode, selectedIdsOfResponses, answerSources },
    actions: { onOpenAnswer, onAddItemToSelected, excludItemFromSelected },
  } = useContext(AnswersLibraryContext)

  const { role, companyName, isSuperUser, isAskQuestionModeBlocked } =
    useAppSelector(state => state.user)
  const { isDemoMode } = useAppSelector(state => state.app)
  const dateHardCoded = isDemoMode && survey_name === `Survey ${companyName} EG`
  const today = new Date()
  const todayString = today.toString()

  let statusIcon: JSX.Element = <></>

  if (status === 'CHECKED' && assigned_user !== null) {
    statusIcon = <StatusIcon status="CHECKED_ASSIGNED" />
  } else {
    statusIcon = <StatusIcon status={status} />
  }

  let sourceLabel = isAskQuestionModeBlocked
    ? 'Requested answers'
    : 'Saved answers'

  if (survey_id) {
    const foundSurvey = answerSources.find(sourceItem => {
      const { id } = sourceItem
      return survey_id === id
    })
    if (foundSurvey) {
      sourceLabel = foundSurvey.label
    }
  }

  const conditionToDisplaySelectCell =
    role !== 'CONTRIBUTOR' && role !== 'VIEWER' && isAssignReviewerMode

  const label = { inputProps: { 'aria-label': id } }

  const isItemSelected = selectedIdsOfResponses.find(
    selectedIds => selectedIds === id
  )

  const onChangedCheckboxHandler = () => {
    if (isItemSelected) {
      excludItemFromSelected(id)
    } else {
      onAddItemToSelected(id)
    }
  }

  const isReviewMode = role === 'STUFF' && status === 'UNCHECKED'

  return (
    <TableRow>
      {conditionToDisplaySelectCell && (
        <CellInNewTable sx={{ px: theme.spacing(1), py: theme.spacing(0.4) }}>
          <Checkbox
            {...label}
            onChange={onChangedCheckboxHandler}
            checked={isItemSelected ? true : false}
            sx={{ my: 0 }}
          />
        </CellInNewTable>
      )}
      {role !== 'VIEWER' && (
        <CellInNewTable>
          <Box
            sx={{
              p: 0,
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {statusIcon}
          </Box>
          {/* <IconButton>{statusIcon}</IconButton> */}
        </CellInNewTable>
      )}
      <CellInNewTable align="left">
        {paragraphGenerator({
          text: question,
          variant: 'h6',
          component: 'h3',
          sx: { m: 0, lineHeight: '24px', wordBreak: 'break-word' },
        })}
      </CellInNewTable>
      <CellInNewTable label={sourceLabel}>
        <Typography sx={{ m: 0, lineHeight: '24px' }}>{sourceLabel}</Typography>
        {isSuperUser && (
          <>
            {survey_question_number !== null && (
              <span>({survey_question_number})</span>
            )}
            {survey_question_order !== null && (
              <span>({survey_question_order})</span>
            )}
          </>
        )}
      </CellInNewTable>
      {role !== 'VIEWER' && role !== 'CONTRIBUTOR' && (
        <CellInNewTable
          sx={{ textAlign: isAssignReviewerMode ? 'left' : 'center' }}
        >
          {isAssignReviewerMode ? (
            <SelectReviewer
              responseId={id}
              selectReviewerValue={
                assigned_user?.id ? assigned_user?.id : 'unassigned'
              }
            />
          ) : assigned_user ? (
            <Typography sx={{ m: 0, lineHeight: '24px' }}>
              {`${assigned_user.first_name} ${
                assigned_user.last_name ? assigned_user.last_name : ''
              }`}
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </CellInNewTable>
      )}
      <CellInNewTable sx={{ minWidth: '110px' }}>
        <Typography sx={{ m: 0, lineHeight: '24px', wordBreak: 'keep-all' }}>
          {dateHardCoded
            ? convertDateToNiceFormat(todayString)
            : convertDateToNiceFormat(updated_at)}
        </Typography>
      </CellInNewTable>
      <CellInNewTable label={'actions'}>
        <Box
          sx={{
            p: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => onOpenAnswer(id, isReviewMode)}
            sx={{ height: '24px', width: '24px' }}
          >
            <ArrowForwardIos sx={{ height: '24px', width: '24px' }} />
          </IconButton>
        </Box>
      </CellInNewTable>
    </TableRow>
  )
}

export default AnswerRow
