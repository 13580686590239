import React, { useEffect, useReducer } from 'react'
import { GeneratedResponseContext } from './generated-response-context'
import {
  GeneratedResponseContextSchema,
  GeneratedResponseState,
} from './generatedResponseInterfaces'
import { initialGeneratedResponseState } from './initialGeneratedResponseState'
import { useLocation, useNavigate } from 'react-router-dom'
import { AnswerItem, SupportiveEvidence } from '../../types/interfaces'
import {
  useSendFeedbackRegResponseMutation,
  useUpdateAnswerMutation,
} from '../../services/responses'
import { useGenerateNewResponseIncludingPrivateEvidenceMutation } from '../../services/generate'
import { useDispatch } from 'react-redux'
import { appActions } from '../../store/App/app-slice'
import { useGetAccessToFileMutation } from '../../services/file'
import LoadingViewForGenerateQuestion from '../../components/LoadingViewForGenerateQuestion/LoadingViewForGenerateQuestion'

interface StateSentByReactDom {
  questionHeading: string
  responseText: string
  responseId: string
  requestId: string
  supportiveEvidences: SupportiveEvidence[]
}

interface Props {
  children: React.ReactNode
}

enum GeneratedResponseActionKind {
  SET_QUESTION_HEADING = 'SET_QUESTION_HEADING',
  SET_RESPONSE_ID = 'SET_RESPONSE_ID',
  SET_REQUEST_ID = 'SET_REQUEST_ID',
  SET_SUPPORTIVE_EVIDENCES = 'SET_SUPPORTIVE_EVIDENCES',
  SET_CURRENT_SUPP_EVIDENCE_TABLE_TAB = 'SET_CURRENT_SUPP_EVIDENCE_TABLE_TAB',
  SET_IS_RESPONSE_INCLUDE_PRI_EVI = 'SET_IS_RESPONSE_INCLUDE_PRI_EVI',
  SET_IS_INFO_REGARDING_TYPE_OF_EVIDENCE_DISPLAYED = 'SET_IS_INFO_REGARDING_TYPE_OF_EVIDENCE_DISPLAYED',
  SET_IS_EDIT_RESPONSE_MODE = 'SET_IS_EDIT_RESPONSE_MODE',
  SET_RESPONSE_BEFORE_EDIT = 'SET_RESPONSE_BEFORE_EDIT',

  CHANGE_RESPONSE_INPUT_VALUE = 'CHANGE_RESPONSE_INPUT_VALUE',
  DELETE_SUPPORTIVE_EVIDENCE = 'DELETE_SUPPORTIVE_EVIDENCE',

  SET_IS_LOADING_IN_REGENERATE_RESPONSE = 'SET_IS_LOADING_IN_REGENERATE_RESPONSE',
  SET_IS_SUCCESS_IN_REGENERATE_RESPONSE = 'SET_IS_SUCCESS_IN_REGENERATE_RESPONSE',
  SET_IS_ERROR_IN_REGENERATE_RESPONSE = 'SET_IS_ERROR_IN_REGENERATE_RESPONSE',

  SET_IS_LOADING_IN_SEND_FEEDBACK = 'SET_IS_LOADING_IN_SEND_FEEDBACK',
  SET_IS_SUCCESS_IN_SEND_FEEDBACK = 'SET_IS_SUCCESS_IN_SEND_FEEDBACK',
  SET_IS_ERROR_IN_SEND_FEEDBACK = 'SET_IS_ERROR_IN_SEND_FEEDBACK',

  SET_IS_LOADING_IN_UPDATE_RESPONSE = 'SET_IS_LOADING_IN_UPDATE_RESPONSE',
  SET_IS_SUCCESS_IN_UPDATE_RESPONSE = 'SET_IS_SUCCESS_IN_UPDATE_RESPONSE',
  SET_IS_ERROR_IN_UPDATE_RESPONSE = 'SET_IS_ERROR_IN_UPDATE_RESPONSE',
}

interface GeneratedResponseAction {
  type: GeneratedResponseActionKind
  payload:
    | string
    | boolean
    | SupportiveEvidence[]
    | SupportiveEvidence['id']
    | GeneratedResponseState['currSupEvidenceTab']
}

const reducerFunction = (
  state: GeneratedResponseState,
  action: GeneratedResponseAction
): GeneratedResponseState => {
  const { type, payload } = action
  switch (type) {
    case GeneratedResponseActionKind.CHANGE_RESPONSE_INPUT_VALUE:
      return {
        ...state,
        responseInputValue: payload as string,
      }
    case GeneratedResponseActionKind.SET_RESPONSE_ID:
      return {
        ...state,
        responseId: payload as string,
      }
    case GeneratedResponseActionKind.SET_REQUEST_ID:
      return {
        ...state,
        requestId: payload as string,
      }
    case GeneratedResponseActionKind.SET_QUESTION_HEADING:
      return {
        ...state,
        questionHeading: payload as string,
      }
    case GeneratedResponseActionKind.SET_SUPPORTIVE_EVIDENCES:
      return {
        ...state,
        supportiveEvidences: [...(payload as SupportiveEvidence[])],
      }
    case GeneratedResponseActionKind.SET_CURRENT_SUPP_EVIDENCE_TABLE_TAB:
      return {
        ...state,
        currSupEvidenceTab:
          payload as GeneratedResponseState['currSupEvidenceTab'],
      }
    case GeneratedResponseActionKind.SET_IS_LOADING_IN_SEND_FEEDBACK:
      return {
        ...state,
        isLoadingSendFeedback: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_SUCCESS_IN_SEND_FEEDBACK:
      return {
        ...state,
        isLoadingSendFeedback: false,
        isSuccessInSendFeedback: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_ERROR_IN_SEND_FEEDBACK:
      return {
        ...state,
        isLoadingSendFeedback: false,
        isErrorInSendFeedback: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_LOADING_IN_UPDATE_RESPONSE:
      return {
        ...state,
        isLoadingInUpdateResponse: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_SUCCESS_IN_UPDATE_RESPONSE:
      return {
        ...state,
        isLoadingSendFeedback: false,
        isSuccessInUpdateResponse: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_ERROR_IN_UPDATE_RESPONSE:
      return {
        ...state,
        isLoadingSendFeedback: false,
        isErrorInUpdateResponse: payload as boolean,
      }
    case GeneratedResponseActionKind.DELETE_SUPPORTIVE_EVIDENCE:
      return {
        ...state,
        supportiveEvidences: state.supportiveEvidences.filter(
          supEvi => supEvi.id !== (payload as SupportiveEvidence['id'])
        ),
      }
    case GeneratedResponseActionKind.SET_IS_LOADING_IN_REGENERATE_RESPONSE:
      return {
        ...state,
        isLoadingInRegenerateResponse: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_ERROR_IN_REGENERATE_RESPONSE:
      return {
        ...state,
        isLoadingInRegenerateResponse: false,
        isErrorInRegenerateResponse: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_SUCCESS_IN_REGENERATE_RESPONSE:
      return {
        ...state,
        isLoadingInRegenerateResponse: false,
        isSuccessInRegenerateResponse: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_RESPONSE_INCLUDE_PRI_EVI:
      return {
        ...state,
        isResIncludePrivateEvi: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_INFO_REGARDING_TYPE_OF_EVIDENCE_DISPLAYED:
      return {
        ...state,
        isInfoRegardingTypeOfEvidenceDisplayed: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_IS_EDIT_RESPONSE_MODE:
      return {
        ...state,
        isEditResponseMode: payload as boolean,
      }
    case GeneratedResponseActionKind.SET_RESPONSE_BEFORE_EDIT:
      return {
        ...state,
        responseBeforeEdit: payload as string,
      }
    default:
      return state
  }
}

const GeneratedResponseContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialGeneratedResponseState
  )

  const dispatchStore = useDispatch()

  const { responseInputValue, responseId, requestId } = state

  const [
    updateResponse,
    {
      isSuccess: isSuccessInUpdateResponse,
      isError: isErrorInUpdateResponse,
      isLoading: isLoadingInUpdateResponse,
      data: dataInUpdateResponse,
    },
  ] = useUpdateAnswerMutation()

  const [
    sendFeedback,
    {
      isError: isErrorInSendFeedback,
      isLoading: isLoadindInSendFeedback,
      isSuccess: isSuccessInSendFedback,
    },
  ] = useSendFeedbackRegResponseMutation()

  const [
    regenerateResponseIncludingPrivEvi,
    {
      data: dataFromRegResIncPriv,
      isError: isErrorInRegResponse,
      isLoading: isLoadindInRegResponse,
      isSuccess: isSuccessInRegResponse,
    },
  ] = useGenerateNewResponseIncludingPrivateEvidenceMutation()

  const [
    getAccessToEvidenceFile,
    {
      // isError: isErrorInGetAccessToEvidenceFile,
      isSuccess: getAccessToEvidenceFileIsSuccess,
      // isLoading: isGetAccessToEvidenceFileLoading,
      data: dataToAccesFile,
      // reset: resetGetAccessToEvidence,
    },
  ] = useGetAccessToFileMutation()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccessInUpdateResponse && dataInUpdateResponse) {
      navigate(
        `/answers-library/null/null/null/null/null/null/null/${dataInUpdateResponse.id}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInUpdateResponse])

  useEffect(() => {
    return () => {
      dispatchStore(appActions.hideSnackbarNotification())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_LOADING_IN_SEND_FEEDBACK,
      payload: isLoadindInSendFeedback,
    })
  }, [isLoadindInSendFeedback])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_SUCCESS_IN_SEND_FEEDBACK,
      payload: isSuccessInSendFedback,
    })
  }, [isSuccessInSendFedback])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_ERROR_IN_SEND_FEEDBACK,
      payload: isErrorInSendFeedback,
    })
  }, [isErrorInSendFeedback])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_LOADING_IN_UPDATE_RESPONSE,
      payload: isLoadingInUpdateResponse,
    })
  }, [isLoadingInUpdateResponse])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_SUCCESS_IN_UPDATE_RESPONSE,
      payload: isSuccessInUpdateResponse,
    })
  }, [isSuccessInUpdateResponse])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_ERROR_IN_UPDATE_RESPONSE,
      payload: isErrorInUpdateResponse,
    })
  }, [isErrorInUpdateResponse])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_LOADING_IN_REGENERATE_RESPONSE,
      payload: isLoadindInRegResponse,
    })
  }, [isLoadindInRegResponse])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_SUCCESS_IN_REGENERATE_RESPONSE,
      payload: isSuccessInRegResponse,
    })
    if (isSuccessInRegResponse) {
      dispatch({
        type: GeneratedResponseActionKind.SET_IS_RESPONSE_INCLUDE_PRI_EVI,
        payload: true,
      })
      window.scrollTo(0, 0)
      dispatchStore(
        appActions.showSnackbarNotification({
          label: 'Your response has been refined with private evidence.',
          type: 'success',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInRegResponse])

  useEffect(() => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_ERROR_IN_REGENERATE_RESPONSE,
      payload: isErrorInRegResponse,
    })
    if (isErrorInRegResponse) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label:
            'Something went wrong while trying to update your response with private evidence. Please try again later or contact support for assistance.',
          type: 'error',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInRegResponse])

  useEffect(() => {
    if (location.state === null) {
      navigate('/ask-question')
    } else {
      const {
        questionHeading,
        responseText,
        responseId,
        supportiveEvidences,
        requestId,
      } = location.state as StateSentByReactDom
      dispatch({
        type: GeneratedResponseActionKind.CHANGE_RESPONSE_INPUT_VALUE,
        payload: responseText,
      })
      dispatch({
        type: GeneratedResponseActionKind.SET_RESPONSE_ID,
        payload: responseId,
      })
      dispatch({
        type: GeneratedResponseActionKind.SET_REQUEST_ID,
        payload: requestId,
      })
      dispatch({
        type: GeneratedResponseActionKind.SET_QUESTION_HEADING,
        payload: questionHeading,
      })
      dispatch({
        type: GeneratedResponseActionKind.SET_SUPPORTIVE_EVIDENCES,
        payload: supportiveEvidences,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    if (dataFromRegResIncPriv) {
      const { text, id, request_id } = dataFromRegResIncPriv
      dispatch({
        type: GeneratedResponseActionKind.CHANGE_RESPONSE_INPUT_VALUE,
        payload: text,
      })
      dispatch({
        type: GeneratedResponseActionKind.SET_RESPONSE_ID,
        payload: id,
      })
      dispatch({
        type: GeneratedResponseActionKind.SET_REQUEST_ID,
        payload: request_id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInRegResponse])

  useEffect(() => {
    if (getAccessToEvidenceFileIsSuccess && dataToAccesFile) {
      const { url: urlWithAccess, path } = dataToAccesFile

      let isPdfFile: boolean = false
      if (path.lastIndexOf('.pdf') !== -1) {
        isPdfFile = true
      }
      window.open(urlWithAccess, isPdfFile ? '_blank' : '_self', 'noreferrer')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToEvidenceFileIsSuccess, dataToAccesFile])

  const onResponseInputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value as string
    dispatch({
      type: GeneratedResponseActionKind.CHANGE_RESPONSE_INPUT_VALUE,
      payload: newValue as string,
    })
  }

  const onResponseMarkdownInputChangeHandler = (newMarkdown: string) => {
    dispatch({
      type: GeneratedResponseActionKind.CHANGE_RESPONSE_INPUT_VALUE,
      payload: newMarkdown,
    })
  }

  const onClickThumbNailHandler = feedback => {
    // if (responseId) {
    //   sendFeedback({
    //     responseId,
    //     body: {
    //       is_user_approved: false,
    //       feedback: feedback,
    //     },
    //   })
    // }
  }

  const onClickRegenerateResponseHandler = () => {
    if (requestId) {
      regenerateResponseIncludingPrivEvi(requestId)
    }
  }

  const onClickSaveToMyAnswersButtonHandler = () => {
    if (responseId) {
      updateResponse({
        responseId,
        body: {
          text: responseInputValue,
          status: 'CHECKED',
        },
      })
    }
  }

  const onClickTabButtonHandler = (
    demandedTabType: GeneratedResponseState['currSupEvidenceTab']
  ) => {
    dispatch({
      type: GeneratedResponseActionKind.SET_CURRENT_SUPP_EVIDENCE_TABLE_TAB,
      payload: demandedTabType,
    })
  }

  const onDeleteSupportiveEvidenceFromResponseHandler = (
    supEviId: SupportiveEvidence['id']
  ) => {
    dispatch({
      type: GeneratedResponseActionKind.DELETE_SUPPORTIVE_EVIDENCE,
      payload: supEviId,
    })
  }

  const onClickCloseInfoRegardingTypeOfEvidenceHandler = () => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_INFO_REGARDING_TYPE_OF_EVIDENCE_DISPLAYED,
      payload: false,
    })
  }

  const switchOnEditModeHandler = () => {
    dispatch({
      type: GeneratedResponseActionKind.SET_RESPONSE_BEFORE_EDIT,
      payload: responseInputValue,
    })
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_EDIT_RESPONSE_MODE,
      payload: true,
    })
  }

  const switchOffEditModeHandler = () => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_EDIT_RESPONSE_MODE,
      payload: false,
    })
  }

  const onClickCancelChangeResponseHandler = () => {
    dispatch({
      type: GeneratedResponseActionKind.SET_IS_EDIT_RESPONSE_MODE,
      payload: false,
    })
    dispatch({
      type: GeneratedResponseActionKind.CHANGE_RESPONSE_INPUT_VALUE,
      payload: state.responseBeforeEdit,
    })
  }

  const onClickOpenPrivateEvidenceHandler = (fileId: string) => {
    getAccessToEvidenceFile(fileId)
  }

  const ctxObject: GeneratedResponseContextSchema = {
    state: state,
    actions: {
      onResponseInputChange: onResponseInputChangeHandler,
      onClickThumbNail: onClickThumbNailHandler,
      onClickRegenerateResponse: onClickRegenerateResponseHandler,
      onClickSaveToMyAnswersButton: onClickSaveToMyAnswersButtonHandler,
      onClickTabButton: onClickTabButtonHandler,
      onDeleteSupportiveEvidenceFromResponse:
        onDeleteSupportiveEvidenceFromResponseHandler,
      onClickCloseInfoRegardingTypeOfEvidence:
        onClickCloseInfoRegardingTypeOfEvidenceHandler,
      switchOffEditMode: switchOffEditModeHandler,
      switchOnEditMode: switchOnEditModeHandler,
      onClickCancelEditResponse: onClickCancelChangeResponseHandler,
      onClickOpenPrivateEvidence: onClickOpenPrivateEvidenceHandler,
      onResponseMarkdownInputChange: onResponseMarkdownInputChangeHandler,
    },
  }

  let content = children

  if (state.isLoadingInRegenerateResponse || responseId === null) {
    content = <LoadingViewForGenerateQuestion />
  }

  return (
    <GeneratedResponseContext.Provider value={ctxObject}>
      {content}
    </GeneratedResponseContext.Provider>
  )
}

export default GeneratedResponseContextProvider
