import React from 'react'
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  useTheme,
} from '@mui/material'
import AppDialog from '../../../ui/HOC/AppDialog/AppDialog'
import UniversalButton from '../../../ui/Buttons/UniversalButton'
import { useNavigate } from 'react-router-dom'

interface Props {
  isDialogOpen: boolean
  onCloseDialog: () => void
}

const FormIsBlockedDialog: React.FC<Props> = ({
  isDialogOpen,
  onCloseDialog,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const onClickHandler = () => {
    navigate('/surveys')
  }
  return (
    <AppDialog isDialogOpen={isDialogOpen} onCloseDialog={onCloseDialog}>
      <DialogTitle id="alert-dialog-title">
        Upload surveys to enable "Ask Single Question"
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          In order to enable the "Ask Single Question" function for your
          company, it is essential for our system to undergo training using the
          ESG data specific to your organisation, obtained from the "Upload
          Survey" function and completion of ESG surveys.
        </DialogContentText>
        <DialogContentText>
          Please click below to upload a survey, we will notify you promptly
          once the "Ask Single Question" feature becomes available for your use.
        </DialogContentText>
      </DialogContent>
      <Box>
        <Stack
          direction={'row'}
          spacing={theme.spacing(3)}
          justifyContent="center"
          alignItems="center"
        >
          <UniversalButton
            label={'Go To Upload Survey mode'}
            onClick={onClickHandler}
          />
        </Stack>
      </Box>
    </AppDialog>
  )
}

export default FormIsBlockedDialog
