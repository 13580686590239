import { createContext } from 'react'
import { AnswerPrevievContext } from './answerPreviewContextInterfaces'
import { initialAnswerPreviewState } from './initialAnswerPreviewState'
import { initialAnswerPreviewActions } from './initialAnswerPreviewActions'

const answerPreviewInitialState: AnswerPrevievContext = {
  state: initialAnswerPreviewState,
  answerData: {
    id: '',
    questionHeading: '',
    answerText: '',
    supportiveEvidence: [],
    isSupportiveEvidenceLoading: false,
    status: 'NULL',
    assignedUser: null,
    dateOfApproval: null,
    isSaveAnswerLoading: false,
    originalResponse: null,
  },
  createMissingEvidenceData: {
    isLoadingCreateMissingEvidence: false,
    isErrorCreateMissingEvidence: false,
    isSuccessCreateMissingEvidence: false,
  },
  actions: initialAnswerPreviewActions,
}

export const AnswerPreviewContext = createContext(answerPreviewInitialState)
