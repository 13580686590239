import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, SxProps, Typography, useTheme } from '@mui/material'
import { diffLines } from 'diff'
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  tablePlugin,
  linkPlugin,
  directivesPlugin,
  AdmonitionDirectiveDescriptor,
  codeBlockPlugin,
  markdownShortcutPlugin,
  MDXEditorMethods,
} from '@mdxeditor/editor'
import { AnswerPreviewContext } from '../../../../contexts/AnswerPreview/answer-preview-contex'
import { AnswerItem } from '../../../../types/interfaces'
import { convertDateToNiceFormat } from '../../../../utils/utils'

interface Props {
  original: string
  edited: string
  boxTitleStyling: SxProps
  modifiedDate: string | undefined
}

interface DiffRendererProps {
  markdownWithDifferenceState: string
}

const DiffRenderer: React.FC<DiffRendererProps> = ({
  markdownWithDifferenceState,
}) => {
  const processedMarkdown = (markdown: string) => {
    return markdown.replace(/\[(.*?)\]\((.*?)\)/g, (match, text, href) => {
      return `<a style="display:inline; color:black;" href="${href}" target="_blank">${text}</a>`
    })
  }

  const ref = React.useRef<MDXEditorMethods>(null)

  useEffect(() => {
    ref.current?.setMarkdown(markdownWithDifferenceState)
  }, [markdownWithDifferenceState])

  return (
    <Box className="diff-renderer">
      <MDXEditor
        ref={ref}
        markdown={processedMarkdown(markdownWithDifferenceState)}
        readOnly={true}
        contentEditableClassName="prose"
        plugins={[
          directivesPlugin({
            directiveDescriptors: [AdmonitionDirectiveDescriptor],
          }),
          linkPlugin(),
          listsPlugin(),
          headingsPlugin(),
          codeBlockPlugin(),
          quotePlugin(),
          markdownShortcutPlugin(),
          thematicBreakPlugin(),
          tablePlugin(),
        ]}
      />
    </Box>
  )
}

const AnswerBoxWithDiff: React.FC<Props> = ({
  original,
  edited,
  boxTitleStyling,
  modifiedDate,
}) => {
  const theme = useTheme()

  const {
    state: { areAnyChangesCompareToOriginal },
    actions: { onDifferenceCompareToOriginalCheck },
  } = useContext(AnswerPreviewContext)

  const [markdownWithDifferenceState, setMarkdownWithDifferenceState] =
    useState<string>('')

  const [calculatedDiff, setCalculatedDiff] = useState<
    { value: string; removed?: boolean; added?: boolean }[]
  >([])

  useEffect(() => {
    const calculatedDiff = diffLines(original, edited)
    setCalculatedDiff(calculatedDiff)
  }, [original, edited])

  useEffect(() => {
    if (calculatedDiff.length > 0) {
      const foundAddedItem = calculatedDiff.find(item => item.added === true)
      const removedItem = calculatedDiff.find(item => item.removed === true)

      if (foundAddedItem || removedItem) {
        onDifferenceCompareToOriginalCheck(true)
      } else {
        onDifferenceCompareToOriginalCheck(false)
      }
      let markdownWithDifference = ''
      calculatedDiff.forEach(part => {
        if (part.added) {
          markdownWithDifference += `
:::info
${part.value}
:::
      `
        } else if (part.removed) {
          markdownWithDifference += `
:::danger
${part.value}
:::
      `
        } else {
          markdownWithDifference += part.value
        }
      })
      setMarkdownWithDifferenceState(markdownWithDifference)
    }
  }, [calculatedDiff])

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  if (areAnyChangesCompareToOriginal) {
    return (
      <Box
        id="answerRow"
        sx={{
          borderBottom: `1px solid ${borderColor}`,
          display: 'grid',
          gridTemplateColumns: '130px 1fr',
        }}
      >
        <Box
          id="leftColumn"
          sx={{
            ...boxTitleStyling,
            flexDirection: 'column',
            alignItems: 'center',
            px: theme.spacing(2),
          }}
        >
          <Typography
            variant="h6"
            component={'h4'}
            sx={{ lineHeight: '24px', textAlign: 'center' }}
          >
            Original answer
          </Typography>
        </Box>
        <Box id="rightColumn">
          <Box
            sx={{
              px: theme.spacing(0.5),
              py: theme.spacing(0.5),
              backgroundColor: 'inherit',
              overflowX: 'auto',
            }}
          >
            <DiffRenderer
              markdownWithDifferenceState={markdownWithDifferenceState}
            />
          </Box>

          {modifiedDate && (
            <Box
              sx={{
                borderTop: `1px solid ${borderColor}`,
                display: 'grid',
                gridTemplateColumns: '3.41fr 1fr',
              }}
            >
              <Box
                sx={{
                  borderRight: `1px solid ${borderColor}`,
                  px: theme.spacing(2.4),
                  py: theme.spacing(0.5),
                }}
              >
                <Typography>Generated by EthicsAnswer</Typography>
              </Box>
              <Box
                sx={{
                  px: theme.spacing(2.4),
                  py: theme.spacing(0.5),
                  textAlign: 'center',
                }}
              >
                <Typography>{convertDateToNiceFormat(modifiedDate)}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    )
  } else return null
}

export default AnswerBoxWithDiff
