import React from 'react'
import {
  Box,
  Container,
  ContainerProps,
  SxProps,
  useTheme,
} from '@mui/material'

interface Props {
  children: React.ReactNode
  maxWidth?: ContainerProps['maxWidth']
  sx?: SxProps
}

const MainContentContainer: React.FC<Props> = ({
  children,
  maxWidth = 'lg',
  sx,
}) => {
  const theme = useTheme()
  return (
    <Box
      component={'main'}
      sx={{
        flexGrow: 1,
        height: '100%',
        ...sx,
      }}
    >
      <Container
        maxWidth={maxWidth}
        sx={{
          paddingTop: theme.spacing(7.5),
          paddingBottom: theme.spacing(6),
        }}
      >
        {children}
      </Container>
    </Box>
  )
}

export default MainContentContainer
