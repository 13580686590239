import React, { useContext, useEffect, useReducer } from 'react'
import { useAppDispatch, useForm } from '../../hooks/hooks'
import { CdpFullSeasonalSurveyDetailsContext } from './cdpFullSeasonalSurveyDetails-context'
import {
  CdpFullSeasonalSurveyDetailsContextSchema,
  CdpFullSeasonalSurveyDetailsState,
} from './cdpFullSeasonalSurveyDetailsInterfaces'

import initialCdpFullSeasonalSurveyDetailsState from './initialCdpFullSeasonalSurveyDetailsState'
import { UploadSurveyContext } from '../UploadSurveyContext/uploadSurvey-context'
import { SurveysContext } from '../SurveysContext/surveys-context'

interface Props {
  surveyId: string
  surveyName: string
  children: React.ReactNode
}

enum CdpFullSeasonalSurveyDetailsActionKind {
  SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION',
  SET_CHECKED_ANSWER = 'SET_CHECKED_ANSWER',
  RESET_FORM = 'RESET_FORM',
}

interface CdpFullSeasonalSurveyDetailsAction {
  type: CdpFullSeasonalSurveyDetailsActionKind
  payload: string | number
}

const reducerFunction = (
  state: CdpFullSeasonalSurveyDetailsState,
  action: CdpFullSeasonalSurveyDetailsAction
): CdpFullSeasonalSurveyDetailsState => {
  const { type, payload } = action
  switch (type) {
    case CdpFullSeasonalSurveyDetailsActionKind.SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: payload as number,
      }
    case CdpFullSeasonalSurveyDetailsActionKind.SET_CHECKED_ANSWER:
      let newQuestionsState = [...state.questions]
      const foundIndexOfAnswer = newQuestionsState[
        state.currentQuestion
      ].answers.findIndex(answerItem => answerItem.id === payload)

      newQuestionsState[state.currentQuestion].answers[
        foundIndexOfAnswer!
      ].isChoosen =
        !state.questions[state.currentQuestion].answers[foundIndexOfAnswer!]
          .isChoosen

      return {
        ...state,
        questions: [...newQuestionsState],
      }

    case CdpFullSeasonalSurveyDetailsActionKind.RESET_FORM:
      return { ...initialCdpFullSeasonalSurveyDetailsState }

    default:
      return state
  }
}

const CdpFullSeasonalSurveyDetailsContextProvider: React.FC<Props> = ({
  children,
  surveyId,
  surveyName,
}) => {
  const [state, dispatch] = useReducer(reducerFunction, {
    ...initialCdpFullSeasonalSurveyDetailsState,
    surveyId,
    surveyName,
  })

  const {
    queryResult: { isSuccessInCreateQuestionnaire },
  } = useContext(UploadSurveyContext)

  const {
    actions: { onSetIdOfSeasonalSurveyModalOpen },
  } = useContext(SurveysContext)

  useEffect(() => {
    if (isSuccessInCreateQuestionnaire) {
      dispatch({
        type: CdpFullSeasonalSurveyDetailsActionKind.RESET_FORM,
        payload: '',
      })
      onSetIdOfSeasonalSurveyModalOpen('')
    }
  }, [isSuccessInCreateQuestionnaire])

  const onSetCurrentQuestionHandler = (newValue: number) => {
    dispatch({
      type: CdpFullSeasonalSurveyDetailsActionKind.SET_CURRENT_QUESTION,
      payload: newValue as number,
    })
  }

  const onChangeCheckedAnswerHandler = (id: string) => {
    dispatch({
      type: CdpFullSeasonalSurveyDetailsActionKind.SET_CHECKED_ANSWER,
      payload: id as string,
    })
  }

  const ctxValue: CdpFullSeasonalSurveyDetailsContextSchema = {
    state: {
      surveyId: state.surveyId,
      surveyName: state.surveyName,
      currentQuestion: state.currentQuestion,
      questions: state.questions,
    },
    actions: {
      onSetCurrentQuestion: onSetCurrentQuestionHandler,
      onChangeCheckedAnswer: onChangeCheckedAnswerHandler,
    },
  }

  return (
    <CdpFullSeasonalSurveyDetailsContext.Provider value={ctxValue}>
      {children}
    </CdpFullSeasonalSurveyDetailsContext.Provider>
  )
}

export default CdpFullSeasonalSurveyDetailsContextProvider
