import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CompanyUser, User } from '../types/interfaces'
import type { RootState } from '../store/store'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/users`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Users', 'Me'],
  endpoints: build => ({
    getUserDetails: build.query<User, void>({
      query: () => ({
        url: `/me`,
      }),
      providesTags: ['Me'],
    }),
    updateUserDataDetails: build.mutation<User, Partial<User>>({
      query: body => ({
        url: `/me`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['Me'],
    }),
    getCompanyUsers: build.query<CompanyUser[], void>({
      query: () => ({
        url: `/company`,
      }),
      providesTags: ['Users'],
    }),
    triggerGetCompanyUsers: build.mutation<CompanyUser[], void>({
      query: () => ({
        url: `/company`,
      }),
      invalidatesTags: ['Users'],
    }),
    createCompanyUser: build.mutation<CompanyUser, CompanyUser>({
      query: body => ({
        url: `/company`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Users'],
    }),
    updateCompanyUserRole: build.mutation<
      CompanyUser,
      { id: string; body: Partial<CompanyUser> }
    >({
      query: ({ id, body }) => ({
        url: `/company/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteCompanyUserRole: build.mutation<string, CompanyUser['id']>({
      query: id => ({
        url: `/company/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
})

export const {
  useGetUserDetailsQuery,
  useUpdateUserDataDetailsMutation,
  useGetCompanyUsersQuery,
  useTriggerGetCompanyUsersMutation,
  useUpdateCompanyUserRoleMutation,
  useCreateCompanyUserMutation,
  useDeleteCompanyUserRoleMutation,
} = usersApi
