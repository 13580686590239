import { GeneratedResponseState } from './generatedResponseInterfaces'

export const initialGeneratedResponseState: GeneratedResponseState = {
  questionHeading: '',
  responseId: null,
  requestId: null,
  responseInputValue: '',
  responseBeforeEdit: '',
  supportiveEvidences: [],
  currSupEvidenceTab: 'PUBLIC',
  isResIncludePrivateEvi: false,
  isInfoRegardingTypeOfEvidenceDisplayed: true,
  isEditResponseMode: false,

  isSuccessInRegenerateResponse: false,
  isLoadingInRegenerateResponse: false,
  isErrorInRegenerateResponse: false,

  isSuccessInSendFeedback: false,
  isLoadingSendFeedback: false,
  isErrorInSendFeedback: false,

  isSuccessInUpdateResponse: false,
  isLoadingInUpdateResponse: false,
  isErrorInUpdateResponse: false,
}
