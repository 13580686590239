import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IGeneratedResponse } from '../../types/interfaces'

interface ResponseViewState {
  responses: {
    isSavedAnswerInMyAnswers: boolean
    responseData: IGeneratedResponse
  }[]
}

const initialState: ResponseViewState = {
  responses: [],
}

export const responsesViewSlice = createSlice({
  name: 'responsesView',
  initialState,
  reducers: {
    addResponse: (state, action: PayloadAction<IGeneratedResponse>) => {
      state.responses = state.responses.concat({
        isSavedAnswerInMyAnswers: false,
        responseData: action.payload,
      })
    },
    resetResponses: state => {
      state.responses = []
    },
    updateResponseData: (state, action: PayloadAction<IGeneratedResponse>) => {
      const responseToUpdateIndex = state.responses.findIndex(
        response => response.responseData.id === action.payload.id
      )
      const stateAfterUpdate = [...state.responses]
      stateAfterUpdate[responseToUpdateIndex] = {
        isSavedAnswerInMyAnswers: true,
        responseData: action.payload,
      }

      state.responses = [...stateAfterUpdate]
    },
    updateResponseComment: (
      state,
      action: PayloadAction<{ responseId: string; newComment: string }>
    ) => {
      const responseToUpdateIndex = state.responses.findIndex(
        response => response.responseData.id === action.payload.responseId
      )
      const stateAfterUpdate = [...state.responses]
      stateAfterUpdate[responseToUpdateIndex] = {
        isSavedAnswerInMyAnswers: true,
        responseData: {
          ...stateAfterUpdate[responseToUpdateIndex].responseData,
          answer_comment: null,
        },
      }
      state.responses = [...stateAfterUpdate]
    },
    setResponseSavedInMyAnswers: (
      state,
      action: PayloadAction<IGeneratedResponse['id']>
    ) => {
      const responseToUpdateIndex = state.responses.findIndex(
        response => response.responseData.id === action.payload
      )

      if (responseToUpdateIndex !== -1) {
        const copyOfResponseState = [...state.responses]
        copyOfResponseState[responseToUpdateIndex].isSavedAnswerInMyAnswers =
          true
        state.responses = [...copyOfResponseState]
      } else {
        console.log(
          `Sorry we couldn't find this response id in state of responses`
        )
      }
    },
  },
})

export const responsesViewActions = responsesViewSlice.actions

export default responsesViewSlice.reducer
