import { Box, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import SelectUniInput from '../../ui/inputs/SelectUniInput'
import { EvidenceLibraryContext } from '../../../contexts/EvidenceLibraryContext/evidence-library-context'
import SearchInput from '../../ui/inputs/SearchInput'

interface Props {}

const EvidenceFilters: React.FC<Props> = () => {
  const theme = useTheme()
  const {
    actions: { onFilterChange, onSearchInputChange },
    state: { filterByTypeOfEvidence, searchInputValue },
  } = useContext(EvidenceLibraryContext)

  const onSubmitSearchFormHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onFilterChange({
      filterType: 'search',
      newValue: searchInputValue ? searchInputValue : 'null',
    })
  }

  return (
    <Box
      component={'form'}
      onSubmit={onSubmitSearchFormHandler}
      sx={{
        display: 'flex',
        minWidth: '50%',
      }}
    >
      <SearchInput
        onChangeTextField={onSearchInputChange}
        searchInputValue={searchInputValue}
        sx={{ minWidth: '65%', '>div': { mb: 0 } }}
      />
      <SelectUniInput
        sx={{ minWidth: '30%', marginLeft: theme.spacing(4) }}
        onChange={newValue =>
          onFilterChange({ filterType: 'type', newValue: newValue })
        }
        currentValue={filterByTypeOfEvidence ? filterByTypeOfEvidence : 'null'}
        defaultValue="null"
        label="Filter by evidence type"
        options={[
          { label: 'All types', value: 'null' },
          { label: 'Public', value: 'public' },
          { label: 'Private', value: 'private' },
        ]}
      />
    </Box>
  )
}

export default EvidenceFilters
