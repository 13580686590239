import { CdpFullSeasonalSurveyDetailsState } from './cdpFullSeasonalSurveyDetailsInterfaces'

const initialCdpFullSeasonalSurveyDetailsState: CdpFullSeasonalSurveyDetailsState =
  {
    surveyId: '',
    surveyName: '',
    currentQuestion: 0,
    questions: [
      {
        heading: `Which sectors does your organization derive revenue from?`,
        answers: [
          {
            label: 'Financial services',
            isChoosen: false,
            id: 'Financial_services',
          },
          {
            label: 'Capital goods',
            isChoosen: false,
            id: 'Capital_goods',
          },
          {
            label: 'Metals & mining',
            isChoosen: false,
            id: 'Metals_mining',
          },
          {
            label: 'Agricultural commodities',
            isChoosen: false,
            id: 'Agricultural_commodities',
          },
          {
            label: 'Cement',
            isChoosen: false,
            id: 'Cement',
          },
          {
            label: 'Real estate',
            isChoosen: false,
            id: 'Real_estate',
          },
          {
            label: 'Oil & gas',
            isChoosen: false,
            id: 'Oil_gas',
          },
          {
            label: 'Transport OEMS - EPM',
            isChoosen: false,
            id: 'Transport_OEMS_EPM',
          },
          {
            label: 'Transport services',
            isChoosen: false,
            id: 'Transport_services',
          },
          {
            label: 'Electric utilities',
            isChoosen: false,
            id: 'Electric_utilities',
          },
          {
            label: 'Food, beverage & tobacco',
            isChoosen: false,
            id: 'Food_beverage_tobacco',
          },
          {
            label: 'Construction',
            isChoosen: false,
            id: 'Construction',
          },
          {
            label: 'Coal',
            isChoosen: false,
            id: 'Coal',
          },
          {
            label: 'Chemicals',
            isChoosen: false,
            id: 'Chemicals',
          },
          {
            label: 'Steel',
            isChoosen: false,
            id: 'Steel',
          },
          {
            label: 'Transport OEMS',
            isChoosen: false,
            id: 'Transport_OEMS',
          },
          {
            label: 'Paper & forestry',
            isChoosen: false,
            id: 'Paper_forestry',
          },
          {
            label: 'All other sectors',
            isChoosen: false,
            id: 'All_other_sectors',
          },
        ],
      },
      {
        heading: `Which questions apply to your organization?`,
        answers: [
          {
            label: 'CDP core and sector questions only',
            isChoosen: false,
            id: 'CDP_core',
          },
          {
            label:
              'Supply chain questions – in addition to CDP core and sector questions. These apply if you are responding to a disclosure request from a customer',
            isChoosen: false,
            id: 'Supply_chain_questions',
          },
          {
            label:
              'RE100 initiative questions – in addition to CDP core and sector questions [CDP Climate Change questionnaire only]. These apply only to members of the RE100 initiative.',
            isChoosen: false,
            id: 'RE100',
          },
        ],
      },
      {
        heading: `Which environmental issues would you like guidance on?`,
        answers: [
          {
            label: 'Climate Change',
            isChoosen: false,
            id: 'Climate_Change',
          },
          {
            label: 'Water',
            isChoosen: false,
            id: 'Water',
          },
          {
            label: 'Forests',
            isChoosen: false,
            id: 'Forests',
          },
          {
            label: 'Biodiversity',
            isChoosen: false,
            id: 'Biodiversity',
          },
          {
            label: 'Plastics',
            isChoosen: false,
            id: 'Plastics',
          },
        ],
      },
      {
        heading: `Which commodities would you like guidance on?`,
        answers: [
          {
            label: 'Timber products',
            isChoosen: false,
            id: 'Timber_products',
          },
          {
            label: 'Soy',
            isChoosen: false,
            id: 'Soy',
          },
          {
            label: 'Cattle products',
            isChoosen: false,
            id: 'Cattle_products',
          },
          {
            label: 'Cocoa',
            isChoosen: false,
            id: 'Cocoa',
          },
          {
            label: 'Palm oil',
            isChoosen: false,
            id: 'Palm_oil',
          },
          {
            label: 'Coffee',
            isChoosen: false,
            id: 'Coffee',
          },
          {
            label: 'Rubber',
            isChoosen: false,
            id: 'Rubber',
          },
        ],
      },
    ],
  }

export default initialCdpFullSeasonalSurveyDetailsState
