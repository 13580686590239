import React from 'react'
import { Button, useTheme } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {}

const LogOutButton: React.FC<Props> = () => {
  const { logout } = useAuth0()
  const theme = useTheme()

  const onClickLogoutHandler = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }
  return (
    <Button
      variant={'outlined'}
      sx={{
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      }}
      onClick={onClickLogoutHandler}
    >
      Log Out
    </Button>
  )
}

export default LogOutButton
