import { createContext } from 'react'

import { SimilarQuestionsContextSchema } from './similarQuestionsInterfaces'
import { initialSimilarQuestionsState } from './initialSimilarQuestionsState'
import { initialSimilarQuestionsActions } from './initialSimilarQuestionsActions'

const similarQuestionsInitialContext: SimilarQuestionsContextSchema = {
  state: initialSimilarQuestionsState,
  actions: initialSimilarQuestionsActions,
}

export const SimilarQuestionsContext = createContext(
  similarQuestionsInitialContext
)
