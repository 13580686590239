/* eslint-disable max-classes-per-file */

import { useTheme } from '@mui/material';
import React, { PureComponent } from 'react'
import { Treemap, ResponsiveContainer } from 'recharts'
import theme from '../../../../themeData/themeData';



const COLORS = [
  '#8889DD',
  '#9597E4',
  '#8DC77B',
  '#A5D297',
  '#E2CF45',
  '#F8C12D',
]

function transformData(data) {
  function transformNode(node) {
    const { colname, value, ...rest } = node;

    if (value !== undefined) {
      rest.size = value;
    }

    if (node.children) {
      rest.children = node.children.map(transformNode);
    }

    return rest;
  }

  return transformNode(data);
}

class CustomizedContent extends PureComponent {
  render() {
    const {
      root,
      depth,
      x,
      y,
      width,
      height,
      index,
      payload,
      colors,
      rank,
      name,

    } = this.props

    let color = theme.palette.primary.main

if(depth===1){

    if(name === 'Environmental'){
      color = '#122F08'
    } else if(name === 'Governance') {
color = theme.palette.secondary.contrastText
    }
} else {
    if(root.name === 'Environmental'){
      color = '#122F08'
    } else if(root.name === 'Governance') {
color = theme.palette.secondary.contrastText
    }
}



    return (
      <>
       
      <g>
          <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: depth < 2 ?color : '#ffffff00',

            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
            zIndex:1
          }}
        />
        </g>
             <g>
         {depth === 1 ? (
          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#fff"
            fontSize={14}
            style={{ filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))"}}
            

          >
            {name}
          </text>
        ) : null}
        {depth === 1 ? (
          <text
            x={x + 4}
            y={y + 18}
            fill="#fff"
            fontSize={16}
             style={{ filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))"}}

          >
            {index + 1}
          </text>
        ) : null}
      
      </g>
        
        </>

    )
  }
}

export default class TreeMapChart extends PureComponent {
    
  render() {
     const {
    data
    } = this.props



    const transformedData = transformData(data).children;


    return (
      <ResponsiveContainer width="100%" height="100%">
        <Treemap
          width={400}
          height={200}
          data={transformedData}
          dataKey="size"
          stroke="#fff"
          fill="#8884d8"
          content={<CustomizedContent colors={COLORS} />}
        />
      </ResponsiveContainer>
    )
  }
}
