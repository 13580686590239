import { Box, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { AnswersLibraryContext } from '../../../contexts/AnswersLibraryContext/answers-library-context'
import SearchInput from '../../ui/inputs/SearchInput'
import SelectUniInput from '../../ui/inputs/SelectUniInput'
import RangeDateInput from '../../ui/inputs/RangeDateInput/RangeDateInput'
import {
  AnswerStatusLabel,
  ExtendedAnswerStatus,
} from '../../../types/interfaces'
import { DateRangeValue } from '../../../types/types'
import { useAppSelector } from '../../../hooks/hooks'
import UniversalButton from '../../ui/Buttons/UniversalButton'

const AnswersLibraryFilters: React.FC = () => {
  const {
    state: {
      answersData,
      searchInputValue,
      filterByLastUpdatedRange,
      filterBySearch,
      answerSources,
      filterBySource,
      filterByStatus,
      filterByIsRequest,
      isAssignReviewerMode,
      assignedReviewers,
      filterByAssignedUser,
    },
    actions: {
      onSearchInputChange,
      onFilterChange,
      onToggleIsAssignReviewerMode,
    },
  } = useContext(AnswersLibraryContext)
  const { role } = useAppSelector(state => state.user)

  const theme = useTheme()

  const onSubmitSearchFormHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onFilterChange({
      filterType: 'search',
      newValue: searchInputValue ? searchInputValue : 'null',
    })
  }

  const onChangeSwitchHandler = () => {
    onFilterChange({
      filterType: 'isAnswerRequest',
      newValue: filterByIsRequest ? 'null' : 'true',
    })
  }

  const onChangeRequestedBy = (newValue: string) => {
    onFilterChange({
      filterType: 'isAnswerRequest',
      newValue: newValue,
    })
  }

  const onChangeAssignedReviewer = (newValue: string) => {
    onFilterChange({
      filterType: 'assignedId',
      newValue: newValue,
    })
  }

  let sourceOptions: { label: string; value: string }[] = []

  const allStatusOptions: {
    label: AnswerStatusLabel | 'Show all answers'
    value: ExtendedAnswerStatus | 'null'
  }[] = [
    { label: 'Show all answers', value: 'null' },
    {
      label: 'Approved' as AnswerStatusLabel | 'Show all answers',
      value: 'APPROVED' as ExtendedAnswerStatus | 'null',
    },
    {
      label: 'Reviewed' as AnswerStatusLabel | 'Show all answers',
      value: 'REVIEWED' as ExtendedAnswerStatus | 'null',
    },
    {
      label: 'Unreviewed' as AnswerStatusLabel | 'Show all answers',
      value: 'CHECKED_ASSIGNED' as ExtendedAnswerStatus | 'null',
    },
    {
      label: 'Unassigned' as AnswerStatusLabel | 'Show all answers',
      value: 'CHECKED' as ExtendedAnswerStatus | 'null',
    },
    {
      label: 'Unchecked' as AnswerStatusLabel | 'Show all answers',
      value: 'UNCHECKED' as ExtendedAnswerStatus | 'null',
    },
    {
      label: 'Rejected by Editor' as AnswerStatusLabel | 'Show all answers',
      value: 'REJECTED_REVIEWED' as ExtendedAnswerStatus | 'null',
    },
    {
      label: 'Rejected by Contributor' as
        | AnswerStatusLabel
        | 'Show all answers',
      value: 'REJECTED_CHECKED' as ExtendedAnswerStatus | 'null',
    },
  ]

  let statusOptionAssignedUsers: {
    label: string | 'All users'
    value: string | 'null'
  }[] = [
    {
      label: 'All users',
      value: 'null',
    },
    ...assignedReviewers.map(assignedReviewer => {
      const { id, name } = assignedReviewer
      return { label: name, value: id }
    }),
  ]

  let statusOptionsFilteredByRole: {
    label: AnswerStatusLabel | 'Show all answers'
    value: ExtendedAnswerStatus | 'null'
  }[] = []

  if (role === 'EDITOR' || role === 'ADMIN') {
    statusOptionsFilteredByRole = allStatusOptions.filter(statusOption => {
      const { value } = statusOption
      return value !== 'UNCHECKED'
    })
  } else if (role === 'CONTRIBUTOR') {
    statusOptionsFilteredByRole = allStatusOptions.filter(statusOption => {
      const { value } = statusOption
      return (
        // value !== 'UNCHECKED' && value !== 'CHECKED' && value !== 'APPROVED'
        value !== 'UNCHECKED' && value !== 'CHECKED'
      )
    })
  } else {
    statusOptionsFilteredByRole = [...allStatusOptions]
  }

  if (answerSources) {
    sourceOptions = answerSources.map(source => {
      const { id, label } = source
      return {
        label: label,
        value: id,
      }
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: theme.spacing(5),
        mt: 0,
      }}
    >
      {((answersData && answersData?.length > 0) || filterBySearch) && (
        <Box sx={{ width: '100%' }}>
          <Box
            component={'form'}
            onSubmit={onSubmitSearchFormHandler}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <SearchInput
              onChangeTextField={onSearchInputChange}
              searchInputValue={searchInputValue}
              sx={{ minWidth: '40%' }}
            />
            <RangeDateInput
              value={filterByLastUpdatedRange}
              onInputChange={(newValue: DateRangeValue) =>
                onFilterChange({
                  filterType: 'lastUpdatedDataRange',
                  newValue: newValue as DateRangeValue,
                })
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <SelectUniInput
                sx={{ minWidth: '25%' }}
                label="Filter by source"
                defaultValue="null"
                currentValue={filterBySource ? filterBySource : 'null'}
                onChange={newValue =>
                  onFilterChange({ filterType: 'source', newValue: newValue })
                }
                options={[
                  { label: 'Show all answers', value: 'null' },
                  ...sourceOptions,
                ]}
              />
              {role !== 'VIEWER' && (
                <SelectUniInput
                  isDisabled={isAssignReviewerMode ? true : false}
                  sx={{ minWidth: '25%', marginLeft: theme.spacing(4) }}
                  onChange={newValue =>
                    onFilterChange({
                      filterType: 'status',
                      newValue: newValue,
                    })
                  }
                  currentValue={filterByStatus ? filterByStatus : 'null'}
                  defaultValue="null"
                  label="Filter by status"
                  options={statusOptionsFilteredByRole}
                />
              )}
              {filterByStatus === 'CHECKED_ASSIGNED' && (
                <>
                  <Typography></Typography>
                  <SelectUniInput
                    sx={{ minWidth: '300px', marginLeft: theme.spacing(4) }}
                    label="Filter by assigned user"
                    defaultValue="true"
                    onChange={onChangeAssignedReviewer}
                    currentValue={
                      filterByAssignedUser ? filterByAssignedUser : 'null'
                    }
                    options={statusOptionAssignedUsers}
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                mt: '10px',
              }}
            >
              {/* <Switch
                checked={filterByIsRequest !== null}
                onChange={onChangeSwitchHandler}
              />
              <Typography sx={{ minWidth: '200px' }}>
                Show only requested
              </Typography> */}
              {/* {role !== 'VIEWER' && filterByIsRequest !== null && (
                <SelectUniInput
                  sx={{ minWidth: '300px', marginLeft: theme.spacing(4) }}
                  label="Filter by who requested"
                  defaultValue="true"
                  onChange={onChangeRequestedBy}
                  currentValue={filterByIsRequest ? filterByIsRequest : 'null'}
                  options={[
                    { label: 'Show all requested', value: 'true' },
                    {
                      label: 'Requested by Viewer',
                      value: 'viewer',
                    },
                    {
                      label: 'Requested by me',
                      value: 'owner',
                    },
                  ]}
                />
              )} */}

              {(role === 'ADMIN' || role === 'EDITOR') &&
                !isAssignReviewerMode && (
                  <UniversalButton
                    onClick={onToggleIsAssignReviewerMode}
                    label="Assign reviewers"
                    stylingType={8}
                  />
                )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default AnswersLibraryFilters
