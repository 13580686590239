import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Box, CircularProgress } from '@mui/material'
import UnauthorisedHeader from '../components/UnauthorisedHeader/UnauthorisedHeader'
import LeftSideBar from '../components/LeftSideBar/LeftSideBar'

const Loading: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '300px 1fr',
          minHeight: '100vh',
        }}
      >
        <LeftSideBar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              textAlign: 'center',
              flexGrow: 1,
            }}
          >
            <CircularProgress />
          </Box>
        </Box>

        {/* <Footer /> */}
      </Box>
    </>
  )
}

export default Loading
