import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import {
  AnswerStatusLabel,
  ExtendedAnswerStatus,
} from '../../../types/interfaces'
import StatusIcon from '../StatusIcon/StatusIcon'

interface Props {
  status: ExtendedAnswerStatus
}

const StatusLabel: React.FC<Props> = ({ status }) => {
  const theme = useTheme()
  let label: AnswerStatusLabel = 'Unchecked'

  switch (status) {
    case 'APPROVED':
      label = 'Approved'
      break
    case 'REVIEWED':
      label = 'Reviewed'
      break
    case 'CHECKED_ASSIGNED':
      label = 'Unreviewed'
      break
    case 'CHECKED':
      label = 'Unassigned'
      break
    case 'REJECTED_REVIEWED':
      label = 'Rejected by Editor'
      break
    case 'REJECTED_CHECKED':
      label = 'Rejected by Contributor'
      break

    default:
      break
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StatusIcon status={status} />
      <Typography
        sx={{ ml: theme.spacing(1) }}
        color={theme.palette.primary.main}
      >
        {label}
      </Typography>
    </Box>
  )
}

export default StatusLabel
