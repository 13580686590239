import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Treemap,
  PolarAngleAxis,
  PolarRadiusAxis,
  RadarChart,
  Radar,
  PolarGrid,
  ResponsiveContainer,
  Rectangle,
  Customized,
  Cell,
} from 'recharts'
import { ChartData } from '../../../types/interfaces'
import theme from '../../../themeData/themeData'
import TreeMapChart from './TreeMapChart/TreeMapChart'

const removeDuplicates = (
  array: ChartData['raw_data'][]
): ChartData['raw_data'][] => {
  let hasSeen = new Set()
  let result: ChartData['raw_data'][] = []
  array.forEach(item => {
    if (!hasSeen.has(item.subcategory)) {
      hasSeen.add(item.subcategory)
      result.push(item)
    }
  })
  return result
}

const ChartBox: React.FC<ChartData> = ({
  title,
  raw_data,
  type,
  x_axis_title,
  y_axis_title,
}) => {
  const theme = useTheme()

  const getColorByLabelName = (name: string) => {
    switch (name) {
      case 'Environmental':
        return '#122F08'
      case 'Governance':
        return theme.palette.secondary.contrastText
      case 'Social':
        return theme.palette.primary.main
      default:
        return '#FFE3D3' // default color
    }
  }

  const colors = [
    '#8884d8',
    '#83a6ed',
    '#8dd1e1',
    '#82ca9d',
    '#a4de6c',
    '#d0ed57',
    '#ffc658',
  ]

  let colorByChartTitle = ''

  const COLORS = {
    Environmental: '#122F08',
    Governance: theme.palette.secondary.contrastText,
    Social: theme.palette.primary.main,
  }

  const isEnvironmentalFoundInTitle = title.indexOf('Environmental') !== -1
  const isGovernanceFoundInTitle = title.indexOf('Governance') !== -1
  const isSocialFoundInTitle = title.indexOf('Social') !== -1

  const transformDataForPolar = (data: any) => {
    const result: any[] = []
    const categories = Object.keys(data)

    categories.forEach(category => {
      const subcategories = Object.keys(data[category])

      subcategories.forEach(subcategory => {
        let existingEntry = result.find(
          entry => entry.subcategory === subcategory
        )

        if (!existingEntry) {
          existingEntry = { subcategory }
          categories.forEach(cat => {
            existingEntry[cat] = 0 // Initialize all categories to 0
          })
          result.push(existingEntry)
        }

        existingEntry[category] = data[category][subcategory]
      })
    })

    return result
  }

  if (isEnvironmentalFoundInTitle) {
    colorByChartTitle = '#122F08'
  } else if (isGovernanceFoundInTitle) {
    colorByChartTitle = theme.palette.secondary.contrastText
  } else if (isSocialFoundInTitle) {
    colorByChartTitle = theme.palette.primary.main
  }
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root?.['borderColor'] ?? ''

  let mainContent: JSX.Element = <span></span>

  const barData = Object.entries(raw_data)
    .map(([key, value]) => ({
      name: key,
      questions: Number(value),
    }))
    .sort((a, b) => b.questions - a.questions)

  const categories = Object.keys(COLORS)
  const subcategories = [
    ...new Set(
      Object.values(raw_data).flatMap(category => Object.keys(category))
    ),
  ]

  const dataPolar = subcategories.map(subcategory => {
    const entry: any = { subcategory }
    categories.forEach(category => {
      entry[category] = raw_data[category]?.[subcategory] || 0
    })
    return entry
  })

  const renderRadars = () =>
    categories.map(category => (
      <Radar
        key={category}
        name={category}
        dataKey={category}
        stroke={COLORS[category]}
        fill={COLORS[category]}
        fillOpacity={0.6}
      />
    ))

  switch (type) {
    case 'LINECHART':
      mainContent = (
        <Box>
          <Typography>{title}</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                label={{
                  value: x_axis_title,
                  position: 'insideBottomRight',
                  offset: 0,
                }}
              />
              <YAxis
                label={{
                  value: y_axis_title,
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )
      break
    case 'BARCHART':
      mainContent = (
        <Box>
          <Typography>{title}</Typography>
          <ResponsiveContainer width="100%" height={600}>
            <BarChart
              data={barData}
              margin={{
                top: 5,
                left: 25,
                bottom: 250,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                label={{
                  value: x_axis_title,
                  position: 'left',
                  offset: 7,
                  fontSize: barData.length > 3 ? '10px' : '8px',
                }}
                dx={-5}
                angle={barData.length > 3 ? -90 : 0} // Rotate labels by -45 degrees
                textAnchor={barData.length > 3 ? 'end' : 'middle'}
              />
              <YAxis
                label={{
                  value: y_axis_title,
                  angle: -90,
                  position: 'insideBottomLeft',
                }}
              />
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey="questions">
                {barData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      colorByChartTitle
                        ? colorByChartTitle
                        : getColorByLabelName(entry.name)
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )
      break
    case 'TREEMAP':
      mainContent = (
        <>
          <Box>
            <Typography>{title}</Typography>
          </Box>
          <Box>
            <TreeMapChart data={raw_data} />
          </Box>
        </>
      )

      break
    case 'POLARCHART':
      const dataPolar = transformDataForPolar(raw_data)

      mainContent = (
        <Box>
          <Typography>{title}</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart
              cx="50%"
              cy="50%"
              outerRadius="80%"
              data={dataPolar}
              margin={{
                top: 5,
                right: 35,
                left: 35,
                bottom: 20,
              }}
            >
              <PolarGrid />
              <PolarAngleAxis
                dataKey="subcategory"
                style={{ fontSize: '10px' }}
              />
              <PolarRadiusAxis angle={30} domain={[0, 100]} />
              {renderRadars()}
              <Tooltip />
            </RadarChart>
          </ResponsiveContainer>
        </Box>
      )
      break
    default:
      mainContent = <Typography>{title}</Typography>
      break
  }

  return (
    <Box
      sx={{
        display: 'grid',
        border: `1px solid ${borderColor}`,
        p: theme.spacing(2),
      }}
    >
      {mainContent}
    </Box>
  )
}

export default ChartBox
