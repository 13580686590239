import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import './styles.css'
import BackupIcon from '@mui/icons-material/Backup'
import { useNavigate, useParams } from 'react-router-dom'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import CloseIcon from '@mui/icons-material/Close'
import DialogAddSurvey from './DialogAddSurvey'
import { SurveysContext } from '../../../contexts/SurveysContext/surveys-context'
import DialogUploadSurvey from './DialogUploadSurvey/DialogUploadSurvey'
import UploadSurveyContextProvider from '../../../contexts/UploadSurveyContext/UploadSurveyContextProvider'
import AddIcon from '@mui/icons-material/Add'

interface Props {
  isSurveysListEmpty: boolean
}

const fileTypes = ['XLSX', 'PDF', 'DOCX']

const DropFileZone: React.FC<Props> = ({ isSurveysListEmpty }) => {
  const {
    actions: { onSetIsDialogAddSurveyOpen, onSetFileDataToUpload },
  } = useContext(SurveysContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const { isUploadModeParam } = useParams()

  const isUploadMode = isUploadModeParam === 'uploadMode'

  const handleChange = async file => {
    const formData = new FormData()
    if (file) {
      onSetFileDataToUpload(file)
      formData.append('file', file)
      formData.append('type', 'CONFIDENTIAL')
      onSetIsDialogAddSurveyOpen(true)
      // await createQuestionnaire(formData)
    }
  }

  const dropFileBox = (
    <Box
      sx={{
        bgcolor: theme.palette.secondary.main,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        mt: theme.spacing(3),
        mb: theme.spacing(3),
        boxSizing: 'border-box',
        p: theme.spacing(0.7),
        pb: theme.spacing(8),
        pt: isSurveysListEmpty ? theme.spacing(8) : 0,
      }}
    >
      {!isSurveysListEmpty && (
        <Box sx={{ textAlign: 'right', pt: theme.spacing(0.8) }}>
          <IconButton onClick={() => navigate('/surveys')}>
            <CloseIcon
              fontSize="large"
              sx={{ color: theme.palette.grey[400] }}
            />
          </IconButton>
        </Box>
      )}
      <Box sx={{ px: theme.spacing(8), pt: theme.spacing(2) }}>
        <UploadSurveyContextProvider>
          <DialogUploadSurvey />
        </UploadSurveyContextProvider>
        <FileUploader
          style={{ backgroundColor: 'red' }}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.primary.contrastText,
              minHeight: '299px',
              border: `2px dashed ${theme.palette.grey[300]}`,
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              margin: 'auto',
            }}
          >
            {isSurveysListEmpty && (
              <Typography variant="h6">
                You currently have no surveys. Upload a survey to begin.
              </Typography>
            )}

            <BackupIcon
              sx={{ fontSize: '80px', color: theme.palette.grey[400] }}
            />

            <Typography textAlign={'center'}>
              Drag your survey file here <br />
              or{' '}
              <Typography
                component="span"
                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              >
                browse to upload
              </Typography>
            </Typography>
            <Typography variant="body2">
              Accepted formats: .pdf, .xlsx, .docx
            </Typography>
          </Box>
        </FileUploader>
      </Box>
    </Box>
  )
  if (isSurveysListEmpty) {
    return dropFileBox
  }

  if (isUploadMode) {
    return dropFileBox
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <UniversalButton
        startIcon={<AddIcon />}
        label="Upload survey"
        onClick={() => navigate('/surveys/uploadMode')}
      />
    </Box>
  )
}

export default DropFileZone
