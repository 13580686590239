import React from 'react'
import HeadTableCell from '../../../ui/tables/NewDesignUniversalTable/HeadTableCell/HeadTableCell'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import {
  AnswerItem,
  GenerateSimilarQuestionsRequestItem,
} from '../../../../types/interfaces'
import SimilarQuestionRow from '../../../similarQuestions/SimilarQuestionsTable/SimilarQuestionRow/SimilarQuestionRow'
import SimilarQuestionRowInUncheckedAnswer from './SimilarQuestionRowInUncheckedAnswer/SimilarQuestionRowInUncheckedAnswer'
import NewDesignUniversalTable from '../../../ui/tables/NewDesignUniversalTable/NewDesignUniversalTable'
import { useDuplicateAnswerMutation } from '../../../../services/responses'

interface Props {
  tableRowsData: GenerateSimilarQuestionsRequestItem[]
  onSetOpenSimilarQuestion: (id: string) => void
}

const SimilarQuestionTableInUncheckedAnswer: React.FC<Props> = ({
  tableRowsData,
  onSetOpenSimilarQuestion,
}) => {
  const theme = useTheme()

  const tableHeaderCells = [
    {
      label: 'Question',
      id: 'question',
      keyToSort: null,
    },
    {
      label: 'Source',
      id: 'source',
      keyToSort: 'source',
    },
    {
      label: 'Status',
      id: 'status',
      keyToSort: null,
    },
    {
      label: 'Last updated',
      id: 'lastUpdated',
      keyToSort: null,
    },
    {
      label: 'Action',
      id: 'actions',
      keyToSort: null,
    },
    {
      label: '',
      id: 'openArrow',
      keyToSort: null,
    },
  ]

  const jsxElementsTableHead = tableHeaderCells.map(
    (tableHeaderCell, index) => {
      const { id, label, keyToSort } = tableHeaderCell
      if (id === 'status') {
        return (
          <HeadTableCell key={`${id}_${index}`}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: index === 0 ? 'flex-start' : 'center',
                alignItems: 'center',
              }}
            >
              <IconButton disabled={true}>
                <CheckCircle />
              </IconButton>
            </Box>
          </HeadTableCell>
        )
      }
      return (
        <HeadTableCell
          key={`${id}_${index}`}
          align={index === 0 ? 'left' : 'center'}
          sx={{
            width: index === 0 ? '50%' : 'default',
            userSelect: 'none',
            py: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: index === 0 ? 'flex-start' : 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2" sx={{ display: 'flex', m: 0 }}>
              {label}
            </Typography>
          </Box>
        </HeadTableCell>
      )
    }
  )

  const jsxTableApprovedRowsContent = tableRowsData.map((answerItem, index) => {
    return (
      <SimilarQuestionRowInUncheckedAnswer
        onSetCurrenOpenSimilarQuestion={onSetOpenSimilarQuestion}
        key={`${index}_${answerItem.id}`}
        {...answerItem}
      />
    )
  })

  return (
    <Box>
      <NewDesignUniversalTable
        headRowBgColor={theme.palette.grey[100]}
        tableHeaders={jsxElementsTableHead}
        tableBodyRows={jsxTableApprovedRowsContent}
      />
    </Box>
  )
}

export default SimilarQuestionTableInUncheckedAnswer
