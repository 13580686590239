import React, { useContext } from 'react'
import { Box, useTheme } from '@mui/material'
import { GeneratedResponseContext } from '../../../contexts/GeneratedResponseContext/generated-response-context'
import UniversalButton from '../../ui/Buttons/UniversalButton'

const SaveAnswerSection: React.FC = () => {
  const {
    state: { isLoadingInUpdateResponse },
    actions: { onClickSaveToMyAnswersButton },
  } = useContext(GeneratedResponseContext)

  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        borderTop: 'none',
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        px: theme.spacing(1),
        py: theme.spacing(1),
      }}
    >
      <UniversalButton
        loading={isLoadingInUpdateResponse}
        onClick={onClickSaveToMyAnswersButton}
        label={`Save Response to Answers library`}
      />
    </Box>
  )
}

export default SaveAnswerSection
