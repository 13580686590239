import React, { useContext, useEffect, useState } from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { AnswerPreviewContext } from '../../../contexts/AnswerPreview/answer-preview-contex'
import { AnswersLibraryContext } from '../../../contexts/AnswersLibraryContext/answers-library-context'
import {
  MDXEditor,
  headingsPlugin,
  MDXEditorMethods,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  tablePlugin,
  linkPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  InsertTable,
  ListsToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  diffSourcePlugin,
} from '@mdxeditor/editor'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import AnswerRightDrawer from '../../AnswersLibrary/AnswersLibraryTabel/AnswerRightDrawer/AnswerRightDrawer'

interface Props {}

const UncheckedAnswerSection: React.FC<Props> = () => {
  const theme = useTheme()
  const ref = React.useRef<MDXEditorMethods>(null)
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const [isViewDetailsModalOpen, setIsViewDeatilsModalOpen] = useState(false)

  const {
    state: { isEditMode, responseInput },
    answerData: { answerText, surveyId },
    regenerateAnswerQuery: { isLoadingRegenerateAnswer },
    updateAnswerQuery: { isLoadingUpdateResponse },
    actions: {
      onResponseChange,
      onClickEditMode,
      onClickSaveAnswer,
      onClickAcceptAnswer,
      onClickRegenerateResponse,
      switchOffEditMode,
      onClickDiscardChangesButton,
    },
  } = useContext(AnswerPreviewContext)

  const {
    state: { answerSources },
  } = useContext(AnswersLibraryContext)

  useEffect(() => {
    ref.current?.setMarkdown(responseInput.value)
  }, [responseInput.value])

  useEffect(() => {
    ref.current?.setMarkdown(answerText)
  }, [answerText])

  const answerSource = answerSources.find(
    sourceItem => sourceItem.id === surveyId
  )

  const sourceToDispaly = answerSource
    ? answerSource.label
    : 'Ask single question mode'

  const mdxEditorPlugins = isEditMode
    ? [
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        tablePlugin(),
        linkPlugin(),
        diffSourcePlugin({
          diffMarkdown: answerText,
          viewMode: 'rich-text',
          readOnlyDiff: true,
        }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <BlockTypeSelect />
              <InsertTable />
              <ListsToggle />
              <DiffSourceToggleWrapper>
                <UndoRedo />
              </DiffSourceToggleWrapper>
            </>
          ),
        }),
      ]
    : [
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        tablePlugin(),
        linkPlugin(),
        diffSourcePlugin({
          diffMarkdown: answerText,
          viewMode: 'rich-text',
          readOnlyDiff: true,
        }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              {/* <UndoRedo />
              <BoldItalicUnderlineToggles />
              <BlockTypeSelect />
              <InsertTable />
              <ListsToggle />
              <DiffSourceToggleWrapper>
                <UndoRedo />
              </DiffSourceToggleWrapper> */}
            </>
          ),
        }),
      ]

  const processedMarkdown = markDown => {
    return markDown.replace(/\[(.*?)\]\((.*?)\)/g, (match, text, href) => {
      return `<a style="display:inline; color:black;"href="${href}" target="_blank">${text}</a>`
    })
  }

  const handleMarkdownChange = (newMarkdown: string) => {
    onResponseChange(newMarkdown)
  }

  const onClickModifyButtonHandler = () => {
    onClickEditMode(answerText)
  }

  const openAnswerDetailsDrawer = () => {
    setIsViewDeatilsModalOpen(true)
  }

  const closeAnswerDetailsDrawer = () => {
    setIsViewDeatilsModalOpen(false)
  }

  return (
    <>
      {isViewDetailsModalOpen && (
        <AnswerRightDrawer
          closeDrawerInReviewModeAction={closeAnswerDetailsDrawer}
        />
      )}
      <Box sx={{ mt: theme.spacing(4) }}>
        <Typography variant="h4">Answer</Typography>
        <Box
          sx={{
            mt: theme.spacing(4),
            border: `1px solid ${borderColor}`,
            display: 'flex',
            flexDirection: 'column',
            '& >div': {
              paddingX: theme.spacing(4),
              paddingY: theme.spacing(2),
            },
            '& > div:not(:first-of-type)': {
              borderTop: `1px solid ${borderColor}`,
            },
          }}
        >
          <Box>
            <Typography>Source: {sourceToDispaly}</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: isEditMode
                ? theme.palette.secondary.main
                : 'inherit',
            }}
          >
            {isEditMode ? (
              <Box id="edit">
                <MDXEditor
                  ref={ref}
                  readOnly={false}
                  markdown={processedMarkdown(responseInput.value)}
                  onChange={handleMarkdownChange}
                  contentEditableClassName={'editReview'}
                  plugins={[...mdxEditorPlugins]}
                />
              </Box>
            ) : (
              <Box>
                <MDXEditor
                  ref={ref}
                  readOnly={true}
                  markdown={processedMarkdown(responseInput.value)}
                  onChange={handleMarkdownChange}
                  contentEditableClassName={'editReview'}
                  plugins={[...mdxEditorPlugins]}
                />
              </Box>
            )}
          </Box>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            direction={'row'}
            spacing={theme.spacing(4)}
          >
            {!isEditMode && (
              <UniversalButton
                stylingType={4}
                label="Modify"
                onClick={onClickModifyButtonHandler}
              />
            )}
            {isEditMode && (
              <UniversalButton
                stylingType={4}
                label="Save"
                onClick={onClickSaveAnswer}
                loading={isLoadingUpdateResponse}
              />
            )}
            {isEditMode && (
              <UniversalButton
                stylingType={4}
                label="Cancel"
                onClick={onClickDiscardChangesButton}
              />
            )}

            <UniversalButton
              onClick={openAnswerDetailsDrawer}
              label="View Evidence"
            />
            <UniversalButton
              label="Accept Answer"
              onClick={onClickAcceptAnswer}
              loading={isLoadingUpdateResponse}
            />
            <UniversalButton
              loading={isLoadingRegenerateAnswer}
              label="Regenerate"
              onClick={onClickRegenerateResponse}
            />
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default UncheckedAnswerSection
