import { createContext } from 'react'
import { CompanyMembersContextSchema } from './companyMembersInterfaces'
import { initialCompanyMembersState } from './initialCompanyMembersState'
import { initialCompanyMembersActions } from './initialCompanyMembersActions'

const companyMembersContext: CompanyMembersContextSchema = {
  state: initialCompanyMembersState,
  queryResults: { isErrorInUpdateRole: false },
  actions: initialCompanyMembersActions,
}

export const CompanyMembersContext = createContext(companyMembersContext)
