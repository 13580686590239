import { UploadEvidenceState } from './uploadEvidenceInterfaces'

const initialUploadEvidenceState: UploadEvidenceState = {
  currEvidenceType: 'CHOOSE',
  isAddEvidenceModalOpen: false,
  currUploadedFileData: null,
  urlInputValue: '',
  isEvidenceUploadingToAddToEvidenceLibrary: false,
}

export default initialUploadEvidenceState
