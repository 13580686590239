import { AnswerItem, SupportiveEvidence } from '../../types/interfaces'
import {
  GeneratedResponseActions,
  GeneratedResponseState,
} from './generatedResponseInterfaces'

export const initialGeneratedResponseActions: GeneratedResponseActions = {
  onResponseInputChange: (e: React.ChangeEvent<HTMLInputElement>) => {},
  onClickThumbNail: (feedback: AnswerItem['feedback']) => {},
  onClickSaveToMyAnswersButton: () => {},

  onClickTabButton: (
    demandedTabType: GeneratedResponseState['currSupEvidenceTab']
  ) => {},
  onDeleteSupportiveEvidenceFromResponse: (
    supEviId: SupportiveEvidence['id']
  ) => {},
  onClickRegenerateResponse: () => {},
  onClickCloseInfoRegardingTypeOfEvidence: () => {},
  switchOffEditMode: () => {},
  switchOnEditMode: () => {},
  onClickCancelEditResponse: () => {},
}
