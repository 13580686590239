import {
  Button,
  ButtonProps,
  FormControlLabel,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import Checkbox from '@mui/material/Checkbox'

interface Props extends ButtonProps {
  stylingType?: 1 | 2 | 3 | 4 | 5
  label?: string
  component?: string
  children?: React.ReactNode
  isChecked: boolean
}

const UniversalCheckBoxButton: React.FC<Props> = ({
  stylingType,
  startIcon,
  endIcon,
  label = 'Universal Button',
  type = 'button',
  size = 'medium',
  href,
  onClick,
  children,
  component,
  isChecked,
}) => {
  const theme = useTheme()

  //default pink action button
  let styling: SxProps = {
    bgcolor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }

  let buttonProps: Props = {
    startIcon,
    endIcon,
    type,
    onClick,
    size,
    href,
    component,
    isChecked,
  }

  switch (stylingType) {
    //inherit button with dark border
    case 1:
      styling = {
        bgcolor: 'inherit',
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
        borderStyle: 'solid',
        borderWidth: '2px',
        minWidth: '104px',
        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 2:
      styling = {
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
        borderStyle: 'solid',
        borderWidth: '2px',
        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 3:
      buttonProps = {
        ...buttonProps,
        variant: 'contained',
      }
      styling = {
        bgcolor: theme.palette.grey[300],
        color: theme.palette.grey[900],

        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 4:
      buttonProps = {
        ...buttonProps,
        variant: 'contained',
      }
      styling = {
        bgcolor: theme.palette.grey[600],
        color: theme.palette.primary.contrastText,

        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 5:
      buttonProps = {
        ...buttonProps,
        variant: 'contained',
      }

      break
  }
  return (
    <FormControlLabel
      control={<Checkbox onClick={onClick} checked={isChecked} />}
      label={<Typography variant="body2">{label}</Typography>}
    />
  )
}

export default UniversalCheckBoxButton
