import React from 'react'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ViewTitleWithNav from '../components/ui/ViewTitleWithNav/ViewTitleWithNav'

const ErrorInRouterPathView: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'space-between',
      }}
    >
      <Header />
      <MainContentContainer
        maxWidth="md"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography
          component={'h2'}
          variant="h4"
          sx={{ marginBottom: theme.spacing(4) }}
        >
          Sorry this page doesn't exist
        </Typography>
        <Stack
          direction={'row'}
          spacing={theme.spacing(4)}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Button variant="contained" onClick={() => navigate('/')}>
            HOME PAGE
          </Button>
        </Stack>
      </MainContentContainer>
      <Footer />
    </Box>
  )
}

export default ErrorInRouterPathView
