import React, { useContext, useState } from 'react'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import SuppEviInAnsGenItem from './SuppEviInAnsGenItem/SuppEviInAnsGenItem'
import { GeneratedResponseContext } from '../../../contexts/GeneratedResponseContext/generated-response-context'
import SupportiveEvidenceContextProvider from '../../../contexts/SupportiveEvidenceContext/SupportiveEvidenceContextProvider'
import { IRemovalEvidenceDialogState } from '../FoundEvidenceSection/EvidenceTable/FoundEvidenceTable'
import ConfirmRemovalEvidenceDialog from '../FoundEvidenceSection/ConfirmRemovalEvidenceDialog/ConfirmRemovalEvidenceDialog'
import AppDialog from '../../ui/HOC/AppDialog/AppDialog'

interface Props {}

const SupportiveEvidenceInAnswerGeneratedSection: React.FC<Props> = () => {
  const theme = useTheme()
  const [isEvidenceSectionExpanded, setIsEvidenceSectionExpanded] =
    useState(true)

  const [removalDialogState, setRemovalDialogState] =
    useState<IRemovalEvidenceDialogState>({
      isDialogOpen: false,
      narrativeId: null,
      supportiveEviId: null,
    })

  const {
    state: {
      supportiveEvidences,
      isLoadingInRegenerateResponse,
      isSuccessInRegenerateResponse,
      isResIncludePrivateEvi,
      responseId,
    },
    actions: { onClickRegenerateResponse },
  } = useContext(GeneratedResponseContext)

  const setOpenRemovalDialogHandler = (evidenceId: string) => {
    setRemovalDialogState({
      isDialogOpen: true,
      narrativeId: responseId,
      supportiveEviId: evidenceId,
    })
  }

  const setCloseRemovalDialogHandler = () => {
    setRemovalDialogState({
      isDialogOpen: false,
      narrativeId: null,
      supportiveEviId: null,
    })
  }

  const toggleExpandEvidenceListHandler = () => {
    setIsEvidenceSectionExpanded(prevState => !prevState)
  }

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  // const supportiveEvidenceFilteredBySource = supportiveEvidences.filter(
  //   supEviItem => {
  //     if (isResIncludePrivateEvi) {
  //       return supEviItem.evidence_chunk.source === 'EVIDENCE'
  //     } else {
  //       return (
  //         supEviItem.evidence_chunk.source === 'EVIDENCE' &&
  //         supEviItem.evidence_chunk.type === 'PUBLIC'
  //       )
  //     }
  //   }
  // )

  if (supportiveEvidences.length === 0) {
    return null
  }
  const supportiveEvidenceJSXItems = supportiveEvidences.map(
    (supEviItem, index) => (
      <SupportiveEvidenceContextProvider key={supEviItem.id} {...supEviItem}>
        <SuppEviInAnsGenItem
          index={index}
          {...supEviItem}
          removalDialogState={removalDialogState}
          onClickRemoveEvidenceCheckbox={() =>
            setOpenRemovalDialogHandler(supEviItem.id)
          }
        />
      </SupportiveEvidenceContextProvider>
    )
  )

  return (
    <Box sx={{ width: '100%' }}>
      <ConfirmRemovalEvidenceDialog
        dialogState={removalDialogState}
        onCloseDialog={setCloseRemovalDialogHandler}
      />
      {/* <AppDialog isDialogOpen={true} onCloseDialog={() => {}}>
        Dialog
      </AppDialog> */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 5fr',
          border: `1px solid ${borderColor}`,
          borderTop: 0,
        }}
      >
        <Box
          id="leftColumnInFirstRow"
          sx={{
            px: theme.spacing(3),
            py: theme.spacing(2),
            borderRight: `1px solid ${borderColor}`,
          }}
        >
          <Typography textAlign={'center'} variant="h6">
            Evidence
          </Typography>
        </Box>
        <Box
          id="rightColumnInSecondRow"
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Box
            id="firstRowInRightColumn"
            sx={{
              display: 'grid',
              gridTemplateColumns: '10fr 1fr',
              height: '100%',
            }}
          >
            <Box
              sx={{
                borderRight: `1px solid ${borderColor}`,
                px: theme.spacing(3),
                py: theme.spacing(2),
              }}
            >
              <Typography>
                These are documents from your Evidence Library used to generate
                response.
              </Typography>
            </Box>

            <Box
              sx={{
                px: theme.spacing(3),
                py: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center≥',
              }}
            >
              <IconButton onClick={toggleExpandEvidenceListHandler}>
                <ArrowBackIosIcon
                  sx={{
                    transform: isEvidenceSectionExpanded
                      ? `rotate(90deg) translateX(4px)`
                      : `rotate(270deg) translateX(4px)`,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          {isEvidenceSectionExpanded && (
            <Box
              id="secondRowInRightColumn"
              sx={{
                borderTop: `1px solid ${borderColor}`,
              }}
            >
              {supportiveEvidenceJSXItems}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SupportiveEvidenceInAnswerGeneratedSection
