import React, {
  useEffect,
  useReducer,
  ChangeEvent,
  SyntheticEvent,
} from 'react'
import { UploadEvidenceContext } from './uploadEvidence-context'
import {
  UploadEvidenceContextSchema,
  UploadEvidenceState,
} from './uploadEvidenceInterfaces'
import { useAddEvidenceLinkOrFileToEviLibraryMutation } from '../../services/evidences'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { appActions } from '../../store/App/app-slice'
import {
  useParseCompanyEvidenceMutation,
  useParseEvidenceItemMutation,
} from '../../services/parse'
import initialUploadEvidenceState from './initialUploadEvidenceState'
import { useNavigate, useParams } from 'react-router-dom'
import { Stats } from 'fs'
import { Box, CircularProgress, Typography } from '@mui/material'
import { FilterEvidenceUrlParamNames } from '../../types/enums'

interface Props {
  children: React.ReactNode
}

enum UploadEvidenceActionKind {
  SET_CURRENT_EVIDENCE_TYPE = 'SET_CURRENT_EVIDENCE_TYPE',
  OPEN_ADD_EVIDENCE_MODAL = 'OPEN_ADD_EVIDENCE_MODAL',
  CLOSE_ADD_EVIDENCE_MODAL = 'CLOSE_ADD_EVIDENCE_MODAL',
  SET_CURRENT_UPLOADED_FILE_DATA = 'SET_CURRENT_UPLOADED_FILE_DATA',
  RESET_CURRENT_UPLOADED_FILE_DATA = 'RESET_CURRENT_UPLOADED_FILE_DATA',
}

interface UploadEvidenceAction {
  type: UploadEvidenceActionKind
  payload: string | UploadEvidenceState['currEvidenceType'] | File
}

const reducerFunction = (
  state: UploadEvidenceState,
  action: UploadEvidenceAction
): UploadEvidenceState => {
  const { type, payload } = action
  switch (type) {
    case UploadEvidenceActionKind.SET_CURRENT_EVIDENCE_TYPE:
      return {
        ...state,
        currUploadedFileData: null,
        currEvidenceType: payload as UploadEvidenceState['currEvidenceType'],
      }
    case UploadEvidenceActionKind.OPEN_ADD_EVIDENCE_MODAL:
      return {
        ...state,
        currUploadedFileData: null,
        isAddEvidenceModalOpen: true,
      }
    case UploadEvidenceActionKind.CLOSE_ADD_EVIDENCE_MODAL:
      return {
        ...state,
        currUploadedFileData: null,
        isAddEvidenceModalOpen: false,
      }
    case UploadEvidenceActionKind.SET_CURRENT_UPLOADED_FILE_DATA:
      return {
        ...state,
        currUploadedFileData: payload as File,
      }
    case UploadEvidenceActionKind.RESET_CURRENT_UPLOADED_FILE_DATA:
      return {
        ...state,
        currUploadedFileData: null,
      }
    default:
      return state
  }
}

const UploadEvidenceContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialUploadEvidenceState
  )

  const [
    addEvidenceLinkOrFileToEviLibrary,
    {
      isError: isErrorInAddEvidenceLinkOrFileToEviLibrary,
      isSuccess: isSuccessAddEvidenceLinkOrFileToEviLibrary,
      data: dataFromAddEvidence,
    },
  ] = useAddEvidenceLinkOrFileToEviLibraryMutation()

  const [
    parseCompanyEvidence,
    {
      isError: isErrorInParseCompanyEvidence,
      error: errorInParseCompanyEvidence,
      isLoading: isLoadingParseCompanyEvidence,
      isSuccess: isSuccessParseCompanyEvidence,
      data: dataParseCompanyEvidence,
    },
  ] = useParseCompanyEvidenceMutation()

  const [
    parse,
    {
      isLoading: isLoadingInEvidenceParse,
      isSuccess: isSuccessInEvidenceParse,
    },
  ] = useParseEvidenceItemMutation()

  const {
    [FilterEvidenceUrlParamNames.typeParam]: typeParam,
    [FilterEvidenceUrlParamNames.searchParam]: searchParam,
    [FilterEvidenceUrlParamNames.isUploadModeParam]: isUploadModeParam,
  } = useParams()

  const userApi = useAppSelector(state => state.user)
  const { companyId } = userApi

  const navigate = useNavigate()

  const dispatchStore = useAppDispatch()

  useEffect(() => {
    if (isErrorInAddEvidenceLinkOrFileToEviLibrary) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't upload file to Evidence Library, please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInAddEvidenceLinkOrFileToEviLibrary])

  useEffect(() => {
    if (isErrorInParseCompanyEvidence) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't parse evidence please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
      console.log(errorInParseCompanyEvidence)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInParseCompanyEvidence])

  useEffect(() => {
    if (isErrorInAddEvidenceLinkOrFileToEviLibrary) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't upload file to Evidence Library, please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInAddEvidenceLinkOrFileToEviLibrary])

  useEffect(() => {
    if (isSuccessAddEvidenceLinkOrFileToEviLibrary && dataFromAddEvidence) {
      const { id } = dataFromAddEvidence
      parse(id)
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Evidence added to library and processing is in progress.`,
          type: 'warning',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAddEvidenceLinkOrFileToEviLibrary])

  useEffect(() => {
    if (isSuccessInEvidenceParse) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Processing Complete! Your evidence has been successfully added.`,
          type: 'success',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInEvidenceParse])

  useEffect(() => {
    if (isSuccessParseCompanyEvidence) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Processing Complete! All of evidence has been successfully parsed.`,
          type: 'success',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessParseCompanyEvidence])

  const onClickContinueInChooseEvidenceTypeHandler = (
    demandedEvidenceType: UploadEvidenceState['currEvidenceType']
  ) => {
    dispatch({
      type: UploadEvidenceActionKind.SET_CURRENT_EVIDENCE_TYPE,
      payload: demandedEvidenceType,
    })
  }

  const onClickAddOrUploadDocumentButtonHandler = () => {
    dispatch({
      type: UploadEvidenceActionKind.OPEN_ADD_EVIDENCE_MODAL,
      payload: '',
    })
  }

  const onClickCloseAddOrUploadModalButtonHandler = () => {
    dispatch({
      type: UploadEvidenceActionKind.CLOSE_ADD_EVIDENCE_MODAL,
      payload: '',
    })
    dispatch({
      type: UploadEvidenceActionKind.SET_CURRENT_EVIDENCE_TYPE,
      payload: 'CHOOSE',
    })
  }

  const onSetCurrentUploadedFileDataHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const filesData = e.target.files

    if (filesData && filesData.length > 0)
      dispatch({
        type: UploadEvidenceActionKind.SET_CURRENT_UPLOADED_FILE_DATA,
        payload: filesData[0],
      })
  }

  const onResetCurrentUploadedFileDataHandler = () => {
    dispatch({
      type: UploadEvidenceActionKind.SET_CURRENT_UPLOADED_FILE_DATA,
      payload: '',
    })
  }

  const addEvidenceLinkOrFileToEviLibraryHandler = (e: SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      urlToDocument?: { value: string }
    }
    if (target.urlToDocument) {
      const url = target.urlToDocument.value // typechecks!
      addEvidenceLinkOrFileToEviLibrary({ evidence: { type: 'PUBLIC', url } })
    } else {
      const formData = new FormData()
      const fileData = state.currUploadedFileData
      if (fileData) {
        formData.append('file', fileData)
        formData.append('type', 'CONFIDENTIAL')
        addEvidenceLinkOrFileToEviLibrary({
          evidence: {
            type: state.currEvidenceType as 'PUBLIC' | 'PRIVATE',
          },
          fileData: fileData,
        })
      }
    }

    navigate(`/evidence-library/${typeParam}/${searchParam}/false/`)
  }

  const onClickParseAllCompaniesEvidenceHandler = () => {
    if (companyId) {
      parseCompanyEvidence(companyId)
    }
  }

  const ctxValue: UploadEvidenceContextSchema = {
    state: {
      isAddEvidenceModalOpen: state.isAddEvidenceModalOpen,
      currEvidenceType: state.currEvidenceType,
      currUploadedFileData: state.currUploadedFileData,
      urlInputValue: state.urlInputValue,
      isEvidenceUploadingToAddToEvidenceLibrary:
        state.isEvidenceUploadingToAddToEvidenceLibrary,
    },
    queries: {
      parseCompanyEvidence: {
        isError: isErrorInParseCompanyEvidence,
        isSuccess: isSuccessParseCompanyEvidence,
        isLoading: isLoadingParseCompanyEvidence,
      },
    },
    actions: {
      onClickContinueInChooseEvidenceType:
        onClickContinueInChooseEvidenceTypeHandler,
      onClickAddOrUploadDocumentButton: onClickAddOrUploadDocumentButtonHandler,
      onSetCurrentUploadedFileData: onSetCurrentUploadedFileDataHandler,
      onResetCurrentUploadedFileData: onResetCurrentUploadedFileDataHandler,
      onClickCloseAddOrUploadModalButton:
        onClickCloseAddOrUploadModalButtonHandler,
      onSubmitAddEvidenceForm: addEvidenceLinkOrFileToEviLibraryHandler,
      onClickParseAllCompaniesEvidence: onClickParseAllCompaniesEvidenceHandler,
    },
  }

  let content = children

  if (isLoadingParseCompanyEvidence) {
    content = (
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography>Parsing evidence in progress..</Typography>
          <CircularProgress />
        </Box>
      </Box>
    )
  }

  return (
    <UploadEvidenceContext.Provider value={ctxValue}>
      {content}
    </UploadEvidenceContext.Provider>
  )
}

export default UploadEvidenceContextProvider
