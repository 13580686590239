import React, { useContext } from 'react'
import { Box, IconButton, TableRow, Typography, useTheme } from '@mui/material'
import CellInNewTable from '../../../ui/tables/NewDesignUniversalTable/CellInNewTable/CellInNewTable'
import { SimilarRequest } from '../../../../types/interfaces'
import { convertDateToNiceFormat } from '../../../../utils/utils'
import {
  ArrowForwardIos,
  CheckCircle,
  HelpOutline,
  History,
} from '@mui/icons-material'

import { SimilarQuestionsContext } from '../../../../contexts/SimilarQuestionsContext/similar-questions-context'
import { useAppSelector } from '../../../../hooks/hooks'
import { paragraphGenerator } from '../../../../utils/paragraphGenerator'
import StatusIcon from '../../../ui/StatusIcon/StatusIcon'

const SimilarQuestionRow: React.FC<SimilarRequest> = ({
  response,
  question,
  id,
}) => {
  const {
    actions: { onClickOpenRightDrawer },
  } = useContext(SimilarQuestionsContext)

  const { role, isAskQuestionModeBlocked } = useAppSelector(state => state.user)

  const theme = useTheme()

  const isViewer = role === 'VIEWER'

  const {
    updated_at,
    status,
    assigned_user,
    request: { survey_name },
  } = response
  let statusIcon: JSX.Element = <></>

  if (status === 'CHECKED' && assigned_user !== null) {
    statusIcon = <StatusIcon status="CHECKED_ASSIGNED" />
  } else {
    statusIcon = <StatusIcon status={status} />
  }

  let statusLabel = isAskQuestionModeBlocked
    ? 'Requested answers'
    : 'Saved answers'

  if (survey_name) {
    statusLabel = survey_name
  }
  return (
    <TableRow>
      <CellInNewTable align="left">
        {paragraphGenerator({
          text: question,
          variant: 'h6',
          component: 'h3',
          sx: { m: 0, lineHeight: '24px' },
        })}
      </CellInNewTable>
      <CellInNewTable label={statusLabel}>
        <Typography sx={{ m: 0, lineHeight: '24px' }}>{statusLabel}</Typography>
      </CellInNewTable>
      {/* {!isViewer && <CellInNewTable>{statusIcon}</CellInNewTable>} */}
      <CellInNewTable>
        <Box
          sx={{
            p: 0,
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {statusIcon}
        </Box>
        {/* <IconButton>{statusIcon}</IconButton> */}
      </CellInNewTable>
      <CellInNewTable>
        <Typography sx={{ m: 0, lineHeight: '24px' }}>
          {convertDateToNiceFormat(updated_at)}
        </Typography>
      </CellInNewTable>
      <CellInNewTable label={'actions'} sx={{ pt: theme.spacing(2.7) }}>
        <Box
          sx={{
            p: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => onClickOpenRightDrawer(id)}
            sx={{ height: '27px', width: '27px' }}
          >
            <ArrowForwardIos sx={{ height: '25px', width: '25px' }} />
          </IconButton>
        </Box>
      </CellInNewTable>
    </TableRow>
  )
}

export default SimilarQuestionRow
