import {
  Alert,
  AlertTitle,
  Collapse,
  IconButton,
  Link,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useContext } from 'react'
import content from '../../assets/content/content'
import { AskQuestionContext } from '../../contexts/AskQuestionContext/ask-question-context'

const AskQuestionError: React.FC = () => {
  const theme = useTheme()
  const {
    state: { isErrorInGenerateResponse },
    actions: { onCloseErrorNotification },
  } = useContext(AskQuestionContext)

  let {
    config: { supportEmail },
    eng: {
      components: {
        aksQuestionError: { errorTitle, errorExplanation },
      },
    },
  } = content

  return (
    <Collapse in={isErrorInGenerateResponse}>
      <Alert
        severity="error"
        sx={{ marginY: theme.spacing(6) }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onCloseErrorNotification}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{errorTitle}</AlertTitle>
        {errorExplanation}
        <br />
        <br />
        {`Support email: `}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
      </Alert>
    </Collapse>
  )
}

export default AskQuestionError
