import React from 'react'
import { SuveysActions } from './SurveysInterfaces'

const initialSurveysActions: SuveysActions = {
  onSetIsDialogAddSurveyOpen: () => {},
  onSetIdOfSeasonalSurveyModalOpen: () => {},
  onSetFileDataToUpload: () => {},
  renderUploadSurveyForm: () => <></>,
  onSubmitSurveyCancel: () => {},
  refetchSurveyData: () => {},
}

export default initialSurveysActions
