import React, { useContext, useEffect, useState } from 'react'
import AppDialog from '../../../ui/HOC/AppDialog/AppDialog'
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material'
import content from '../../../../assets/content/content'
import UniversalButton from '../../../ui/Buttons/UniversalButton'
import { Close } from '@mui/icons-material'
import { IRemovalEvidenceDialogState } from '../EvidenceTable/FoundEvidenceTable'
import { useAppSelector } from '../../../../hooks/hooks'
import { useDeleteSupportiveEvidencesMutation } from '../../../../services/supportiveEvidences'
import { GeneratedResponseContext } from '../../../../contexts/GeneratedResponseContext/generated-response-context'

interface Props {
  dialogState: IRemovalEvidenceDialogState
  onCloseDialog: () => void
}

const ConfirmRemovalEvidenceDialog: React.FC<Props> = ({
  dialogState,
  onCloseDialog,
}) => {
  const theme = useTheme()
  const [isFirstErrorSpotted, setIsFistErrorSpotted] = useState(false)
  const {
    actions: { onDeleteSupportiveEvidenceFromResponse },
  } = useContext(GeneratedResponseContext)

  const { isDialogOpen, narrativeId, supportiveEviId } = dialogState
  const [
    deleteEvidence,
    {
      isLoading: isLoadingDeleteSupEvi,
      isSuccess: isSuccessInDeleteSupEvi,
      isError: isErrorInDeleteSupEvi,
      reset: resetDeleteSupEvi,
    },
  ] = useDeleteSupportiveEvidencesMutation()

  const { username } = useAppSelector(state => state.user)

  useEffect(() => {
    if (isSuccessInDeleteSupEvi) {
      if (supportiveEviId) {
        onDeleteSupportiveEvidenceFromResponse(supportiveEviId)
      }
      const timer = setTimeout(() => {
        onCloseDialog()
      }, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isSuccessInDeleteSupEvi])

  useEffect(() => {
    if (isSuccessInDeleteSupEvi) {
      const timer = setTimeout(() => {
        resetDeleteSupEvi()
        setIsFistErrorSpotted(false)
      }, 2500)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isSuccessInDeleteSupEvi])

  useEffect(() => {
    if (isErrorInDeleteSupEvi && !isFirstErrorSpotted) {
      setIsFistErrorSpotted(true)
    }
  }, [isErrorInDeleteSupEvi, isFirstErrorSpotted])

  const onClickCloseEivDialogHandler = () => {
    onCloseDialog()
    setIsFistErrorSpotted(false)
  }

  const deleteEvidenceFromResponseHandler = () => {
    if (isDialogOpen && supportiveEviId) {
      deleteEvidence(supportiveEviId)
    }
  }

  let {
    eng: {
      components: {
        confirmRemovalEvidenceDialog: { title, info, successTitle, errorTitle },
      },
      buttons: { cancel, remove },
    },
  } = content

  let mainContent: JSX.Element = (
    <>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {info}
        </DialogContentText>
      </DialogContent>
      <Box>
        <Stack
          direction={'row'}
          spacing={theme.spacing(3)}
          justifyContent="center"
          alignItems="center"
        >
          <UniversalButton
            loading={isLoadingDeleteSupEvi}
            label={remove}
            onClick={deleteEvidenceFromResponseHandler}
          />
          <UniversalButton
            stylingType={4}
            label={cancel}
            onClick={onCloseDialog}
          />
        </Stack>
      </Box>
    </>
  )

  let titleColor = 'inherit'

  if (isFirstErrorSpotted && isLoadingDeleteSupEvi) {
    titleColor = theme.palette.error.main
    title = errorTitle
    mainContent = (
      <Box>
        <Stack
          direction={'row'}
          spacing={theme.spacing(3)}
          justifyContent="center"
          alignItems="center"
        >
          <UniversalButton
            loading={isLoadingDeleteSupEvi}
            label={'Try again'}
            onClick={deleteEvidenceFromResponseHandler}
          />
          <UniversalButton
            stylingType={4}
            label={cancel}
            onClick={onClickCloseEivDialogHandler}
          />
        </Stack>
      </Box>
    )
  }

  if (isErrorInDeleteSupEvi) {
    titleColor = theme.palette.error.main
    title = errorTitle
    mainContent = (
      <Box>
        <Stack
          direction={'row'}
          spacing={theme.spacing(3)}
          justifyContent="center"
          alignItems="center"
        >
          <UniversalButton
            loading={isLoadingDeleteSupEvi}
            label={'Try again'}
            onClick={deleteEvidenceFromResponseHandler}
          />
          <UniversalButton
            stylingType={4}
            label={cancel}
            onClick={onClickCloseEivDialogHandler}
          />
        </Stack>
      </Box>
    )
  }

  if (isSuccessInDeleteSupEvi) {
    title = successTitle
    titleColor = theme.palette.success.main
    mainContent = <></>
  }

  return (
    <AppDialog
      isDialogOpen={isDialogOpen}
      onCloseDialog={onClickCloseEivDialogHandler}
    >
      <DialogTitle
        color={titleColor}
        sx={{ m: 0, paddingTop: 0 }}
        id="alert-dialog-title"
      >
        {title}
      </DialogTitle>
      {mainContent}
    </AppDialog>
  )
}

export default ConfirmRemovalEvidenceDialog
