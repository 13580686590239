import React from 'react'
import { Box, Container } from '@mui/material'
import LogoOnDark from '../ui/Logo/EgLogoOnDark'
import theme from '../../themeData/themeData'

interface Props {}

const UnauthorisedHeader: React.FC<Props> = () => {
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.dark,
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="xl">
        <LogoOnDark />
      </Container>
    </Box>
  )
}

export default UnauthorisedHeader
