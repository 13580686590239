import React from 'react'
import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  SxProps,
  useTheme,
} from '@mui/material'
import { Close } from '@mui/icons-material'

interface Props {
  children: React.ReactNode
  onCloseDialog: () => void
  isDialogOpen: boolean
  maxWidth?: DialogProps['maxWidth']
  mainContentStyling?: SxProps
}

const UniversalDialog: React.FC<Props> = ({
  onCloseDialog,
  isDialogOpen,
  children,
  maxWidth = 'sm',
  mainContentStyling,
}) => {
  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!
  return (
    <Dialog
      open={isDialogOpen}
      onClose={onCloseDialog}
      maxWidth={maxWidth}
      fullWidth
    >
      <Box
        sx={{
          textAlign: 'right',
          border: `1px solid ${borderColor}`,
          display: 'flex',
          borderBottom: 0,
        }}
      >
        <Box
          sx={{
            textAlign: 'right',
            borderRight: `1px solid ${borderColor}`,
            width: '96%',
          }}
        ></Box>
        <Box>
          <IconButton onClick={onCloseDialog}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          boxSizing: 'border-box',
          border: `1px solid ${borderColor}`,
          borderBottom: 'none',
          borderRadius: '0px',
          p: 0,
          pb: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          ...mainContentStyling,
        }}
      >
        {children}
      </Box>
    </Dialog>
  )
}

export default UniversalDialog
