import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import UniversalDialog from '../../../../ui/UniversalDialog/UniversalDialog'
import { FileDetailsInSurvey } from '../../../../../types/interfaces'
import MySurveyItemLink from '../../../MySurveyItemLink'
import { Download } from '@mui/icons-material'
import { useGetAccessToFileMutation } from '../../../../../services/file'
import { useAppDispatch } from '../../../../../hooks/hooks'
import { appActions } from '../../../../../store/App/app-slice'
import { useGenerateSurveyAnotationReportMutation } from '../../../../../services/surveys'

interface Props {
  isOpen: boolean
  onClose: () => void
  file: FileDetailsInSurvey | null
  completedFile: FileDetailsInSurvey | null
  annotationFile: FileDetailsInSurvey | null
  surveyId
}

const DownloadFileDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  file,
  completedFile,
  annotationFile,
  surveyId,
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const [desiredFileName, setDesiredFileName] = useState('')

  const [
    getAccessToSurveyFile,
    {
      isError: isErrorInGetAccessToSurveyFile,
      isSuccess: isSuccessInGetAccessSurveyFile,
      isLoading: isLoadingInGetAccessSurveyFile,
      data: dataToAccessSurveyFile,
      reset: resetGetAccessToSurveyFile,
    },
  ] = useGetAccessToFileMutation()

  const [
    generateAnnotationRaport,
    {
      isError: isErrorInGetAnnotationRaport,
      isSuccess: isSuccessInGetAnnotationRaport,
      isLoading: isLoadingInGetAnnotationRaport,
      data: dataToAccessAnnotationRaport,
      reset: resetGetAccessToAnnotationRaport,
    },
  ] = useGenerateSurveyAnotationReportMutation()

  useEffect(() => {
    if (isSuccessInGetAnnotationRaport && dataToAccessAnnotationRaport) {
      const { url } = dataToAccessAnnotationRaport
      const downloadFile = async (fileUrl, fileName) => {
        try {
          const response = await fetch(fileUrl)
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }

          const blob = await response.blob()
          const downloadUrl = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(downloadUrl)
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error)
        }
      }

      downloadFile(url, desiredFileName)

      console.log(url)
    }
  }, [isSuccessInGetAnnotationRaport])

  useEffect(() => {
    if (isSuccessInGetAccessSurveyFile && dataToAccessSurveyFile) {
      const { url } = dataToAccessSurveyFile

      const downloadFile = async (fileUrl, fileName) => {
        try {
          const response = await fetch(fileUrl)
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }

          const blob = await response.blob()
          const downloadUrl = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(downloadUrl)
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error)
        }
      }

      downloadFile(url, desiredFileName)
    }
  }, [isSuccessInGetAccessSurveyFile])

  const onClickButtonHandler = (fileId: string, desiredFileName: string) => {
    setDesiredFileName(desiredFileName)
    getAccessToSurveyFile(fileId)
  }

  const onClickAdvancedButtonHandler = (
    desiredFileName: string,
    type: 'excel' | 'pdf'
  ) => {
    setDesiredFileName(desiredFileName)
    generateAnnotationRaport({ questionareId: surveyId, type })
  }

  let isPdfFile: boolean = false

  if (isErrorInGetAccessToSurveyFile || isErrorInGetAnnotationRaport) {
    dispatch(
      appActions.showSnackbarNotification({
        label: `Sorry, we couldn't access our database. Please try again later, if the problem persists, please contact us.`,
        type: 'error',
      })
    )
  }

  let content = (
    <>
      {file && (
        <Box
          onClick={() => {
            onClickButtonHandler(file.id!, file.original_file_name)
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
        >
          <MySurveyItemLink
            label="Questions only"
            fileId={file?.id}
            filePath={file.path}
          />
          <Tooltip title="XLSX" arrow placement="left">
            <IconButton
              onClick={() => {
                onClickButtonHandler(file.id!, file.original_file_name)
              }}
            >
              <Download />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {completedFile && (
        <Box
          onClick={() => {
            onClickButtonHandler(
              completedFile.id!,
              completedFile.original_file_name
            )
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
        >
          <MySurveyItemLink
            label="Questions + Answers"
            fileId={completedFile?.id}
            filePath={completedFile.path}
          />
          <Tooltip title="XLSX" arrow placement="left">
            <IconButton
              onClick={() => {
                onClickButtonHandler(
                  completedFile.id!,
                  completedFile.friendly_name
                )
              }}
            >
              <Download />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 300,
            my: theme.spacing(1.3),
            p: 0,
            lineHeight: '25px',
            wordWrap: 'normal',
            minWidth: '120px',
            color: theme.palette.primary.main,
            cursor: 'auto',
          }}
        >
          Questions + Answers + Annotation
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isLoadingInGetAnnotationRaport ? (
            <CircularProgress />
          ) : (
            <>
              <Tooltip
                title="XLSX -> it doesn'contain rendered tables in answers"
                arrow
                placement="left"
              >
                <IconButton
                  onClick={() => {
                    onClickAdvancedButtonHandler(surveyId, 'excel')
                  }}
                >
                  <Download />
                </IconButton>
              </Tooltip>
              <Tooltip title="PDF" arrow placement="right">
                <IconButton
                  onClick={() => {
                    onClickAdvancedButtonHandler(surveyId, 'pdf')
                  }}
                >
                  <Download />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
    </>
  )

  if (isLoadingInGetAccessSurveyFile) {
    content = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '150px',
          width: '100%',
        }}
      >
        <CircularProgress sx={{ height: '400px' }} />
      </Box>
    )
  }

  return (
    <UniversalDialog isDialogOpen={isOpen} onCloseDialog={onClose}>
      <Box sx={{ px: theme.spacing(4), py: theme.spacing(2) }}>
        <Typography component={'h3'} variant="h6">
          Download Survey
        </Typography>
        {content}
      </Box>
    </UniversalDialog>
  )
}

export default DownloadFileDialog
