import { configureStore } from '@reduxjs/toolkit'
import appReducer from './App/app-slice'
import userReducer from './User/user-slice'
import responseViewReducer from './ResponsesView/ResponsesView-slice'
import evidenceListViewReducer from './EvidenceListView/EvidenceListView-slice'
import { usersApi } from '../services/users'
import { generateApi } from '../services/generate'
import { setupListeners } from '@reduxjs/toolkit/query'
import { evidencesApi } from '../services/evidences'
import { responsesApi } from '../services/responses'
import { hubSpotUserTrackingApi } from '../services/hubSpotUserTracking'
import { surveysApi } from '../services/surveys'
import { supportiveEvidencesApi } from '../services/supportiveEvidences'
import { fileApi } from '../services/file'
import { parseApi } from '../services/parse'
import { visualisationsApi } from '../services/visualisations'
import { dashboardApi } from '../services/dashboard'
import { seasonalSurveysApi } from '../services/seasonalSurveys'

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    responseView: responseViewReducer,
    evidenceListView: evidenceListViewReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [generateApi.reducerPath]: generateApi.reducer,
    [evidencesApi.reducerPath]: evidencesApi.reducer,
    [responsesApi.reducerPath]: responsesApi.reducer,
    [hubSpotUserTrackingApi.reducerPath]: hubSpotUserTrackingApi.reducer,
    [surveysApi.reducerPath]: surveysApi.reducer,
    [supportiveEvidencesApi.reducerPath]: supportiveEvidencesApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [parseApi.reducerPath]: parseApi.reducer,
    [visualisationsApi.reducerPath]: visualisationsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [seasonalSurveysApi.reducerPath]: seasonalSurveysApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      usersApi.middleware,
      generateApi.middleware,
      evidencesApi.middleware,
      responsesApi.middleware,
      hubSpotUserTrackingApi.middleware,
      surveysApi.middleware,
      supportiveEvidencesApi.middleware,
      fileApi.middleware,
      parseApi.middleware,
      visualisationsApi.middleware,
      dashboardApi.middleware,
      seasonalSurveysApi.middleware
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
setupListeners(store.dispatch)
