export enum FilterUrlParamNames {
  dataRangeStart = 'dataRangeStart',
  dataRangeEnd = 'dataRangeEnd',
  sourceParam = 'sourceParam',
  statusParam = 'statusParam',
  assignedParam = 'assignedParam',
  searchParam = 'searchParam',
  openAnswerIdParam = 'openAnswerIdParam',
  isShownOnlyRequestedParam = 'isShownOnlyRequestedParam',
}

export enum FilterEvidenceUrlParamNames {
  typeParam = 'typeParam',
  searchParam = 'searchParam',
  isUploadModeParam = 'isUploadModeParam',
}
