import React, { useContext } from 'react'
import { Box, SxProps, Typography, useTheme } from '@mui/material'
import { SupportiveEvidence } from '../../../types/interfaces'
import SupportiveEvidenceRow from './SupportiveEvidenceRow/SupportiveEvidenceRow'
import SupportiveEvidenceContextProvider from '../../../contexts/SupportiveEvidenceContext/SupportiveEvidenceContextProvider'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import { useAppSelector } from '../../../hooks/hooks'
import { AnswerPreviewContext } from '../../../contexts/AnswerPreview/answer-preview-contex'
import UniversalDialog from '../../ui/UniversalDialog/UniversalDialog'
import MissingEvidenceModal from './MissingEvidenceModal/MissingEvidenceModal'

interface Props {
  borderColor: string
  boxTitleStyling: SxProps
  supportiveEvidence: SupportiveEvidence[]
  cellPagination: SxProps
}

const SupportiveEvidenceSection: React.FC<Props> = ({
  borderColor,
  boxTitleStyling,
  supportiveEvidence,
  cellPagination,
}) => {
  const theme = useTheme()
  const { isSuperUser, role } = useAppSelector(state => state.user)
  const {
    state: { isEditorAssignedAsContributor },
    actions: { onClickOpenAddMissingEvidenceModal },
    answerData: { status },
  } = useContext(AnswerPreviewContext)

  let canUserAddMissingEvidence = false
  if (role === 'ADMIN' || role === 'STUFF') {
    canUserAddMissingEvidence = true
  } else if (
    role === 'CONTRIBUTOR' &&
    (status === 'CHECKED' || status === 'REJECTED_REVIEWED')
  ) {
    canUserAddMissingEvidence = true
  } else if (
    role === 'EDITOR' &&
    isEditorAssignedAsContributor &&
    status !== 'APPROVED'
  ) {
    canUserAddMissingEvidence = true
  } else if (role === 'EDITOR' && status === 'REVIEWED') {
    canUserAddMissingEvidence = true
  }

  const evidenceRowsJSXElements = supportiveEvidence.map((suppEvi, index) => {
    return (
      <SupportiveEvidenceContextProvider key={suppEvi.id} {...suppEvi}>
        <SupportiveEvidenceRow
          cellPagination={cellPagination}
          isLastItem={index === supportiveEvidence.length - 1}
          {...suppEvi}
          borderColor={borderColor}
          canUserRemoveEvidence={canUserAddMissingEvidence}
        />
      </SupportiveEvidenceContextProvider>
    )
  })

  if (supportiveEvidence.length === 0 && !canUserAddMissingEvidence) {
    return null
  }

  return (
    <Box
      id="evidenceRow"
      sx={{
        borderBottom: `1px solid ${borderColor}`,
        display: 'grid',
        gridTemplateColumns: '130px 1fr',
        alignItems: 'stretch',
      }}
    >
      <Box
        id="leftColumnInEvidenceRow"
        sx={{
          ...boxTitleStyling,
          display: 'grid',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" component={'h4'}>
          Evidence
        </Typography>
      </Box>
      <Box
        id="rightColumnInEvidenceRow"
        sx={{ display: 'grid', height: '100%' }}
      >
        {evidenceRowsJSXElements}
        {canUserAddMissingEvidence && (
          <Box sx={{ ...cellPagination }}>
            <Typography sx={{ mb: 0 }}>Missing evidence?</Typography>
            <UniversalButton
              stylingType={6}
              size="large"
              onClick={onClickOpenAddMissingEvidenceModal}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: 400,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  lineBreak: 'anywhere',
                  m: 0,
                }}
              >
                Add additional evidence to this question
              </Typography>
            </UniversalButton>
            <MissingEvidenceModal />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SupportiveEvidenceSection
