import React, { useContext } from 'react'
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import content from '../../../assets/content/content'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import { SimilarQuestionsContext } from '../../../contexts/SimilarQuestionsContext/similar-questions-context'
import { useAppSelector } from '../../../hooks/hooks'
import { useNavigate } from 'react-router-dom'

const GenerateNewAnswerSection: React.FC = () => {
  const theme = useTheme()
  const {
    state: {
      similarRequests,
      isViewerRequestNewAnswerSent,
      isLoadingInGenerateNewResponse,
    },
    actions: {
      onClickGenerateNewResponseButton,
      onClickGenerateNewResponseButtonForExistingRequest,
    },
  } = useContext(SimilarQuestionsContext)

  const { role, isAskQuestionModeBlocked } = useAppSelector(state => state.user)

  const navigate = useNavigate()

  const {
    eng: {
      views: {
        similarQuestions: {
          infoViewer,
          infoEditor,
          buttonTextViewer,
          buttonTextEditor,
          infoBlockedEditor,
        },
      },
    },
  } = content

  const isViewer = role === 'VIEWER'

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let textInSection =
    'If none of these questions satisfy your question, you can generate a new answer.'

  if (isViewer) {
    textInSection = infoViewer
  } else if (isAskQuestionModeBlocked && role !== 'STUFF') {
    textInSection = infoBlockedEditor
  }

  let mainContent: JSX.Element = (
    <>
      {similarRequests.length > 0 ? (
        <Typography>{textInSection}</Typography>
      ) : (
        <>
          <Typography>
            {isViewer
              ? `The ESG team has not previously approved a response to a similar
            question. You can submit a new request.`
              : `There are no approved questions in EthicsAnswer which match or are similar to your request. You can ask EthicsAnswer to generate a new response below. Your account is in LLM learning mode therefore EthicsAnswer's ESG Analyst team is revising each newly generated answer. We will notify you by email when your newly generated answer will be ready to view, this process usually takes less than 24 hours.`}
          </Typography>
        </>
      )}
      <Stack alignItems={'center'} justifyContent="center" direction={'row'}>
        {/* <Tooltip
          title={
            isAskQuestionModeBlocked
              ? 'This functionality is blocked ask customer support to unblock'
              : ''
          }
        >
          <span>
            <UniversalButton
              disabled={isAskQuestionModeBlocked}
              loading={isLoadingInGenerateNewResponse}
              label={isViewer ? buttonTextViewer : buttonTextEditor}
              onClick={onClickGenerateNewResponseButton}
            />
          </span>
        </Tooltip> */}
        <UniversalButton
          loading={isLoadingInGenerateNewResponse}
          label={isViewer ? buttonTextViewer : buttonTextEditor}
          // onClick={
          //   isAskQuestionModeBlocked && role !== 'STUFF'
          //     ? onClickGenerateNewResponseButtonForExistingRequest
          //     : onClickGenerateNewResponseButton
          // }
          onClick={onClickGenerateNewResponseButtonForExistingRequest}
        />
      </Stack>
    </>
  )

  if (isViewerRequestNewAnswerSent) {
    mainContent = (
      <>
        <Typography component={'h6'} variant="h6">
          Your request has been sent.
        </Typography>
        <Typography>
          We will notify you when it is ready for you to view.
        </Typography>
        <Stack>
          <UniversalButton
            label="Ask New Question"
            onClick={() => navigate('/ask-question')}
          />
        </Stack>
      </>
    )
  }

  return (
    <Box
      sx={{
        my: theme.spacing(4),
        border: `1px solid ${borderColor}`,
        px: theme.spacing(2.5),
        py: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {mainContent}
    </Box>
  )
}

export default GenerateNewAnswerSection
