import React, { useContext } from 'react'
import {
  Box,
  CircularProgress,
  Drawer,
  Typography,
  useTheme,
} from '@mui/material'
import { AnswersLibraryContext } from '../../../../contexts/AnswersLibraryContext/answers-library-context'
import AnswerPreviewContexProvider from '../../../../contexts/AnswerPreview/AnswerPreviewContexProvider'
import AnswerPreview from '../../../AnswerPreview/AnswerPreview'
import UniversalButton from '../../../ui/Buttons/UniversalButton'
import { AnswerItem } from '../../../../types/interfaces'
import { convertDateToNiceFormat } from '../../../../utils/utils'
import { useLocation } from 'react-router-dom'

interface Props {
  closeDrawerInReviewModeAction?: () => void
}

const AnswerRightDrawer: React.FC<Props> = ({
  closeDrawerInReviewModeAction,
}) => {
  const {
    state: { currentOpenAnswer, answersData, isLoadingInSavedAnswers },
    queryResults: { isFetching },
    actions: { onCloseAnswer },
  } = useContext(AnswersLibraryContext)

  const theme = useTheme()

  const location = useLocation()

  const isUncheckedReviewMode = location.pathname.indexOf('review') !== -1

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const answerDataToPass: AnswerItem | undefined = answersData.find(
    answerItem => {
      return answerItem.id === currentOpenAnswer
    }
  )

  let topRowJsxItem = (
    <Box
      id="closeRow"
      sx={{
        border: `1px solid ${borderColor}`,
        py: theme.spacing(0.5),
        px: theme.spacing(2),
        borderBottom: 'none',
      }}
    >
      <UniversalButton
        onClick={
          isUncheckedReviewMode ? closeDrawerInReviewModeAction : onCloseAnswer
        }
        label="Close"
        stylingType={6}
      />
    </Box>
  )

  if (answerDataToPass) {
    const { status, updated_at } = answerDataToPass
    if (status === 'APPROVED') {
      const updatedDateInNiceFormat = convertDateToNiceFormat(updated_at)
      topRowJsxItem = (
        <Box
          id="closeRow"
          sx={{
            border: `1px solid ${borderColor}`,

            borderBottom: 'none',
            display: 'grid',
            gridTemplateColumns: '2.4fr 1fr',
            alignItems: 'stretch',
          }}
        >
          <Box
            sx={{
              borderRight: `1px solid ${borderColor}`,
              py: theme.spacing(0.5),
              px: theme.spacing(2),
            }}
          >
            <UniversalButton
              onClick={onCloseAnswer}
              label="Close"
              stylingType={6}
            />
          </Box>
          <Box
            sx={{
              py: theme.spacing(0.5),
              px: theme.spacing(2),
            }}
          >
            <Typography>{`Last Updated: ${updatedDateInNiceFormat}`}</Typography>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Drawer
      anchor={'right'}
      open={currentOpenAnswer ? true : false}
      onClose={
        isUncheckedReviewMode ? closeDrawerInReviewModeAction : onCloseAnswer
      }
    >
      <Box
        sx={{
          height: '100%',
          width: '80vw',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {topRowJsxItem}
        {answerDataToPass ? (
          <AnswerPreviewContexProvider {...answerDataToPass}>
            <AnswerPreview
              displayType={
                isUncheckedReviewMode
                  ? 'answerUncheckedReview'
                  : 'answersLibrary'
              }
            />
          </AnswerPreviewContexProvider>
        ) : (
          <Box
            sx={{
              display: 'flex',
              borderTop: `1px solid ${borderColor}`,
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Drawer>
  )
}

export default AnswerRightDrawer
