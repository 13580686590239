import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import ErrorView from './ErrorView'
import Auth0Loading from './Loading'
import { useAppDispatch } from '../hooks/hooks'
import { useGetEvidencesQuery } from '../services/evidences'
import Loading from './Loading'
import EvidenceProvidedContent from '../components/getStarted/EvidenceProvidedContent/EvidenceProvidedContent'
import { userActions } from '../store/User/user-slice'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import { appActions } from '../store/App/app-slice'

const GetStarted: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    data: evidenceData,
    isLoading: isEvidenceLoading,
    isError: isEvidenceError,
    error: evidenceDataError,
  } = useGetEvidencesQuery()

  const {
    eng: {
      views: {
        getStarted: { heading },
      },
    },
  } = content

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Get Started'))
  }, [])

  useEffect(() => {
    if (evidenceData && evidenceData.length) {
      dispatch(userActions.setEvidenceProvided())
    } else {
      dispatch(userActions.setEvidenceNotProvided())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evidenceData])

  useEffect(() => {
    localStorage.setItem('wasGetStartedShown', 'true')
  }, [])

  // @ts-ignore: Unreachable code error
  // var _hsq = (window._hsq = window._hsq || [])

  // useEffect(() => {
  //   _hsq.push(['setPath', '/ask-question'])
  //   _hsq.push(['trackPageView'])
  // }, [])

  let mainContentJxs: JSX.Element

  if (isEvidenceLoading) {
    return <Auth0Loading />
  }

  if (isEvidenceError) {
    return <ErrorView errorData={evidenceDataError} />
  }

  if (isEvidenceLoading) {
    return <Loading />
  }

  if (evidenceData && evidenceData.length > 0) {
    mainContentJxs = <EvidenceProvidedContent />
  } else {
    // mainContentJxs = <EvidenceNotProvidedContent />
    mainContentJxs = <EvidenceProvidedContent />
  }

  return (
    <MainContentContainer
      maxWidth="lg"
      sx={{
        alignItems: 'start',
        display: 'flex',
        paddingBottom: '3%',
      }}
    >
      <Box component={'article'}>
        {/* <Box
          sx={{
            border: `1px solid ${pinkColor}`,
            width: '100%',
            minHeight: '500px',
            mb: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
        >
          <Typography>Movie box</Typography>
        </Box> */}
        {mainContentJxs}
      </Box>
    </MainContentContainer>
  )
}

export default GetStarted
