import React from 'react'

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
interface Props {
  label: string
  sx?: SxProps
  helper?: string
  id: string
  valueSelect: string
  onChangeSelect: (e: SelectChangeEvent<string>) => void
  isRequired: boolean
}

const SelectYearInput: React.FC<Props> = ({
  label,
  sx,
  valueSelect,
  onChangeSelect,
  isRequired,
}) => {
  return (
    <>
      <FormControl sx={{ ...sx }}>
        <InputLabel size="small" id={`select-${label}`}>
          {label}
        </InputLabel>
        <Select
          labelId={`select-${label}`}
          id={`select-${label}`}
          value={valueSelect}
          onChange={onChangeSelect}
          size="small"
          label={label}
          required={isRequired ? true : false}
        >
          <MenuItem key={'2024'} value={'2024'}>
            2024
          </MenuItem>
          <MenuItem key={'2023'} value={'2023'}>
            2023
          </MenuItem>
          <MenuItem key={'2022'} value={'2022'}>
            2022
          </MenuItem>
          <MenuItem key={'2021'} value={'2021'}>
            2021
          </MenuItem>
          <MenuItem key={'2020'} value={'2020'}>
            2020
          </MenuItem>
          <MenuItem key={'2019'} value={'2019'}>
            2019
          </MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

export default SelectYearInput
