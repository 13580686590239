import { EvidenceLibraryState } from './evidenceLibraryInterfaces'

export const initialEvidenceLibraryState: EvidenceLibraryState = {
  searchInputValue: '',
  evidenceData: [],
  isLoadingEvidenceData: false,
  isErrorEvidenceData: false,
  filterByTypeOfEvidence: null,
  filteredEvidence: [],
  filterBySearch: '',
}
