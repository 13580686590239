import React from 'react'
import { TableRow } from '@mui/material'

interface Props {
  children: React.ReactNode
}

const RowInNewTable: React.FC<Props> = ({ children }) => {
  return <TableRow>{children}</TableRow>
}

export default RowInNewTable
