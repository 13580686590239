import React, { useContext } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import content from '../../../assets/content/content'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ErrorAlert from '../../ui/Allerts/ErrorAlert/ErrorAlert'
import SuccessAlert from '../../ui/Allerts/SuccessAlert/SuccessAlert'
import theme from '../../../themeData/themeData'
import { GeneratedResponseContext } from '../../../contexts/GeneratedResponseContext/generated-response-context'

const ResponseRecomendationSection: React.FC = () => {
  const {
    eng: {
      views: {
        questionResponse: { recomendationQuestion },
      },
    },
  } = content

  const {
    state: {
      isLoadingSendFeedback,
      isSuccessInSendFeedback,
      isErrorInSendFeedback,
    },
    actions: { onClickThumbNail },
  } = useContext(GeneratedResponseContext)

  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let jsxItemsAfterRecomQuestion = (
    <Stack spacing={theme.spacing(0.5)} direction={'row'}>
      <IconButton sx={{ m: 0 }} onClick={() => onClickThumbNail('LIKE')}>
        <ThumbUpAltIcon />
      </IconButton>
      <IconButton sx={{ m: 0 }} onClick={() => onClickThumbNail('DISLIKE')}>
        <ThumbDownAltIcon />
      </IconButton>
    </Stack>
  )

  if (isLoadingSendFeedback) {
    jsxItemsAfterRecomQuestion = (
      <Box sx={{ display: 'flex', ml: 2 }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      {isSuccessInSendFeedback && (
        <SuccessAlert
          isSuccess={isSuccessInSendFeedback}
          alertContent={'Thank you for your feedback.'}
        />
      )}
      {isErrorInSendFeedback && (
        <ErrorAlert
          isError={isErrorInSendFeedback}
          alertContent={'Something went wrong. Please try again later.'}
        />
      )}
      {!isSuccessInSendFeedback && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderTop: `1px solid ${borderColor}`,
            px: theme.spacing(3),
            py: theme.spacing(2),
          }}
        >
          <Typography>{recomendationQuestion}</Typography>
          {jsxItemsAfterRecomQuestion}
        </Box>
      )}
    </>
  )
}

export default ResponseRecomendationSection
