import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  FormHelperText,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
interface Props {
  label?: string
  options: { value: string; label: string }[]
  helper?: string
  sx?: SxProps
  onChange?: (newValue: string) => void
  currentValue?: string
  isRequired?: boolean
  hideLabel?: boolean
  defaultValue?: string
  isDisabled?: boolean
}

const SelectUniInput: React.FC<Props> = ({
  label,
  options,
  sx,
  helper,
  onChange,
  currentValue,
  isRequired,
  hideLabel,
  defaultValue,
  isDisabled,
}) => {
  const optionsJsxElements = options.map((option, index) => {
    const { label, value } = option
    return (
      <MenuItem key={`${index}_${label}`} value={value}>
        {label}
      </MenuItem>
    )
  })

  const handleChange = (event: SelectChangeEvent) => {
    if (onChange) {
      onChange(event.target.value as string)
    }
  }
  return (
    <FormControl disabled={isDisabled} sx={{ div: { marginBottom: 0 }, ...sx }}>
      {!hideLabel && (
        <InputLabel size="small" id={`uni-select-label-${label}`}>
          {label}
        </InputLabel>
      )}
      <Select
        labelId={`uni-select-label-${label}`}
        id={`uni-select-label-${label}`}
        value={currentValue}
        label={hideLabel ? '' : label}
        onChange={handleChange}
        size="small"
        required={isRequired ? true : false}
        defaultValue={defaultValue ? defaultValue : ''}
      >
        {optionsJsxElements}
      </Select>
      {helper && (
        <FormHelperText sx={{ m: 0, marginBottom: 1 }}>{helper}</FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectUniInput
