import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Evidence, FileAccess, FileDetails } from '../types/interfaces'
import type { RootState } from '../store/store'

export const parseApi = createApi({
  reducerPath: 'parseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/parse/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['Evidences'],
  endpoints: build => ({
    parseEvidenceItem: build.mutation<string, Evidence['id']>({
      query: evidenceId => ({
        url: `evidence/${evidenceId}/`,
        method: 'POST',
      }),
      invalidatesTags: ['Evidences'],
    }),
    parseCompanyEvidence: build.mutation<string, string>({
      query: companyId => ({
        url: `company/${companyId}/`,
        method: 'POST',
      }),
      invalidatesTags: ['Evidences'],
    }),
  }),
})

export const { useParseEvidenceItemMutation, useParseCompanyEvidenceMutation } =
  parseApi
