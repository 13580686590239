import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useContext } from 'react'
import { Close } from '@mui/icons-material'
import { AnswerPreviewContext } from '../../../../contexts/AnswerPreview/answer-preview-contex'
import UniversalButton from '../../../ui/Buttons/UniversalButton'

const RejectDialog: React.FC = () => {
  const {
    state: { isRejectModalOpen },
    actions: { onSubmitRejectFeedback, onClickCloseRejectModal },
  } = useContext(AnswerPreviewContext)

  const theme = useTheme()

  const onSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      rejectFeedback: { value: string }
    }
    const rejectFedbackValue = target.rejectFeedback.value // typechecks!
    onSubmitRejectFeedback(rejectFedbackValue)
  }

  return (
    <Dialog
      open={isRejectModalOpen}
      onClose={onClickCloseRejectModal}
      maxWidth="md"
      fullWidth
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          minHeight: '370px',

          paddingX: theme.spacing(4),
          paddingY: theme.spacing(2.5),
        }}
        component={'form'}
        onSubmit={onSubmitHandler}
      >
        <Box sx={{ textAlign: 'right' }}>
          <IconButton onClick={onClickCloseRejectModal}>
            <Close />
          </IconButton>
        </Box>
        <DialogTitle sx={{ paddingLeft: 0, m: 0 }}>
          You have rejected an answer.
        </DialogTitle>
        <Typography>
          Please let us know the reason you are rejecting the answer.
          EthicsAnswer will review and regenerate the answer.
        </Typography>
        <TextField id="rejectFeedback" fullWidth multiline minRows={4} />
        <Stack
          direction={'row'}
          spacing={theme.spacing(3)}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <UniversalButton label="Submit" type="submit" />
          <UniversalButton
            label="Cancel"
            onClick={onClickCloseRejectModal}
            stylingType={4}
          />
        </Stack>
      </Box>
    </Dialog>
  )
}

export default RejectDialog
