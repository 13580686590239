import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'

const Loading: React.FC = () => {
  return (
    <MainContentContainer maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '500px',
          alignItems: 'center',
        }}
      >
        <CircularProgress sx={{ height: '100%' }} />
      </Box>
    </MainContentContainer>
  )
}

export default Loading
