import {
  Box,
  DialogContentText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useContext } from 'react'
import UniversalButton from '../../../../ui/Buttons/UniversalButton'
import { UploadEvidenceContext } from '../../../../../contexts/UploadEvidenceContext/uploadEvidence-context'
import UploadFileSharedContent from './UploadFileSharedContent'

const DialogPublicContent: React.FC = () => {
  const {
    actions: { onSubmitAddEvidenceForm },
  } = useContext(UploadEvidenceContext)

  const theme = useTheme()

  return (
    <Box sx={{ px: theme.spacing(4), py: theme.spacing(3) }}>
      <Typography component={'h3'} variant="h6">
        Add a public document.
      </Typography>
      <DialogContentText>
        Provide the link or locate the public document you wish to add to the
        Evidence Library.
      </DialogContentText>
      <Box id="addURL" component={'form'} onSubmit={onSubmitAddEvidenceForm}>
        <Typography component={'h3'} variant="subtitle1">
          Add URL
        </Typography>
        <TextField
          id="urlToDocument"
          label="URL to document"
          type="URL"
          fullWidth
          variant="outlined"
          required
        />
        <UniversalButton type="submit" stylingType={5} label="Add" />
      </Box>
      <Typography component={'h3'} variant="subtitle1">
        OR
      </Typography>

      <Box id="addFile" component={'form'} onSubmit={onSubmitAddEvidenceForm}>
        <Typography component={'h3'} variant="subtitle1">
          Upload file
        </Typography>
        <UploadFileSharedContent />
      </Box>
    </Box>
  )
}

export default DialogPublicContent
