import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import Fuse from 'fuse.js'
import {
  AnswerItem,
  Evidence,
  IHistoricalQuestionsByDay,
  Survey,
} from '../types/interfaces'
import { AskQuestionState } from '../contexts/AskQuestionContext/askQuestionInterfaces'

export const detectBrowser = (userAgent: string): string => {
  if (/chrome/i.test(userAgent)) {
    return 'Google Chrome'
  } else if (/firefox/i.test(userAgent)) {
    return 'Mozilla Firefox'
  } else if (/safari/i.test(userAgent)) {
    return 'Apple Safari'
  } else if (/opera|opr/i.test(userAgent)) {
    return 'Opera'
  } else if (/edge/i.test(userAgent)) {
    return 'Microsoft Edge'
  } else if (/msie|trident/i.test(userAgent)) {
    return 'Internet Explorer'
  } else {
    return 'Unknown'
  }
}

export const processMonth = (monthNumber: number): string => {
  const realMonth = monthNumber + 1
  if (realMonth < 10) {
    return `0${realMonth.toString()}`
  }
  return realMonth.toString()
}

export const compareDates = (
  a: IHistoricalQuestionsByDay,
  b: IHistoricalQuestionsByDay
) => {
  if (a.year !== b.year) {
    return b.year - a.year
  }

  if (a.month !== b.month) {
    return b.month - a.month
  }

  return b.day - a.day
}

export const monthsNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const processFileNameFromUrl = (url: string): string => {
  let result = ''

  if (url) {
    const slashIndexFromBack = url.lastIndexOf('/')
    result = url.slice(slashIndexFromBack + 1, url.length)
  }
  return result
}

export const isFetchBaseQueryErrorType = (
  variable: any
): variable is FetchBaseQueryError => {
  return variable && typeof variable === 'object'
}

export const cleanEvidenceSentence = (sentence: string): string[] => {
  let sentenceAfterCleaning = ''
  const charactersToRemove = [`“`, `'`]
  sentence = sentence.trim()

  for (const character of charactersToRemove) {
    const isCharacterExistInSentenceAtBegiining =
      sentence.indexOf(character) === 0
    if (isCharacterExistInSentenceAtBegiining) {
      sentenceAfterCleaning = sentence.slice(1, sentence.length)
      break
    } else {
      sentenceAfterCleaning = sentence
    }
  }

  for (const character of charactersToRemove) {
    const isCharacterExistInSentenceInEnd =
      sentenceAfterCleaning.lastIndexOf(character) ===
      sentenceAfterCleaning.length
    if (isCharacterExistInSentenceInEnd) {
      sentenceAfterCleaning = sentenceAfterCleaning.slice(
        0,
        sentence.length - 1
      )
      break
    }
  }
  let sentensecDividedForParagraphs = sentenceAfterCleaning.split('\n')
  sentensecDividedForParagraphs[0] = `"...${sentensecDividedForParagraphs[0]}`
  sentensecDividedForParagraphs[
    sentensecDividedForParagraphs.length
  ] = `${sentensecDividedForParagraphs.length}..."`
  return sentensecDividedForParagraphs
}

export const checkIfItIsSpecificFileNameToTriggerProgressBar = (
  companyName: string,
  fileName: string
): boolean => {
  const cleanedFileName = processFileNameFromUrl(fileName).replaceAll('&', '')

  const companyNameWithSwapedSpaces = companyName
    .trim()
    .replaceAll(' ', '_')
    .replaceAll('&', '')
  const fileNameTemplate = `Survey_${companyNameWithSwapedSpaces}_EG`

  const doesFileNameContainTemplate =
    cleanedFileName.indexOf(fileNameTemplate) !== -1

  return doesFileNameContainTemplate
}

export const isCompanyNameExistInArray = (
  companyName: string,
  arrayOfCompaniesNames: string[]
) => {
  let result = false

  const foundCompanyName = arrayOfCompaniesNames.find(
    companyNameItem => companyName === companyNameItem
  )
  if (foundCompanyName) {
    result = true
  }
  return result
}

export const checkIsItPdfFile = (path: string): boolean => {
  const extensionsToFind = ['.pdf', '.PDF']
  let result = false

  extensionsToFind.forEach(extension => {
    const lastIndexOfSearchExtensionInPath = path.lastIndexOf(extension)

    if (lastIndexOfSearchExtensionInPath !== -1) {
      result = true
      return
    }
  })
  return result
}

export const createMetricObject = (
  value: AskQuestionState['unit'],
  label: string
): { value: AskQuestionState['unit']; label: string } => {
  return { value, label }
}

export const calculateProcessingDays = (
  startDate: Date,
  processingDays: number
): Date => {
  const weekends: number[] = [0, 5, 6] // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
  let currentDate = new Date(startDate)
  let remainingDays = processingDays

  while (remainingDays > 0) {
    currentDate.setDate(currentDate.getDate() + 1)
    const currentDay = currentDate.getDay()
    if (!weekends.includes(currentDay)) {
      remainingDays--
    }
  }

  return currentDate
}

export const subtractDates = (
  dateOfCreation: Date,
  todaysDate: Date
): number => {
  return todaysDate.getTime() - dateOfCreation.getTime()
}

export const sortSurveysData = (
  data: Survey[],
  sortBy: keyof Survey | 'status',
  sortOrder: 'ASC' | 'DES'
): Survey[] => {
  const orderFactor = sortOrder === 'ASC' ? 1 : -1
  // if (sortBy === 'created') {
  //   return data.sort((a, b) => {
  //     const dataA = new Date(a.created).getDate()
  //     const dataB = new Date(b.created).getDate()

  //     if (dataA > dataB) {
  //       return sortOrder === 'ASC' ? 1 : -1
  //     } else if (dataA < dataB) {
  //       return sortOrder === 'ASC' ? -1 : 1
  //     } else {
  //       return 0
  //     }
  //   })
  // }

  if (sortBy === 'status') {
    return data.sort((a, b) => {
      if (a.completed_file && b.completed_file) {
        return 0
      } else if (a.completed_file) {
        return orderFactor
      } else if (b.completed_file) {
        return orderFactor * -1
      } else {
        return 0
      }
    })
  }

  if (sortBy === 'file') {
    return data.sort((a, b) => {
      if (a.file && b.file) {
        return a.file.path.localeCompare(b.file.path) * orderFactor
      } else if (a.file) {
        return sortOrder === 'ASC' ? 1 : -1
      } else if (b.file) {
        return sortOrder === 'ASC' ? -1 : 1
      }
      return 0
    })
  }
  return data
}

// Helper function to find the insertion index in a sorted array
// const findInsertionIndex = (array: PreviousResponse[], date: Date) => {
//   for (let i = 0; i < array.length; i++) {
//     if (new Date(array[i].updated_at) < date) {
//       return i
//     }
//   }
//   return array.length
// }

export const groupResponsesFuse = (
  responses: Fuse.FuseResult<AnswerItem>[]
) => {
  const groupedData: AnswerItem[] = []

  responses.forEach(response => {
    const { item } = response
    const updatedAtDate = new Date(item.updated_at)

    groupedData.push({ ...item })
    // Insert response while maintaining sorted order by updated_at (latest to oldest)
  })

  return groupedData
}

export const groupEvidenceFuse = (responses: Fuse.FuseResult<Evidence>[]) => {
  const groupedData: Evidence[] = []

  responses.forEach(response => {
    const { item } = response
    const updatedAtDate = new Date(item.updated_at)

    groupedData.push({ ...item })
    // Insert response while maintaining sorted order by updated_at (latest to oldest)
  })

  return groupedData
}

export const convertDateToNiceFormat = (dateString: string): string => {
  const dateObj = new Date(dateString)
  const result = `${dateObj.getFullYear()}-${processMonth(
    dateObj.getMonth()
  )}-${dateObj.getDate()}`
  return result
}

export const getRandomValue = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
