export const audienceOptions = [
  { label: 'Investors', value: 'INVESTORS' },
  { label: 'Shareholders', value: 'SHAREHOLDERS' },
  { label: 'Regulators', value: 'REGULATORS' },
  { label: 'Customers', value: 'CUSTOMERS' },
  { label: 'Supply chain partners', value: 'SUPPLY_CHAIN_PARTNERS' },
  { label: 'Employees', value: 'EMPLOYEES' },
  {
    label: 'Non governmental organisations',
    value: 'NON_GOVERNMENTAL_ORGANISATIONS',
  },
  { label: 'Internal departments', value: 'INTERNAL_DEPARTMENTS' },
]

export const evidenceDepthOptions = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
  { label: '2021', value: '2021' },
  { label: '2020', value: '2020' },
  { label: '2019', value: '2019' },
]

export const evidenceTypeOptions = [
  { label: 'Only Public', value: 'PUBLIC' },
  { label: 'Only Private', value: 'PRIVATE' },
  { label: 'Public and private', value: 'BOTH' },
]

export const answerLengthOptions = [
  { value: 'DETAILED', label: 'Detailed' },
  { value: 'MINIMUM', label: 'Minimum' },
]
