import React, { useContext, useEffect } from 'react'
import {
  Box,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import { AnswerPreviewContext } from '../../../contexts/AnswerPreview/answer-preview-contex'
import { useAppSelector } from '../../../hooks/hooks'
import { useLocation } from 'react-router-dom'
import { paragraphGenerator } from '../../../utils/paragraphGenerator'
import '@mdxeditor/editor/style.css'
import {
  MDXEditor,
  headingsPlugin,
  MDXEditorMethods,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  tablePlugin,
  linkPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  InsertTable,
  ListsToggle,
  DiffSourceToggleWrapper,
  diffSourcePlugin,
} from '@mdxeditor/editor'
import AnswerBoxWithDiff from './AnswerBoxWithDiff/AnswerBoxWithDiff'
import { AnswerItem } from '../../../types/interfaces'
import { convertDateToNiceFormat } from '../../../utils/utils'

interface Props {
  boxTitleStyling: SxProps
  heading: string
  answerText: string
  originalResponse: AnswerItem['original_response']
  cellPagination: SxProps
}

const CustomLink = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const HeadingAndAnswerSection: React.FC<Props> = ({
  boxTitleStyling,
  heading,
  answerText,
  cellPagination,
  originalResponse,
}) => {
  const theme = useTheme()
  const location = useLocation()

  const { userId } = useAppSelector(state => state.user)
  const ref = React.useRef<MDXEditorMethods>(null)

  const {
    state: { isEditMode, responseInput, areAnyChangesCompareToOriginal },
    answerData: {
      status,
      isSaveAnswerLoading,
      assignedUser,
      modifiedBy,
      lastModified,
    },
    companyUsersData,
    actions: {
      onClickEditMode,
      onResponseChange,
      onClickDiscardChangesButton,
      onClickSaveAnswer,
    },
  } = useContext(AnswerPreviewContext)

  const { role, isSuperUser } = useAppSelector(state => state.user)

  const { pathname } = location

  const isReviewUncheckedMode = pathname.indexOf('review') !== -1

  const isSimilarQuestions = pathname === '/similar-questions'

  const isViewer = role === 'VIEWER'

  let isSaveAnswerButtonDisabled = false

  if (answerText === responseInput.value) {
    isSaveAnswerButtonDisabled = false
  }

  const onClickModifyButtonHandler = () => {
    onClickEditMode(answerText)
  }

  const handleMarkdownChange = (newMarkdown: string) => {
    onResponseChange(newMarkdown)
  }

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const boxStyling: SxProps = {
    borderBottom: `1px solid ${borderColor}`,
    ...cellPagination,
  }

  const regeneratingSentence = `The answer has been rejected. You will be notified by email once it's regenerated and ready for review and approval.`

  let conditionToHideAnswer = isViewer && status !== 'APPROVED'

  let conditionToShowOriginalAnswer =
    (role === 'STUFF' || role === 'ADMIN' || role === 'EDITOR') &&
    status === 'REVIEWED'

  let isRegenerating = status === 'REJECTED_CHECKED'

  let canUserChangeStatus = userId === assignedUser?.id

  if (role === 'STUFF') {
    canUserChangeStatus = true
  } else if (role === 'CONTRIBUTOR' && status === 'REVIEWED') {
    canUserChangeStatus = false
  } else if ((role === 'ADMIN' || role === 'EDITOR') && status === 'REVIEWED') {
    canUserChangeStatus = true
  }

  let canUserModifyAnswer = false
  const isApproved = status === 'APPROVED'

  if (isReviewUncheckedMode) {
    canUserModifyAnswer = false
  } else if (role === 'STUFF' && !isEditMode) {
    canUserModifyAnswer = true
  } else if (
    !isSimilarQuestions &&
    canUserChangeStatus &&
    !isApproved &&
    !isViewer &&
    !isRegenerating &&
    !isEditMode
  ) {
    canUserModifyAnswer = true
  }

  // Process Markdown content to replace standard links with custom links
  const processedMarkdown = markDown => {
    return markDown.replace(/\[(.*?)\]\((.*?)\)/g, (match, text, href) => {
      return `<a style="display:inline; color:black;"href="${href}" target="_blank">${text}</a>`
    })
  }

  const modifiedByUserName = companyUsersData.data.find(
    userItem => modifiedBy === userItem.id
  )

  const mdxEditorPlugins = isEditMode
    ? [
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        tablePlugin(),
        linkPlugin(),
        diffSourcePlugin({
          diffMarkdown: answerText,
          viewMode: 'rich-text',
          readOnlyDiff: true,
        }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <BlockTypeSelect />
              <InsertTable />
              <ListsToggle />
              <DiffSourceToggleWrapper>
                <UndoRedo />
              </DiffSourceToggleWrapper>
            </>
          ),
        }),
      ]
    : [
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        tablePlugin(),
        linkPlugin(),
        diffSourcePlugin({
          diffMarkdown: answerText,
          viewMode: 'rich-text',
          readOnlyDiff: true,
        }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              {/* <UndoRedo />
              <BoldItalicUnderlineToggles />
              <BlockTypeSelect />
              <InsertTable />
              <ListsToggle />
              <DiffSourceToggleWrapper>
                <UndoRedo />
              </DiffSourceToggleWrapper> */}
            </>
          ),
        }),
      ]

  return (
    <>
      <Box
        id="headingRow"
        sx={{
          ...boxStyling,
        }}
      >
        <Typography variant="h4" component={'h3'}>
          {paragraphGenerator({
            text: heading,
            component: 'h3',
            variant: 'h6',
            // sx: { fontWeight: 'bold' },
          })}
        </Typography>
      </Box>
      {!conditionToHideAnswer && (
        <Box
          id="answerRow"
          sx={{
            borderBottom: `1px solid ${borderColor}`,
            display: 'grid',
            gridTemplateColumns: '130px 1fr',
          }}
        >
          <Box
            id="leftColumn"
            sx={{
              ...boxTitleStyling,
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h6"
              component={'h4'}
              sx={{ lineHeight: '24px' }}
              textAlign={'center'}
            >
              {areAnyChangesCompareToOriginal && status === 'REVIEWED'
                ? 'Modified Answer'
                : 'Answer'}
            </Typography>
            {canUserModifyAnswer && (
              <UniversalButton
                label="Modify"
                stylingType={2}
                onClick={onClickModifyButtonHandler}
              />
            )}
          </Box>
          <Box
            id="rightColumn"
            sx={{
              backgroundColor: isEditMode
                ? theme.palette.secondary.main
                : 'inherit',

              display: 'flex',
              flexDirection: 'column',
              overflowX: 'auto',
            }}
          >
            {isEditMode ? (
              <Box
                sx={{
                  px: theme.spacing(1),
                  py: isEditMode ? theme.spacing(4) : theme.spacing(0.5),
                  overflow: 'scroll',
                }}
              >
                <MDXEditor
                  ref={ref}
                  markdown={processedMarkdown(responseInput.value)}
                  readOnly={false}
                  onChange={handleMarkdownChange}
                  contentEditableClassName="edit"
                  plugins={[...mdxEditorPlugins]}
                />
                <Stack
                  direction={'row'}
                  spacing={theme.spacing(4)}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ marginTop: theme.spacing(2) }}
                >
                  <UniversalButton
                    label="Save"
                    loading={isSaveAnswerLoading}
                    onClick={onClickSaveAnswer}
                    disabled={isSaveAnswerButtonDisabled}
                  />
                  <UniversalButton
                    label="Cancel"
                    stylingType={4}
                    onClick={onClickDiscardChangesButton}
                  />
                </Stack>
              </Box>
            ) : isRegenerating && !isSuperUser ? (
              <Typography variant="body1">{regeneratingSentence}</Typography>
            ) : (
              <Box sx={{ flexGrow: 1, overflowX: 'auto' }}>
                {/* Use the processed Markdown with custom link syntax */}
                <MDXEditor
                  ref={ref}
                  markdown={processedMarkdown(answerText)}
                  readOnly={true}
                  contentEditableClassName="prose"
                  plugins={[...mdxEditorPlugins]}
                />
              </Box>
            )}
            {areAnyChangesCompareToOriginal &&
              status === 'REVIEWED' &&
              !isEditMode && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '3.41fr 1fr',
                    borderTop: `1px solid ${borderColor}`,
                  }}
                >
                  <Box
                    sx={{
                      borderRight: `1px solid ${borderColor}`,
                      px: theme.spacing(2.4),
                      py: theme.spacing(0.5),
                    }}
                  >
                    <Typography>
                      Modified by:{' '}
                      {`${modifiedByUserName?.first_name} ${
                        modifiedByUserName?.last_name
                          ? modifiedByUserName?.last_name
                          : ''
                      }`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      px: theme.spacing(2.4),
                      py: theme.spacing(0.5),
                      textAlign: 'center',
                    }}
                  >
                    <Typography>{`${convertDateToNiceFormat(
                      lastModified
                    )}`}</Typography>
                  </Box>
                </Box>
              )}
          </Box>
        </Box>
      )}
      {!conditionToHideAnswer &&
        originalResponse?.text !== null &&
        conditionToShowOriginalAnswer && (
          <AnswerBoxWithDiff
            original={processedMarkdown(originalResponse?.text)}
            edited={processedMarkdown(answerText)}
            boxTitleStyling={boxTitleStyling}
            modifiedDate={originalResponse?.created_at}
          />
        )}
    </>
  )
}

export default HeadingAndAnswerSection
