import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { DashBoardData } from '../types/interfaces'
import type { RootState } from '../store/store'

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/dashboard`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: [`Dashboard`],
  endpoints: build => ({
    getDashboardData: build.query<DashBoardData, void>({
      query: () => ({
        url: `/`,
      }),
      providesTags: ['Dashboard'],
    }),
  }),
})

export const { useGetDashboardDataQuery } = dashboardApi
