import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { userActions } from '../store/User/user-slice'
import NoSupportForMobileDevices from '../views/NoSupportForMobileDevices'
import { useMediaQuery } from 'react-responsive'
import theme from '../themeData/themeData'
import LeftSideBar from '../components/LeftSideBar/LeftSideBar'

const Auth: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0()

  const { token, isSuperUser } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 765px)',
  })

  const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently()
        dispatch(userActions.setToken(accessToken))
      } catch (e) {
        console.log(e)
      }
    }

    if (!token) {
      getToken()
    }
  }, [getAccessTokenSilently, dispatch, token])

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '300px 1fr' }}>
      <LeftSideBar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'space-between',
        }}
      >
        <Header />
        {isSuperUser && (
          <Typography
            sx={{ mx: theme.spacing(3), textAlign: 'center', mb: 0 }}
            variant="h6"
            color="primary"
          >
            Super user account
          </Typography>
        )}
        {token ? (
          <>
            {isDesktopOrLaptop ? (
              <Outlet />
            ) : isProduction ? (
              <NoSupportForMobileDevices />
            ) : (
              <Outlet />
            )}
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  )
}

export default Auth
