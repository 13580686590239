import React from 'react'
import { Button, Stack, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import { useAuth0 } from '@auth0/auth0-react'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import LogOutButton from '../components/ui/Buttons/LogOutButton'
import { processEmailInStringAndReturnJsx } from '../utils/ProcessEmailInString'

interface Props {
  errorData: SerializedError | FetchBaseQueryError
}

const ErrorView: React.FC<Props> = ({ errorData }) => {
  const theme = useTheme()
  const { isAuthenticated } = useAuth0()

  let {
    eng: {
      views: {
        errorView: { title, heading },
      },
    },
  } = content

  console.log(errorData)

  function isFetchBaseQueryErrorType(
    variable: any
  ): variable is FetchBaseQueryError {
    return variable && typeof variable === 'object'
  }

  if (errorData) {
    if (isFetchBaseQueryErrorType(errorData)) {
      const { data } = errorData

      if (data) {
        const { redirection, user } = data as {
          message?: string
          user?: string
          redirection?: string
        }
        if (redirection && redirection === 'blocade' && user) {
          title = `Access blocked: multiple users from the same company detected`
          heading = `We're sorry, but our licensing agreement restricts multiple users from the same company to access this app simultaneously. Currently, another user from your company with the email address ${user} is already using the app. For security reasons and to ensure a smooth user experience, we require that they save and exit the survey before granting you access.`
        }
      }
    }
  }

  return (
    <MainContentContainer
      maxWidth="md"
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Typography
        component={'h2'}
        variant="h4"
        sx={{ marginBottom: theme.spacing(4) }}
      >
        {title}
      </Typography>
      <Typography component={'h2'} variant="h4">
        {processEmailInStringAndReturnJsx(heading)}
      </Typography>
      {/* {isDevelopment && (
          <Typography>Error message: {JSON.stringify(errorData)}</Typography>
        )} */}
      <Stack
        direction={'row'}
        spacing={theme.spacing(4)}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Button variant="contained" onClick={() => window.location.reload()}>
          GO BACK
        </Button>
        {isAuthenticated && <LogOutButton />}
      </Stack>
    </MainContentContainer>
  )
}

export default ErrorView
