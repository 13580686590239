import React, { useContext, useEffect } from 'react'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import MembersTable from '../components/members/MembersTable/MembersTable'
import UniversalButton from '../components/ui/Buttons/UniversalButton'
import { Add } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import NoAccessToThisView from '../components/shared/NoAccessToThisView/NoAccessToThisView'
import { Box, Stack, useTheme } from '@mui/material'
import { CompanyMembersContext } from '../contexts/CompanyMembersContext/companyMembers-context'
import { appActions } from '../store/App/app-slice'

const Members: React.FC = () => {
  const { role } = useAppSelector(state => state.user)
  const {
    actions: { onOpenModal },
  } = useContext(CompanyMembersContext)
  const theme = useTheme()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Members'))
  }, [])

  const isUserHasAccess = role === 'ADMIN' || role === 'STUFF'

  return (
    <MainContentContainer
      maxWidth="lg"
      sx={{
        alignItems: 'start',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(4),
        }}
      >
        {isUserHasAccess && (
          <Stack justifyContent={'flex-end'}>
            <UniversalButton
              label={'Add member'}
              startIcon={<Add />}
              onClick={onOpenModal}
            />
          </Stack>
        )}
      </Box>
      {isUserHasAccess ? <MembersTable /> : <NoAccessToThisView />}
    </MainContentContainer>
  )
}

export default Members
