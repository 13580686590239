import { Box, DialogContentText, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import UploadFileSharedContent from './UploadFileSharedContent'
import { UploadEvidenceContext } from '../../../../../contexts/UploadEvidenceContext/uploadEvidence-context'

const DialogPrivateContent: React.FC = () => {
  const {
    actions: { onSubmitAddEvidenceForm },
  } = useContext(UploadEvidenceContext)

  const theme = useTheme()

  return (
    <Box sx={{ px: theme.spacing(4), py: theme.spacing(3) }}>
      <Typography component={'h3'} variant="h6">
        Add a private document.
      </Typography>
      <DialogContentText>
        Provide the link or locate the public document you wish to add to the
        Evidence Library.
      </DialogContentText>
      <Box id="addFile" component={'form'} onSubmit={onSubmitAddEvidenceForm}>
        <UploadFileSharedContent />
      </Box>
    </Box>
  )
}

export default DialogPrivateContent
