import React, { useContext, useEffect, useReducer } from 'react'
import { useAppDispatch } from '../../hooks/hooks'
import { UploadSurveyContext } from './uploadSurvey-context'
import initialUploadSurveyState from './initialUploadSurveyState'
import {
  UploadSurveyContextSchema,
  UploadSurveyState,
} from './uploadSurveyInterfaces'
import { AudienceValue, UploadSurveyType } from '../../types/types'
import { SelectChangeEvent } from '@mui/material'
import { useCreateQuestionnaireFileMutation } from '../../services/surveys'
import { SurveysContext } from '../SurveysContext/surveys-context'
import { SeasonalSurveyQuery, SurveyDetails } from '../../types/interfaces'
import { appActions } from '../../store/App/app-slice'
import { Navigate } from 'react-router-dom'

interface Props {
  children: React.ReactNode
}

enum UploadSurveyActionKind {
  SET_CURRENT_DIALOG_PAGE = 'SET_CURRENT_DIALOG_PAGE',
  SET_FILE_TITLE_VALUE = 'SET_FILE_TITLE_VALUE',
  SET_SURVEY_TYPE = 'SET_SURVEY_TYPE',
  SET_SURVEY_SOURCE = 'SET_SURVEY_SOURCE',
  SET_AUDIENCE_VALUE = 'SET_AUDIENCE_VALUE',
  SET_CUSTOM_INTERNAL_DEPARTMENT = 'SET_CUSTOM_INTERNAL_DEPARTMENT',
  SET_SURVEY_FOCUS = 'SET_SURVEY_FOCUS',
  SET_SURVEY_DEADLINE = 'SET_SURVEY_DEADLINE',
  SET_SURVEY_EVIDENCE_DEPTH = 'SET_SURVEY_EVIDENCE_DEPTH',
  SET_SURVEY_EVIDENCE_TYPE = 'SET_SURVEY_EVIDENCE_TYPE',
  SET_SURVEY_ANSWER_LENGTH = 'SET_SURVEY_ANSWER_LENGTH',
  SET_SURVEY_COMMENTS = 'SET_SURVEY_COMMENTS',
  RESET_FORM = 'RESET_FORM',
}

interface UploadSurveyAction {
  type: UploadSurveyActionKind
  payload:
    | string
    | number
    | UploadSurveyType
    | AudienceValue
    | 'MINIMUM'
    | 'DETAILED'
    | null
}

const reducerFunction = (
  state: UploadSurveyState,
  action: UploadSurveyAction
): UploadSurveyState => {
  const { type, payload } = action
  switch (type) {
    case UploadSurveyActionKind.SET_CURRENT_DIALOG_PAGE:
      return {
        ...state,
        dialogCurrentPage: payload as number,
      }
    case UploadSurveyActionKind.SET_FILE_TITLE_VALUE:
      return {
        ...state,
        fileTitle: payload as string,
      }

    case UploadSurveyActionKind.SET_SURVEY_TYPE:
      return {
        ...state,
        surveyType: payload as UploadSurveyType,
      }
    case UploadSurveyActionKind.SET_SURVEY_SOURCE:
      return {
        ...state,
        surveySource: payload as string,
      }
    case UploadSurveyActionKind.SET_AUDIENCE_VALUE:
      return {
        ...state,
        surveyAudience: payload as AudienceValue,
      }
    case UploadSurveyActionKind.SET_CUSTOM_INTERNAL_DEPARTMENT:
      return {
        ...state,
        customInternalDepartment: payload as string,
      }
    case UploadSurveyActionKind.SET_SURVEY_FOCUS:
      return {
        ...state,
        surveyFocus: payload as string,
      }
    case UploadSurveyActionKind.SET_SURVEY_DEADLINE:
      return {
        ...state,
        surveyDeadline: payload as string,
      }
    case UploadSurveyActionKind.SET_SURVEY_EVIDENCE_DEPTH:
      return {
        ...state,
        evidenceDepth: payload as string,
      }
    case UploadSurveyActionKind.SET_SURVEY_EVIDENCE_TYPE:
      return {
        ...state,
        evidenceType: payload as string,
      }
    case UploadSurveyActionKind.SET_SURVEY_ANSWER_LENGTH:
      return {
        ...state,
        answerLength: payload as 'MINIMUM' | 'DETAILED' | null,
      }
    case UploadSurveyActionKind.SET_SURVEY_COMMENTS:
      return {
        ...state,
        comment: payload as string,
      }
    case UploadSurveyActionKind.RESET_FORM:
      return {
        ...initialUploadSurveyState,
      }

    default:
      return state
  }
}

const UploadSurveyContextProvider: React.FC<Props> = ({ children }) => {
  const {
    actions: { refetchSurveyData },
  } = useContext(SurveysContext)
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialUploadSurveyState
  )

  const dispatchStore = useAppDispatch()

  const {
    state: { surveyToUploadFileData },
  } = useContext(SurveysContext)
  const [
    createQuestionnaire,
    {
      isSuccess: isSuccessInCreateQuestionnaire,
      isError: isErrorInCreateQuestionnaire,
      isLoading: isLoadingInCreateQuestionnaire,
      originalArgs: originalArgsInCreateQuestionnaire,
      reset,
    },
  ] = useCreateQuestionnaireFileMutation()

  useEffect(() => {
    if (isSuccessInCreateQuestionnaire) {
      refetchSurveyData()
      reset()
    }
  }, [isSuccessInCreateQuestionnaire])

  useEffect(() => {
    dispatch({ type: UploadSurveyActionKind.RESET_FORM, payload: '' })
    reset()
  }, [surveyToUploadFileData])

  useEffect(() => {
    if (isErrorInCreateQuestionnaire) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't upload file to Surveys Library, please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
    } else {
      dispatchStore(appActions.hideSnackbarNotification())
    }
    return () => {
      dispatchStore(appActions.hideSnackbarNotification())
    }
  }, [isErrorInCreateQuestionnaire])

  if (
    isSuccessInCreateQuestionnaire &&
    originalArgsInCreateQuestionnaire?.surveyDetails.seasonal_survey === ''
  ) {
    return <Navigate to={'/success-in-load-survey'} />
  }

  const onClickNextPageHandler = () => {
    let newState = 0
    if (state.dialogCurrentPage < 2) {
      newState = state.dialogCurrentPage + 1
    }
    dispatch({
      type: UploadSurveyActionKind.SET_CURRENT_DIALOG_PAGE,
      payload: newState,
    })
  }

  const onClickBackPageHandler = () => {
    let newState = 0
    if (state.dialogCurrentPage > 1) {
      newState = state.dialogCurrentPage - 1
    }
    dispatch({
      type: UploadSurveyActionKind.SET_CURRENT_DIALOG_PAGE,
      payload: newState,
    })
  }

  const onChangeFileTitleInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_FILE_TITLE_VALUE,
      payload: newValue,
    })
  }
  const onChangeSurveyTypeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_TYPE,
      payload: newValue,
    })
  }

  const onChangeSurveySourceHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_SOURCE,
      payload: newValue,
    })
  }

  const onChangeAudienceSelectInputHandler = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_AUDIENCE_VALUE,
      payload: newValue as AudienceValue,
    })
  }

  const onChangeCustomInternalDepartmentHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_CUSTOM_INTERNAL_DEPARTMENT,
      payload: newValue as string,
    })
  }

  const onChangeSurveyFocusInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_FOCUS,
      payload: newValue as string,
    })
  }

  const onChangeSurveyDeadlineHandler = (newValue: string) => {
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_DEADLINE,
      payload: newValue as string,
    })
  }

  const onChangeEvidenceDepthHandler = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_EVIDENCE_DEPTH,
      payload: newValue as AudienceValue,
    })
  }

  const onChangeEvidenceTypeSelectInputHadler = (
    e: SelectChangeEvent<string>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_EVIDENCE_TYPE,
      payload: newValue as AudienceValue,
    })
  }

  const onChangeAnswerLengthSelectInputHandler = (
    e: SelectChangeEvent<string>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_ANSWER_LENGTH,
      payload: newValue as AudienceValue,
    })
  }

  const onChangeCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: UploadSurveyActionKind.SET_SURVEY_COMMENTS,
      payload: newValue as string,
    })
  }

  const onSubmitUploadSurveyHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()

    let deadlineDate = ''
    if (surveyToUploadFileData) {
      if (state.surveyDeadline) {
        deadlineDate = new Date(state.surveyDeadline).toUTCString()
      }
      let surveyDetailsData: SurveyDetails = {
        name: state.fileTitle,
        source: state.surveySource,
        target_audience: state.surveyAudience,
        internal_departments: state.customInternalDepartment,
        completion_years: state.evidenceDepth,
        deadline: deadlineDate,
        answers_context: state.surveyFocus,
        answers_length: state.answerLength,
        answers_confidence: '',
        seasonal_survey: '',
        evidence_type: state.evidenceType,
        additional_info: state.comment,
        seasonal_survey_id: '',
      }

      createQuestionnaire({
        fileData: surveyToUploadFileData,
        surveyDetails: surveyDetailsData,
        surveyType: state.surveyType,
      })
    }
  }

  const createSeasonalSurveyHandler = (
    surveyName: string,
    seasonalSurveyId: string,
    additionalInfo?: string
  ) => {
    createQuestionnaire({
      surveyDetails: {
        seasonal_survey_id: seasonalSurveyId,
        name: surveyName,
        source: '',
        target_audience: '',
        internal_departments: '',
        completion_years: '',
        deadline: '',
        answers_context: '',
        answers_length: 'DETAILED',
        answers_confidence: '',
        evidence_type: '',
        additional_info: additionalInfo ? additionalInfo : '',
      },
    })
  }

  const ctxValue: UploadSurveyContextSchema = {
    state: {
      dialogCurrentPage: state.dialogCurrentPage,
      fileTitle: state.fileTitle,
      surveyType: state.surveyType,
      surveySource: state.surveySource,
      surveyAudience: state.surveyAudience,
      customInternalDepartment: state.customInternalDepartment,
      comment: state.comment,
      evidenceDepth: state.evidenceDepth,
      answerLength: state.answerLength,
      evidenceType: state.evidenceType,
      surveyDeadline: state.surveyDeadline,
      surveyFocus: state.surveyFocus,
    },
    queryResult: {
      isErrorInCreateQuestionnaire,
      isLoadingInCreateQuestionnaire,
      isSuccessInCreateQuestionnaire,
    },
    actions: {
      onClickBackButton: onClickBackPageHandler,
      onClickContinueButton: onClickNextPageHandler,
      onChangeFileTitleInput: onChangeFileTitleInputHandler,
      onChangeUploadSurveyType: onChangeSurveyTypeHandler,
      onChangeSurveySourceInput: onChangeSurveySourceHandler,
      onChangeAudienceSelectInput: onChangeAudienceSelectInputHandler,
      onChangeCustomInternalInput: onChangeCustomInternalDepartmentHandler,
      onChangeSurveyFocusInput: onChangeSurveyFocusInputHandler,
      onChangeSurveyDeadline: onChangeSurveyDeadlineHandler,
      onChangeEvidenceDepthSelectInput: onChangeEvidenceDepthHandler,
      onChangeEvidenceTypeSelectInput: onChangeEvidenceTypeSelectInputHadler,
      onChangeAnswerLengthSelectInput: onChangeAnswerLengthSelectInputHandler,
      onChangeCommentInput: onChangeCommentInputHandler,
      onClickSubmitForm: onSubmitUploadSurveyHandler,
      onClickGenerateSeasonalSurvey: createSeasonalSurveyHandler,
    },
  }

  return (
    <UploadSurveyContext.Provider value={ctxValue}>
      {children}
    </UploadSurveyContext.Provider>
  )
}
export default UploadSurveyContextProvider
