import React from 'react'
import { CdpFullSeasonalSurveyDetailsActions } from './cdpFullSeasonalSurveyDetailsInterfaces'

const initialCdpFullSeasonalSurveyDetailsActions: CdpFullSeasonalSurveyDetailsActions =
  {
    onChangeCheckedAnswer: () => {},
    onSetCurrentQuestion: () => {},
  }

export default initialCdpFullSeasonalSurveyDetailsActions
