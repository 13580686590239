import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SeasonalSurveyQuery } from '../types/interfaces'
import type { RootState } from '../store/store'

export const seasonalSurveysApi = createApi({
  reducerPath: 'seasonalSurveysApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/seasonal_survey`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['SeasonalSurveys'],
  endpoints: build => ({
    getSeasonalSurveys: build.query<SeasonalSurveyQuery[], void>({
      query: () => ({
        url: `/`,
      }),
      providesTags: ['SeasonalSurveys'],
    }),
  }),
})

export const { useGetSeasonalSurveysQuery } = seasonalSurveysApi
