import React, { useContext } from 'react'
import { UploadEvidenceContext } from '../../../../../contexts/UploadEvidenceContext/uploadEvidence-context'
import UniversalButton from '../../../../ui/Buttons/UniversalButton'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Delete } from '@mui/icons-material'

const UploadFileSharedContent: React.FC = () => {
  const {
    state: { currUploadedFileData },
    actions: { onSetCurrentUploadedFileData, onResetCurrentUploadedFileData },
  } = useContext(UploadEvidenceContext)

  const theme = useTheme()

  return (
    <>
      {!currUploadedFileData && (
        <UniversalButton component="label" stylingType={5} label="Choose File">
          <input
            type="file"
            onChange={onSetCurrentUploadedFileData}
            accept=".pdf,.doc,.docx,image/*,.xlsx,.xls,.txt"
            style={{
              clip: `rect(0 0 0 0)`,
              clipPath: `inset(50%)`,
              height: `1px`,
              overflow: `hidden`,
              position: `absolute`,
              bottom: 0,
              left: 0,
              whiteSpace: 'nowrap',
              width: '1px',
            }}
          />
        </UniversalButton>
      )}
      {currUploadedFileData && (
        <>
          <Typography variant="body1">Choosen file:</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              padding: 0,
            }}
          >
            <Typography variant="body2">
              {currUploadedFileData?.name}
            </Typography>
            <IconButton
              sx={{ ml: theme.spacing(1) }}
              onClick={onResetCurrentUploadedFileData}
            >
              <Delete />
            </IconButton>
          </Box>
          <UniversalButton type="submit" stylingType={5} label="Send" />
        </>
      )}
    </>
  )
}

export default UploadFileSharedContent
