import React, { useContext, useEffect } from 'react'
import { AnswerPreviewContext } from '../../../contexts/AnswerPreview/answer-preview-contex'
import { useGetSimilarRequestsMutation } from '../../../services/generate'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import SimilarQuestionTableInUncheckedAnswer from './SimilarQuestionTableInUncheckedAnswer/SimilarQuestionTableInUncheckedAnswer'
import SimilarQuestionRightDrawer from '../../similarQuestions/SimilarQuestionsTable/SimilarQuestionRightDrawer/SimilarQuestionRightDrawer'
import { GenerateSimilarQuestionsRequestItem } from '../../../types/interfaces'

interface Props {
  onSetSimilarRequests: (items: GenerateSimilarQuestionsRequestItem[]) => void
  requestId: string
  onSetCurrenOpenSimilarQuestion: (id: string) => void
}

const SimilarQuestionsSectionInUncheckedAnswer: React.FC<Props> = ({
  onSetSimilarRequests,
  requestId,
  onSetCurrenOpenSimilarQuestion,
}) => {
  const {} = useContext(AnswerPreviewContext)

  const theme = useTheme()

  const [
    getSimilarRequest,
    {
      data: dataSimilarRequests,
      isSuccess: isSuccessGetSimilarRequests,
      isLoading: isLoadingGetSimilarRequests,
      isError: isErrorGetSimilarRequests,
    },
  ] = useGetSimilarRequestsMutation()

  useEffect(() => {
    getSimilarRequest(requestId)
  }, [])

  useEffect(() => {
    if (dataSimilarRequests !== undefined) {
      onSetSimilarRequests(dataSimilarRequests)
    }
  }, [isSuccessGetSimilarRequests])

  let content: React.JSX.Element

  if (isLoadingGetSimilarRequests) {
    content = (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    )
  } else if (isErrorGetSimilarRequests) {
    content = <Typography>Error in fetching similar request</Typography>
  } else if (dataSimilarRequests !== undefined) {
    const filteredByApproved = dataSimilarRequests.filter(anwerItem => {
      const {
        response: { status },
      } = anwerItem
      return status === 'APPROVED'
    })
    if (filteredByApproved.length > 0) {
      content = (
        <Box>
          <Typography variant="h4">
            Similar questions with approved answers
          </Typography>
          <SimilarQuestionTableInUncheckedAnswer
            onSetOpenSimilarQuestion={onSetCurrenOpenSimilarQuestion}
            tableRowsData={filteredByApproved}
          />
        </Box>
      )
    } else {
      content = (
        <Box>
          <Typography variant="h4">
            We couldn't find similar questions with approved answers.
          </Typography>
        </Box>
      )
    }
  } else {
    content = <span></span>
  }

  return (
    <Box sx={{ my: theme.spacing(4) }}>
      <SimilarQuestionRightDrawer />
      {content}
    </Box>
  )
}

export default SimilarQuestionsSectionInUncheckedAnswer
