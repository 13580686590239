import { SurveysState } from './SurveysInterfaces'

const initialSurveysState: SurveysState = {
  surveysData: [],
  seasonalSurveys: [],
  isDialogAddSurveyOpen: false,
  idOfSeasonalSurveyModalOpen: '',
  surveyToUploadFileData: null,
  selectFieldComplitionYearValue: '',
  deadlineDate: '',
  confidenceLevel: '',
  answersLength: 'DETAILED',
  typeOfEvidence: '',
  targetAudience: '',
}

export default initialSurveysState
