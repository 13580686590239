import React from 'react'
import { Box, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import { IProfileSetUpIcon } from '../../../types/interfaces'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { People } from '@mui/icons-material'
import { useAppSelector } from '../../../hooks/hooks'
import BarChartIcon from '@mui/icons-material/BarChart'

interface Props extends IProfileSetUpIcon {
  onLogout: () => void
  onCloseMenu: () => void
}
const ProfileSetUpMenu: React.FC<Props> = ({
  email,
  fullName,
  onLogout,
  onCloseMenu,
}) => {
  const { role, companyId, companyName } = useAppSelector(state => state.user)
  const theme = useTheme()
  const navigate = useNavigate()

  const isOwensCorning = companyId === '49352f32-f9ea-42eb-957c-9aa802f4a937'

  const onClickProfileHandler = () => {
    navigate('/edit-profile')
  }

  // const onClickBillingHandler = () => {
  //   window.open(
  //     'https://billing.ethicsanswer.com/p/login/00gbMJbpYgS5cHCeUU',
  //     '_blank'
  //   )
  // }

  const onClickGetStardedHandler = () => {
    navigate('/get-started')
  }

  const onClickMembersHandler = () => {
    navigate('/members')
  }

  const onClickVisualizationsHandler = () => {
    navigate('/analytics')
  }

  let linksData = [
    {
      label: 'Profile',
      icon: AccountCircleIcon,
      onClickAction: onClickProfileHandler,
    },
    {
      label: 'Members',
      icon: People,
      onClickAction: onClickMembersHandler,
    },
    {
      label: 'Analytics',
      icon: BarChartIcon,
      onClickAction: onClickVisualizationsHandler,
    },
    {
      label: 'Get started',
      icon: PlayArrowIcon,
      onClickAction: onClickGetStardedHandler,
    },
  ]

  if (role !== 'ADMIN' && role !== 'STUFF') {
    linksData.splice(1, 1)
  }

  if (role === 'CONTRIBUTOR' || role === 'VIEWER') {
    linksData = linksData.filter(
      menuElement => menuElement.label !== 'Analytics'
    )
  }

  const LinkJSXElements = linksData.map((linkItem, index) => {
    const { icon, label, onClickAction } = linkItem

    const CurrentIcon = icon

    return (
      <MenuItem key={`${index}_${label}`} onClick={onClickAction}>
        <ListItemIcon>
          <CurrentIcon />
        </ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
    )
  })

  return (
    <Box
      onMouseLeave={onCloseMenu}
      sx={{
        position: 'absolute',
        transform: 'translate(-85%, 0%)',
        zIndex: 20,
      }}
    >
      <Paper sx={{ width: 280, maxWidth: '100%' }}>
        <MenuList>
          <Box sx={{ paddingX: theme.spacing(2) }}>
            <Typography variant="h6" sx={{ marginBottom: 0 }}>
              {companyName}
            </Typography>
            <Typography sx={{ marginBottom: 0 }}>
              {fullName.replace('null', '').replace('null', '')}
            </Typography>
            <Typography variant="subtitle2" sx={{ marginTop: 0 }}>
              {role}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 0 }}>
              {email}
            </Typography>
          </Box>
          {LinkJSXElements}
          <Divider />
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Log out</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </Box>
  )
}

export default ProfileSetUpMenu
