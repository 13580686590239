import { Box, Typography, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import React from 'react'
import { Survey } from '../../../../types/interfaces'

interface Props {
  label: string
  keyToSort: string | null
  sortOrder: 'ASC' | 'DES'
  sortedBy: keyof Survey | 'status'
  onClickHeadCell: (key: string) => void
  index: number
}

const TableHeadCell: React.FC<Props> = ({
  label,
  keyToSort,
  sortOrder,
  sortedBy,
  onClickHeadCell,
  index,
}) => {
  const theme = useTheme()
  let sortingIcon: JSX.Element

  if (sortOrder === 'ASC') {
    sortingIcon = <ArrowDropUpIcon />
  } else {
    sortingIcon = <ArrowDropDownIcon />
  }
  return (
    <Box
      sx={{
        px: theme.spacing(3),
        cursor: keyToSort ? 'pointer' : 'text',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: index === 0 ? 'flex-start' : 'center',
      }}
      onClick={keyToSort ? () => onClickHeadCell(keyToSort) : () => {}}
    >
      <Typography variant="body2">{label}</Typography>
      {keyToSort === sortedBy && sortingIcon}
    </Box>
  )
}

export default TableHeadCell
