import React, { useEffect, useReducer } from 'react'
import {
  SimilarQuestionsContextSchema,
  SimilarQuestionsState,
} from './similarQuestionsInterfaces'
import { initialSimilarQuestionsState } from './initialSimilarQuestionsState'
import { useLocation, useNavigate } from 'react-router-dom'
import { AskRequest, SimilarRequest, Unit } from '../../types/interfaces'
import { SimilarQuestionsContext } from './similar-questions-context'
import {
  useAskForAnswerApprovalMutation,
  useCreateRequestMutation,
  useGenerateNewResponseMutation,
} from '../../services/generate'
import { appActions } from '../../store/App/app-slice'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'

export interface StateSentFromAskQuestionView {
  questionHeading: string
  is_metric: boolean
  unit: Unit
  similarRequests: SimilarRequest[]
  requestId: AskRequest['request_id']
}

interface Props {
  children: React.ReactNode
}

enum SimilarQuestionsActionKind {
  SET_QUESTION_HEADING = 'SET_QUESTION_HEADING',
  SET_IS_METRIC = 'SET_IS_METRIC',
  SET_UNIT = 'SET_UNIT',
  SET_SIMILAR_REQUESTS = 'SET_SIMILAR_REQUESTS',
  // SET_CURRENT_LOADING_RESPONSE_ID = 'SET_CURRENT_LOADING_RESPONSE_ID',
  SET_REQUEST_ID = 'SET_REQUEST_ID',
  SET_NEW_REQUEST_ID = 'SET_NEW_REQUEST_ID',
  SET_IS_LOADING_IN_GENERATE_NEW_RESPONSE = 'SET_IS_LOADING_IN_GENERATE_NEW_RESPONSE',
  SET_CURRENT_OPEN_SIMILAR_QUESTION_ID = 'SET_CURRENT_OPEN_SIMILAR_QUESTION_ID',
  SET_IS_VIEWER_REQUEST_NEW_ANSWER_SENT = 'SET_IS_VIEWER_REQUEST_NEW_ANSWER_SENT',
  SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_SENT = 'SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_SENT',
  SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_LOADING = 'SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_LOADING',
  SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_MODAL_OPEN = 'SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_MODAL_OPEN',
}

interface SimilarQuestionsAction {
  type: SimilarQuestionsActionKind
  payload:
    | string
    | boolean
    | SimilarRequest[]
    | AskRequest['request_id']
    | Unit
    | SimilarRequest['id']
}

const reducerFunction = (
  state: SimilarQuestionsState,
  action: SimilarQuestionsAction
): SimilarQuestionsState => {
  const { type, payload } = action
  switch (type) {
    case SimilarQuestionsActionKind.SET_QUESTION_HEADING:
      return {
        ...state,
        questionHeading: payload as string,
      }
    case SimilarQuestionsActionKind.SET_UNIT:
      return {
        ...state,
        unit: payload as Unit,
      }
    case SimilarQuestionsActionKind.SET_IS_METRIC:
      return {
        ...state,
        isMetric: payload as boolean,
      }

    case SimilarQuestionsActionKind.SET_SIMILAR_REQUESTS:
      return {
        ...state,
        similarRequests: payload as SimilarRequest[],
      }
    // case SimilarQuestionsActionKind.SET_CURRENT_LOADING_RESPONSE_ID:
    //   return {
    //     ...state,
    //     currentLoadingResponseId: payload as string,
    //   }
    case SimilarQuestionsActionKind.SET_REQUEST_ID:
      return {
        ...state,
        requestId: payload as AskRequest['request_id'],
      }
    case SimilarQuestionsActionKind.SET_NEW_REQUEST_ID:
      return {
        ...state,
        newRequestId: payload as AskRequest['request_id'],
      }
    case SimilarQuestionsActionKind.SET_IS_LOADING_IN_GENERATE_NEW_RESPONSE:
      return {
        ...state,
        isLoadingInGenerateNewResponse: payload as boolean,
      }

    case SimilarQuestionsActionKind.SET_CURRENT_OPEN_SIMILAR_QUESTION_ID:
      return {
        ...state,
        currentOpenSimilarQuestion: payload as SimilarRequest['id'],
      }

    case SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_NEW_ANSWER_SENT:
      return {
        ...state,
        isViewerRequestNewAnswerSent: payload as boolean,
      }

    case SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_SENT:
      return {
        ...state,
        isViewerRequestAnswerApprovalSent: payload as boolean,
      }
    case SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_LOADING:
      return {
        ...state,
        isViewerRequestAnswerApprovalLoading: payload as boolean,
      }
    case SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_MODAL_OPEN:
      return {
        ...state,
        isViewerRequestAnswerApprovalModalOpen: payload as boolean,
      }
    default:
      return state
  }
}

const SimilarQuestionsContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialSimilarQuestionsState
  )
  const dispatchStore = useAppDispatch()
  const { role, isAskQuestionModeBlocked } = useAppSelector(state => state.user)

  const [
    createNewRequest,
    {
      data: dataFromCreateNewRequest,
      isSuccess: isSuccesInCreateNewRequest,
      isLoading: isLoadingInCreateNewRequest,
      isError: isErrorInCreateNewRequest,
    },
  ] = useCreateRequestMutation()

  const [
    askForAnswerApproval,
    {
      isSuccess: isSuccesAskForAnswerApproval,
      isLoading: isLoadingAskForAnswerApproval,
      isError: isErrorAskForAnswerApproval,
      reset: resetAskForAnswerApproval,
    },
  ] = useAskForAnswerApprovalMutation()

  const [
    generateNewResponse,
    {
      data: generateNewResponseData,
      isSuccess: isSuccessInGenerateNewResponse,
      isLoading: isLoadingInGenerateNewResponse,
      isError: isErrorInGenerateNewResponse,
    },
  ] = useGenerateNewResponseMutation()

  // const [
  //   setResponseIdIsDispalyed,
  //   {
  //     isSuccess: isSuccessInSetResponseIdDispalyed,
  //     isError: isErrorInSetResponseIdDispalyed,
  //   },
  // ] = useSetResponseIsDisplayedMutation()

  const { questionHeading, isMetric, unit, requestId } = state

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.state === null) {
      navigate('/ask-question')
    } else {
      const { questionHeading, similarRequests, requestId, is_metric, unit } =
        location.state as StateSentFromAskQuestionView

      const sortedSimilarRequest = [...similarRequests].sort((a, b) => {
        const dateA = new Date(a.response.updated_at)
        const dateB = new Date(b.response.updated_at)

        return dateB.getTime() - dateA.getTime()
      })

      dispatch({
        type: SimilarQuestionsActionKind.SET_QUESTION_HEADING,
        payload: questionHeading,
      })
      dispatch({
        type: SimilarQuestionsActionKind.SET_IS_METRIC,
        payload: is_metric as boolean,
      })
      dispatch({
        type: SimilarQuestionsActionKind.SET_UNIT,
        payload: unit as Unit,
      })
      dispatch({
        type: SimilarQuestionsActionKind.SET_SIMILAR_REQUESTS,
        payload: sortedSimilarRequest,
      })
      dispatch({
        type: SimilarQuestionsActionKind.SET_REQUEST_ID,
        payload: requestId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  // useEffect(() => {
  //   if (isSuccessInSetResponseIdDispalyed) {
  //     navigate(`/answers-library/open/${state.currentLoadingResponseId}`)
  //   } else {
  //     dispatch({
  //       type: SimilarQuestionsActionKind.SET_CURRENT_LOADING_RESPONSE_ID,
  //       payload: '',
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSuccessInSetResponseIdDispalyed])

  useEffect(() => {
    if (isSuccesInCreateNewRequest && dataFromCreateNewRequest) {
      const { request_id } = dataFromCreateNewRequest
      generateNewResponse(request_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccesInCreateNewRequest])

  useEffect(() => {
    if (isSuccessInGenerateNewResponse && generateNewResponseData) {
      if (role === 'VIEWER') {
        dispatchStore(appActions.setDashboardRequireRefetch())
        dispatch({
          type: SimilarQuestionsActionKind.SET_IS_LOADING_IN_GENERATE_NEW_RESPONSE,
          payload: false,
        })
        dispatch({
          type: SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_NEW_ANSWER_SENT,
          payload: true,
        })
      } else if (isAskQuestionModeBlocked && role !== 'STUFF') {
        dispatchStore(appActions.setDashboardRequireRefetch())
        dispatch({
          type: SimilarQuestionsActionKind.SET_IS_LOADING_IN_GENERATE_NEW_RESPONSE,
          payload: false,
        })
        dispatch({
          type: SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_NEW_ANSWER_SENT,
          payload: true,
        })
      } else {
        navigate('/generated-response', {
          state: {
            questionHeading: questionHeading,
            responseText: generateNewResponseData['text'],
            responseId: generateNewResponseData['id'],
            requestId: generateNewResponseData['request_id'],
            supportiveEvidences:
              generateNewResponseData['supportive_evidences'],
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInGenerateNewResponse])

  useEffect(() => {
    dispatch({
      type: SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_SENT,
      payload: isSuccesAskForAnswerApproval,
    })
    if (isSuccesAskForAnswerApproval) {
      dispatch({
        type: SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_MODAL_OPEN,
        payload: true,
      })
    }
  }, [isSuccesAskForAnswerApproval])

  useEffect(() => {
    if (isLoadingInCreateNewRequest || isLoadingInGenerateNewResponse)
      dispatch({
        type: SimilarQuestionsActionKind.SET_IS_LOADING_IN_GENERATE_NEW_RESPONSE,
        payload: true,
      })
  }, [isLoadingInCreateNewRequest, isLoadingInGenerateNewResponse])

  useEffect(() => {
    dispatch({
      type: SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_LOADING,
      payload: isLoadingAskForAnswerApproval,
    })
  }, [isLoadingAskForAnswerApproval])

  useEffect(() => {
    if (isErrorInCreateNewRequest || isErrorInGenerateNewResponse) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't generate a response for your question at the moment. Please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
      dispatch({
        type: SimilarQuestionsActionKind.SET_IS_LOADING_IN_GENERATE_NEW_RESPONSE,
        payload: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInCreateNewRequest, isErrorInGenerateNewResponse])

  useEffect(() => {
    if (isErrorAskForAnswerApproval) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we can't send a request for answer approval. Please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
    }
    return () => {
      dispatchStore(appActions.hideSnackbarNotification())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorAskForAnswerApproval])

  // const onClickViewAnswerButtonHandler = (
  //   responseId: PreviousResponse['id']
  // ) => {
  //   if (responseId) {
  //     dispatch({
  //       type: SimilarQuestionsActionKind.SET_CURRENT_LOADING_RESPONSE_ID,
  //       payload: responseId,
  //     })
  //     setResponseIdIsDispalyed({ responseId })
  //   } else {
  //     console.log('Error! Please provide responseId')
  //   }
  // }

  useEffect(() => {
    if (state.currentOpenSimilarQuestion === null) {
      resetAskForAnswerApproval()
    }
  }, [state.currentOpenSimilarQuestion])

  const onClickGenerateNewResponseButtonHandler = () => {
    createNewRequest({
      question: questionHeading,
      is_metric: isMetric,
      unit: unit,
    })
  }

  const onClickGenerateNewResponseButtonForExistingRequestHandler = () => {
    generateNewResponse(requestId)
  }

  const onClickOpenRightDrawerHandler = (responseId: SimilarRequest['id']) => {
    dispatch({
      type: SimilarQuestionsActionKind.SET_CURRENT_OPEN_SIMILAR_QUESTION_ID,
      payload: responseId,
    })
  }

  const onClickCloseRightDrawerHandler = () => {
    dispatch({
      type: SimilarQuestionsActionKind.SET_CURRENT_OPEN_SIMILAR_QUESTION_ID,
      payload: null,
    })
  }

  const onClickRequestApproveAnswerButtonHandler = () => {
    if (state.currentOpenSimilarQuestion) {
      askForAnswerApproval(state.currentOpenSimilarQuestion)
    }
  }

  const onClickCloseApproveAnswerSuccessModalHandler = () => {
    dispatch({
      type: SimilarQuestionsActionKind.SET_IS_VIEWER_REQUEST_ANSWER_APPROVAL_MODAL_OPEN,
      payload: false,
    })
  }

  const ctxObject: SimilarQuestionsContextSchema = {
    state: state,
    actions: {
      onClickOpenRightDrawer: onClickOpenRightDrawerHandler,
      onClickCloseRightDrawer: onClickCloseRightDrawerHandler,
      onClickGenerateNewResponseButton: onClickGenerateNewResponseButtonHandler,
      onClickGenerateNewResponseButtonForExistingRequest:
        onClickGenerateNewResponseButtonForExistingRequestHandler,
      onClickRequestApproveAnswerButton:
        onClickRequestApproveAnswerButtonHandler,
      onClickCloseApproveAnswerSuccessModal:
        onClickCloseApproveAnswerSuccessModalHandler,
    },
  }

  return (
    <SimilarQuestionsContext.Provider value={ctxObject}>
      {children}
    </SimilarQuestionsContext.Provider>
  )
}

export default SimilarQuestionsContextProvider
