import React, { useEffect, useReducer } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AnswerItem, ExtendedAnswerStatus } from '../../types/interfaces'
import {
  useBulkReviewerAssigmentMutation,
  useGetAnswersQuery,
} from '../../services/responses'
import { groupResponsesFuse } from '../../utils/utils'
import Fuse from 'fuse.js'
import ErrorView from '../../views/ErrorView'
import {
  AnswersLibraryContextSchema,
  AnswersLibraryState,
} from './answersLibraryInterfaces'
import { initialAnswersLibraryState } from './initialAnswersLibraryState'
import { AnswersLibraryContext } from './answers-library-context'
import { FilterUrlParamNames } from '../../types/enums'
import {
  AnswersLibraryFilterType,
  DateRangeValue,
  DateRangeValuePiece,
} from '../../types/types'
import { stat } from 'fs'
import { useGetDashboardDataQuery } from '../../services/dashboard'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { useGetSurveysQuery } from '../../services/surveys'
import { SelectChangeEvent } from '@mui/material'
import { appActions } from '../../store/App/app-slice'
import LoadingInsideComponent from '../../components/ui/LoadingInsideComponent/LoadingInsideComponent'

interface Props {
  children: React.ReactNode
}

enum AnswersLibraryActionKind {
  SET_SEARCH_INPUT_VALUE = 'SET_SEARCH_INPUT_VALUE',
  SET_SAVED_ANSWERS_TO_DISPLAY = 'SET_SAVED_ANSWERS_TO_DISPLAY',
  SET_SAVED_ANSWER_IS_LOADING = 'SET_SAVED_ANSWER_IS_LOADING',
  SET_CURRENT_OPEN_ANSWER = 'SET_CURRENT_OPEN_ANSWER',
  SET_DATA_RANGE_FILTER = 'SET_DATA_RANGE_FILTER',
  SET_SOURCE_FILTER = 'SET_SOURCE_FILTER',
  SET_SEARCH_FILTER = 'SET_SEARCH_FILTER',
  SET_STATUS_FILTER = 'SET_STATUS_FILTER',
  SET_ASSIGNED_BY_FILTER = 'SET_ASSIGNED_BY_FILTER',
  SET_SHOW_ONLY_REQUEST_FILTER = 'SET_SHOW_ONLY_REQUEST_FILTER',
  SET_FILTERED_ANSWERS = 'SET_FILTERED_ANSWERS',
  SET_ANSWERS_SOURCES = 'SET_ANSWERS_SOURCES',
  SET_IS_ASSIGN_REVIEWER_MODE = 'SET_IS_ASSIGN_REVIEWER_MODE',
  SET_SELECTED_REVIEWER = 'SET_SELECTED_REVIEWER',
  SET_SELECTED_RESPONSE_IDS = 'SET_SELECTED_RESPONSE_IDS',
  SET_ASSIGNED_REVIEWERS = 'SET_ASSIGNED_REVIEWERS',
  SET_CURRENT_PAGE_INDEX = 'SET_CURRENT_PAGE_INDEX',
}

interface AnswersLibraryAction {
  type: AnswersLibraryActionKind
  payload:
    | string
    | boolean
    | AnswerItem[]
    | null
    | DateRangeValue
    | { id: string; label: string }[]
    | ExtendedAnswerStatus
    | string[]
    | { id: string; name: string }[]
    | number
}

const reducerFunction = (
  state: AnswersLibraryState,
  action: AnswersLibraryAction
): AnswersLibraryState => {
  const { type, payload } = action
  switch (type) {
    case AnswersLibraryActionKind.SET_SEARCH_INPUT_VALUE:
      return {
        ...state,
        searchInputValue: payload as string,
      }
    case AnswersLibraryActionKind.SET_SAVED_ANSWERS_TO_DISPLAY:
      return {
        ...state,
        answersData: payload as AnswerItem[],
      }
    case AnswersLibraryActionKind.SET_SAVED_ANSWER_IS_LOADING:
      return {
        ...state,
        isLoadingInSavedAnswers: payload as boolean,
      }
    case AnswersLibraryActionKind.SET_CURRENT_OPEN_ANSWER:
      return {
        ...state,
        currentOpenAnswer: payload as string | null,
      }
    case AnswersLibraryActionKind.SET_DATA_RANGE_FILTER:
      return {
        ...state,
        filterByLastUpdatedRange: payload as DateRangeValue,
      }
    case AnswersLibraryActionKind.SET_SOURCE_FILTER:
      return {
        ...state,
        filterBySource: payload as string | null,
      }
    case AnswersLibraryActionKind.SET_SEARCH_FILTER:
      return {
        ...state,
        filterBySearch: payload as string | null,
      }
    case AnswersLibraryActionKind.SET_STATUS_FILTER:
      return {
        ...state,
        filterByStatus: payload as ExtendedAnswerStatus | null,
      }
    case AnswersLibraryActionKind.SET_FILTERED_ANSWERS:
      return {
        ...state,
        filteredAnswers: payload as AnswerItem[],
      }
    case AnswersLibraryActionKind.SET_ANSWERS_SOURCES:
      return {
        ...state,
        answerSources: payload as { id: string; label: string }[],
      }
    case AnswersLibraryActionKind.SET_SHOW_ONLY_REQUEST_FILTER:
      return {
        ...state,
        filterByIsRequest: payload as string,
      }
    case AnswersLibraryActionKind.SET_ASSIGNED_BY_FILTER:
      return {
        ...state,
        filterByAssignedUser: payload as string,
      }

    case AnswersLibraryActionKind.SET_IS_ASSIGN_REVIEWER_MODE:
      return {
        ...state,
        isAssignReviewerMode: payload as boolean,
      }
    case AnswersLibraryActionKind.SET_SELECTED_REVIEWER:
      return {
        ...state,
        assignedReviewer: payload as string,
      }
    case AnswersLibraryActionKind.SET_SELECTED_RESPONSE_IDS:
      return {
        ...state,
        selectedIdsOfResponses: payload as string[],
      }
    case AnswersLibraryActionKind.SET_ASSIGNED_REVIEWERS:
      return {
        ...state,
        assignedReviewers: payload as { id: string; name: string }[],
      }
    case AnswersLibraryActionKind.SET_CURRENT_PAGE_INDEX:
      return {
        ...state,
        currentPageIndex: payload as number,
      }
    default:
      return state
  }
}

const AnswersLibraryContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducerFunction,
    initialAnswersLibraryState
  )
  const { role, isAskQuestionModeBlocked } = useAppSelector(state => state.user)
  const {
    data: dataFromSavedAnswers,
    isSuccess: isSuccessInSavedAnswers,
    isLoading: isLoadingInSavedAnswers,
    isError: isErrorInSavedAnswers,
    error: errorDataInSavedAnswers,
    isFetching: isAnswersFetching,
    refetch: refetchAnswers,
  } = useGetAnswersQuery()

  const {
    data: dashboardData,
    isLoading: isLoadingDashboardData,
    isError: isErrorDashboardData,
    error: errorDashboardData,
    refetch,
  } = useGetDashboardDataQuery()

  const {
    data: surveysData,
    isLoading: isLoadingSurveysData,
    isError: isErrorSurveysData,
    error: errorSurveysData,
  } = useGetSurveysQuery()

  const [
    assignReviewer,
    {
      isLoading: isLoadingAssignReviewer,
      isSuccess: isSuccessAssignReviewer,
      isError: isErrorAssignReviewer,
    },
  ] = useBulkReviewerAssigmentMutation()

  const {
    [FilterUrlParamNames.dataRangeStart]: dataRangeStart,
    [FilterUrlParamNames.dataRangeEnd]: dataRangeEnd,
    [FilterUrlParamNames.sourceParam]: sourceParam,
    [FilterUrlParamNames.statusParam]: statusParam,
    [FilterUrlParamNames.assignedParam]: assignedParam,
    [FilterUrlParamNames.searchParam]: searchParam,
    [FilterUrlParamNames.isShownOnlyRequestedParam]: isShownOnlyRequestedParam,
    [FilterUrlParamNames.openAnswerIdParam]: openAnswerIdParam,
  } = useParams()

  const navigate = useNavigate()

  const appDispatch = useAppDispatch()

  const location = useLocation()

  //issue Jack's case

  // useEffect(() => {
  //   if (isAnswersFetching) {
  //     dispatch({
  //       type: AnswersLibraryActionKind.SET_CURRENT_PAGE_INDEX,
  //       payload: 1,
  //     })
  //   }
  // }, [isAnswersFetching])

  useEffect(() => {
    dispatch({
      type: AnswersLibraryActionKind.SET_SAVED_ANSWER_IS_LOADING,
      payload:
        isLoadingInSavedAnswers ||
        isLoadingDashboardData ||
        isLoadingSurveysData,
    })
  }, [isLoadingInSavedAnswers, isLoadingDashboardData, isLoadingSurveysData])

  useEffect(() => {
    let dataRangeStartValue: DateRangeValuePiece = null
    let dataRangeEndValue: DateRangeValuePiece = null

    if (dataRangeStart !== 'null' && dataRangeStart !== undefined) {
      dataRangeStartValue = new Date(dataRangeStart)
    }

    if (dataRangeEnd !== 'null' && dataRangeEnd !== undefined) {
      dataRangeEndValue = new Date(dataRangeEnd)
    }

    dispatch({
      type: AnswersLibraryActionKind.SET_DATA_RANGE_FILTER,
      payload: [dataRangeStartValue, dataRangeEndValue],
    })
  }, [dataRangeStart, dataRangeEnd])

  useEffect(() => {
    if (!sourceParam || sourceParam === 'null') {
      dispatch({
        type: AnswersLibraryActionKind.SET_SOURCE_FILTER,
        payload: null,
      })
    } else {
      dispatch({
        type: AnswersLibraryActionKind.SET_SOURCE_FILTER,
        payload: sourceParam as string,
      })
    }
  }, [sourceParam])

  useEffect(() => {
    if (!statusParam || statusParam === 'null') {
      dispatch({
        type: AnswersLibraryActionKind.SET_STATUS_FILTER,
        payload: null,
      })
    } else {
      dispatch({
        type: AnswersLibraryActionKind.SET_STATUS_FILTER,
        payload: statusParam,
      })
    }
  }, [statusParam])

  useEffect(() => {
    if (!assignedParam || assignedParam === 'null') {
      dispatch({
        type: AnswersLibraryActionKind.SET_ASSIGNED_BY_FILTER,
        payload: null,
      })
    } else {
      dispatch({
        type: AnswersLibraryActionKind.SET_ASSIGNED_BY_FILTER,
        payload: assignedParam,
      })
    }
  }, [assignedParam])

  useEffect(() => {
    console.log('updated answers!')
    if (dataFromSavedAnswers) {
      dispatch({
        type: AnswersLibraryActionKind.SET_SAVED_ANSWERS_TO_DISPLAY,
        payload: dataFromSavedAnswers,
      })
    }
  }, [isSuccessInSavedAnswers, isAnswersFetching])

  useEffect(() => {
    if (!searchParam || searchParam === 'null') {
      dispatch({
        type: AnswersLibraryActionKind.SET_SEARCH_INPUT_VALUE,
        payload: '',
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_SEARCH_FILTER,
        payload: null,
      })
    } else {
      dispatch({
        type: AnswersLibraryActionKind.SET_SEARCH_INPUT_VALUE,
        payload: decodeURIComponent(searchParam),
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_SEARCH_FILTER,
        payload: decodeURIComponent(searchParam),
      })
    }
  }, [searchParam])

  useEffect(() => {
    if (!isShownOnlyRequestedParam || isShownOnlyRequestedParam === 'null') {
      dispatch({
        type: AnswersLibraryActionKind.SET_SHOW_ONLY_REQUEST_FILTER,
        payload: null,
      })
    } else {
      dispatch({
        type: AnswersLibraryActionKind.SET_SHOW_ONLY_REQUEST_FILTER,
        payload: isShownOnlyRequestedParam as string,
      })
    }
  }, [isShownOnlyRequestedParam])

  useEffect(() => {
    if (!openAnswerIdParam || openAnswerIdParam === 'null') {
      dispatch({
        type: AnswersLibraryActionKind.SET_CURRENT_OPEN_ANSWER,
        payload: null,
      })
    } else {
      dispatch({
        type: AnswersLibraryActionKind.SET_CURRENT_OPEN_ANSWER,
        payload: openAnswerIdParam as string,
      })
    }
  }, [openAnswerIdParam])

  useEffect(() => {
    if (isSuccessInSavedAnswers && dataFromSavedAnswers) {
      let newDataToDisplay: AnswerItem[] = []

      const sortedSavedAnswersData = [...dataFromSavedAnswers].sort((a, b) => {
        const dateA = new Date(a.updated_at)
        const dateB = new Date(b.updated_at)

        return dateB.getTime() - dateA.getTime()
      })

      const answersSources: { id: string; label: string }[] = []

      let assignedUsers: { id: string; name: string }[] = []

      dataFromSavedAnswers.forEach(answer => {
        const {
          request: { survey_name, survey_id },
          assigned_user,
        } = answer

        if (assigned_user) {
          const isAssignedExist = assignedUsers.find(assignedUser => {
            const { id } = assignedUser
            return assigned_user.id === id
          })
          if (!isAssignedExist) {
            assignedUsers = assignedUsers.concat({
              id: assigned_user.id,
              name: `${assigned_user.first_name} ${
                assigned_user.last_name ? assigned_user.last_name : ''
              } `,
            })
          }
        }

        const foundNameInCurrentSources = answersSources.find(answerSource => {
          if (survey_id === null || survey_id === undefined) {
            return answerSource.id === '1'
          } else {
            return answerSource.id === survey_id
          }
        })

        const foundInCurrenSourcesAllSurveys = answersSources.find(
          answersSource => answersSource.id === '2'
        )

        if (!foundNameInCurrentSources) {
          if (survey_id === null || survey_id === undefined) {
            answersSources.push({
              id: '1',
              label: isAskQuestionModeBlocked
                ? 'Requested answers'
                : 'Saved Answers',
            })
          } else {
            if (!foundInCurrenSourcesAllSurveys) {
              answersSources.push({
                id: '2',
                label: 'All surveys',
              })
            }
            if (survey_name) {
              answersSources.push({ id: survey_id, label: survey_name! })
            } else if (surveysData) {
              const foundSurveyName = surveysData.find(surveItem => {
                const { id } = surveItem
                return id === survey_id
              })

              let surveyTitle = 'Check Survey Name'

              if (foundSurveyName) {
                if (foundSurveyName.name) {
                  surveyTitle = foundSurveyName.name
                } else {
                  surveyTitle = foundSurveyName.file?.friendly_name!
                }
              }

              answersSources.push({ id: survey_id, label: surveyTitle })
            }
          }
        }
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_ASSIGNED_REVIEWERS,
        payload: [...assignedUsers],
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_ANSWERS_SOURCES,
        payload: [...answersSources],
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_SAVED_ANSWERS_TO_DISPLAY,
        payload: sortedSavedAnswersData,
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_FILTERED_ANSWERS,
        payload: sortedSavedAnswersData,
      })
    }
  }, [
    isSuccessInSavedAnswers,
    dataFromSavedAnswers,
    searchParam,
    refetchAnswers,
  ])

  useEffect(() => {
    if (isSuccessAssignReviewer) {
      appDispatch(
        appActions.showSnackbarNotification({
          label: 'Success! Selected answers have been assigned for review.',
          type: 'success',
        })
      )
      dispatch({
        type: AnswersLibraryActionKind.SET_IS_ASSIGN_REVIEWER_MODE,
        payload: false,
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_SELECTED_RESPONSE_IDS,
        payload: [],
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_SELECTED_REVIEWER,
        payload: 'select',
      })
    }
  }, [isSuccessAssignReviewer])

  useEffect(() => {
    if (isErrorAssignReviewer) {
      appDispatch(
        appActions.showSnackbarNotification({
          label: 'Failed to assign selected questions. Please try again.',
          type: 'error',
        })
      )
    }
  }, [isErrorAssignReviewer])

  useEffect(() => {
    if (isSuccessAssignReviewer) {
      appDispatch(
        appActions.showSnackbarNotification({
          label: 'Success! Selected answers have been assigned for review.',
          type: 'success',
        })
      )
      dispatch({
        type: AnswersLibraryActionKind.SET_IS_ASSIGN_REVIEWER_MODE,
        payload: false,
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_SELECTED_RESPONSE_IDS,
        payload: [],
      })
      dispatch({
        type: AnswersLibraryActionKind.SET_SELECTED_REVIEWER,
        payload: 'select',
      })
    }
  }, [isSuccessAssignReviewer])

  useEffect(() => {
    if (
      state.filterBySearch ||
      state.filterByStatus ||
      state.filterByLastUpdatedRange ||
      state.filterBySource ||
      state.filterByIsRequest
    ) {
      let filteredAnswers = [...state.answersData]
      if (state.filterBySearch) {
        const options = {
          isCaseSensitive: true,
          location: 20,
          threshold: 0.4,
          distance: 100,
          minMatchCharLength: 2,
          keys: ['request.question'],
        }
        const fuse = new Fuse(filteredAnswers, options)
        filteredAnswers = groupResponsesFuse(fuse.search(state.filterBySearch))
      }
      if (state.filterByStatus) {
        filteredAnswers = filteredAnswers.filter(answerItem => {
          const { status, assigned_user } = answerItem
          if (
            status === 'CHECKED' &&
            state.filterByStatus === 'CHECKED_ASSIGNED' &&
            assigned_user !== null
          ) {
            return true
          } else if (
            status === 'CHECKED' &&
            state.filterByStatus === 'CHECKED' &&
            assigned_user !== null
          ) {
          } else return status === state.filterByStatus
        })
      }
      if (
        state.filterByAssignedUser &&
        state.filterByStatus === 'CHECKED_ASSIGNED'
      ) {
        filteredAnswers = filteredAnswers.filter(answerItem => {
          const { assigned_user } = answerItem
          return assigned_user?.id === state.filterByAssignedUser
        })
      }
      if (state.filterBySource) {
        filteredAnswers = filteredAnswers.filter(answerItem => {
          const {
            request: { survey_name, survey_id },
          } = answerItem
          if (survey_id === null || survey_id === undefined) {
            return '1' === (state.filterBySource as string)
          } else if (state.filterBySource === '2') {
            return survey_id !== null
          } else {
            return survey_id === state.filterBySource
          }
        })
      }
      if (state.filterByLastUpdatedRange) {
        filteredAnswers = filteredAnswers.filter(answerItem => {
          const updatedAt = new Date(answerItem.updated_at)
          const [startDate, endDate] = state.filterByLastUpdatedRange as [
            DateRangeValuePiece,
            DateRangeValuePiece
          ]

          // If both start and end dates are provided
          if (startDate && endDate) {
            return updatedAt >= startDate && updatedAt <= endDate
          } else if (startDate) {
            // If only start date is provided
            return updatedAt >= startDate
          } else if (endDate) {
            // If only end date is provided
            return updatedAt <= endDate
          }
          return true // Return true if no date range is provided
        })
      }
      // if (
      //   state.filterByIsRequest === 'owner' &&
      //   dashboardData &&
      //   dashboardData.requested_answers_by_owner_ready_to_review !== null &&
      //   role !== 'VIEWER'
      // ) {
      //   const requestedResponseIds: string[] =
      //     dashboardData.requested_answers_by_owner_ready_to_review.map(
      //       item => item.request.response.id
      //     )

      //   filteredAnswers = filteredAnswers.filter(answer =>
      //     requestedResponseIds.includes(answer.id)
      //   )
      // }
      // if (
      //   state.filterByIsRequest === 'viewer' &&
      //   dashboardData &&
      //   dashboardData.requested_answers_by_viewer !== null &&
      //   role !== 'VIEWER'
      // ) {
      //   const requestedResponseIds: string[] =
      //     dashboardData.requested_answers_by_viewer.map(
      //       item => item.request.response.id
      //     )

      //   filteredAnswers = filteredAnswers.filter(answer =>
      //     requestedResponseIds.includes(answer.id)
      //   )
      // }
      // if (
      //   state.filterByIsRequest === 'true' &&
      //   dashboardData &&
      //   dashboardData.requested_answers_by_owner_ready_to_review !== null &&
      //   dashboardData.requested_answers_by_viewer !== null &&
      //   role !== 'VIEWER'
      // ) {
      //   const requestedItems = [
      //     ...dashboardData.requested_answers_by_owner_ready_to_review,
      //     ...dashboardData.requested_answers_by_viewer,
      //   ]
      //   const requestedResponseIds: string[] = requestedItems.map(
      //     item => item.request.response.id
      //   )

      //   filteredAnswers = filteredAnswers.filter(answer =>
      //     requestedResponseIds.includes(answer.id)
      //   )
      // }
      dispatch({
        type: AnswersLibraryActionKind.SET_FILTERED_ANSWERS,
        payload: [...filteredAnswers],
      })
    } else {
      dispatch({
        type: AnswersLibraryActionKind.SET_FILTERED_ANSWERS,
        payload: state.answersData,
      })
    }
  }, [
    state.filterBySource,
    state.filterBySearch,
    state.filterByStatus,
    state.filterByLastUpdatedRange,
    state.answersData,
    state.filterByIsRequest,
    state.filterByAssignedUser,
    dashboardData,
  ])

  useEffect(() => {
    dispatch({
      type: AnswersLibraryActionKind.SET_CURRENT_PAGE_INDEX,
      payload: 1,
    })
  }, [
    state.filterBySource,
    state.filterBySearch,
    state.filterByStatus,
    state.filterByLastUpdatedRange,
    state.filterByIsRequest,
    state.filterByAssignedUser,
  ])

  const onSearchInputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value as string
    dispatch({
      type: AnswersLibraryActionKind.SET_SEARCH_INPUT_VALUE,
      payload: newValue as string,
    })
  }

  const onOpenAnswerHandler = (
    questionIdToOpen: string,
    isReviewMode?: boolean
  ) => {
    onFilterChangeHandler({
      filterType: 'openAnswer',
      newValue: questionIdToOpen,
      isReviewMode: isReviewMode,
    })
  }

  const onCloseAnswerHandler = () => {
    onFilterChangeHandler({
      filterType: 'openAnswer',
      newValue: null,
    })
  }

  const onToggleIsAssignReviewerModeHandler = () => {
    if (!state.isAssignReviewerMode) {
      onFilterChangeHandler({ filterType: 'status', newValue: 'CHECKED' })
    }
    dispatch({
      type: AnswersLibraryActionKind.SET_IS_ASSIGN_REVIEWER_MODE,
      payload: !state.isAssignReviewerMode,
    })
    dispatch({
      type: AnswersLibraryActionKind.SET_SELECTED_RESPONSE_IDS,
      payload: [],
    })
    dispatch({
      type: AnswersLibraryActionKind.SET_SELECTED_REVIEWER,
      payload: 'select',
    })
  }

  const onChangeSelectReviewerHandler = (event: SelectChangeEvent<string>) => {
    const reviewerId = event.target.value

    dispatch({
      type: AnswersLibraryActionKind.SET_SELECTED_REVIEWER,
      payload: reviewerId,
    })
  }

  const onFilterChangeHandler = ({
    filterType,
    newValue,
    isReviewMode,
  }: {
    filterType: AnswersLibraryFilterType
    newValue: string | DateRangeValue | null
    isReviewMode?: boolean
  }) => {
    let currentStartDateUrlValue = 'null'
    let currentEndDateUrlValue = 'null'
    let currentSourceUrlValue = 'null'
    let currentStatusUrl = 'null'
    let currentAssignedId = 'null'
    let currentSearchUrl = 'null'
    let currentIsShownOnlyRequestedUrl = 'null'
    let currentOpenAnswerIdUrl = 'null'

    let isStaffReviewMode = false

    if (state.filterBySource) {
      currentSourceUrlValue = state.filterBySource
    }

    if (state.filterBySearch) {
      currentSearchUrl = state.filterBySearch
    }

    if (state.filterByStatus) {
      currentStatusUrl = state.filterByStatus
    }

    if (state.filterByAssignedUser) {
      currentAssignedId = state.filterByAssignedUser
    }

    if (state.filterByIsRequest) {
      currentIsShownOnlyRequestedUrl = state.filterByIsRequest
    }
    if (state.filterByLastUpdatedRange) {
      if (state.filterByLastUpdatedRange[0] !== null) {
        currentStartDateUrlValue = new Date(
          state.filterByLastUpdatedRange[0]
        ).toISOString()
      }
      if (state.filterByLastUpdatedRange[1] !== null) {
        currentEndDateUrlValue = new Date(
          state.filterByLastUpdatedRange[1]
        ).toISOString()
      }
    }

    if (state.currentOpenAnswer) {
      currentOpenAnswerIdUrl = state.currentOpenAnswer
    }
    if (filterType === 'lastUpdatedDataRange') {
      if (newValue) {
        currentStartDateUrlValue = new Date(newValue![0]).toISOString()
        currentEndDateUrlValue = new Date(newValue![1]).toISOString()
      } else {
        currentStartDateUrlValue = 'null'
        currentEndDateUrlValue = 'null'
      }
    }
    if (filterType === 'search') {
      currentSearchUrl = newValue as string
    }
    if (filterType === 'status') {
      if (newValue !== 'CHECKED_ASSIGNED') {
        currentAssignedId = 'null'
      }
      currentStatusUrl = newValue as string
    }

    if (filterType === 'assignedId') {
      currentAssignedId = newValue as string
    }
    if (filterType === 'openAnswer') {
      currentOpenAnswerIdUrl = newValue as string
    }

    if (filterType === 'source') {
      currentSourceUrlValue = newValue as string
    }

    if (filterType === 'isAnswerRequest') {
      currentIsShownOnlyRequestedUrl = newValue as string
    }
    const redirectionUrl = `/answers-library/${encodeURIComponent(
      currentStartDateUrlValue
    )}/${encodeURIComponent(currentEndDateUrlValue)}/${encodeURIComponent(
      currentSourceUrlValue
    )}/${encodeURIComponent(currentStatusUrl)}/${encodeURIComponent(
      currentAssignedId
    )}/${encodeURIComponent(currentSearchUrl)}/${encodeURIComponent(
      currentIsShownOnlyRequestedUrl
    )}/${encodeURIComponent(currentOpenAnswerIdUrl)}`

    if (isReviewMode) {
      navigate(`${redirectionUrl}/review/`)
    } else {
      navigate(redirectionUrl)
    }
  }

  const onAddItemToSelectedHandler = (idToAdd: string) => {
    const newState = state.selectedIdsOfResponses.concat(idToAdd)
    dispatch({
      type: AnswersLibraryActionKind.SET_SELECTED_RESPONSE_IDS,
      payload: newState,
    })
  }

  const excludItemFromSelectedHandler = (idToRemove: string) => {
    const newState = state.selectedIdsOfResponses.filter(
      id => idToRemove !== id
    )
    dispatch({
      type: AnswersLibraryActionKind.SET_SELECTED_RESPONSE_IDS,
      payload: newState,
    })
  }

  const onSubmitBulkAssigmentHandler = () => {
    assignReviewer({
      body: {
        user_id: state.assignedReviewer,
        response_ids: state.selectedIdsOfResponses,
      },
    })
  }

  const onChangeNumberOfPageHandler = pageNumber => {
    dispatch({
      type: AnswersLibraryActionKind.SET_CURRENT_PAGE_INDEX,
      payload: pageNumber,
    })
  }

  const ctxObject: AnswersLibraryContextSchema = {
    state: state,
    queryResults: {
      isFetching: isAnswersFetching,
    },
    bulkQueryResults: {
      isLoadingAssignReviewer,
    },
    actions: {
      onSearchInputChange: onSearchInputChangeHandler,
      onOpenAnswer: onOpenAnswerHandler,
      onCloseAnswer: onCloseAnswerHandler,
      onFilterChange: onFilterChangeHandler,
      onToggleIsAssignReviewerMode: onToggleIsAssignReviewerModeHandler,
      onChangeSelectedReviewer: onChangeSelectReviewerHandler,
      onAddItemToSelected: onAddItemToSelectedHandler,
      excludItemFromSelected: excludItemFromSelectedHandler,
      onSubmitBulkAssigment: onSubmitBulkAssigmentHandler,
      onChangeNumberOfPage: onChangeNumberOfPageHandler,
      refetchAnswers: refetchAnswers,
    },
  }

  let content = children

  if (isErrorInSavedAnswers) {
    content = <ErrorView errorData={errorDataInSavedAnswers} />
  }

  if (isLoadingInSavedAnswers) {
    content = (
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <AnswersLibraryContext.Provider value={ctxObject}>
      {/* {JSON.stringify(state.filterByIsRequest)} */}
      {/* {role === 'VIEWER' && (
        <>
          <Typography>requested_answers_by_viewer_ready_to_view</Typography>
          {JSON.stringify(
            dashboardData?.requested_answers_by_viewer_ready_to_view
          )}
        </>
      )} */}

      {/* {role !== 'VIEWER' && (
        <>
          <Typography>requested_answers_by_owner_ready_to_review:</Typography>
          {JSON.stringify(
            dashboardData?.requested_answers_by_owner_ready_to_review
          )}
          <Typography>requested_answers_by_viewer</Typography>
          {JSON.stringify(dashboardData?.requested_answers_by_viewer)}
        </>
      )} */}

      {content}
    </AnswersLibraryContext.Provider>
  )
}

export default AnswersLibraryContextProvider
