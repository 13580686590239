import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IHubspotForm } from '../types/interfaces'

export const hubSpotUserTrackingApi = createApi({
  reducerPath: 'hubspotApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.hsforms.com/submissions/v3/integration/submit/8510725/`,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  tagTypes: ['Hubspot'],
  endpoints: build => ({
    triggerSignedUpAndProfileDetailsAccessed: build.mutation<any, IHubspotForm>(
      {
        query: formData => ({
          url: `32f26342-e1b6-46e9-9584-f409f43132e0`,
          method: 'POST',
          body: JSON.stringify(formData),
        }),
        invalidatesTags: ['Hubspot'],
      }
    ),
    triggerHubspotProfileDetailsCompleted: build.mutation<any, IHubspotForm>({
      query: formData => ({
        url: `50371db9-3ddb-495a-b46b-9780ad7bd2b9`,
        method: 'POST',
        body: JSON.stringify(formData),
      }),
      invalidatesTags: ['Hubspot'],
    }),
    triggerWaitingForAuthorisationViewAccessed: build.mutation<
      any,
      IHubspotForm
    >({
      query: formData => ({
        url: `639531b7-3d9d-4a8e-bd57-c4c976e6b104`,
        method: 'POST',
        body: JSON.stringify(formData),
      }),
      invalidatesTags: ['Hubspot'],
    }),
    triggerAskQuestionViewAccessed: build.mutation<any, IHubspotForm>({
      query: formData => ({
        url: `29b3321e-664b-4d5c-9acd-275696d0e489`,
        method: 'POST',
        body: JSON.stringify(formData),
      }),
      invalidatesTags: ['Hubspot'],
    }),
    triggerClickSignUpButton: build.mutation<any, void>({
      query: () => ({
        url: `bace4c4f-4066-4763-803d-2bf2b8d4474e`,
        method: 'POST',
        body: JSON.stringify({ fields: [{ name: 'sign_up', value: 'ok' }] }),
      }),
      invalidatesTags: ['Hubspot'],
    }),
    triggerClickLogInButton: build.mutation<any, void>({
      query: () => ({
        url: `17f71c05-ee3c-4a07-90d0-04230664e247`,
        method: 'POST',
        body: JSON.stringify({ fields: [{ name: 'login', value: 'ok' }] }),
      }),
      invalidatesTags: ['Hubspot'],
    }),

    sendGetHelpQuestionToHubspoForm: build.mutation<any, IHubspotForm>({
      query: formData => ({
        url: `9e109c1c-dbf0-4e29-a9b0-952689f2197b`,
        method: 'POST',
        body: JSON.stringify(formData),
      }),
      invalidatesTags: ['Hubspot'],
    }),
  }),
})

export const {
  useTriggerSignedUpAndProfileDetailsAccessedMutation,
  useTriggerHubspotProfileDetailsCompletedMutation,
  useTriggerWaitingForAuthorisationViewAccessedMutation,
  useTriggerAskQuestionViewAccessedMutation,
  useTriggerClickSignUpButtonMutation,
  useTriggerClickLogInButtonMutation,
  useSendGetHelpQuestionToHubspoFormMutation,
} = hubSpotUserTrackingApi
