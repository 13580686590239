import React, { useEffect, useRef, useState } from 'react'
import { Box, Container, Link, useTheme } from '@mui/material'
import TextOnDark from '../ui/Typographies/TextOnDark'
import { useLocation } from 'react-router-dom'
import GetHelpSection from './GetHelpSection/GetHelpSection'
import { useAuth0 } from '@auth0/auth0-react'

const Footer: React.FC = () => {
  const theme = useTheme()

  const date = new Date()
  const location = useLocation()
  const { isAuthenticated } = useAuth0()

  return (
    <Box
      id="footer"
      component={'footer'}
      sx={{
        backgroundColor: theme.palette.primary.dark,
        position: 'relative',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: theme.spacing(2),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextOnDark>
          © {date.getFullYear()} EthicsGrade. All rights reserved.
        </TextOnDark>
        <Box>
          <Link
            sx={{ display: 'inline-block' }}
            href="/terms-and-conditions"
            target="_blank"
            underline="hover"
            variant="body1"
            color="primary.contrastText"
          >
            Terms
          </Link>
        </Box>
      </Container>
      {isAuthenticated && <GetHelpSection />}
    </Box>
  )
}

export default Footer
