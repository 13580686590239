import React from 'react'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { DashboardMainItemInterface } from '../DashboardItems/DashboardItems'
import { ArrowForwardIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/hooks'
import UniversalButton from '../../ui/Buttons/UniversalButton'

const DashboardSubItem: React.FC<{ title: string; quantity: number }> = ({
  title,
  quantity,
}) => {
  const theme = useTheme()
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '10fr minMax(60px,1fr)',
        borderBottom: `1px solid ${borderColor}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          borderRight: `1px solid ${borderColor}`,
          borderLeft: `1px solid ${borderColor}`,
          paddingX: theme.spacing(3.5),
        }}
      >
        <Typography
          component={'h3'}
          variant="h5"
          sx={{ m: 0, textAlign: 'left' }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          py: theme.spacing(0.5),
          px: theme.spacing(2),
          borderRight: `1px solid ${borderColor}`,
        }}
      >
        <Typography sx={{ textAlign: 'right' }}>{quantity}</Typography>
      </Box>
    </Box>
  )
}

const DashboardMainItem: React.FC<DashboardMainItemInterface> = ({
  title,
  mainNumber,
  mainNumberText,
  url,
  subItems,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { isDemoMode } = useAppSelector(state => state.app)

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const subItemsJsx = subItems.map((item, index) => {
    const { title, quantity } = item
    return (
      <DashboardSubItem
        key={`${index}_${title}_sub`}
        title={title}
        quantity={quantity}
      />
    )
  })

  if (title === 'Surveys' && isDemoMode) {
    subItemsJsx.push(
      <Box
        key={`custom_sub`}
        sx={{
          border: `1px solid ${borderColor}`,
          borderTop: 'none',
          textAlign: 'center',
        }}
      >
        <UniversalButton
          label="Upload Survey"
          onClick={() => navigate(`/surveys/uploadModeDemo`)}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'grid',
        borderBottom: 'none',
        gridTemplateRows: '50px 200px 55px 55px 55px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '10fr 1fr',
          backgroundColor: theme.palette.secondary.main,
          border: `1px solid ${borderColor}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: `1px solid ${borderColor}`,
          }}
        >
          <Typography
            component={'h3'}
            variant="h5"
            sx={{ m: 0, color: `#00294B` }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: theme.spacing(0.2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => navigate(url)}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2.5),
          borderBottom: `1px solid ${borderColor}`,
          borderRight: `1px solid ${borderColor}`,
          borderLeft: `1px solid ${borderColor}`,
        }}
      >
        <Typography
          sx={{ marginBottom: theme.spacing(0.5) }}
          component={'h3'}
          variant="h2"
        >
          {mainNumber}
        </Typography>
        <Typography sx={{ marginTop: theme.spacing(0.5) }} variant="body2">
          {mainNumberText}
        </Typography>
      </Box>
      <Box>{subItemsJsx}</Box>
    </Box>
  )
}

export default DashboardMainItem
