import React, { useEffect, useState } from 'react'
import { Box, IconButton, SxProps, Typography, useTheme } from '@mui/material'
import { Survey } from '../../../../types/interfaces'
import {
  getRandomValue,
  processMonth,
  subtractDates,
} from '../../../../utils/utils'
import ApprovedLabel from '../../../ui/Labels/ApprovedLabel'
import ReviewItems from './ReviewItems/ReviewItems'
import { Download } from '@mui/icons-material'
import UniversalDialog from '../../../ui/UniversalDialog/UniversalDialog'
import theme from '../../../../themeData/themeData'
import MySurveyItemLink from '../../MySurveyItemLink'
import DownloadFileDialog from './DownloadFileDialog/DownloadFileDialog'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'

interface CellProps {
  text: string
}

interface ItemProps extends Survey {
  borderStyle: string
  gridParam: string
  isHidden: boolean
}

const passOnlyNumber = (quantity: number | undefined) => {
  if (quantity !== undefined) {
    return quantity
  } else {
    return 0
  }
}

const TableItemCell: React.FC<CellProps> = ({ text }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', px: theme.spacing(3) }}>
      <Typography textAlign={'center'}>{text}</Typography>
    </Box>
  )
}

const HardCodedItem: React.FC<ItemProps> = ({
  id,
  name,
  file,
  completed_file,
  annotation_file,
  borderStyle,
  status,
  responses_approved,
  responses_reviewed,
  responses_unreviewed,
  responses_unassigned,
  responses_rejected_by_editor,
  responses_rejected_by_contributor,
  gridParam,
  isHidden,
  created_at,
}) => {
  const [isDialogDownloadFileOpen, setIsDialogDownloadFileOpen] =
    useState(false)

  const [isInProgress, setIsInProgress] = useState(true)

  const randomValue = getRandomValue(5000, 20000)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInProgress(false)
    }, randomValue)
    return () => clearTimeout(timer)
  }, [])

  const { companyId, role } = useAppSelector(state => state.user)

  const isViever = role === 'VIEWER'

  // let title = name
  let title = ''
  let statusLabel = ''

  // if (file && !name) {
  //   const { friendly_name } = file
  //   title = friendly_name
  // } else if (completed_file && !name) {
  //   const { friendly_name } = completed_file
  //   title = friendly_name
  // }

  if (file) {
    const { friendly_name } = file
    title = friendly_name
  } else if (completed_file) {
    const { friendly_name } = completed_file
    title = friendly_name
  }

  const date = new Date()
  const year = date.getFullYear()
  const today = new Date()
  const month = processMonth(date.getMonth())
  const day = date.getDate()
  const dateToDisplay = `${year}-${month}-${day}`

  const oneMonthInMilliseconds = 2629800000
  const expiredPeriodInMilliseconds = oneMonthInMilliseconds

  const howLongInMillisecondsThisSurveyExist = subtractDates(date, today)
  const reqCompaniesIds = [
    '9b0df610-1a36-4dc5-a61e-94baf802d241',
    '4d27de9f-2202-4e0a-a071-0b70caf331c4',
    '49352f32-f9ea-42eb-957c-9aa802f4a937',
    '6bb47904-1abe-4299-bd9f-fe14e116dad5',
  ]
  const isLinkExpired =
    howLongInMillisecondsThisSurveyExist > expiredPeriodInMilliseconds &&
    (reqCompaniesIds.find(reqCompanyId => reqCompanyId === companyId)
      ? true
      : false)

  switch (status) {
    case 'GENERATING':
      statusLabel = 'Generating answers'
      break
    case 'READY':
      statusLabel = 'Ready'
      break
    case 'PROCESSING':
      statusLabel = 'Processing'
      break
  }

  const openDownloadFileDialogHandler = () => {
    setIsDialogDownloadFileOpen(true)
  }

  const closeDownloadFileDialogHandler = () => {
    setIsDialogDownloadFileOpen(false)
  }

  if (isHidden) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: gridParam,

        '>div:not(:last-child)': {
          borderRight: borderStyle,
        },
        '>div:not(:first-child)': {
          justifyContent: 'center',
        },
        ':not(:last-child)': {
          borderBottom: borderStyle,
        },
      }}
    >
      <TableItemCell text={title} />
      <TableItemCell text={dateToDisplay} />
      <TableItemCell text={isInProgress ? 'Generating answers' : statusLabel} />
      <Box
        sx={{
          '>div:not(:last-child)': { borderBottom: borderStyle },
        }}
      >
        {status === 'READY' && !isInProgress && (
          <ReviewItems
            borderStyle={borderStyle}
            responses_approved={passOnlyNumber(responses_approved)}
            responses_reviewed={passOnlyNumber(responses_reviewed)}
            responses_unreviewed={passOnlyNumber(responses_unreviewed)}
            responses_unassigned={passOnlyNumber(responses_unassigned)}
            responses_rejected_by_editor={passOnlyNumber(
              responses_rejected_by_editor
            )}
            responses_rejected_by_contributor={passOnlyNumber(
              responses_rejected_by_contributor
            )}
            sourceId={id}
          />
        )}
      </Box>
      {!isViever && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!isInProgress && (completed_file || annotation_file) && (
            <IconButton
              disabled={isLinkExpired}
              onClick={openDownloadFileDialogHandler}
            >
              <Download />
            </IconButton>
          )}
        </Box>
      )}
      <DownloadFileDialog
        isOpen={isDialogDownloadFileOpen}
        onClose={closeDownloadFileDialogHandler}
        file={file}
        completedFile={completed_file}
        annotationFile={annotation_file}
      />
    </Box>
  )
}

export default HardCodedItem
