import React from 'react'
import ReactDOM from 'react-dom/client'
import Hotjar from '@hotjar/browser'
import { Auth0Provider } from '@auth0/auth0-react'
import { App } from './app'
import { ThemeProvider } from '@mui/system'
import theme from './themeData/themeData'
import './index.css'
import { Provider } from 'react-redux'
import { store } from '../src/store/store'
import ReactGA from 'react-ga4'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')

isProduction && ReactGA.initialize('G-KJMXWFWZ32')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const domain = process.env.REACT_APP_AUTH0_DOMAIN!
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!
const audience = process.env.REACT_APP_AUTH0_AUDIENCE!

const siteId = 3680756
const hotjarVersion = 6

isProduction && Hotjar.init(siteId, hotjarVersion)

root.render(
  <ThemeProvider theme={theme}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </ThemeProvider>
)
