import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useContext } from 'react'
import { Close } from '@mui/icons-material'
import { SimilarQuestionsContext } from '../../../contexts/SimilarQuestionsContext/similar-questions-context'
import UniversalButton from '../../ui/Buttons/UniversalButton'

const SuccessDialog: React.FC = () => {
  const {
    state: { isViewerRequestAnswerApprovalModalOpen },
    actions: { onClickCloseApproveAnswerSuccessModal },
  } = useContext(SimilarQuestionsContext)

  const theme = useTheme()

  const onSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onClickCloseApproveAnswerSuccessModal()
  }

  return (
    <Dialog
      open={isViewerRequestAnswerApprovalModalOpen}
      onClose={onClickCloseApproveAnswerSuccessModal}
      maxWidth="sm"
      fullWidth
    >
      <Box
        sx={{
          boxSizing: 'border-box',

          paddingX: theme.spacing(4),
          paddingY: theme.spacing(2.5),
        }}
        component={'form'}
        onSubmit={onSubmitHandler}
      >
        <Box sx={{ textAlign: 'right' }}>
          <IconButton onClick={onClickCloseApproveAnswerSuccessModal}>
            <Close />
          </IconButton>
        </Box>
        <DialogTitle sx={{ paddingLeft: 0, m: 0 }}>
          Your request has been submitted
        </DialogTitle>
        <Typography>
          We will notify you when it is ready for you to view.
        </Typography>
        <Stack
          direction={'row'}
          spacing={theme.spacing(3)}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <UniversalButton label="Close" type="submit" stylingType={4} />
        </Stack>
      </Box>
    </Dialog>
  )
}

export default SuccessDialog
