import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'

interface Props {
  tableHeaders: JSX.Element[]
  tableBodyRows: JSX.Element[]
  switchOffBorderInHeader?: boolean
  headRowBgColor?: string
}

const NewDesignUniversalTable: React.FC<Props> = ({
  tableHeaders,
  tableBodyRows,
  switchOffBorderInHeader,
  headRowBgColor,
}) => {
  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
        }}
      >
        <TableHead
          sx={{
            backgroundColor: headRowBgColor ? headRowBgColor : 'inherit',
            borderLeft: switchOffBorderInHeader
              ? 0
              : `1px solid ${borderColor}`,
            borderRight: switchOffBorderInHeader
              ? 0
              : `1px solid ${borderColor}`,
          }}
        >
          <TableRow>{tableHeaders}</TableRow>
        </TableHead>
        <TableBody>{tableBodyRows}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default NewDesignUniversalTable
