import {
  Avatar,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect } from 'react'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import CloseIcon from '@mui/icons-material/Close'
import ToriAvatar from '../../../assets/images/tori_avatar.png'
import { useSendGetHelpQuestionToHubspoFormMutation } from '../../../services/hubSpotUserTracking'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  positionProperties: {
    position: 'fixed' | 'relative' | 'absolute'
    bottom?: string
    right?: string
    left?: string
  }
  onCloseHelpBoxClick: () => void
}

interface IGetHelpFormValues {
  question: { value: string }
}

const GetHelpBox: React.FC<Props> = ({
  positionProperties,
  onCloseHelpBoxClick,
}) => {
  const theme = useTheme()
  const [
    sendAskForHelpQuestion,
    {
      isSuccess: isSuccessInSendAskForHelpQuestion,
      isError: isErrorInSendAskForHelpQuestion,
      isLoading: isSendAskForHelpQuestionLoading,
      reset: resetResultOfAskForHelpQuestion,
    },
  ] = useSendGetHelpQuestionToHubspoFormMutation()
  const { user } = useAuth0()

  useEffect(() => {
    if (isSuccessInSendAskForHelpQuestion) {
      const timer = setTimeout(() => {
        onCloseHelpBoxClick()
      }, 4000)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInSendAskForHelpQuestion])

  useEffect(() => {
    if (isErrorInSendAskForHelpQuestion) {
      const timer = setTimeout(() => {
        resetResultOfAskForHelpQuestion()
      }, 4000)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInSendAskForHelpQuestion])
  let userEmail = ''

  if (user) {
    const { email } = user
    if (email) {
      userEmail = email
    }
  }

  const onSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & IGetHelpFormValues
    if (userEmail) {
      sendAskForHelpQuestion({
        fields: [
          { name: 'email', value: userEmail },
          { name: 'message', value: target.question.value },
        ],
      })
    }
  }

  let mainContent = (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: theme.spacing(2),
        }}
      >
        <Avatar
          alt="Tori's avatar"
          src={ToriAvatar}
          sx={{ width: '110px', height: '110px' }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '24px',
              marginTop: 0,
            }}
          >
            Hi there! I'm Tori. I'm an actual person.
          </Typography>
          <Typography sx={{ fontSize: '20px', lineHeight: '24px' }}>
            Let me know what I can help with and I will get back to you within
            24hours.
          </Typography>
        </Box>
      </Box>
      <Box>
        <TextField
          id="question"
          fullWidth
          multiline
          minRows={4}
          required
        ></TextField>
      </Box>
      <Box>
        <Stack
          direction={'row'}
          spacing={theme.spacing(4)}
          justifyContent="center"
          alignItems="center"
        >
          <UniversalButton
            type="submit"
            loading={isSendAskForHelpQuestionLoading}
            label="Ask a question"
          />
          <UniversalButton
            stylingType={4}
            onClick={onCloseHelpBoxClick}
            label="cancel"
          />
        </Stack>
      </Box>
    </>
  )

  if (isErrorInSendAskForHelpQuestion) {
    mainContent = (
      <Box
        sx={{
          display: 'flex',
          minWidth: '400px',
          minHeight: '200px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>
          Error in sending question.
        </Typography>
        <Typography>Please try again later.</Typography>
      </Box>
    )
  }

  if (isSuccessInSendAskForHelpQuestion) {
    mainContent = (
      <Box
        sx={{
          display: 'flex',
          minWidth: '400px',
          minHeight: '200px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>Question is sent</Typography>
        <Typography>We will answer in 48h</Typography>
      </Box>
    )
  }

  return (
    <Box
      component={'form'}
      onSubmit={onSubmitHandler}
      sx={{
        backgroundColor: 'white',
        paddingY: theme.spacing(5),
        paddingX: theme.spacing(4),
        maxWidth: '500px',
        ...positionProperties,
        zIndex: 500,
        boxShadow: 3,
      }}
    >
      <Box sx={{ textAlign: 'right', m: 0 }}>
        <IconButton onClick={onCloseHelpBoxClick} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      {mainContent}
    </Box>
  )
}

export default GetHelpBox
