import { createContext } from 'react'

import { GeneratedResponseContextSchema } from './generatedResponseInterfaces'
import { initialGeneratedResponseState } from './initialGeneratedResponseState'
import { initialGeneratedResponseActions } from './initialGeneratedResponseActions'

const generatedResponseInitialContext: GeneratedResponseContextSchema = {
  state: initialGeneratedResponseState,
  actions: initialGeneratedResponseActions,
}

export const GeneratedResponseContext = createContext(
  generatedResponseInitialContext
)
