import React, { useContext, useEffect, useState } from 'react'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import { Box, CircularProgress, Typography } from '@mui/material'

import {
  useGenerateVisualisationMutation,
  useGetVisualisationsQuery,
} from '../services/visualisations'
import ErrorView from './ErrorView'
import ChartBox from '../components/Visualizations/ChartBox/ChartBox'
import theme from '../themeData/themeData'
import UniversalButton from '../components/ui/Buttons/UniversalButton'
import { SurveysContext } from '../contexts/SurveysContext/surveys-context'
import { audienceOptions } from '../components/surveys/DropFileZone/DialogUploadSurvey/optionsForSelectInouts'
import { AnswersLibraryContext } from '../contexts/AnswersLibraryContext/answers-library-context'
import SelectMulInput from '../components/ui/inputs/SelectMulInput'

const Analytics: React.FC = () => {
  const { data, isError, error, refetch } = useGetVisualisationsQuery()
  const [audienceTypes, setAudeienceTyps] = useState<string[]>([])
  const [surveyIds, setSurveyIds] = useState<string[]>([])
  const [surveyOptions, setSurveyOptions] = useState<
    { label: string; value: string }[]
  >([])
  const {
    state: { answerSources },
  } = useContext(AnswersLibraryContext)

  const [
    generateAnalistycsQuery,
    {
      isSuccess: isSuccessInGenerateAnalitycs,
      isLoading: isLoadingInGenerateAnalitycs,
      isError: isErrorInGenerateAnalitycsQuery,
      error: errorInGenerateAnalitycsQuery,
    },
  ] = useGenerateVisualisationMutation()

  const generateAnalistycsQueryHandler = () => {
    generateAnalistycsQuery({
      survey_ids: surveyIds,
      survey_audience_types: audienceTypes,
    })
  }

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (data?.status === 'GENERATING') {
      interval = setInterval(() => {
        refetch()
      }, 300000) // 300000 milliseconds = 5 minutes
    }

    setAudeienceTyps(audienceOptions.map(o => o.value))

    answerSources.forEach(source => {
      if (!['1', '2'].includes(source.id)) {
        setSurveyIds(ids => [...ids, source.id])
        setSurveyOptions(options => [
          ...options,
          {
            label: source.label,
            value: source.id,
          },
        ])
      }
    })

    return () => clearInterval(interval)
  }, [data?.status, refetch])

  useEffect(() => {
    refetch()
  }, [isSuccessInGenerateAnalitycs])

  let lastUpdateDateInNiceFormat: string | null = null

  let mainContent = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '500px',
      }}
    >
      <CircularProgress />
    </Box>
  )

  if (error && isError) {
    return <ErrorView errorData={error} />
  }
  if (data && data.status === 'GENERATED') {
    lastUpdateDateInNiceFormat = new Date(data.updated_at).toLocaleDateString()
    const jsxItems = data.visualisations.map((item, index) => {
      return <ChartBox key={`${index}_${item.title}`} {...item} />
    })
    mainContent = (
      <>
        <Typography variant="h6" component={'h3'}>
          Last update: {lastUpdateDateInNiceFormat}
        </Typography>
        <Box
          sx={{
            maxWidth: '800px',
            gap: theme.spacing(3),
            display: 'flex',
          }}
        >
          <UniversalButton
            onClick={generateAnalistycsQueryHandler}
            label="Refresh analytics data"
            loading={isLoadingInGenerateAnalitycs}
          ></UniversalButton>
          <SelectMulInput
            sx={{ width: '33.33%' }}
            label="Filter by source"
            defaultValue={surveyIds}
            currentValue={surveyIds}
            onChange={newValue => setSurveyIds(newValue)}
            options={surveyOptions}
          />
          <SelectMulInput
            sx={{ width: '33.33%' }}
            label="Filter by audience"
            defaultValue={audienceTypes}
            currentValue={audienceTypes}
            onChange={newValue => setAudeienceTyps(newValue)}
            options={audienceOptions}
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateColumns: '1fr 1fr',
            marginTop: theme.spacing(4),
          }}
        >
          {jsxItems}
        </Box>
      </>
    )
  } else if (
    (data && data.status === 'GENERATING') ||
    isLoadingInGenerateAnalitycs
  ) {
    mainContent = (
      <Box>
        <Typography variant="h6" component={'h4'}>
          Generating Your Analytics Data...
        </Typography>
        <Typography>
          Your request to generate analytics data is being processed. This may
          take approximately 10-15 minutes to complete.
        </Typography>
        <Typography>
          We will notify you via email once the data is ready. In the meantime,
          you can continue to use other features of the app without any
          interruptions.
        </Typography>
        <Typography>Thank you for your patience!</Typography>
        <Box textAlign={'center'}>
          <CircularProgress />
        </Box>
      </Box>
    )
  } else if (data && data.status === null) {
    mainContent = (
      <Box>
        <Typography>
          Click the "Generate Analytics Data" button below to start generating
          your data. This process can take approximately 10-15 minutes to
          complete. Once the data is ready, we will notify you via email.
        </Typography>
        <Typography>
          In the meantime, feel free to continue exploring and using other
          features within the app. Your analytics data will be available soon!
        </Typography>
        <UniversalButton
          onClick={generateAnalistycsQueryHandler}
          label="Generate analytics data"
          loading={isLoadingInGenerateAnalitycs}
        ></UniversalButton>
      </Box>
    )
  }

  return (
    <MainContentContainer
      maxWidth="xl"
      sx={{
        alignItems: 'start',
        display: 'flex',
      }}
    >
      <ViewTitle label={`Analytics`} />
      {mainContent}
    </MainContentContainer>
  )
}

export default Analytics
