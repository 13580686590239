import React, { useContext } from 'react'
import { Box, useTheme } from '@mui/material'
import SeasonalSurveyRow from './SeasonalSurveyRow/SeasonalSurveyRow'
import { SurveysContext } from '../../../../contexts/SurveysContext/surveys-context'
import DialogGenerateSeasonalSurvey from './DialogGenerateSeasonalSurvey/DialogGenerateSeasonalSurvey'

interface Props {}

const SeasonalSurveysTabel: React.FC<Props> = () => {
  const theme = useTheme()
  const {
    state: { seasonalSurveys, idOfSeasonalSurveyModalOpen },
  } = useContext(SurveysContext)

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const tableRows = seasonalSurveys.map((surveyItem, index) => {
    return (
      <SeasonalSurveyRow key={`${surveyItem.name}_${index}`} {...surveyItem} />
    )
  })

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          '> div': {
            borderBottom: `1px solid ${borderColor}`,
            ':last-child': {
              borderBottom: 'none',
            },
          },
        }}
      >
        {tableRows}
      </Box>
      <DialogGenerateSeasonalSurvey />
    </>
  )
}

export default SeasonalSurveysTabel
