import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useGetUserDetailsQuery } from '../../services/users'
import ErrorView from '../../views/ErrorView'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { userActions } from '../../store/User/user-slice'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import NoAccessToThisView from '../shared/NoAccessToThisView/NoAccessToThisView'
import CompanyMembersContextProvider from '../../contexts/CompanyMembersContext/CompanyMembersContextProvider'

interface Props {
  children: React.ReactNode
}

const UsernameProvider: React.FC<Props> = ({ children }) => {
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    error: userDataError,
  } = useGetUserDetailsQuery()
  const { pathname } = useLocation()
  const [triggerTimer, setTriggerTimer] = useState(false)

  const dispatch = useAppDispatch()
  const { user, logout } = useAuth0()

  const userState = useAppSelector(state => state.user)

  const { companyId, role, companyName } = userState

  window.addEventListener('mousemove', () => {
    setTriggerTimer(prevState => !prevState)
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      logout({ logoutParams: { returnTo: window.location.origin } })
    }, 900000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerTimer])

  useEffect(() => {
    if (userData && user) {
      const { company, role, id } = userData
      const { email } = user
      if (userData.is_super_user) {
        dispatch(userActions.setSuperUser())
      }
      if (company && company.id && company.name && email) {
        dispatch(userActions.setCompanyId(company.id))
        dispatch(userActions.setCompanyName(company.name))
        dispatch(userActions.setUserEmail(email))
        dispatch(userActions.setUserId(id))
        if (company.is_ask_single_question_unlocked) {
          dispatch(userActions.unblockAskQuestionMode())
        }
      }
      if (role === null) {
        dispatch(userActions.setUserRole('VIEWER'))
      }
      if (role) {
        dispatch(userActions.setUserRole(role))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, user])

  if (isUserDataLoading) {
    return (
      <Box
        component={'article'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '350px',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (isUserDataError) {
    return <ErrorView errorData={userDataError} />
  }

  if (companyId && userData?.is_user_details_completed && user && companyName) {
    if (pathname === '/' || pathname === '/waiting-for-authorisation') {
      if (localStorage.getItem('wasGetStartedShown') === 'true') {
        return <Navigate to="/dashboard" />
      }
      return <Navigate to="/get-started" />
    }
  }

  if (!companyId && userData?.is_user_details_completed) {
    if (pathname === '/waiting-for-authorisation') {
      return children
    } else return <Navigate to="/waiting-for-authorisation" />
  } else {
    return children
  }
}

export default UsernameProvider
