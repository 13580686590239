import LogoOnDark from '../ui/Logo/EgLogoOnDark'
import React from 'react'
import LogOutButton from '../ui/Buttons/LogOutButton'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { IProfileSetUpIcon, User } from '../../types/interfaces'
import ProfileSetUpIcon from './ProfileSetUpIcon/ProfileSetUpIcon'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { appActions } from '../../store/App/app-slice'
import { evidenceListActions } from '../../store/EvidenceListView/EvidenceListView-slice'
import {
  Alert,
  Box,
  Container,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material'

import NavLinks from '../NavLinks/NavLinks'
import { userActions } from '../../store/User/user-slice'
import ViewTitle from '../ui/ViewTitle/ViewTitle'

const Header = () => {
  const theme = useTheme()
  const { isAuthenticated, logout, user } = useAuth0()
  const { queries } = useAppSelector(state => state.usersApi)
  const { username, companyId, userEmail } = useAppSelector(state => state.user)
  const { currentLoadingEvidenceItems } = useAppSelector(
    state => state.evidenceListView
  )
  const { queries: queriesInEvidence } = useAppSelector(
    state => state.evidencesApi
  )

  const { currentViewTitle } = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()

  const onClickLogoutHandler = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const snackbarNotificationState = useAppSelector(
    state => state.app.snackbarNotificationState
  )

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  useEffect(() => {
    if (snackbarNotificationState.isDisplayed) {
      const timer = setTimeout(() => {
        dispatch(appActions.hideSnackbarNotification())
      }, 6000)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarNotificationState.isDisplayed])

  useEffect(() => {
    if (
      queriesInEvidence[`getCompanyEvidence("${username}")`]?.status ===
        'fulfilled' &&
      currentLoadingEvidenceItems.length > 0
    ) {
      dispatch(evidenceListActions.resetEvidenceToEvidenceTable())
    }
    // eslint-disable-next-line
  }, [queriesInEvidence])

  useEffect(() => {
    if (user) {
      const { email } = user
      if (email) {
        dispatch(userActions.setUserEmail(email))
      }
    }
    // eslint-disable-next-line
  }, [user])

  let getUserDetailsQueryData = queries['getUserDetails(undefined)']

  let profileData: IProfileSetUpIcon | null = null

  let isUserAutorisedByEg: boolean = false

  if (getUserDetailsQueryData?.status === 'fulfilled') {
    const queryData = getUserDetailsQueryData.data as User
    const { company, first_name, last_name } = queryData
    if (company) {
      if (company.id) {
        isUserAutorisedByEg = true
      }
    }
    profileData = {
      email: userEmail ? userEmail : '',
      fullName: `${first_name} ${last_name}`,
    }
  }

  return (
    <>
      {snackbarNotificationState.isDisplayed && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ mt: '65px' }}
          open={snackbarNotificationState.isDisplayed}
          key={'top-center'}
        >
          <Alert
            sx={{ width: '100%' }}
            severity={
              snackbarNotificationState.type
                ? snackbarNotificationState.type
                : undefined
            }
          >
            {snackbarNotificationState.label}
          </Alert>
        </Snackbar>
      )}
      <Box
        component={'header'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0,
          borderBottom: `1px solid ${borderColor}`,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: '100px',
            height: '100%',
            my: 0,
            py: 0,
          }}
        >
          <ViewTitle label={currentViewTitle} />
          {isAuthenticated && !isUserAutorisedByEg ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LogOutButton />
            </Box>
          ) : (
            profileData &&
            profileData.fullName && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                {/* <UniversalButton
                  stylingType={7}
                  component={NavLink}
                  to="/pricing"
                  label="Upgrade Plan"
                /> */}

                <ProfileSetUpIcon
                  {...profileData}
                  onLogout={onClickLogoutHandler}
                />
              </Box>
            )
          )}
        </Container>
      </Box>
    </>
  )
}

export default Header
