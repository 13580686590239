import React, { useContext } from 'react'
import UniversalDialog from '../../../../ui/UniversalDialog/UniversalDialog'
import { Typography } from '@mui/material'
import { SurveysContext } from '../../../../../contexts/SurveysContext/surveys-context'
import CdpSmeSeasonalSurveyDetailsContextProvider from '../../../../../contexts/CdpSmeSeasonalSurveyDetails/CdpSmeSeasonalSurveyDetailsContextProvider'
import CdpSmeDialogContent from './CdpSmeDialogContent/CdpSmeDialogContent'

import CdpFullDialogContent from './CdpFullDialogContent/CdpFullDialogContent'
import CdpFullSeasonalSurveyDetailsContextProvider from '../../../../../contexts/CdpFullSeasonalSurveyDetails/CdpFullSeasonalSurveyDetailsContextProvider'

interface Props {}

const DialogGenerateSeasonalSurvey: React.FC<Props> = () => {
  const {
    state: { idOfSeasonalSurveyModalOpen },
    actions: { onSetIdOfSeasonalSurveyModalOpen },
  } = useContext(SurveysContext)

  let dialogConent: JSX.Element = <span></span>

  if (idOfSeasonalSurveyModalOpen === '00159c6a-73bf-448c-bd19-6919170a89c2') {
    dialogConent = (
      <CdpSmeSeasonalSurveyDetailsContextProvider
        surveyId={idOfSeasonalSurveyModalOpen}
        surveyName="CDP 2024 Questionnaire - Small and Medium Enterprise (SME) version"
      >
        <CdpSmeDialogContent />
      </CdpSmeSeasonalSurveyDetailsContextProvider>
    )
  } else if (
    idOfSeasonalSurveyModalOpen === '4af2bc6f-109c-4ee5-a32d-eb9fecbaf27f'
  ) {
    dialogConent = (
      <CdpFullSeasonalSurveyDetailsContextProvider
        surveyId={idOfSeasonalSurveyModalOpen}
        surveyName="CDP 2024 Questionnaire - Full version"
      >
        <CdpFullDialogContent />
      </CdpFullSeasonalSurveyDetailsContextProvider>
    )
  } else {
    dialogConent = (
      <Typography>
        This type of seasonal survey doesn't exit. Please please contact our
        support team.
      </Typography>
    )
  }
  return (
    <UniversalDialog
      isDialogOpen={idOfSeasonalSurveyModalOpen !== ''}
      onCloseDialog={() => onSetIdOfSeasonalSurveyModalOpen('')}
    >
      {dialogConent}
    </UniversalDialog>
  )
}

export default DialogGenerateSeasonalSurvey
