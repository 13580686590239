import React, { useContext } from 'react'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import QuestionHeading from '../components/questionResponse/QuestionHeading/QuestionHeading'
import { Stack, Typography } from '@mui/material'
import content from '../assets/content/content'
import theme from '../themeData/themeData'
import SimilarQuestionsItems from '../components/similarQuestions/SimilarQuestionsItems/SimilarQuestionsItems'
import UniversalButton from '../components/ui/Buttons/UniversalButton'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import { SimilarQuestionsContext } from '../contexts/SimilarQuestionsContext/similar-questions-context'
import LoadingViewForGenerateQuestion from '../components/LoadingViewForGenerateQuestion/LoadingViewForGenerateQuestion'
import SimilarApprovedQuestionsTable from '../components/similarQuestions/SimilarQuestionsTable/SimilarApprovedQuestionsTable'
import GenerateNewAnswerSection from '../components/similarQuestions/GenerateNewAnswerSection/GenerateNewAnswerSection'
import { useAppSelector } from '../hooks/hooks'
import SimilarQuestionRightDrawer from '../components/similarQuestions/SimilarQuestionsTable/SimilarQuestionRightDrawer/SimilarQuestionRightDrawer'
import SimilarNotApprovedQuestionsTable from '../components/similarQuestions/SimilarQuestionsTable/SimilarNotApprovedQuestionsTable'

const SimilarQuestions: React.FC = () => {
  const {
    state: { isLoadingInGenerateNewResponse, similarRequests },
  } = useContext(SimilarQuestionsContext)

  const { role, isAskQuestionModeBlocked } = useAppSelector(state => state.user)

  const {
    eng: {
      views: {
        similarQuestions: { title, heading },
      },
    },
  } = content

  // if (isLoadingInGenerateNewResponse && role !== 'VIEWER') {
  //   return <LoadingViewForGenerateQuestion />
  // }

  if (isLoadingInGenerateNewResponse && role === 'STUFF') {
    return <LoadingViewForGenerateQuestion />
  } else if (
    isLoadingInGenerateNewResponse &&
    !isAskQuestionModeBlocked &&
    role !== 'VIEWER'
  ) {
    return <LoadingViewForGenerateQuestion />
  }

  return (
    <MainContentContainer maxWidth="xl">
      <QuestionHeading />
      <SimilarQuestionRightDrawer />
      <SimilarApprovedQuestionsTable />
      <SimilarNotApprovedQuestionsTable />
      <GenerateNewAnswerSection />
    </MainContentContainer>
  )
}

export default SimilarQuestions
