import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Root from './routes/root'
import Welcome from './views/Welcome'
import Loading from './routes/loading'
import AccountProfile from './views/AccountProfile'
import TermsAndConditions from './views/TermsAndConditions'
import WaitingForAuthorisation from './views/WaitingForAuthorisation'
import EvidenceLibrary from './views/EvidenceLibrary'
import EditProfile from './views/EditProfile'
import Auth from './routes/auth'
import AskQuestion from './views/AskQuestion'
import UsernameAndContextsProvider from './components/HOC/UsernameAndContextsProvider'
import ReactGA from 'react-ga4'
import GetStarted from './views/GetStarted'
import LoadSurveySuccess from './views/LoadSurveySuccess'
import SimilarQuestions from './views/SimilarQuestions'
import ErrorInRouterPathView from './views/ErrorInRouterPathView'
import Home from './views/Home'
import RedirectToAuth0SignUpLogIn from './components/RedirectToAuth0SignUpLogIn/RedirectToAuth0SignUpLogIn'
import AskQuestionContextProvider from './contexts/AskQuestionContext/AskQuestionContextProvider'
import GeneratedResponse from './views/GeneratedResponse'
import GeneratedResponseContextProvider from './contexts/GeneratedResponseContext/GeneratedResponseContextProvider'
import SimilarQuestionsContextProvider from './contexts/SimilarQuestionsContext/SimilarQuestionsContextProvider'
import SurveysContextProvider from './contexts/SurveysContext/SurveysContextProvider'
import Members from './views/Members'
import CompanyMembersContextProvider from './contexts/CompanyMembersContext/CompanyMembersContextProvider'
import UploadEvidenceContextProvider from './contexts/UploadEvidenceContext/UploadEvidenceContextProvider'
import AnswersLibrary from './views/AnswersLibrary'
import AnswersLibraryContextProvider from './contexts/AnswersLibraryContext/AnswersLibraryContextProvider'
import { FilterEvidenceUrlParamNames, FilterUrlParamNames } from './types/enums'
import Visualizations from './views/Analytics'
import Surveys from './views/Surveys'
import EvidenceLibraryContextProvider from './contexts/EvidenceLibraryContext/EvidenceLibraryContextProvider'
import Analytics from './views/Analytics'
import ReviewUncheckedAnswer from './views/ReviewUncheckedAnswer'

const routerConfig = (isAuthenticated: boolean, isLoading: boolean) => {
  let viewDependentOnConditions: {
    path: string
    element: JSX.Element
    errorElement?: JSX.Element
  }[] = [
    {
      path: '/',
      element: <Welcome />,
    },
    {
      path: '/sign-up',
      element: <RedirectToAuth0SignUpLogIn isSignUp={true} />,
    },
    {
      path: '/log-in',
      element: <RedirectToAuth0SignUpLogIn />,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
    },
    // {
    //   path: '/pricing',
    //   element: <Pricing />,
    // },
  ]

  if (isAuthenticated) {
    viewDependentOnConditions = [
      {
        path: '/',
        element: (
          <UsernameAndContextsProvider>
            <AccountProfile />
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/sign-up',
        element: <RedirectToAuth0SignUpLogIn />,
      },
      {
        path: '/log-in',
        element: <RedirectToAuth0SignUpLogIn />,
      },
      {
        path: '/waiting-for-authorisation',
        element: (
          <UsernameAndContextsProvider>
            <WaitingForAuthorisation />,
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/get-started',
        element: (
          <UsernameAndContextsProvider>
            <GetStarted />
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/dashboard',
        element: (
          <UsernameAndContextsProvider>
            <Home />
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/success-in-load-survey',
        element: <LoadSurveySuccess />,
      },
      {
        path: '/ask-question',
        element: (
          <UsernameAndContextsProvider>
            <AskQuestionContextProvider>
              <AskQuestion />
            </AskQuestionContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/generated-response',
        element: (
          <UsernameAndContextsProvider>
            <GeneratedResponseContextProvider>
              <GeneratedResponse />
            </GeneratedResponseContextProvider>
          </UsernameAndContextsProvider>
        ),
      },

      {
        path: '/similar-questions',
        element: (
          <UsernameAndContextsProvider>
            <SimilarQuestionsContextProvider>
              <SimilarQuestions />
            </SimilarQuestionsContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/surveys',
        element: (
          <UsernameAndContextsProvider>
            <SurveysContextProvider>
              <Surveys />
            </SurveysContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/surveys/:isUploadModeParam',
        element: (
          <UsernameAndContextsProvider>
            <SurveysContextProvider>
              <Surveys />
            </SurveysContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/answers-library',
        element: (
          <UsernameAndContextsProvider>
            <AnswersLibraryContextProvider>
              <AnswersLibrary />
            </AnswersLibraryContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/answers-library/open/:openSavedAnswerId',
        element: (
          <UsernameAndContextsProvider>
            <AnswersLibraryContextProvider>
              <AnswersLibrary />
            </AnswersLibraryContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: `/answers-library/:${FilterUrlParamNames.dataRangeStart}/:${FilterUrlParamNames.dataRangeEnd}/:${FilterUrlParamNames.sourceParam}/:${FilterUrlParamNames.statusParam}/:${FilterUrlParamNames.assignedParam}/:${FilterUrlParamNames.searchParam}/:${FilterUrlParamNames.isShownOnlyRequestedParam}/:${FilterUrlParamNames.openAnswerIdParam}/review/`,
        element: (
          <UsernameAndContextsProvider>
            <AnswersLibraryContextProvider>
              <ReviewUncheckedAnswer />
            </AnswersLibraryContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: `/answers-library/:${FilterUrlParamNames.dataRangeStart}/:${FilterUrlParamNames.dataRangeEnd}/:${FilterUrlParamNames.sourceParam}/:${FilterUrlParamNames.statusParam}/:${FilterUrlParamNames.assignedParam}/:${FilterUrlParamNames.searchParam}/:${FilterUrlParamNames.isShownOnlyRequestedParam}/:${FilterUrlParamNames.openAnswerIdParam}/`,
        element: (
          <UsernameAndContextsProvider>
            <AnswersLibraryContextProvider>
              <AnswersLibrary />
            </AnswersLibraryContextProvider>
          </UsernameAndContextsProvider>
        ),
      },

      {
        path: '/evidence-library',
        element: (
          <UsernameAndContextsProvider>
            <EvidenceLibraryContextProvider>
              <UploadEvidenceContextProvider>
                <EvidenceLibrary />
              </UploadEvidenceContextProvider>
            </EvidenceLibraryContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: `/evidence-library/:${FilterEvidenceUrlParamNames.typeParam}/:${FilterEvidenceUrlParamNames.searchParam}/:${FilterEvidenceUrlParamNames.isUploadModeParam}/`,
        element: (
          <UsernameAndContextsProvider>
            <UploadEvidenceContextProvider>
              <EvidenceLibraryContextProvider>
                <EvidenceLibrary />
              </EvidenceLibraryContextProvider>
            </UploadEvidenceContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/edit-profile',
        element: (
          <UsernameAndContextsProvider>
            <EditProfile />
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/members',
        element: (
          <UsernameAndContextsProvider>
            <CompanyMembersContextProvider>
              <Members />
            </CompanyMembersContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      {
        path: '/analytics',
        element: (
          <UsernameAndContextsProvider>
            <AnswersLibraryContextProvider>
              <Analytics />
            </AnswersLibraryContextProvider>
          </UsernameAndContextsProvider>
        ),
      },
      // {
      //   path: '/pricing',
      //   element: (
      //     <UsernameAndContextsProvider>
      //       <Pricing />
      //     </UsernameAndContextsProvider>
      //   ),
      // },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />,
      },
    ]
  }

  if (isLoading) {
    return [
      {
        path: '/',
        element: <Loading />,
      },
      {
        path: '/sign-up',
        element: <RedirectToAuth0SignUpLogIn />,
      },
      {
        path: '/log-in',
        element: <RedirectToAuth0SignUpLogIn />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />,
      },
      // {
      //   path: '/pricing',
      //   element: <Pricing />,
      // },
    ]
  } else if (isAuthenticated) {
    return [
      {
        path: '/',
        element: <Auth />,
        errorElement: <ErrorInRouterPathView />,
        children: [...viewDependentOnConditions],
      },
    ]
  } else {
    return [
      {
        path: '/',
        element: <Root />,

        children: [...viewDependentOnConditions],
      },
    ]
  }
}

export const App: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  const router = createBrowserRouter(routerConfig(isAuthenticated, isLoading))
  const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')

  if (isAuthenticated && isProduction) {
    ReactGA.event({
      category: 'log in',
      action: 'log in',
      label: 'user loged in', // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: 'xhr', // optional, beacon/xhr/image
    })
  }

  if (
    window.location.pathname !== '/' &&
    !isAuthenticated &&
    window.location.pathname !== '/terms-and-conditions' &&
    window.location.pathname !== '/sign-up' &&
    window.location.pathname !== '/log-in'
    // window.location.pathname !== '/pricing'
  ) {
    window.location.replace('/')

    return <Loading />
  }

  return <RouterProvider router={router} />
}
