import React, { useContext } from 'react'
import {
  Box,
  Chip,
  IconButton,
  SxProps,
  Typography,
  Link,
  useTheme,
} from '@mui/material'
import { FileDetails, SupportiveEvidence } from '../../../../types/interfaces'
import { ArrowForwardIos, OpenInNew } from '@mui/icons-material'
import { SupportiveEvidenceContext } from '../../../../contexts/SupportiveEvidenceContext/supportive-evidence-contex'
import {
  checkIsItPdfFile,
  processFileNameFromUrl,
} from '../../../../utils/utils'
import { useAppSelector } from '../../../../hooks/hooks'
import UniversalCheckBoxButton from '../../../ui/Buttons/UniversalCheckBoxButton'
import ConfirmRemovalEvidenceDialog from './ConfirmRemovalEvidenceDialog/ConfirmRemovalEvidenceDialog'
import { useLocation } from 'react-router-dom'
import PDFpreview from '../../../questionResponse/FoundEvidenceSection/EvidenceItem/PDFpreview'

const SupportiveEvidenceRow: React.FC<
  SupportiveEvidence & {
    borderColor: string
    isLastItem: boolean
    cellPagination: SxProps
    canUserRemoveEvidence?: boolean
  }
> = ({
  sentence,
  cellPagination,
  borderColor,
  page_number,
  evidence,
  id,
  updated_sentence,
  isLastItem,
  evidence_chunk,
  response_id,
  image,
  canUserRemoveEvidence = true,
}) => {
  const {
    state: {
      isExtended,
      isRemoveEvidenceDialogOpen,
      isPdfPreviewShown,
      isErrorInPreviewPdfFromCompanysServer,
      isErrorInDisplayingPdfScreenshot,
    },
    actions: {
      onToggleExtedned,
      onClickEvidenceHeading,
      onClickRemoveEvidenceCheckbox,
      onShowPdfPreview,
      onErrorInPreviewPdfFromCompanysServer,
      onErrorInDisplayingPdfScreenshot,
    },
  } = useContext(SupportiveEvidenceContext)
  const theme = useTheme()

  const location = useLocation()
  const { pathname } = location
  const { role } = useAppSelector(state => state.user)

  const isViewer = role === 'VIEWER'

  let evidenceHeading: JSX.Element | null = null
  let headingContent = ''
  const isSimilarQuestions = pathname === '/similar-questions'

  const cellStyling = {
    ...cellPagination,
    ':not(:last-child)': { borderRight: `1px solid ${borderColor}` },
    display: 'flex',
    alignItems: 'center',
    py: theme.spacing(0),
  }

  let supportiveEvidenceSentence = ''
  let supportiveEvidenceHtmlContent = ''
  let isItPdfFile = false
  let pageNumber = page_number
  let labelType = ''
  let pdfsScreenshotRedirectItem: JSX.Element = <></>
  let evidenceUrl: string | null = null
  let evidenceFileDetails: FileDetails | null = null

  if (evidence) {
    // const { text, type, id } = props.evidence_chunk
    const { category, url, file, type } = evidence
    supportiveEvidenceSentence = updated_sentence
      ? updated_sentence
      : sentence
      ? sentence
      : ''

    labelType = `${type[0]}${type.slice(-type.length + 1).toLowerCase()}`

    if (category) {
      headingContent = category
    } else if (file && file.friendly_name) {
      headingContent = file.friendly_name
    } else if (file && file.original_file_name) {
      headingContent = file.original_file_name
    } else if (file && file.path) {
      headingContent = processFileNameFromUrl(file.path)
    } else if (url) {
      headingContent = url
    } else {
      headingContent = id
    }
    if (url) {
      evidenceUrl = url
      isItPdfFile = checkIsItPdfFile(url)
      evidenceHeading = (
        <Link
          href={pageNumber !== null ? `${url}#page=${pageNumber}` : url}
          target="_blank"
          sx={{
            fontSize: '20px',
            lineHeight: '25px',
            wordBreak: 'break-all',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            lineBreak: 'anywhere',
          }}
        >
          {headingContent} <OpenInNew sx={{ ml: '10px' }} />
        </Link>
      )
      pdfsScreenshotRedirectItem = (
        <Link
          href={pageNumber ? `${url}#page=${pageNumber}` : url}
          target="_blank"
        >
          <img
            style={{
              maxWidth: '300px',
              cursor: 'pointer',
              border: `1px solid ${theme.palette.grey[400]}`,
              boxShadow: `0px 5px 21px -10px rgba(147, 147, 152, 1)`,
            }}
            src={image!}
            alt="Evidence Preview"
            loading="lazy"
            onError={onErrorInDisplayingPdfScreenshot}
          />
        </Link>
      )
    } else if (file) {
      isItPdfFile = checkIsItPdfFile(file.path)
      evidenceFileDetails = file
      evidenceHeading = (
        <Typography
          onClick={onClickEvidenceHeading}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 400,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            lineBreak: 'anywhere',
          }}
        >
          {headingContent} <OpenInNew sx={{ ml: '10px' }} />
        </Typography>
      )
      pdfsScreenshotRedirectItem = (
        <img
          onClick={onClickEvidenceHeading}
          style={{
            maxWidth: '300px',
            cursor: 'pointer',
            border: `1px solid ${borderColor}`,
            boxShadow: `0px 5px 21px -10px rgba(147, 147, 152, 1)`,
          }}
          src={image!}
          alt="Evidence Preview"
          loading="lazy"
          onError={onErrorInDisplayingPdfScreenshot}
        />
      )
    } else {
      evidenceHeading = (
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 400,

            display: 'flex',
            alignItems: 'center',
            lineBreak: 'anywhere',
          }}
        >
          {headingContent}
        </Typography>
      )
      pdfsScreenshotRedirectItem = (
        <img
          style={{ maxWidth: '300px', cursor: 'pointer' }}
          src={image!}
          alt="Evidence Preview"
          loading="lazy"
          onError={onErrorInDisplayingPdfScreenshot}
        />
      )
    }
  } else return null

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridTemplateRows: 'auto',
          alignItems: 'stretch',
          ':not(:last-child)': {
            borderBottom: `1px solid ${borderColor}`,
          },
        }}
      >
        <Box sx={{ ...cellStyling, py: theme.spacing(1) }}>
          {evidenceHeading}
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1.5fr 0.5fr' }}>
          <Box sx={{ ...cellStyling, justifyContent: 'center' }}>
            <Chip
              size="small"
              sx={{
                backgroundColor:
                  labelType === 'Private'
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
              }}
              label={labelType}
            />
          </Box>
          <Box sx={{ ...cellStyling, justifyContent: 'center' }}>
            {pageNumber !== null && <Typography>Page {pageNumber}</Typography>}
          </Box>
          <Box sx={{ ...cellStyling, justifyContent: 'center' }}>
            <IconButton onClick={onToggleExtedned}>
              <ArrowForwardIos
                sx={{ rotate: isExtended ? '-90deg' : '-270deg' }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {isExtended && (
        <>
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,

              display: 'grid',
              gridTemplateColumns:
                isItPdfFile && isPdfPreviewShown ? '1fr 357px' : '1fr',
              borderBottom: isLastItem ? 'none' : `1px solid ${borderColor}`,
            }}
          >
            <Box sx={{ ...cellPagination }}>
              {supportiveEvidenceSentence && (
                <Typography>{supportiveEvidenceSentence}</Typography>
              )}
              {!isViewer && !isSimilarQuestions && canUserRemoveEvidence && (
                <Box sx={{ mt: theme.spacing(1) }}>
                  <UniversalCheckBoxButton
                    isChecked={isRemoveEvidenceDialogOpen}
                    onClick={onClickRemoveEvidenceCheckbox}
                    label="Remove this evidence. It is not relevant to the question."
                  />
                </Box>
              )}
            </Box>
            {/* {JSON.stringify(
              `is error from server: ${isErrorInPreviewPdfFromCompanysServer}`
            )}
            {JSON.stringify(image)} */}
            {isItPdfFile && (
              <Box
                sx={{
                  py: '20px',
                  display:
                    isPdfPreviewShown && !isErrorInDisplayingPdfScreenshot
                      ? 'flex'
                      : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.palette.secondary.main,
                  borderLeft: `1px solid ${borderColor}`,
                }}
              >
                {!isErrorInPreviewPdfFromCompanysServer ? (
                  <PDFpreview
                    onPreviewShow={onShowPdfPreview}
                    onLoadError={onErrorInPreviewPdfFromCompanysServer}
                    fileUrl={
                      evidenceUrl
                        ? evidenceUrl
                        : evidenceFileDetails
                        ? evidenceFileDetails.path
                        : ''
                    }
                    isUrlConfidential={evidenceFileDetails ? true : false}
                    pageNumber={pageNumber ? pageNumber : 0}
                    evidenceId={id}
                    fileId={evidenceFileDetails ? evidenceFileDetails.id : null}
                  />
                ) : (
                  pdfsScreenshotRedirectItem
                )}
              </Box>
            )}
          </Box>
          <ConfirmRemovalEvidenceDialog />
        </>
      )}
    </>
  )
}

export default SupportiveEvidenceRow
