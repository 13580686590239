import React, { useEffect, useReducer } from 'react'
import { useAppDispatch, useForm } from '../../hooks/hooks'
import { SurveysContext } from './surveys-context'
import { SurveysContextSchema, SurveysState } from './SurveysInterfaces'
import { SeasonalSurvey, Survey, SurveyDetails } from '../../types/interfaces'
import initialSurveysState from './initialSurveysState'
import {
  useCreateQuestionnaireFileMutation,
  useGetSurveysQuery,
} from '../../services/surveys'
import LoadingInsideComponent from '../../components/ui/LoadingInsideComponent/LoadingInsideComponent'
import ErrorView from '../../views/ErrorView'
import { appActions } from '../../store/App/app-slice'
import { Navigate } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material'
import { useGetSeasonalSurveysQuery } from '../../services/seasonalSurveys'

interface Props {
  children: React.ReactNode
}

enum SurveysActionKind {
  SET_SURVEYS_DATA = 'SET_SURVEYS_DATA',
  SET_SEASONAL_SURVEYS = 'SET_SEASONAL_SURVEYS',
  SET_IS_DIALOG_ADD_SURVEY_OPEN = 'SET_IS_DIALOG_ADD_SURVEY_OPEN',
  SET_ID_OF_SEASONAL_SURVEY_MODAL_OPEN = 'SET_ID_OF_SEASONAL_SURVEY_MODAL_OPEN',
  SET_FILE_DATA_TO_UPLOAD_SURVEY = 'SET_FILE_DATA_TO_UPLOAD_SURVEY',
  RESET_FILE_DATA_TO_UPLOAD = 'RESET_FILE_DATA_TO_UPLOAD',
  SET_SELECT_COMPLITION_YEAR_VALUE = 'SET_SELECT_COMPLITION_YEAR_VALUE',
  SET_DEADLINE_DATE = 'SET_DEADLINE_DATE',
  SET_CONFIDENCE_LEVEL = 'SET_CONFIDENCE_LEVEL',
  SET_ANSWERS_LENGTH = 'SET_ANSWERS_LENGTH',
  SET_TYPE_OF_EVIDENCE = 'SET_TYPE_OF_EVIDENCE',
  SET_TYPE_OF_AUDIENCE = 'SET_TYPE_OF_AUDIENCE',
}

interface SurveysAction {
  type: SurveysActionKind
  payload:
    | string
    | Survey[]
    | boolean
    | File
    | null
    | SurveysState['confidenceLevel']
    | SeasonalSurvey[]
}

const reducerFunction = (
  state: SurveysState,
  action: SurveysAction
): SurveysState => {
  const { type, payload } = action
  switch (type) {
    case SurveysActionKind.SET_SURVEYS_DATA:
      return {
        ...state,
        surveysData: payload as Survey[],
      }
    case SurveysActionKind.SET_IS_DIALOG_ADD_SURVEY_OPEN:
      return {
        ...state,
        isDialogAddSurveyOpen: payload as boolean,
      }
    case SurveysActionKind.SET_ID_OF_SEASONAL_SURVEY_MODAL_OPEN:
      return {
        ...state,
        idOfSeasonalSurveyModalOpen: payload as string,
      }
    case SurveysActionKind.SET_FILE_DATA_TO_UPLOAD_SURVEY:
      return {
        ...state,
        surveyToUploadFileData: payload as File,
      }
    case SurveysActionKind.RESET_FILE_DATA_TO_UPLOAD:
      return {
        ...state,
        surveyToUploadFileData: null,
      }
    case SurveysActionKind.SET_SELECT_COMPLITION_YEAR_VALUE:
      return {
        ...state,
        selectFieldComplitionYearValue: payload as string,
      }
    case SurveysActionKind.SET_DEADLINE_DATE:
      return {
        ...state,
        deadlineDate: payload as string,
      }
    case SurveysActionKind.SET_CONFIDENCE_LEVEL:
      return {
        ...state,
        confidenceLevel: payload as SurveysState['confidenceLevel'],
      }
    case SurveysActionKind.SET_ANSWERS_LENGTH:
      return {
        ...state,
        answersLength: payload as SurveysState['answersLength'],
      }
    case SurveysActionKind.SET_TYPE_OF_EVIDENCE:
      return {
        ...state,
        typeOfEvidence: payload as SurveysState['typeOfEvidence'],
      }
    case SurveysActionKind.SET_TYPE_OF_AUDIENCE:
      return {
        ...state,
        targetAudience: payload as SurveysState['targetAudience'],
      }
    case SurveysActionKind.SET_SEASONAL_SURVEYS:
      return {
        ...state,
        seasonalSurveys: payload as SeasonalSurvey[],
      }
    default:
      return state
  }
}

const SurveysContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducerFunction, initialSurveysState)
  const [renderForm] = useForm()
  const {
    data: surveysData,
    isSuccess: isSuccessSurveysData,
    isLoading: isLoadingSurveysData,
    isError: isErrorSurveysData,
    error: errorSurveysData,
    refetch: refetchSurveyData,
  } = useGetSurveysQuery()
  const [
    createQuestionnaire,
    {
      isSuccess: isSuccessInCreateQuestionnaire,
      isError: isErrorInCreateQuestionnaire,
      isLoading: isLoadingInCreateQuestionnaire,
      originalArgs: originalArgsInCreateQuestionnaire,
      reset: resetCreateQuestionare,
    },
  ] = useCreateQuestionnaireFileMutation()

  const {
    data: seasonalSurveysData,
    isSuccess: isSuccessSeasonalSurveysData,
    isLoading: isLoadingSeasonalSurveysData,
    isError: isErrorSeasonalSurveysData,
    error: errorSeasonalSurveysData,
  } = useGetSeasonalSurveysQuery()

  const dispatchStore = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatchStore(appActions.hideSnackbarNotification())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSuccessSurveysData && surveysData.length > 0) {
      const newSurveysState = surveysData.filter(surveyItem => {
        const { seasonal_survey } = surveyItem
        return seasonal_survey === null
      })
      dispatch({
        type: SurveysActionKind.SET_SURVEYS_DATA,
        payload: newSurveysState as Survey[],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSurveysData, surveysData])

  useEffect(() => {
    if (
      isSuccessSeasonalSurveysData &&
      seasonalSurveysData.length > 0 &&
      isSuccessSurveysData
    ) {
      const newSeasonalSurveysState: SeasonalSurvey[] = seasonalSurveysData.map(
        seasSur => {
          const { id, name } = seasSur
          const foundSeasonalItemInSurveys = surveysData.find(surveyItem => {
            const { seasonal_survey } = surveyItem
            return id === seasonal_survey?.id
          })
          if (foundSeasonalItemInSurveys) {
            if (foundSeasonalItemInSurveys.completed_file) {
              return { id, name, status: 'Ready' }
            } else {
              return { id, name, status: 'Processing' }
            }
          } else {
            return { id, name, status: null }
          }
        }
      )
      dispatch({
        type: SurveysActionKind.SET_SEASONAL_SURVEYS,
        payload: newSeasonalSurveysState,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSeasonalSurveysData, isSuccessSurveysData, surveysData])

  useEffect(() => {
    if (isErrorInCreateQuestionnaire) {
      dispatchStore(
        appActions.showSnackbarNotification({
          label: `Sorry, we couldn't upload Survey file, please try again later or contact support for assistance.`,
          type: 'error',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInCreateQuestionnaire])

  const onSetIsDialogAddSurveyOpenHandler = (isOpenValue: boolean) => {
    dispatch({
      type: SurveysActionKind.SET_IS_DIALOG_ADD_SURVEY_OPEN,
      payload: isOpenValue,
    })
  }

  const onSetFileDataToUploadHandler = (fileData: File) => {
    dispatch({
      type: SurveysActionKind.SET_FILE_DATA_TO_UPLOAD_SURVEY,
      payload: fileData,
    })
  }

  const onCancelSubmitHandler = () => {
    dispatch({
      type: SurveysActionKind.SET_IS_DIALOG_ADD_SURVEY_OPEN,
      payload: false,
    })
    dispatch({
      type: SurveysActionKind.SET_FILE_DATA_TO_UPLOAD_SURVEY,
      payload: null,
    })
  }

  const onSetSelectComplitionYearValueHandler = (
    e: SelectChangeEvent<string>
  ) => {
    const newValue = e.target.value
    if (newValue)
      dispatch({
        type: SurveysActionKind.SET_SELECT_COMPLITION_YEAR_VALUE,
        payload: newValue,
      })
  }

  const onChangeDeadlineDateHandler = (newValue: string) => {
    dispatch({
      type: SurveysActionKind.SET_DEADLINE_DATE,
      payload: newValue,
    })
  }

  const onChangeConfidenceLevelHandler = (newValue: string) => {
    dispatch({
      type: SurveysActionKind.SET_CONFIDENCE_LEVEL,
      payload: newValue as SurveysState['confidenceLevel'],
    })
  }

  const onChangeAsnwerLengthlHandler = (newValue: string) => {
    dispatch({
      type: SurveysActionKind.SET_ANSWERS_LENGTH,
      payload: newValue as SurveysState['answersLength'],
    })
  }

  const onChangeTypeOfEvidenceHandler = (newValue: string) => {
    dispatch({
      type: SurveysActionKind.SET_TYPE_OF_EVIDENCE,
      payload: newValue as SurveysState['typeOfEvidence'],
    })
  }

  const onChangeTypeOfAudienceHandler = (newValue: string) => {
    dispatch({
      type: SurveysActionKind.SET_TYPE_OF_AUDIENCE,
      payload: newValue as SurveysState['typeOfEvidence'],
    })
  }

  const renderAddSurveyFileFormHandler = () => {
    const onSubmitHandler = (e: React.SyntheticEvent) => {
      e.preventDefault()

      const target = e.target as typeof e.target & {
        surveyTitle: { value: string }
        surveySource: { value: string }
        contextOfSurveyAnswers: { value: string }
        comments: { value: string }
        customInternal: { value: string }
      }

      let deadlineDate = ''

      if (state.deadlineDate) {
        deadlineDate = new Date(state.deadlineDate).toUTCString()
      }

      let surveyDetailsData: SurveyDetails = {
        seasonal_survey_id: '',
        name: target.surveyTitle.value,
        source: target.surveySource.value,
        target_audience: state.targetAudience,
        internal_departments: target.customInternal
          ? target.customInternal.value
          : '',
        completion_years: state.selectFieldComplitionYearValue,
        deadline: deadlineDate,
        answers_context: target.contextOfSurveyAnswers.value,
        answers_length: state.answersLength,
        answers_confidence: state.confidenceLevel,
        evidence_type: state.typeOfEvidence,
        additional_info: target.comments.value,
      }

      if (state.surveyToUploadFileData) {
        createQuestionnaire({
          fileData: state.surveyToUploadFileData,
          surveyDetails: surveyDetailsData as SurveyDetails,
          surveyType: 'COMPLETED',
        })
      }
    }
    return renderForm({
      onSubmit: onSubmitHandler,
      onCancel: onCancelSubmitHandler,
      formElements: [
        { id: 'surveyTitle', label: 'Survey Title', type: 'text' },
        {
          id: 'surveySource',
          label: 'Survey Source',
          type: 'text',
          helper: `Specify the survey's source, e.g., customers, employees, investor relations department, or an ESG rating agency.`,
        },
        {
          id: 'targetAudience',
          label: 'Survey Target Audience',
          type: 'selectAudience',
          helper: 'Specify whom the responses should be directed to.',
          options: [
            { label: 'Investors', value: 'INVESTORS' },
            { label: 'Shareholders', value: 'SHAREHOLDERS' },
            { label: 'Regulators', value: 'REGULATORS' },
            { label: 'Customers', value: 'CUSTOMERS' },
            { label: 'Supply chain partners', value: 'SUPPLY_CHAIN_PARTNERS' },
            { label: 'Employees', value: 'EMPLOYEES' },
            {
              label: 'Non governmental organisations',
              value: 'NON_GOVERNMENTAL_ORGANISATIONS',
            },
            { label: 'Internal departments', value: 'INTERNAL_DEPARTMENTS' },
          ],
          currentValue: state.targetAudience,
          onChange: onChangeTypeOfAudienceHandler,
        },
        {
          id: 'completionYear',
          label: 'Completion Year',
          type: 'year',
          helper: 'Select the year for which the survey needs to be completed.',
          currentValue: state.selectFieldComplitionYearValue,
          onChange: onSetSelectComplitionYearValueHandler,
        },
        {
          id: 'contextOfSurveyAnswers',
          label: 'Context of survey answers',
          type: 'multiline',
          helper: `Is the survey asking about a specific product or service of yours, or specific aspects of operations?`,
        },
        {
          id: 'deadline',
          label: 'Survey Deadline',
          type: 'date',
          onChange: onChangeDeadlineDateHandler,
          currentValue: state.deadlineDate,
        },
        {
          id: 'confidenceLevel',
          label: 'Answers Confidence Level',
          type: 'select',
          options: [
            {
              label: 'Very high (70%-100%)',
              value: 'high',
            },
            {
              label: 'High - all responses that generated by EA',
              value: 'default',
            },
          ],
          onChange: onChangeConfidenceLevelHandler,
          currentValue: state.confidenceLevel,
        },
        {
          id: 'responseLength',
          label: 'Answers Length',
          type: 'select',
          options: [
            { value: 'long', label: 'Long (up to 1000 characters)' },
            { value: 'short', label: 'Short (up to 500 characters)' },
          ],
          currentValue: state.answersLength,
          onChange: onChangeAsnwerLengthlHandler,
        },
        {
          id: 'useOfEvidence',
          label: 'Use of Evidence',
          type: 'select',
          options: [
            { label: 'Only Public', value: 'PUBLIC' },
            { label: 'Only Private', value: 'PRIVATE' },
            { label: 'Public and private', value: 'BOTH' },
          ],
          onChange: onChangeTypeOfEvidenceHandler,
          currentValue: state.typeOfEvidence,
        },
        {
          id: 'comments',
          label: 'Additional Comments/Details',
          type: 'multiline',
        },
      ],
      isUploadSurveyLoading: isLoadingInCreateQuestionnaire,
    })
  }

  const onSetIdOfSeasonalSurveyModalOpenHandler = (id: string) => {
    dispatch({
      type: SurveysActionKind.SET_ID_OF_SEASONAL_SURVEY_MODAL_OPEN,
      payload: id as string,
    })
  }

  const ctxValue: SurveysContextSchema = {
    state: {
      surveysData: state.surveysData,
      surveyToUploadFileData: state.surveyToUploadFileData,
      isDialogAddSurveyOpen: state.isDialogAddSurveyOpen,
      idOfSeasonalSurveyModalOpen: state.idOfSeasonalSurveyModalOpen,
      selectFieldComplitionYearValue: state.selectFieldComplitionYearValue,
      deadlineDate: state.deadlineDate,
      confidenceLevel: state.confidenceLevel,
      answersLength: state.answersLength,
      typeOfEvidence: state.typeOfEvidence,
      targetAudience: state.targetAudience,
      seasonalSurveys: state.seasonalSurveys,
    },
    actions: {
      onSetIsDialogAddSurveyOpen: onSetIsDialogAddSurveyOpenHandler,
      onSetIdOfSeasonalSurveyModalOpen: onSetIdOfSeasonalSurveyModalOpenHandler,
      onSetFileDataToUpload: onSetFileDataToUploadHandler,
      renderUploadSurveyForm: renderAddSurveyFileFormHandler,
      onSubmitSurveyCancel: onCancelSubmitHandler,
      refetchSurveyData: refetchSurveyData,
    },
  }

  let content = children

  if (isErrorSurveysData) {
    return <ErrorView errorData={errorSurveysData} />
  }

  if (isLoadingSurveysData || isLoadingSeasonalSurveysData) {
    content = <LoadingInsideComponent />
  }

  if (
    isSuccessInCreateQuestionnaire &&
    originalArgsInCreateQuestionnaire?.surveyDetails.seasonal_survey === ''
  ) {
    console.log(originalArgsInCreateQuestionnaire)
    return <Navigate to={'/success-in-load-survey'} />
  }

  return (
    <SurveysContext.Provider value={ctxValue}>
      {content}
    </SurveysContext.Provider>
  )
}

export default SurveysContextProvider
