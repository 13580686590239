import React, { useState } from 'react'
import { Box, IconButton, useTheme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import ProfileSetUpMenu from '../ProfileSetUpMenu/ProfileSetUpMenu'
import { IProfileSetUpIcon } from '../../../types/interfaces'

interface Props extends IProfileSetUpIcon {
  onLogout: () => void
}

const ProfileSetUpIcon: React.FC<Props> = profileSetUpData => {
  const [isMenuExtended, setIsMenuExtended] = useState(false)
  const theme = useTheme()
  const { fullName, onLogout } = profileSetUpData

  const onClickHandler = (): void => {
    setIsMenuExtended(prevState => !prevState)
  }

  function stringAvatar(name: string) {
    let secondLetter = name.split(' ')[1][0]
    let firstLetter = name.split(' ')[0][0]
    let iconContent = firstLetter + secondLetter

    if (secondLetter === 'n') {
      iconContent = firstLetter
    }
    return {
      sx: {
        bgcolor: theme.palette.primary.main,
        cursor: 'pointer',
        margin: 0,
      },
      children: iconContent,
    }
  }
  return (
    <Box>
      <IconButton onClick={onClickHandler} sx={{ padding: 0 }}>
        <Avatar {...stringAvatar(fullName)} />
      </IconButton>
      {isMenuExtended && profileSetUpData && (
        <ProfileSetUpMenu
          {...profileSetUpData}
          onCloseMenu={onClickHandler}
          onLogout={onLogout}
        />
      )}
    </Box>
  )
}

export default ProfileSetUpIcon
