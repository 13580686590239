import { AlertProps } from '@mui/material'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface AppState {
  currentViewTitle: string
  isLeftMenuExtended: boolean
  isAppLoading: boolean
  isDemoMode: boolean
  errors: string[]
  dashboardRequireRefetch: boolean
  isModalOpen: boolean
  topNotificationState: {
    isDisplayed: boolean
    type: AlertProps['severity'] | null
    label: null | string
  }
  snackbarNotificationState: {
    isDisplayed: boolean
    type: AlertProps['severity'] | null
    label: null | string
  }
}

const initialState: AppState = {
  currentViewTitle: '',
  isLeftMenuExtended: true,
  isAppLoading: false,
  isDemoMode: false,
  errors: [],
  dashboardRequireRefetch: false,
  isModalOpen: false,
  topNotificationState: { isDisplayed: false, type: null, label: null },
  snackbarNotificationState: {
    isDisplayed: false,
    type: null,
    label: null,
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    unExtendLeftMenu: state => {
      state.isLeftMenuExtended = false
    },
    extendLeftMenu: state => {
      state.isLeftMenuExtended = true
    },
    setLoadingOn: state => {
      state.isAppLoading = true
    },
    setLoadingOff: state => {
      state.isAppLoading = false
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errors.push(action.payload)
    },
    clearErrors: state => {
      state.errors = []
    },
    setModalOpen: state => {
      state.isModalOpen = true
    },
    setModalClose: state => {
      state.isModalOpen = false
    },
    showTopNotification: (
      state,
      action: PayloadAction<{
        label: string
        type: string | AlertProps['severity']
      }>
    ) => {
      state.topNotificationState.isDisplayed = true
      state.topNotificationState.label = action.payload.label
      state.topNotificationState.type = action.payload
        .type as AlertProps['severity']
    },
    hideTopNotification: state => {
      state.topNotificationState.isDisplayed = false
      state.topNotificationState.label = null
      state.topNotificationState.type = null
    },
    showSnackbarNotification: (
      state,
      action: PayloadAction<{
        label: string
        type: AlertProps['severity']
      }>
    ) => {
      state.snackbarNotificationState.isDisplayed = true
      state.snackbarNotificationState.label = action.payload.label
      state.snackbarNotificationState.type = action.payload
        .type as AlertProps['severity']
    },
    hideSnackbarNotification: state => {
      state.snackbarNotificationState.isDisplayed = false
      state.snackbarNotificationState.label = null
      state.snackbarNotificationState.type = null
    },
    switchOnDemoMode: state => {
      state.isDemoMode = true
    },
    switchOffDemoMode: state => {
      state.isDemoMode = false
    },
    setDashboardRequireRefetch: state => {
      state.dashboardRequireRefetch = true
    },
    setDashboardNotRequireRefetch: state => {
      state.dashboardRequireRefetch = false
    },
    setCurrentViewTitle: (state, action: PayloadAction<string>) => {
      state.currentViewTitle = action.payload
    },
  },
})

export const appActions = appSlice.actions

export default appSlice.reducer
