import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  useTheme,
  Link,
  LinearProgress,
  Alert,
  Chip,
} from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { Evidence } from '../../../../types/interfaces'
import { processFileNameFromUrl, processMonth } from '../../../../utils/utils'
import DeleteEvidenceIcon from '../DeleteEvidenceIcon/DeleteEvidenceIcon'
import CellInTable from '../../../ui/tables/CellInTable/CellInTable'
import { useGetAccessToFileMutation } from '../../../../services/file'
import CellInNewTable from '../../../ui/tables/NewDesignUniversalTable/CellInNewTable/CellInNewTable'
import { OpenInNew } from '@mui/icons-material'
import { useAppSelector } from '../../../../hooks/hooks'
import { convertDateToNiceFormat } from '../../../../utils/utils'

interface Props extends Evidence {
  showDelEviSuccess: () => void
}

const EvidenceLibraryItem: React.FC<Props> = ({
  file,
  id,
  url,
  user_id,
  category,
  showDelEviSuccess,
  year_applicable_from,
  updated_at,
  type,
  parsed_at,
}) => {
  const theme = useTheme()

  const { role } = useAppSelector(state => state.user)

  let evidenceHeading = ''

  if (category) {
    evidenceHeading = category
  } else if (file) {
    const { friendly_name, original_file_name, path } = file
    if (friendly_name) {
      evidenceHeading = friendly_name
    } else if (original_file_name) {
      evidenceHeading = original_file_name
    } else {
      evidenceHeading = processFileNameFromUrl(path)
    }
  } else if (url) {
    evidenceHeading = url
  }

  const [delEviErrorState, setDelEviErrorState] = useState<{
    isShown: boolean
    label: string
  }>({
    isShown: false,
    label: '',
  })

  const [
    getAccessToEvidenceFile,
    {
      isError: isErrorInGetAccessToEvidenceFile,
      isSuccess: getAccessToEvidenceFileIsSuccess,
      isLoading,
      data,
      reset: resetGetAccessToEvidence,
    },
  ] = useGetAccessToFileMutation()

  const { isSuperUser } = useAppSelector(state => state.user)

  useEffect(() => {
    if (getAccessToEvidenceFileIsSuccess && data) {
      const { url, path } = data

      let isPdfFile: boolean = false
      if (path && path.lastIndexOf('.pdf') !== -1) {
        isPdfFile = true
      }
      window.open(url, isPdfFile ? '_blank' : '_self', 'noreferrer')
    }
  }, [getAccessToEvidenceFileIsSuccess, data])

  useEffect(() => {
    if (isErrorInGetAccessToEvidenceFile) {
      const timer = setTimeout(() => {
        resetGetAccessToEvidence()
      }, 7000)

      return () => clearTimeout(timer)
    }
  }, [isErrorInGetAccessToEvidenceFile, resetGetAccessToEvidence])

  useEffect(() => {
    if (delEviErrorState.isShown) {
      const timer = setTimeout(() => {
        setIsDelEviErrorShownFalseHandler()
      }, 6000)

      return () => clearTimeout(timer)
    }
  }, [delEviErrorState.isShown])

  const isEvidenceAddedByUser = user_id !== 'research@ethicsgrade.io'

  const getAccessToEvidenceHandler = () => {
    if (file) {
      getAccessToEvidenceFile(file?.id)
    }
  }

  const setIsDelEviErrorShownTrueHandler = (label: string): void => {
    setDelEviErrorState({ isShown: true, label })
  }

  const setIsDelEviErrorShownFalseHandler = (): void => {
    setDelEviErrorState({ isShown: false, label: '' })
  }

  let dateToDisplay = ''
  let deleteIconToDisplay = <span></span>

  const date = new Date(updated_at)
  const year = date.getFullYear()
  const month = processMonth(date.getMonth())
  const day = date.getDate()
  dateToDisplay = `${year}-${month}-${day}`
  deleteIconToDisplay = (
    <DeleteEvidenceIcon
      id={id}
      showDelEviSuccess={showDelEviSuccess}
      showDelEviError={setIsDelEviErrorShownTrueHandler}
    />
  )

  const labelType = `${type[0]}${type.slice(-type.length + 1).toLowerCase()}`

  return (
    <>
      <TableRow>
        <CellInNewTable align="left" sx={{ verticalAlign: 'center' }}>
          {isLoading && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                mr: '20px',
                width: '80%',
              }}
            />
          )}
          {isErrorInGetAccessToEvidenceFile && (
            <Alert
              severity="error"
              sx={{
                m: 0,
              }}
              onClose={() => {
                resetGetAccessToEvidence()
              }}
            >
              Sorry, I couldn't get access to the file preview. Please try
              again, if the problem still persists, please contact us.
            </Alert>
          )}
          {delEviErrorState.isShown ? (
            <Alert
              severity="error"
              sx={{
                m: 0,
              }}
              onClose={setIsDelEviErrorShownFalseHandler}
            >
              {delEviErrorState.label}
            </Alert>
          ) : file && !isErrorInGetAccessToEvidenceFile ? (
            <Typography
              onClick={getAccessToEvidenceHandler}
              variant="body1"
              sx={{
                m: 0,
                p: 0,
                cursor: 'pointer',
                color: theme.palette.primary.main,
                lineHeight: '24px',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {evidenceHeading} <OpenInNew sx={{ ml: '10px' }} />
            </Typography>
          ) : (
            url && (
              <Link
                href={url}
                target="_blank"
                sx={{
                  fontSize: '20px',
                  lineHeight: '24px',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {evidenceHeading}

                <OpenInNew sx={{ ml: '10px' }} />
              </Link>
            )
          )}
          {isSuperUser && <Typography>{id}</Typography>}
        </CellInNewTable>
        <CellInNewTable
          align="center"
          sx={{ whiteSpace: 'nowrap', verticalAlign: 'center' }}
        >
          <Chip
            size="small"
            sx={{
              backgroundColor:
                labelType === 'Private'
                  ? theme.palette.secondary.contrastText
                  : theme.palette.secondary.main,
            }}
            label={labelType}
          />
        </CellInNewTable>
        <CellInNewTable
          align="center"
          sx={{ whiteSpace: 'nowrap', verticalAlign: 'center' }}
          label={year_applicable_from ? year_applicable_from.toString() : ''}
        />

        <CellInNewTable
          align="center"
          sx={{ whiteSpace: 'nowrap', verticalAlign: 'center' }}
          label={dateToDisplay ? dateToDisplay : ''}
        />
        {role === 'STUFF' && (
          <CellInNewTable align="center" sx={{ verticalAlign: 'center' }}>
            {parsed_at === null ? (
              <Typography sx={{ color: 'red' }}>Not parsed</Typography>
            ) : (
              <Typography>{convertDateToNiceFormat(parsed_at)}</Typography>
            )}
          </CellInNewTable>
        )}
        <CellInNewTable align="center" sx={{ verticalAlign: 'center' }}>
          {isEvidenceAddedByUser ? (
            deleteIconToDisplay
          ) : (
            <Box sx={{ height: '40px' }}></Box>
          )}
        </CellInNewTable>
      </TableRow>
    </>
  )
}

export default EvidenceLibraryItem
