import React, { useEffect } from 'react'
import { Alert, AlertTitle, Box, Collapse, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  isSuccess: boolean
  alertContent: string
}

const SuccessAlert: React.FC<Props> = ({ isSuccess, alertContent }) => {
  const [open, setOpen] = React.useState(isSuccess)

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false)
    }, 6000)

    return () => clearTimeout(timer)
  }, [isSuccess])

  if (!isSuccess) {
    return null
  }

  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        <AlertTitle>Success</AlertTitle>
        {alertContent}
      </Alert>
    </Collapse>
  )
}

export default SuccessAlert
