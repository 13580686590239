import { SvgIconTypeMap } from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import BackupIcon from '@mui/icons-material/Backup'
import PostAddIcon from '@mui/icons-material/PostAdd'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import TaskIcon from '@mui/icons-material/Task'
import { AnswerItem } from '../../types/interfaces'
import { AnswerLibraryTableKeySortType } from '../../types/types'

export const emailSwap = `[supportEmail]`

const createEvidenceProvidedStep = (
  title: string,
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  },
  heading: string,
  content: string,
  path: string,
  buttonLabel: string
) => {
  return {
    title,
    icon,
    heading,
    content,
    path,
    buttonLabel,
  }
}

const createEvidenceNotProvidedStep = (
  title: string,
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  },

  content: string
) => {
  return {
    title,
    icon,
    content,
  }
}

const content = {
  config: {
    supportEmail: 'support@ethicsanswer.com',
  },
  eng: {
    views: {
      auth0Loading: {
        title: 'Loading...',
      },
      auth0Error: {
        title: 'Authorisation Error',
        heading:
          'Something went wrong. Please try again, if a problem still persists, please contact us.',
      },
      errorView: {
        title: 'Your account is under maintenance:',
        heading: `We apologize for the inconvenience. Your account is currently undergoing maintenance to provide you with an even better experience. Please try again later. If you have any urgent concerns, please contact our support team at ${emailSwap}. Thank you for your patience!`,
      },
      welcome: {
        title: 'Ask Any ESG Question',
        heading:
          'EthicsAnswer is a SaaS platform using Generative AI to complete ESG surveys quickly and accurately.',
        introduction:
          'EthicsAnswer takes the manual search, copy and paste out of responding to ESG surveys and other disclosure requests. Simply upload a survey or answer individual questions on our platform and receive completed surveys in 48 hours or individual questions instantly.',
      },
      accountProfile: {
        title: 'Ask Any ESG Question',
        heading: 'Account Profile',
        introduction:
          'Provide the following information to complete your account profile.',
        astriskInfo: '* Any fields indicated with an asterisk is required',
      },
      waitingForAuthorisation: {
        title: 'Ask Any ESG Question',
        heading: 'Waiting for Authorisation',
        introduction: 'Thank you for signing up for EthicsAnswer!',
        paragraphs: [
          `To ensure the security of our platform we're verifying your account manually, don't worry we know when you sign up for a solution like EthicsAnswer it's because you need a solution to a problem you have now, so we won't keep you waiting. Keep an eye on your inbox, one of our team will be in touch very soon to discuss how we can support you in completing an ESG survey while the verification process takes place.`,
          `For questions or assistance, please reach out to us at`,
          `We're ready to help!`,
        ],
        email: 'support@ethicsanswer.com',
      },
      getStarted: {
        title: 'Get Started',
        heading: 'Get Started',
        introduction: 'Let EthicsAnswer support your ESG-related requests. ',
        evidenceProvidedElements: [
          // createEvidenceProvidedStep(
          //   'Ask a question',
          //   QuestionMarkIcon,
          //   'Do you have an ESG related question from an investor?',
          //   'EthicsAnswer will provide answers to any ESG question. We will use publicly available documents and ones you`ve uploaded into your evidence library to generate answers to your questions. ',
          //   '/ask-question',
          //   'ask'
          // ),
          createEvidenceProvidedStep(
            'Upload survey',
            BackupIcon,
            'Do you have a multi-question ESG survey you need to complete?',
            'Save time and effort by uploading your whole survey. EthicsAnswer will analyse the questions and provide you with answers you can review and customise.',
            '/surveys/uploadMode',
            'upload'
          ),
          createEvidenceProvidedStep(
            'Add evidence',
            PostAddIcon,
            'Do you have additional documents to use as evidence in answering ESG questions?',
            'EthicsAnswer will have your publicly available documents ready to use as evidence. You can add additional public or private documents.',
            '/evidence-library',
            'add'
          ),
        ],
        evidenceNotProvidedElements: [
          createEvidenceNotProvidedStep(
            'Upload',
            BackupIcon,
            'You can upload your ESG survey questions as PDF, XLSX or DOCX format.'
          ),
          createEvidenceNotProvidedStep(
            'Analysis',
            ManageSearchIcon,
            'EthicsAnswer will analyze the questions, and formulate responses.'
          ),
          createEvidenceNotProvidedStep(
            'Responses',
            TaskIcon,
            'EthicsAnswer will present responses back to you for review. '
          ),
        ],
      },
      home: {
        title: 'Dashboard',
        evidenceProvidedElements: [
          createEvidenceProvidedStep(
            'Ask a question',
            QuestionMarkIcon,
            'Do you have an ESG related question from an investor?',
            'EthicsAnswer will provide answers to any ESG question. We will use publicly available documents and ones you`ve uploaded into your evidence library to generate answers to your questions. ',
            '/ask-question',
            'ask'
          ),
          createEvidenceProvidedStep(
            'Upload survey',
            BackupIcon,
            'Do you have a multi-question ESG survey you need to complete?',
            'Save time and effort by uploading your whole survey. EthicsAnswer will analyse the questions and provide you with answers you can review and customise.',
            '/ask-question/multipleMode',
            'upload'
          ),
          createEvidenceProvidedStep(
            'Add evidence',
            PostAddIcon,
            'Do you have additional documents to use as evidence in answering ESG questions?',
            'EthicsAnswer will have your publicly available documents ready to use as evidence. You can add additional public or private documents.',
            '/evidence-library',
            'add'
          ),
        ],
        evidenceNotProvidedElements: [
          createEvidenceNotProvidedStep(
            'Upload',
            BackupIcon,
            'You can upload your ESG survey questions as PDF, XLSX or DOCX format.'
          ),
          createEvidenceNotProvidedStep(
            'Analysis',
            ManageSearchIcon,
            'EthicsAnswer will analyze the questions, and formulate responses.'
          ),
          createEvidenceNotProvidedStep(
            'Responses',
            TaskIcon,
            'EthicsAnswer will present responses back to you for review. '
          ),
        ],
      },
      loadSurvey: {
        heading: 'Upload Survey',
        introduction:
          'Get answers to any ESG question by referencing your Evidence Library and formulating the answer in a format that you can use to respond to survey questions.',
      },
      loadSurveySuccess: {
        heading: 'Survey upload complete',
      },
      askQuestion: {
        title: 'Ask Any ESG Question',
        heading: 'Ask a question',
        introduction:
          'Get answers to any ESG question by referencing your Evidence Library and formulating the answer in a format that you can use to respond to survey questions.',
        tabs: ['Single Question', 'Upload survey'],
        questionExamplesTitle: 'Question examples:',
        questionExamplesItems: [
          'What were your absolute Scope 1 emissions in 2022?',
          'What was the number of women in the total workforce last year?',
          'Do you provide employee training?',
          'How do you handle bribery or corruption?',
        ],
        errorTitleAfterSubmissionForm: 'Question Submission Error',
        errorAfterSubmissionForm:
          "Sorry, we couldn't generate a response for your question at the moment. Please try again later or contact support for assistance.",
      },
      loadingViewForGenerateQuestion: {
        title: 'Generating the answer...',
        introduction: 'Please wait while we analyze the evidence.',
        additionalInfo:
          'Please do not refresh the page. This may take a few seconds...',
      },
      similarQuestions: {
        title: 'Review response',
        heading: 'We found similar questions from your previous answers',
        tableHeaderCells: [
          {
            label: 'Question',
            id: 'question',
            keyToSort: null,
          },
          {
            label: 'Source',
            id: 'source',
            keyToSort: 'source',
          },
          {
            label: 'Status',
            id: 'status',
            keyToSort: null,
          },
          {
            label: 'Last updated',
            id: 'lastUpdated',
            keyToSort: null,
          },
          {
            label: '',
            id: 'actions',
            keyToSort: null,
          },
        ],
        infoBlockedEditor: `If none of these responses satisfy your question, you can ask EthicsAnswer to generate a new response below. Your account is in LLM learning mode therefore EthicsAnswer's ESG Analyst team is revising each newly generated answer. We will notify you by email when your newly generated answer will be ready to view, this process usually takes less than 24 hours.`,
        infoEditor: `If none of these questions satisfy your question, you can generate a new answer.`,
        buttonTextEditor: 'Generate new answer',
        infoViewer:
          'If none of these responses satisfy your question, you can request a new answer be generated by your ESG team.',
        buttonTextViewer: 'Request new answer',
      },
      questionResponse: {
        title: 'Ask Any ESG Question',
        heading: 'Question Response',
        confidence: 'Confidence',
        detailedResponse: 'Detailed Response',
        bestEvidence: 'Best Supporting Evidence',
        page: 'Page',
        recomendationQuestion: 'Did this response answer your question?',
      },
      questionResponseWithFocusOnEvidence: {
        title: 'Ask Any ESG Question',
        heading: 'Response Evidence',
        introduction:
          'Below is a list of references pertinent to your question found in the documents in the Evidence Library in order of relevance. You can choose keep only the items that is most material to the question before you saving to My Answers.',
        responseHeader: 'Response draft based on public data',
        responseHeaderIncludePrivate:
          'Response draft refined with private evidence.',
        responseSubHeader:
          'This response has been generated using the evidence listed below.',
        responseInfo:
          "Editing and saving responses enhances EthicsAnswer's future performance.",
      },
      evidenceLibrary: {
        title: 'Ask Any ESG Question',
        heading: 'Evidence',
        addPubliceEvidenceButtonHeading: 'Add a public evidence',
        addPrivateEvidenceButtonHeading: 'Upload a document',
        evidenceAddedByUser: {
          title: 'My Documents',
          introduction:
            'These documents are private and not shared outside EthicsAnswer. These documents will are referenced in answering your questions.',
        },
        evidencePublicSection: {
          title: 'Public Documents',
          introduction:
            'These documents have been collected from public sources. You can add your additional or latest versions of publicly available documents. These documents will are referenced in answering your questions.',
        },
      },
      myAnswers: {
        title: 'Ask Any ESG Question',
        heading: 'Saved Answers',
        searchResultHeading: 'Search results for ',
        infoWhenHistoryIsEmpty:
          'Currently empty, it will store previously saved responses. Interact with the app to populate it.',
        infoInCaseOfEmptySearchResult:
          'We apologize, but it seems that there are no results matching your search criteria.',
      },
      answersLibrary: {
        heading: 'Answers library',
        tableHeaderCells: [
          {
            label: '',
            id: 'select',
            keyToSort: null,
          },
          {
            label: 'Status',
            id: 'status',
            keyToSort: 'status' as AnswerLibraryTableKeySortType,
          },
          {
            label: 'Question',
            id: 'question',
            keyToSort: null,
          },
          {
            label: 'Source',
            id: 'source',
            keyToSort: 'source',
          },
          {
            label: 'Reviewer',
            id: 'reviewer',
            keyToSort: null,
          },

          {
            label: 'Last updated',
            id: 'lastUpdated',
            keyToSort: 'lastUpdated' as AnswerLibraryTableKeySortType,
          },
          {
            label: '',
            id: 'actions',
            keyToSort: null,
          },
        ],
        searchResultHeading: 'Search results for ',
        infoWhenHistoryIsEmpty:
          'Currently empty, it will store previously saved responses. Interact with the app to populate it.',
        infoInCaseOfEmptySearchResult:
          'We apologize, but it seems that there are no results matching your search criteria.',
      },
      mySurveys: {
        title: 'Surveys',
        infoWhenSurveysListIsEmpty:
          'Currently empty, it will store previously uploaded surveys. Interact with the app to populate it.',
      },
      history: {
        title: 'Ask Any ESG Question',
        heading: 'History',
        searchResultHeading: 'Search results for ',
        infoWhenHistoryIsEmpty:
          'Currently empty, it will store previously generated responses. Interact with the app to populate it.',
        infoInCaseOfEmptySearchResult:
          'We apologize, but it seems that there are no results matching your search criteria.',
      },
      termsAndConditions: {
        title: 'Terms and Conditions',
        heading: 'ETHICSANSWER - Terms of Use and Service',
      },
    },
    components: {
      addDocumentDialog: {
        info: 'Provide the link or locate the public document you wish to add to the Evidence Library.',
        private: {
          title: 'Add a private document',
        },
        public: {
          title: 'Add a public document',
        },
      },
      confirmRemovalEvidenceDialog: {
        title: 'Are you sure you want to remove this evidence?',
        info: 'We will only remove this evidence from this response. It will remain in your Evidence Library and it will continue to be used to answer other questions.',
        successTitle: 'The evidence was successfully removed.',
        errorTitle: `Sorry we couldn't delete evidence, please try again!`,
      },
      forms: {
        accountProfileForm: {
          personalPartTitle: 'Your information',
          rolePartTitle: 'Your role',
          radioButtonsLabel: 'Select all that apply:',
          consent:
            'I confirm that the company details provided in the above form are accurate, and I am an authorised representative of this company. By clicking submit below, I consent to allow EthicsGrade to store and process my personal information.',
          termsAndConditions:
            'I have read and accept the terms and conditions of use.',
          errorTitleAfterSubmission: 'Submission Failed',
          errorAfterSubmission:
            'We encountered an issue while submitting your information. Try again in a few minutes. If the problem persists please contact us.',
          success: 'Success! Your profile has been updated.',
        },
        generateResponseForm: {
          errorTitleAfterSubmissionForm: 'Question Submission Issue',
          errorAfterSubmissionForm:
            "Sorry, we couldn't generate a response for your question at the moment. Please try again later or contact support for assistance.",
        },
      },
      evidenceTable: {
        infoInCaseOfEmpty:
          'There is currently no documents in the Evidence Library.',
        tableHeaderCells: [
          { label: 'Title', id: 'title', keyToSort: 'category' },
          { label: 'Type', id: 'type', keyToSort: 'type' },
          {
            label: 'Year',
            id: 'year',
            keyToSort: 'year_applicable_to',
          },
          { label: 'Date Added', id: 'dateAdded', keyToSort: 'updated_at' },
          { label: 'Parsed', id: 'parsed', keyToSort: null },
          { label: 'Actions', id: 'actions', keyToSort: null },
        ],
      },
      surveysTable: {
        infoInCaseOfEmpty:
          'There is currently no documents in the Evidence Library.',
        tableHeaderCells: [
          { label: 'Title', id: 'title', keyToSort: 'file' },
          { label: 'Date added', id: 'dateAdded', keyToSort: null },
          { label: 'Status', id: 'status', keyToSort: null },
          { label: 'Review answers', id: 'review', keyToSort: null },
          { label: ' ', id: 'download', keyToSort: null },
        ],
      },
      membersTable: {
        tableHeaderCells: [
          { label: 'Name', id: 'name', keyToSort: 'name' },
          { label: 'Email', id: 'email', keyToSort: 'email' },
          // { label: 'Last active', id: 'Last active', keyToSort: 'lastActive' },
          { label: 'Role', id: 'role', keyToSort: null },
          { label: 'Actions', id: 'actions', keyToSort: null },
        ],
      },
      preFooter: {
        title: 'Ask Any ESG question',
      },
      aksQuestionError: {
        errorTitle: 'Question Submission Issue',
        errorExplanation: `We're experiencing exceptionally high demand. Please hang tight as we work on scaling our systems.`,
        // errorExplanation:
        //   "Sorry, we couldn't generate a response for your question at the moment. Please try again later or contact support for assistance.",
      },
    },
    buttons: {
      signUp: 'Sign up',
      login: 'Login',
      formSubmit: 'Submit',
      checkAuthorisationStatus: 'Check Authorisation Status',
      search: 'Search',
      update: 'Update',
      uploadFile: 'Upload file',
      add: 'Add',
      remove: 'Remove',
      cancel: 'Cancel',
      generateNewAnswer: 'Generate new answer',
    },
  },
}

export default content
