import React, { useEffect } from 'react'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import AccountProfileForm from '../components/forms/AccountProfileForm/AccountProfileForm'
import { useGetUserDetailsQuery } from '../services/users'
import Auth0Loading from './Loading'
import ErrorView from './ErrorView'
import { useTriggerSignedUpAndProfileDetailsAccessedMutation } from '../services/hubSpotUserTracking'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { appActions } from '../store/App/app-slice'

const AccountProfile: React.FC = () => {
  const { isLoading, data, isError, error } = useGetUserDetailsQuery()
  const { userEmail } = useAppSelector(state => state.user)
  const [registerSignUp] = useTriggerSignedUpAndProfileDetailsAccessedMutation()

  const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')

  const dispatch = useAppDispatch()
  const theme = useTheme()

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Account Profile'))
  }, [])

  useEffect(() => {
    if (data && isProduction && userEmail) {
      registerSignUp({ fields: [{ name: 'email', value: userEmail }] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isProduction])

  const {
    eng: {
      views: {
        accountProfile: { heading, introduction, astriskInfo },
      },
    },
  } = content

  if (isLoading) {
    return <Auth0Loading />
  }

  if (isError) {
    return <ErrorView errorData={error} />
  }

  if (data) {
    return (
      <MainContentContainer maxWidth="xl">
        <Box component={'article'} sx={{ mb: theme.spacing(2.5) }}>
          <Typography>{introduction}</Typography>
          <Typography variant={'subtitle1'}>{astriskInfo}</Typography>
        </Box>
        <AccountProfileForm {...data} />
      </MainContentContainer>
    )
  }

  return <CircularProgress />
}

export default AccountProfile
