import React, { useContext, useEffect, useState } from 'react'
import AppDialog from '../../../../ui/HOC/AppDialog/AppDialog'
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material'
import content from '../../../../../assets/content/content'
import UniversalButton from '../../../../ui/Buttons/UniversalButton'
import { Close } from '@mui/icons-material'

import { SupportiveEvidenceContext } from '../../../../../contexts/SupportiveEvidenceContext/supportive-evidence-contex'

const ConfirmRemovalEvidenceDialog: React.FC = () => {
  const theme = useTheme()

  const {
    state: { isRemoveEvidenceDialogOpen },
    results: { deleteSupportiveEvidence },
    actions: {
      onClickRemoveEvidenceCheckbox,
      onClickRemoveSupportiveEvidenceAprovalButton,
    },
  } = useContext(SupportiveEvidenceContext)

  let {
    eng: {
      components: {
        confirmRemovalEvidenceDialog: { title, info, successTitle, errorTitle },
      },
      buttons: { cancel, remove },
    },
  } = content

  let mainContent: JSX.Element = (
    <>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {info}
        </DialogContentText>
      </DialogContent>
      <Box>
        <Stack
          direction={'row'}
          spacing={theme.spacing(3)}
          justifyContent="center"
          alignItems="center"
        >
          <UniversalButton
            loading={deleteSupportiveEvidence.isLoading}
            label={remove}
            onClick={onClickRemoveSupportiveEvidenceAprovalButton}
          />
          <UniversalButton
            stylingType={4}
            label={cancel}
            onClick={onClickRemoveEvidenceCheckbox}
          />
        </Stack>
      </Box>
    </>
  )

  let titleColor = 'inherit'

  return (
    <AppDialog
      isDialogOpen={isRemoveEvidenceDialogOpen}
      onCloseDialog={onClickRemoveEvidenceCheckbox}
    >
      <DialogTitle
        color={titleColor}
        sx={{ m: 0, paddingTop: 0 }}
        id="alert-dialog-title"
      >
        {title}
      </DialogTitle>
      {mainContent}
    </AppDialog>
  )
}

export default ConfirmRemovalEvidenceDialog
