import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  IQuestionnaire,
  IQuestionnairePreview,
  IReadyQuestionnairePreview,
  IReadyQuestionnaireAnnotatedPreview,
  Survey,
  SurveyDetails,
} from '../types/interfaces'
import type { RootState } from '../store/store'
import { UploadSurveyType } from '../types/types'

export const surveysApi = createApi({
  reducerPath: 'surveysApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/surveys`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['Surveys'],
  endpoints: build => ({
    getSurveys: build.query<Survey[], void>({
      query: () => ({
        url: `/`,
      }),
      providesTags: ['Surveys'],
    }),
    createQuestionnaireFile: build.mutation<
      Survey,
      {
        fileData?: File
        surveyDetails: SurveyDetails
        surveyType?: UploadSurveyType
      }
    >({
      query: ({ fileData, surveyDetails, surveyType }) => {
        const form = new FormData()
        Object.keys(surveyDetails).forEach(function (key) {
          const value = surveyDetails[key]
          if (value) {
            form.append(key, value as string)
          }
        })
        if (surveyType === 'TO_GENERATE' && fileData) {
          form.append('file', fileData)
        } else if (fileData) {
          form.append('completed_file', fileData)
        }

        return {
          url: `/`,
          method: 'POST',
          body: form,
        }
      },
      invalidatesTags: ['Surveys'],
    }),
    getQuestionnairePreview: build.mutation<
      IQuestionnairePreview,
      { questionnaireId: string; username: string }
    >({
      query: ({ questionnaireId, username }) => ({
        url: `get_questionnaire`,
        method: 'GET',
        params: { id: questionnaireId, username },
      }),
    }),
    getAccessToCompletedQuestionnaire: build.mutation<
      IReadyQuestionnairePreview,
      { questionnaireId: string; username: string }
    >({
      query: ({ questionnaireId, username }) => ({
        url: `get_completed_questionnaire`,
        method: 'GET',
        params: { id: questionnaireId, username },
      }),
    }),
    getAccessToCompletedAnnotatedQuestionnaire: build.mutation<
      IReadyQuestionnaireAnnotatedPreview,
      { questionnaireId: string; username: string }
    >({
      query: ({ questionnaireId, username }) => ({
        url: `get_completed_annotated_questionnaire`,
        method: 'GET',
        params: { id: questionnaireId, username },
      }),
    }),
    deleteQuestionnaire: build.mutation<
      { message: string; unique_com_evidence_id: string },
      { username: string; questionareId: IQuestionnaire['id'] }
    >({
      query: ({ username, questionareId }) => ({
        url: `questionnaire/${questionareId}/`,
        method: 'DELETE',
        params: {
          username: username,
        },
      }),
      invalidatesTags: ['Surveys'],
    }),
    generateSurveyAnotationReport: build.mutation<
      { id: string; url: string },
      { questionareId: IQuestionnaire['id']; type: 'pdf' | 'excel' }
    >({
      query: ({ questionareId, type }) => ({
        url: `/${questionareId}/annotation`,
        method: 'GET',
        params: {
          fmt: type,
        },
      }),
      invalidatesTags: ['Surveys'],
    }),
  }),
})

export const {
  useGetSurveysQuery,
  useCreateQuestionnaireFileMutation,
  useGetQuestionnairePreviewMutation,
  useGetAccessToCompletedQuestionnaireMutation,
  useGetAccessToCompletedAnnotatedQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useGenerateSurveyAnotationReportMutation,
} = surveysApi
