import React, { useContext } from 'react'
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useGetCompanyUsersQuery } from '../../../../services/users'
import { AnswersLibraryContext } from '../../../../contexts/AnswersLibraryContext/answers-library-context'
import UniversalButton from '../../../ui/Buttons/UniversalButton'

interface Props {}

const AssingReviewerContainer: React.FC<Props> = () => {
  const { data: companyUsers, isLoading: isLoadingCompanyUser } =
    useGetCompanyUsersQuery()

  const {
    state: { assignedReviewer, selectedIdsOfResponses },
    bulkQueryResults: { isLoadingAssignReviewer },
    actions: {
      onChangeSelectedReviewer,
      onToggleIsAssignReviewerMode,
      onSubmitBulkAssigment,
      onFilterChange,
    },
  } = useContext(AnswersLibraryContext)
  const theme = useTheme()
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let selectReviewerJSXitem = <CircularProgress />

  if (companyUsers) {
    let selectReviewersOptions = companyUsers
      .filter(user => {
        const { role } = user

        return role !== 'VIEWER' && role !== 'STUFF'
      })
      .map(user => {
        const { id, first_name, last_name, role } = user
        let lastName = last_name !== null ? last_name : ''
        return (
          <MenuItem key={id} value={id}>{`${first_name} ${lastName}`}</MenuItem>
        )
      })

    selectReviewerJSXitem = (
      <Select
        sx={{ bgcolor: 'white' }}
        labelId="bulk_reviewer"
        id="select-reviewer"
        size="small"
        defaultValue={'select'}
        value={assignedReviewer}
        onChange={onChangeSelectedReviewer}
      >
        <MenuItem value={'select'} key={'select'}>
          Select reviewer
        </MenuItem>
        <MenuItem value={'unassigned'} key={'unassigned'}>
          Unassigned
        </MenuItem>
        {selectReviewersOptions}
      </Select>
    )
  }

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        borderBottom: 'none',
        backgroundColor: theme.palette.primary.light,
        p: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ my: theme.spacing(3) }}>
        Selected answers:{' '}
        <Typography component={'span'} sx={{ fontWeight: 500 }}>
          {selectedIdsOfResponses.length}
        </Typography>
      </Typography>
      {selectedIdsOfResponses.length > 0 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', ml: theme.spacing(2) }}
        >
          <Typography>Assign to:</Typography>
          <FormControl size="small" sx={{ ml: theme.spacing(2) }}>
            {selectReviewerJSXitem}
          </FormControl>
        </Box>
      )}
      <Stack
        direction={'row'}
        sx={{ ml: theme.spacing(4) }}
        spacing={theme.spacing(4)}
        alignItems={'center'}
      >
        {selectedIdsOfResponses.length > 0 && assignedReviewer !== 'select' && (
          <UniversalButton
            sx={{ minWidth: '100px' }}
            label="Submit"
            onClick={onSubmitBulkAssigment}
            loading={isLoadingAssignReviewer}
          />
        )}
        <UniversalButton
          stylingType={1}
          label="Cancel"
          onClick={onToggleIsAssignReviewerMode}
        />
      </Stack>
    </Box>
  )
}

export default AssingReviewerContainer
