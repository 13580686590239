import React, { useState } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { Survey } from '../../../types/interfaces'
import content from '../../../assets/content/content'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {
  checkIfItIsSpecificFileNameToTriggerProgressBar,
  sortSurveysData,
} from '../../../utils/utils'
import { useAppSelector } from '../../../hooks/hooks'
import SurveyTableItem from './SurveyTableItem/SurveyTableItem'
import TableHeadCell from './TableHeadCell/TableHeadCell'
import HardCodedItem from './SurveyTableItem/HardCodedItem'

interface Props {
  surveysData: Survey[]
}

const NewTabelWithSurveyItems: React.FC<Props> = ({ surveysData }) => {
  const theme = useTheme()
  const userState = useAppSelector(state => state.user)
  const { companyName, role } = userState
  const { isDemoMode } = useAppSelector(state => state.app)
  const [sortedBy, setSortedBy] = useState<keyof Survey | 'status'>('status')
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DES'>('DES')

  const {
    eng: {
      components: {
        surveysTable: { tableHeaderCells },
      },
    },
  } = content
  let counterOfSpecific = 0

  const dataAfterSorting = sortSurveysData(
    surveysData.map(item => item),
    sortedBy,
    sortOrder
  )

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const borderStyle = `1px solid ${borderColor}`

  let gridColumn = '1.5fr 0.7fr 0.8fr 1.2fr 0.3fr'

  const isViewer = role === 'VIEWER'

  if (isViewer) {
    gridColumn = '1.5fr 0.7fr 0.8fr 1.2fr'
  }

  const tableItems = dataAfterSorting.map(surveyItem => {
    if (surveyItem.file) {
      const isSpecific = checkIfItIsSpecificFileNameToTriggerProgressBar(
        companyName!,
        surveyItem.file.original_file_name
      )
      if (isSpecific) {
        counterOfSpecific = counterOfSpecific + 1
      }

      const isSpecificHidden = counterOfSpecific > 1

      if (isSpecific && isDemoMode) {
        return (
          <HardCodedItem
            isHidden={isSpecificHidden}
            key={surveyItem.id}
            {...surveyItem}
            borderStyle={borderStyle}
            gridParam={gridColumn}
          />
        )
      } else {
        return (
          <SurveyTableItem
            key={surveyItem.id}
            {...surveyItem}
            borderStyle={borderStyle}
            gridParam={gridColumn}
          />
        )
      }
    } else {
      return (
        <SurveyTableItem
          key={surveyItem.id}
          {...surveyItem}
          borderStyle={borderStyle}
          gridParam={gridColumn}
        />
      )
    }
  })

  const tableItemsJSX = tableItems.filter(item => {
    return item !== null
  })

  const onClickHeaderCellHandler = (key: string) => {
    setSortedBy(key as keyof Survey)
    if (key === sortedBy) {
      setSortOrder(prevState => {
        if (prevState === 'ASC') {
          return 'DES'
        } else {
          return 'ASC'
        }
      })
    }
  }

  let sortingIcon: JSX.Element

  if (sortOrder === 'ASC') {
    sortingIcon = <ArrowDropUpIcon />
  } else {
    sortingIcon = <ArrowDropDownIcon />
  }

  return (
    <Box
      sx={{
        width: '100%',
        border: borderStyle,
        display: 'grid',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: gridColumn,
          '>div:not(:last-child)': { borderRight: borderStyle },
          borderBottom: borderStyle,
          bgcolor: theme.palette.grey[100],
        }}
      >
        {tableHeaderCells
          .map((headerCell, index) => {
            const { id } = headerCell
            if (id === 'download' && isViewer) {
              return null
            } else {
              return (
                <TableHeadCell
                  key={id}
                  {...headerCell}
                  sortOrder={sortOrder}
                  sortedBy={sortedBy}
                  onClickHeadCell={onClickHeaderCellHandler}
                  index={index}
                />
              )
            }
          })
          .filter(item => item !== null)}
      </Box>
      {tableItemsJSX}
    </Box>
  )
}

export default NewTabelWithSurveyItems
