import React, { useContext, useEffect, useReducer } from 'react'
import { useAppDispatch, useForm } from '../../hooks/hooks'
import { CdpSmeSeasonalSurveyDetailsContext } from './cdpSmeSeasonalSurveyDetails-context'
import {
  CdpSmeSeasonalSurveyDetailsContextSchema,
  CdpSmeSeasonalSurveyDetailsState,
} from './cdpSmeSeasonalSurveyDetailsInterfaces'

import initialCdpSmeSeasonalSurveyDetailsState from './initialCdpSmeSeasonalSurveyDetailsState'
import { UploadSurveyContext } from '../UploadSurveyContext/uploadSurvey-context'
import { SurveysContext } from '../SurveysContext/surveys-context'

interface Props {
  surveyId: string
  surveyName: string
  children: React.ReactNode
}

enum CdpSmeSeasonalSurveyDetailsActionKind {
  SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION',
  SET_CHECKED_ANSWER = 'SET_CHECKED_ANSWER',
  RESET_FORM = 'RESET_FORM',
}

interface CdpSmeSeasonalSurveyDetailsAction {
  type: CdpSmeSeasonalSurveyDetailsActionKind
  payload: string | number
}

const reducerFunction = (
  state: CdpSmeSeasonalSurveyDetailsState,
  action: CdpSmeSeasonalSurveyDetailsAction
): CdpSmeSeasonalSurveyDetailsState => {
  const { type, payload } = action
  switch (type) {
    case CdpSmeSeasonalSurveyDetailsActionKind.SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: payload as number,
      }
    case CdpSmeSeasonalSurveyDetailsActionKind.SET_CHECKED_ANSWER:
      let newQuestionsState = [...state.questions]
      const foundIndexOfAnswer = newQuestionsState[
        state.currentQuestion
      ].answers.findIndex(answerItem => answerItem.id === payload)

      newQuestionsState[state.currentQuestion].answers[
        foundIndexOfAnswer!
      ].isChoosen =
        !state.questions[state.currentQuestion].answers[foundIndexOfAnswer!]
          .isChoosen

      return {
        ...state,
        questions: [...newQuestionsState],
      }

    case CdpSmeSeasonalSurveyDetailsActionKind.RESET_FORM:
      return { ...initialCdpSmeSeasonalSurveyDetailsState }

    default:
      return state
  }
}

const CdpSmeSeasonalSurveyDetailsContextProvider: React.FC<Props> = ({
  children,
  surveyId,
  surveyName,
}) => {
  const [state, dispatch] = useReducer(reducerFunction, {
    ...initialCdpSmeSeasonalSurveyDetailsState,
    surveyId,
    surveyName,
  })

  const {
    queryResult: { isSuccessInCreateQuestionnaire },
  } = useContext(UploadSurveyContext)

  const {
    actions: { onSetIdOfSeasonalSurveyModalOpen },
  } = useContext(SurveysContext)

  useEffect(() => {
    if (isSuccessInCreateQuestionnaire) {
      dispatch({
        type: CdpSmeSeasonalSurveyDetailsActionKind.RESET_FORM,
        payload: '',
      })
      onSetIdOfSeasonalSurveyModalOpen('')
    }
  }, [isSuccessInCreateQuestionnaire])

  const onSetCurrentQuestionHandler = (newValue: number) => {
    dispatch({
      type: CdpSmeSeasonalSurveyDetailsActionKind.SET_CURRENT_QUESTION,
      payload: newValue as number,
    })
  }

  const onChangeCheckedAnswerHandler = (id: string) => {
    dispatch({
      type: CdpSmeSeasonalSurveyDetailsActionKind.SET_CHECKED_ANSWER,
      payload: id as string,
    })
  }

  const ctxValue: CdpSmeSeasonalSurveyDetailsContextSchema = {
    state: {
      surveyId: state.surveyId,
      surveyName: state.surveyName,
      currentQuestion: state.currentQuestion,
      questions: state.questions,
    },
    actions: {
      onSetCurrentQuestion: onSetCurrentQuestionHandler,
      onChangeCheckedAnswer: onChangeCheckedAnswerHandler,
    },
  }

  return (
    <CdpSmeSeasonalSurveyDetailsContext.Provider value={ctxValue}>
      {children}
    </CdpSmeSeasonalSurveyDetailsContext.Provider>
  )
}

export default CdpSmeSeasonalSurveyDetailsContextProvider
