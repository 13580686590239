import React, { useContext, useEffect, useReducer } from 'react'
import { AnswerPreviewContext } from './answer-preview-contex'
import {
  useDeleteResponseMutation,
  useUpdateAnswerMutation,
} from '../../services/responses'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { appActions } from '../../store/App/app-slice'
import { AnswerItem, AnswerStatus, CompanyUser } from '../../types/interfaces'
import {
  AnswerPrevievContext,
  AnswerPrevievState,
} from './answerPreviewContextInterfaces'
import { initialAnswerPreviewState } from './initialAnswerPreviewState'
import {
  useCreateSupportiveEvidencesMutation,
  useGetSupportiveEvidencesQuery,
} from '../../services/supportiveEvidences'
import { useTriggerGetCompanyUsersMutation } from '../../services/users'
import { AnswersLibraryContext } from '../AnswersLibraryContext/answers-library-context'
import {
  Box,
  CircularProgress,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useRegenerateResponseMutation } from '../../services/generate'

const PAGE_RANGE_REGEX = /^(\d+-\d+|\d+)(,\s?(\d+-\d+|\d+))*$/

const isValidPageRanges = (pageRanges: string): boolean => {
  const ranges = pageRanges.split(',').map(range => range.trim())
  for (const range of ranges) {
    const [start, end] = range.split('-').map(Number)
    if (end !== undefined && start > end) {
      return false
    }
  }
  return true
}

enum FormActionKind {
  SET_INPUT_VALUE = 'SET_INPUT_VALUE',
  // SET_REQUEST_ID = 'SET_REQUEST_ID',
  SWITCH_TO_EDIT_MODE = 'SWITCH_TO_EDIT_MODE',
  SWITCH_OFF_EDIT_MODE = 'SWITCH_OFF_EDIT_MODE',
  SET_APPROVED_BY_NAME = 'SET_APPROVED_BY_NAME',
  SET_IS_REJECT_MODAL_OPEN = 'SET_IS_REJECT_MODAL_OPEN',
  SET_IS_ADD_MISSING_EVIDENCE_MODAL_OPEN = 'SET_IS_ADD_MISSING_EVIDENCE_MODAL_OPEN',
  SET_CHOOSEN_MISSING_EVIDENCE_ID = 'SET_CHOOSEN_MISSING_EVIDENCE_ID',
  SET_ADDING_EVIDENCE_MODAL_STEP = 'SET_ADDING_EVIDENCE_MODAL_STEP',
  SET_RANGE_INPUT_VALUE = 'SET_RANGE_INPUT_VALUE',
  SET_SENTENCE_MISSING_EVIDENCE_INPUT_VALUE = 'SET_SENTENCE_MISSING_EVIDENCE_INPUT_VALUE',
  SET_VALIDATION_ERROR_OF_RANGE_INPUT = 'SET_VALIDATION_ERROR_OF_RANGE_INPUT',
  SET_REVIEWERS = 'SET_REVIEWERS',
  SET_SELECT_REVIEWER_VALUE = 'SET_SELECT_REVIEWER_VALUE',
  SET_ARE_ANY_CHANGES_COMPARE_TO_ORIGINAL = 'SET_ARE_ANY_CHANGES_COMPARE_TO_ORIGINAL',
  SET_IS_EDITOR_ASSIGNED_AS_CONTRIBUTOR = 'SET_IS_EDITOR_ASSIGNED_AS_CONTRIBUTOR',
}

interface AnswerPreviewAction {
  type: FormActionKind
  payload:
    | string
    | { label: JSX.Element | string; type: string }
    | boolean
    | AnswerItem['id']
    | number
    | null
    | CompanyUser[]
}

const reducerFuction = (
  state: AnswerPrevievState,
  action: AnswerPreviewAction
): AnswerPrevievState => {
  const { type, payload } = action
  switch (type) {
    case FormActionKind.SET_INPUT_VALUE:
      return {
        ...state,
        responseInput: {
          isValid: true,
          value: payload as string,
        },
      }

    case FormActionKind.SWITCH_TO_EDIT_MODE:
      return {
        ...state,
        isEditMode: true,
      }
    case FormActionKind.SWITCH_OFF_EDIT_MODE:
      return {
        ...state,
        isEditMode: false,
      }
    case FormActionKind.SET_APPROVED_BY_NAME:
      return {
        ...state,
        approvedByName: payload as string,
      }
    case FormActionKind.SET_IS_REJECT_MODAL_OPEN:
      return {
        ...state,
        isRejectModalOpen: payload as boolean,
      }
    case FormActionKind.SET_IS_ADD_MISSING_EVIDENCE_MODAL_OPEN:
      return {
        ...state,
        isAddMissingEvidenceModalOpen: payload as boolean,
      }
    case FormActionKind.SET_CHOOSEN_MISSING_EVIDENCE_ID:
      return {
        ...state,
        choosenMissingEvidenceId: payload as string,
      }
    case FormActionKind.SET_ADDING_EVIDENCE_MODAL_STEP:
      return {
        ...state,
        addingMissingEvidenceStep: payload as number,
      }
    case FormActionKind.SET_RANGE_INPUT_VALUE:
      return {
        ...state,
        pageRangesInputValue: payload as string,
      }
    case FormActionKind.SET_SENTENCE_MISSING_EVIDENCE_INPUT_VALUE:
      return {
        ...state,
        missingEvidenceSentenceInputValue: payload as string,
      }
    case FormActionKind.SET_VALIDATION_ERROR_OF_RANGE_INPUT:
      return {
        ...state,
        errorInPageRangesValidation: payload as string,
      }
    case FormActionKind.SET_REVIEWERS:
      return {
        ...state,
        reviewers: payload as CompanyUser[],
      }
    case FormActionKind.SET_SELECT_REVIEWER_VALUE:
      return {
        ...state,
        selectReviewerValue: payload as string,
      }
    case FormActionKind.SET_IS_EDITOR_ASSIGNED_AS_CONTRIBUTOR:
      return {
        ...state,
        isEditorAssignedAsContributor: payload as boolean,
      }
    case FormActionKind.SET_ARE_ANY_CHANGES_COMPARE_TO_ORIGINAL:
      return {
        ...state,
        areAnyChangesCompareToOriginal: payload as boolean,
      }
    // case FormActionKind.SET_REQUEST_ID:
    //   return {
    //     ...state,
    //     requestId: payload as string,
    //   }
    default:
      return state
  }
}

const AnswerPreviewContexProvider: React.FC<
  AnswerItem & { children?: React.ReactNode }
> = ({
  children,
  request,
  text,
  id,
  status,
  modified_by_user_id,
  updated_at,
  assigned_user,
  original_response,
}) => {
  const { question, survey_id, id: requestId } = request

  const {
    actions: { onOpenAnswer, refetchAnswers },
  } = useContext(AnswersLibraryContext)

  const { userId, role } = useAppSelector(state => state.user)

  const [state, dispatch] = useReducer(
    reducerFuction,
    initialAnswerPreviewState
  )

  const location = useLocation()

  const dispatchAction = useAppDispatch()

  const {
    data: supportiveEvidemnceData,
    isLoading: isLoadingSupportiveEvidence,
  } = useGetSupportiveEvidencesQuery(id)

  const [
    updateResponse,
    {
      isSuccess: isSuccessInUpdateResponse,
      isLoading: isLoadingInSaveResponse,
      isError: isErrorInUpdateResponse,
      data,
    },
  ] = useUpdateAnswerMutation()

  const [
    getCompanyUsers,
    {
      isSuccess: isSuccessCompanyUsers,
      isLoading: isLoadingCompanyUsers,
      isError: isErrorCompanyUsers,
      data: companyUsersData,
    },
  ] = useTriggerGetCompanyUsersMutation()

  const [
    deleteResponse,
    {
      isSuccess: isSuccessInDeleteResponse,
      isError: isErrorInDeleteResponse,
      isLoading: isLoadingInDeleteResponse,
    },
  ] = useDeleteResponseMutation()

  const [
    createMissingEvidence,
    {
      isLoading: isLoadingCreateMissingEvidence,
      isError: isErrorCreateMissingEvidence,
      isSuccess: isSuccessCreateMissingEvidence,
    },
  ] = useCreateSupportiveEvidencesMutation()

  const [
    regenerateResponse,
    {
      isLoading: isLoadingRegenerateResponse,
      isError: isErrorRegenerateResponse,
      isSuccess: isSuccessRegenerateResponse,
      data: dataRegenerateResponse,
    },
  ] = useRegenerateResponseMutation()

  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  useEffect(() => {
    if (isSuccessInUpdateResponse) {
      dispatch({ type: FormActionKind.SWITCH_OFF_EDIT_MODE, payload: '' })
      dispatch({
        type: FormActionKind.SET_IS_REJECT_MODAL_OPEN,
        payload: false,
      })
      dispatchAction(appActions.setDashboardRequireRefetch())
      refetchAnswers()
      const isReviewMode = role === 'STUFF' && data.status === 'UNCHECKED'
      onOpenAnswer(data.id, isReviewMode)
    }
  }, [isSuccessInUpdateResponse])

  useEffect(() => {
    if (isSuccessRegenerateResponse) {
      dispatch({ type: FormActionKind.SWITCH_OFF_EDIT_MODE, payload: '' })
      dispatch({
        type: FormActionKind.SET_IS_REJECT_MODAL_OPEN,
        payload: false,
      })
      dispatchAction(appActions.setDashboardRequireRefetch())
      refetchAnswers()
      const isReviewMode =
        role === 'STUFF' && dataRegenerateResponse.status === 'UNCHECKED'
      onOpenAnswer(dataRegenerateResponse.id, isReviewMode)
    }
  }, [isSuccessRegenerateResponse])

  useEffect(() => {
    if (isSuccessInDeleteResponse) {
      const label = `Answer succesful deleted`

      dispatchAction(
        appActions.showSnackbarNotification({ label, type: 'success' })
      )
    }
  }, [isSuccessInDeleteResponse])

  useEffect(() => {
    let label = 'Error'

    if (isErrorInDeleteResponse || isErrorInUpdateResponse) {
      if (isErrorInDeleteResponse) {
        label = `Sorry, we couldn't delete the response. Please try again later or contact support for assistance.`
      } else if (isErrorInUpdateResponse) {
        label = `Sorry, we couldn't update the response. Please try again later or contact support for assistance.`
      }
      dispatchAction(
        appActions.showSnackbarNotification({ label, type: 'error' })
      )
      return () => {
        dispatchAction(appActions.hideSnackbarNotification())
      }
    }
  }, [isErrorInDeleteResponse, isErrorInUpdateResponse])

  useEffect(() => {
    if (modified_by_user_id) {
      getCompanyUsers()
    }
  }, [modified_by_user_id])

  useEffect(() => {
    if (isSuccessCompanyUsers && companyUsersData) {
      const approvedByUserObject = companyUsersData.find(
        user => user.id === modified_by_user_id
      )
      if (approvedByUserObject) {
        if (approvedByUserObject.first_name && approvedByUserObject.last_name) {
          dispatch({
            type: FormActionKind.SET_APPROVED_BY_NAME,
            payload: `${approvedByUserObject.first_name} ${approvedByUserObject.last_name}`,
          })
        } else if (approvedByUserObject.email) {
          dispatch({
            type: FormActionKind.SET_APPROVED_BY_NAME,
            payload: `${approvedByUserObject.email}`,
          })
        }
      }
    }
  }, [isSuccessCompanyUsers])

  useEffect(() => {
    if (isSuccessCreateMissingEvidence) {
      dispatch({
        type: FormActionKind.SET_ADDING_EVIDENCE_MODAL_STEP,
        payload: 0,
      })
      dispatch({
        type: FormActionKind.SET_CHOOSEN_MISSING_EVIDENCE_ID,
        payload: null,
      })
      dispatch({
        type: FormActionKind.SET_IS_ADD_MISSING_EVIDENCE_MODAL_OPEN,
        payload: false,
      })
      const label = `Evidence succesful added.`

      dispatchAction(
        appActions.showSnackbarNotification({ label, type: 'success' })
      )
    }
  }, [isSuccessCreateMissingEvidence])

  useEffect(() => {
    if (isErrorCreateMissingEvidence) {
      const label = `Sorry, we couldn't add evidence to this question, please try again later or contact support for assistance.`

      dispatchAction(
        appActions.showSnackbarNotification({ label, type: 'error' })
      )
    }
  }, [isErrorCreateMissingEvidence])

  useEffect(() => {
    if (
      status === 'CHECKED' &&
      assigned_user === null &&
      state.reviewers === null
    ) {
      getCompanyUsers()
    }
  }, [status, assigned_user, state.reviewers])

  useEffect(() => {
    if (assigned_user) {
      const { id } = assigned_user
      dispatch({
        type: FormActionKind.SET_SELECT_REVIEWER_VALUE,
        payload: id,
      })
    } else {
      dispatch({
        type: FormActionKind.SET_SELECT_REVIEWER_VALUE,
        payload: 'unassigned',
      })
    }
  }, [assigned_user])

  useEffect(() => {
    if (companyUsersData) {
      const cotributorsData = companyUsersData.filter(user => {
        const { role } = user
        return role === 'CONTRIBUTOR' || role === 'EDITOR' || role === 'ADMIN'
      })
      dispatch({ type: FormActionKind.SET_REVIEWERS, payload: cotributorsData })
    }
  }, [isSuccessCompanyUsers, companyUsersData])

  useEffect(() => {
    if (assigned_user && role === 'EDITOR' && assigned_user.id === userId) {
      dispatch({
        type: FormActionKind.SET_IS_EDITOR_ASSIGNED_AS_CONTRIBUTOR,
        payload: true,
      })
    } else {
      dispatch({
        type: FormActionKind.SET_IS_EDITOR_ASSIGNED_AS_CONTRIBUTOR,
        payload: false,
      })
    }
  }, [state.responseId, userId, role, assigned_user])

  const changeResponseHandler = (newValue: string) => {
    dispatch({
      payload: newValue,
      type: FormActionKind.SET_INPUT_VALUE,
    })
  }

  const onClickSaveAnswersHandler = () => {
    updateResponse({
      responseId: id,
      body: {
        status: status,
        text: state.responseInput.value,
      },
    })
  }

  const onClickRegenerateResponseHandler = () => {
    regenerateResponse(requestId)
  }

  const onUpdateReviewerHandler = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string
    updateResponse({
      responseId: id,
      body: {
        status: 'CHECKED',
        assigned_user_id: newValue === 'unassigned' ? '' : newValue,
      },
    })
  }

  const onUpdateStatusHandler = (event: SelectChangeEvent) => {
    const newValue = event.target.value as AnswerStatus
    updateResponse({
      responseId: id,
      body: {
        status: newValue,
      },
    })
  }
  const onClickDeleteFromMyAnswersHandler = () => {
    deleteResponse({
      responseId: state.responseId,
    })
  }

  const onClickEditModeHandler = (inputInitailValue: string) => {
    dispatch({ type: FormActionKind.SWITCH_TO_EDIT_MODE, payload: '' })
    dispatch({
      type: FormActionKind.SET_INPUT_VALUE,
      payload: inputInitailValue,
    })
  }

  const switchOffEditModeHandler = () => {
    dispatch({ type: FormActionKind.SWITCH_OFF_EDIT_MODE, payload: '' })
  }

  const onClickDiscardChangesHandler = () => {
    dispatch({ type: FormActionKind.SWITCH_OFF_EDIT_MODE, payload: '' })
    dispatch({ payload: text, type: FormActionKind.SET_INPUT_VALUE })
  }

  const onClickAproveAnswerHandler = () => {
    updateResponse({
      responseId: id,
      body: { status: 'APPROVED', feedback: 'LIKE' },
    })
  }

  const onClickAcceptAnswerHandler = () => {
    updateResponse({
      responseId: id,
      body: { status: 'CHECKED' },
    })
  }

  const onSubmitRejectFeedbackHandler = (feedbackText: string) => {
    updateResponse({
      responseId: id,
      body: {
        feedback_text: feedbackText,
        feedback: 'DISLIKE',
        status: 'REJECTED_REVIEWED',
      },
    })
  }

  const onClickOpenRejectModalHandler = () => {
    dispatch({ type: FormActionKind.SET_IS_REJECT_MODAL_OPEN, payload: true })
  }

  const onClickCloseRejectModalHandler = () => {
    dispatch({ type: FormActionKind.SET_IS_REJECT_MODAL_OPEN, payload: false })
  }

  const onClickOpenAddMissingEvidenceModalHandler = () => {
    dispatch({
      type: FormActionKind.SET_IS_ADD_MISSING_EVIDENCE_MODAL_OPEN,
      payload: true,
    })
  }

  const onClickCloseAddMissingEvidenceModalHandler = () => {
    dispatch({
      type: FormActionKind.SET_IS_ADD_MISSING_EVIDENCE_MODAL_OPEN,
      payload: false,
    })
  }

  const onSelectMissingEvidenceHandler = (eviId: string) => {
    dispatch({
      type: FormActionKind.SET_CHOOSEN_MISSING_EVIDENCE_ID,
      payload: eviId,
    })
  }

  const onChangeMissingEvidenceStepHandler = stepNumber => {
    dispatch({
      type: FormActionKind.SET_ADDING_EVIDENCE_MODAL_STEP,
      payload: stepNumber,
    })
  }

  const onClickCancelButtonInAddMissingEvidenceDialogHandler = () => {
    dispatch({
      type: FormActionKind.SET_ADDING_EVIDENCE_MODAL_STEP,
      payload: 0,
    })
    dispatch({
      type: FormActionKind.SET_CHOOSEN_MISSING_EVIDENCE_ID,
      payload: null,
    })
    dispatch({
      type: FormActionKind.SET_IS_ADD_MISSING_EVIDENCE_MODAL_OPEN,
      payload: false,
    })
  }

  const onChangePageRangeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({ type: FormActionKind.SET_RANGE_INPUT_VALUE, payload: newValue })
    if (!PAGE_RANGE_REGEX.test(newValue)) {
      dispatch({
        type: FormActionKind.SET_VALIDATION_ERROR_OF_RANGE_INPUT,
        payload: `Invalid format. Use ranges like 1-5, 7, 10-12`,
      })
    } else if (!isValidPageRanges(newValue)) {
      dispatch({
        type: FormActionKind.SET_VALIDATION_ERROR_OF_RANGE_INPUT,
        payload:
          'Invalid range. Ensure start page is less than or equal to end page.',
      })
    } else {
      dispatch({
        type: FormActionKind.SET_VALIDATION_ERROR_OF_RANGE_INPUT,
        payload: ``,
      })
    }
  }

  const onChangeMissingEvidenceSentenceInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value
    dispatch({
      type: FormActionKind.SET_SENTENCE_MISSING_EVIDENCE_INPUT_VALUE,
      payload: newValue,
    })
  }

  const onSubmitCreateMissingEvidenceHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (state.choosenMissingEvidenceId) {
      createMissingEvidence({
        evidence_id: state.choosenMissingEvidenceId,
        page_number: state.pageRangesInputValue,
        response_id: id,
        sentence: state.missingEvidenceSentenceInputValue,
      })
    }
  }

  const onDifferenceCompareToOriginalCheckHandler = isExist => {
    dispatch({
      type: FormActionKind.SET_ARE_ANY_CHANGES_COMPARE_TO_ORIGINAL,
      payload: isExist,
    })
  }

  const ctxValue: AnswerPrevievContext = {
    state: state,
    answerData: {
      id: id,
      questionHeading: question,
      dateOfApproval: updated_at,
      answerText: text,
      status: status,
      assignedUser: assigned_user,
      supportiveEvidence: supportiveEvidemnceData
        ? supportiveEvidemnceData
        : [],
      isSupportiveEvidenceLoading: isLoadingSupportiveEvidence,
      isSaveAnswerLoading: isLoadingInSaveResponse,
      originalResponse: original_response,
      lastModified: updated_at,
      modifiedBy: modified_by_user_id,
      surveyId: survey_id,
    },
    createMissingEvidenceData: {
      isErrorCreateMissingEvidence,
      isLoadingCreateMissingEvidence,
      isSuccessCreateMissingEvidence,
    },
    regenerateAnswerQuery: {
      isLoadingRegenerateAnswer: isLoadingRegenerateResponse,
    },
    updateAnswerQuery: {
      isLoadingUpdateResponse: isLoadingInSaveResponse,
    },
    companyUsersData: { data: companyUsersData ? companyUsersData : [] },
    actions: {
      onResponseChange: changeResponseHandler,
      onClickSaveAnswer: onClickSaveAnswersHandler,
      onClickDiscardChangesButton: onClickDiscardChangesHandler,
      onClickDeleteFromMyAnswers: onClickDeleteFromMyAnswersHandler,
      onClickEditMode: onClickEditModeHandler,
      switchOffEditMode: switchOffEditModeHandler,
      onClickAproveAnswer: onClickAproveAnswerHandler,
      onClickAcceptAnswer: onClickAcceptAnswerHandler,
      onSubmitRejectFeedback: onSubmitRejectFeedbackHandler,
      onClickOpenRejectModal: onClickOpenRejectModalHandler,
      onClickCloseRejectModal: onClickCloseRejectModalHandler,
      onClickOpenAddMissingEvidenceModal:
        onClickOpenAddMissingEvidenceModalHandler,
      onClickCloseAddMissingEvidenceModal:
        onClickCloseAddMissingEvidenceModalHandler,
      onSelectMissingEvidence: onSelectMissingEvidenceHandler,
      onChangeAddingMissingEvidenceStep: onChangeMissingEvidenceStepHandler,
      onClickCancelButtonInAddMissingEvidenceDialog:
        onClickCancelButtonInAddMissingEvidenceDialogHandler,
      onSubmitCreateMissingEvidence: onSubmitCreateMissingEvidenceHandler,
      onChangePageRangeInput: onChangePageRangeInputHandler,
      onChangeMissingEvidenceSentenceInput:
        onChangeMissingEvidenceSentenceInputHandler,
      onUpdateReviewer: onUpdateReviewerHandler,
      onUpdateStatus: onUpdateStatusHandler,
      onDifferenceCompareToOriginalCheck:
        onDifferenceCompareToOriginalCheckHandler,
      onClickRegenerateResponse: onClickRegenerateResponseHandler,
    },
  }

  let mainContent = children

  return (
    <AnswerPreviewContext.Provider value={ctxValue}>
      {mainContent}
    </AnswerPreviewContext.Provider>
  )
}

export default AnswerPreviewContexProvider
