import React from 'react'
import { Button } from '@mui/material'
import content from '../../../assets/content/content'
import { useAuth0 } from '@auth0/auth0-react'
import ReactGA from 'react-ga4'
import { useTriggerClickSignUpButtonMutation } from '../../../services/hubSpotUserTracking'

const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')

const {
  eng: {
    buttons: { signUp },
  },
} = content

const SignUpButton: React.FC = () => {
  const [triggerClickToHubspot] = useTriggerClickSignUpButtonMutation()

  const { loginWithRedirect } = useAuth0()

  const signUpHandler = () => {
    isProduction &&
      ReactGA.event({
        category: 'Authorization',
        action: 'User clicked SignUp Button',
        label: 'User clicked SignUp Button Label', // optional
        value: 0, // optional, must be a number
        nonInteraction: true, // optional, true/false
      })
    isProduction && triggerClickToHubspot()
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        redirect_uri: window.location.origin,
      },
    })
  }
  return (
    <Button onClick={signUpHandler} size="large" variant="contained">
      {signUp}
    </Button>
  )
}

export default SignUpButton
