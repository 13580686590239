import React, { useContext, useEffect } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import { useParams } from 'react-router-dom'
import DropFileZone from '../components/surveys/DropFileZone/DropFileZone'
import { SurveysContext } from '../contexts/SurveysContext/surveys-context'
import NewTabelWithSurveyItems from '../components/surveys/NewTabelWithSurveyItems/NewTabelWithSurveyItems'
import { useAppDispatch } from '../hooks/hooks'
import { appActions } from '../store/App/app-slice'
import SeasonalSurveysSection from '../components/surveys/SeasonalSurveysSection/SeasonalSurveysSection'
import UploadSurveyContextProvider from '../contexts/UploadSurveyContext/UploadSurveyContextProvider'

const Surveys: React.FC = () => {
  const {
    state: { surveysData },
  } = useContext(SurveysContext)
  const theme = useTheme()
  const { isUploadModeParam } = useParams()
  const isUploadMode = isUploadModeParam === 'uploadMode'
  const isUploadModeDemo = isUploadModeParam === 'uploadModeDemo'

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Surveys'))
  }, [])

  let isUploadedSurveysListEmpty: boolean | undefined

  let mainContent: JSX.Element | null | JSX.Element[] = null
  let mySurveysTitle: JSX.Element | null | JSX.Element[] = null

  isUploadedSurveysListEmpty = surveysData.length === 0

  if (!isUploadModeDemo && !isUploadedSurveysListEmpty) {
    mainContent = <NewTabelWithSurveyItems surveysData={surveysData} />
    mySurveysTitle = (
      <Typography variant="h5" component={'h2'}>
        My Surveys
      </Typography>
    )
  }

  return (
    <MainContentContainer
      maxWidth="xl"
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          mb: theme.spacing(5),
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems:
            isUploadedSurveysListEmpty || isUploadMode || isUploadModeDemo
              ? 'flex-start'
              : 'center',
          flexDirection:
            isUploadedSurveysListEmpty || isUploadMode || isUploadModeDemo
              ? 'column-reverse'
              : 'row',
        }}
      >
        {mySurveysTitle}
        <DropFileZone
          isSurveysListEmpty={
            isUploadedSurveysListEmpty || isUploadModeDemo ? true : false
          }
        />
      </Box>
      {mainContent}
      <UploadSurveyContextProvider>
        <SeasonalSurveysSection />
      </UploadSurveyContextProvider>
    </MainContentContainer>
  )
}

export default Surveys
