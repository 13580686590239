import React, { useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import './PDFpreview.css'
import { Box } from '@mui/material'
import { useGetAccessToFileMutation } from '../../../../services/file'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  fileUrl: string
  pageNumber: number | null
  onPreviewShow: () => void
  onLoadError: () => void
  isUrlConfidential: boolean
  evidenceId: string
  fileId: string | null
}

const PDFpreview: React.FC<Props> = ({
  fileUrl,
  pageNumber,
  onPreviewShow,
  onLoadError,
  isUrlConfidential,
  evidenceId,
  fileId,
}) => {
  const openInNewTab = () => {
    window.open(
      pageNumber ? `${fileUrl}#page=${pageNumber}` : fileUrl,
      '_blank',
      'noreferrer'
    )
  }

  const [getAccessToEvidenceFile, { data }] = useGetAccessToFileMutation()

  useEffect(() => {
    if (isUrlConfidential && fileId) {
      getAccessToEvidenceFile(fileId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setPreviewIsShown = () => {
    onPreviewShow()
  }

  if (isUrlConfidential) {
    if (data) {
      const { url } = data
      if (url) {
        return (
          <Box
            className="Example__container__document"
            sx={{ cursor: 'pointer' }}
          >
            <Document
              onLoadSuccess={setPreviewIsShown}
              onLoadError={onLoadError}
              file={url}
              loading={null}
              onClick={() => openInNewTab()}
            >
              <Page
                pageNumber={pageNumber ? Number(pageNumber) : 1}
                width={295}
              />
            </Document>
          </Box>
        )
      } else return null
    } else {
      return null
    }
  }

  return (
    <Box className="Example__container__document" sx={{ cursor: 'pointer' }}>
      <Document
        onLoadSuccess={setPreviewIsShown}
        onLoadError={onLoadError}
        file={fileUrl}
        loading={null}
        onClick={() => openInNewTab()}
      >
        <Page pageNumber={pageNumber ? Number(pageNumber) : 1} width={295} />
      </Document>
    </Box>
  )
}

export default PDFpreview
