import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import NewDesignUniversalTable from '../../ui/tables/NewDesignUniversalTable/NewDesignUniversalTable'
import content from '../../../assets/content/content'
import HeadTableCell from '../../ui/tables/NewDesignUniversalTable/HeadTableCell/HeadTableCell'
import { AnswersLibraryContext } from '../../../contexts/AnswersLibraryContext/answers-library-context'
import AnswerRow from './AnswerRow/AnswerRow'
import { CheckCircle } from '@mui/icons-material'
import AnswerRightDrawer from './AnswerRightDrawer/AnswerRightDrawer'
import { AnswerItem } from '../../../types/interfaces'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { AnswerLibraryTableKeySortType } from '../../../types/types'
import { useAppSelector } from '../../../hooks/hooks'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import { useLocation, useNavigate } from 'react-router-dom'
import AssingReviewerContainer from './AssingReviewerContainer/AssingReviewerContainer'

interface Props {}

const AnswersLibraryTabel: React.FC<Props> = () => {
  const theme = useTheme()
  const {
    state: {
      filteredAnswers,
      filterByLastUpdatedRange,
      filterBySearch,
      filterBySource,
      filterByStatus,
      searchInputValue,
      isAssignReviewerMode,
      answerSources,
      currentPageIndex,
    },
    queryResults: { isFetching },
    actions: {
      onOpenAnswer,
      onCloseAnswer,
      onToggleIsAssignReviewerMode,
      onChangeNumberOfPage,
    },
  } = useContext(AnswersLibraryContext)
  const {
    eng: {
      views: {
        answersLibrary: { tableHeaderCells },
      },
    },
  } = content

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const [sortBy, setSortBy] =
    useState<AnswerLibraryTableKeySortType>('lastUpdated')
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DES'>('ASC')
  const { role } = useAppSelector(state => state.user)

  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    if (filterBySource !== null && filterBySource !== '1') {
      setSortBy('source')
    } else {
      setSortBy('lastUpdated')
    }
  }, [filterBySource])

  const numberOfItemOnOnePage = 20
  const quantityOfPages = Math.ceil(
    filteredAnswers.length / numberOfItemOnOnePage
  )

  let isAnyFilterSelected = false

  if (Array.isArray(filterByLastUpdatedRange)) {
    const isDataRangeSelected = !filterByLastUpdatedRange.every(
      curValue => curValue === null
    )
    isAnyFilterSelected =
      isDataRangeSelected ||
      filterBySearch !== null ||
      filterBySource !== null ||
      filterByStatus !== null ||
      searchInputValue !== ''
  } else {
    isAnyFilterSelected =
      filterBySearch !== null ||
      filterBySource !== null ||
      filterByStatus !== null ||
      searchInputValue !== ''
  }

  const onClickHeaderCellHandler = (key: string) => {
    setSortBy(key as AnswerLibraryTableKeySortType)
    if (key === sortBy) {
      setSortOrder(prevState => {
        if (prevState === 'ASC') {
          return 'DES'
        } else {
          return 'ASC'
        }
      })
    }
  }

  const changePageHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onChangeNumberOfPage(value)
  }

  const resetFiltersHandler = () => {
    if (isAssignReviewerMode) {
      onToggleIsAssignReviewerMode()
    }
    navigate('/answers-library')
  }

  function sortData(data: AnswerItem[]): AnswerItem[] {
    if (sortBy === 'lastUpdated') {
      return data.sort((a, b) => {
        const dateA = new Date(a.updated_at)
        const dateB = new Date(b.updated_at)
        const sortOrderFactor = sortOrder === 'ASC' ? 1 : -1

        return (dateB.getTime() - dateA.getTime()) * sortOrderFactor
      })
    }

    // if (sortBy === 'status') {
    //   return data.sort((a, b) => {
    //     const ascending = sortOrder === 'ASC'
    //     if (a.is_user_approved && !b.is_user_approved) {
    //       return ascending ? -1 : 1
    //     } else if (!a.is_user_approved && b.is_user_approved) {
    //       return ascending ? 1 : -1
    //     } else if (a.is_user_approved === null && b.is_user_approved === null) {
    //       if (a.is_regenerating && !b.is_regenerating) {
    //         return ascending ? 1 : -1
    //       } else if (!a.is_regenerating && b.is_regenerating) {
    //         return ascending ? -1 : 1
    //       } else {
    //         return 0
    //       }
    //     } else {
    //       return 0
    //     }
    //   })
    // }
    if (sortBy === 'source') {
      return data.sort((a, b) => {
        const surveyNameA = a.request.survey_name || 'Saved answers'
        const surveyNameB = b.request.survey_name || 'Saved answers'
        const comparison = surveyNameA.localeCompare(surveyNameB)
        if (comparison !== 0) {
          return sortOrder === 'ASC' ? comparison : -comparison
        } else {
          const orderA =
            a.request.survey_question_order ?? Number.MAX_SAFE_INTEGER
          const orderB =
            b.request.survey_question_order ?? Number.MAX_SAFE_INTEGER

          const orderComparison = orderA - orderB

          return sortOrder === 'ASC' ? orderComparison : -orderComparison
        }
      })
    }

    return data.sort((a, b) => {
      const orderFactor = sortOrder === 'ASC' ? 1 : -1

      // Check if the property is a number and use numeric comparison
      if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
        return (a[sortBy]! as number) - (b[sortBy]! as number) * orderFactor
      }

      // If the property is not a number, use string comparison
      return a[sortBy] && b[sortBy]
        ? a[sortBy]!.toString().localeCompare(b[sortBy]! as string) *
            orderFactor
        : 0
    })
  }

  let sortingIcon: JSX.Element

  if (sortOrder === 'ASC') {
    sortingIcon = <ArrowDropUpIcon />
  } else {
    sortingIcon = <ArrowDropDownIcon />
  }

  let tableHeaderCellIncludingRole = [...tableHeaderCells]

  if (role === 'VIEWER') {
    tableHeaderCellIncludingRole = tableHeaderCells.filter(headerItem => {
      const { label, id } = headerItem
      return label !== 'Status' && label !== 'Reviewer' && id !== 'select'
    })
  } else if (role === 'CONTRIBUTOR') {
    tableHeaderCellIncludingRole = tableHeaderCells.filter(headerItem => {
      const { label, id } = headerItem
      return label !== 'Reviewer' && id !== 'select'
    })
  } else if (!isAssignReviewerMode) {
    tableHeaderCellIncludingRole = tableHeaderCells.filter(headerItem => {
      const { label, id } = headerItem
      return id !== 'select'
    })
  }

  // if (!(role !== 'CONTRIBUTOR' && role !== 'VIEWER' && isAssignReviewerMode)) {
  //   tableHeaderCellIncludingRole = tableHeaderCells.filter(headerItem => {
  //     const { label, id } = headerItem
  //     return id !== 'select'
  //   })
  // }

  const jsxElementsTableHead = tableHeaderCellIncludingRole.map(
    (tableHeaderCell, index) => {
      const { id, label, keyToSort } = tableHeaderCell
      if (id === 'status') {
        return (
          <HeadTableCell key={`${id}_${index}`}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                disabled={false}
                onClick={
                  keyToSort
                    ? () => onClickHeaderCellHandler(keyToSort)
                    : () => {}
                }
              >
                <CheckCircle />
              </IconButton>
              {keyToSort === sortBy && sortingIcon}
            </Box>
          </HeadTableCell>
        )
      }
      return (
        <HeadTableCell
          key={`${id}_${index}_head`}
          align={id === 'question' ? 'left' : 'center'}
          sx={{
            width: id === 'question' ? '50%' : 'default',
            cursor: keyToSort ? 'pointer' : 'text',
            userSelect: 'none',
            py: theme.spacing(1),
            px: theme.spacing(2),
          }}
          onClick={
            keyToSort ? () => onClickHeaderCellHandler(keyToSort) : () => {}
          }
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: id === 'question' ? 'flex-start' : 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2" sx={{ display: 'flex', m: 0 }}>
              {label}
            </Typography>
            {keyToSort === sortBy && sortingIcon}
          </Box>
        </HeadTableCell>
      )
    }
  )

  const sortedData = sortData(filteredAnswers)

  const paginatedAnswer = sortedData.slice(
    (currentPageIndex - 1) * numberOfItemOnOnePage,
    currentPageIndex * numberOfItemOnOnePage
  )

  const jsxTableRowsContent = paginatedAnswer.map((answerItem, index) => {
    return <AnswerRow key={`${index}_${answerItem.id}`} {...answerItem} />
  })

  let mainContent: JSX.Element = <span></span>

  if (isFetching) {
    mainContent = (
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          py: theme.spacing(2.5),
          px: theme.spacing(3.5),
          backgroundColor: theme.palette.grey[100],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh',
          flexGrow: 1,
        }}
      >
        <CircularProgress />
      </Box>
    )
  } else if (isAnyFilterSelected && filteredAnswers.length === 0) {
    mainContent = (
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          py: theme.spacing(2.5),
          px: theme.spacing(3.5),
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Typography sx={{ m: 0, marginBottom: theme.spacing(1) }}>
          There are no answers that match your filters. Please try filtering
          again.
        </Typography>
        <Stack direction={'row'}>
          <UniversalButton
            size="large"
            stylingType={6}
            onClick={resetFiltersHandler}
            sx={{ m: 0 }}
          >
            <Typography sx={{ m: 0, color: theme.palette.primary.main }}>
              Reset filters
            </Typography>
          </UniversalButton>
        </Stack>
      </Box>
    )
  } else {
    mainContent = (
      <>
        {isAssignReviewerMode && <AssingReviewerContainer />}
        <NewDesignUniversalTable
          headRowBgColor={theme.palette.grey[100]}
          tableHeaders={jsxElementsTableHead}
          tableBodyRows={jsxTableRowsContent}
        />
        {quantityOfPages > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: theme.spacing(3),
            }}
          >
            <Pagination
              count={quantityOfPages}
              size="large"
              page={currentPageIndex}
              onChange={changePageHandler}
            />
          </Box>
        )}
      </>
    )
  }

  return (
    <>
      <AnswerRightDrawer />
      {mainContent}
    </>
  )
}

export default AnswersLibraryTabel
