import { AnswersLibraryState } from './answersLibraryInterfaces'

export const initialAnswersLibraryState: AnswersLibraryState = {
  searchInputValue: '',
  isLoadingInSavedAnswers: false,
  isErrorInSavedAnswers: false,
  answerSources: [],
  answersData: [],
  currentOpenAnswer: null,
  isAssignReviewerMode: false,
  filterByLastUpdatedRange: [null, null],
  filterBySearch: null,
  filterBySource: null,
  filterByStatus: null,
  filterByAssignedUser: null,
  assignedReviewers: [],
  filteredAnswers: [],
  filterByIsRequest: '',
  assignedReviewer: 'select',
  selectedIdsOfResponses: [],
  currentPageIndex: 1,
}
