import React from 'react'
import { Typography, useTheme, TypographyProps } from '@mui/material'

interface Props extends TypographyProps {
  component?: React.ElementType<any>
  children: React.ReactNode
}

const TextOnDark: React.FC<Props> = ({ children, ...typographyProps }) => {
  const theme = useTheme()
  return (
    <Typography {...typographyProps} color={theme.palette.primary.contrastText}>
      {children}
    </Typography>
  )
}

export default TextOnDark
