import React, { useContext, useState } from 'react'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import QuestionHeading from '../components/questionResponse/QuestionHeading/QuestionHeading'
import { Box, CircularProgress } from '@mui/material'
import content from '../assets/content/content'
import theme from '../themeData/themeData'
import UniversalButton from '../components/ui/Buttons/UniversalButton'
import { useAppSelector } from '../hooks/hooks'
import SimilarQuestionRightDrawer from '../components/similarQuestions/SimilarQuestionsTable/SimilarQuestionRightDrawer/SimilarQuestionRightDrawer'
import { AnswersLibraryContext } from '../contexts/AnswersLibraryContext/answers-library-context'
import AnswerPreviewContexProvider from '../contexts/AnswerPreview/AnswerPreviewContexProvider'
import {
  AnswerItem,
  GenerateSimilarQuestionsRequestItem,
} from '../types/interfaces'
import UncheckedAnswerSection from '../components/ReviewUncheckedAnswer/UncheckedAnswerSection/UncheckedAnswerSection'
import SimilarQuestionsSectionInUncheckedAnswer from '../components/ReviewUncheckedAnswer/SimilarQuestionsSectionInUncheckedAnswer/SimilarQuestionsSectionInUncheckedAnswer'

const ReviewUncheckedAnswer: React.FC = () => {
  const { role, isAskQuestionModeBlocked } = useAppSelector(state => state.user)

  const {
    state: { currentOpenAnswer, answersData, isLoadingInSavedAnswers },
    actions: { onCloseAnswer },
  } = useContext(AnswersLibraryContext)

  const [
    currentOpenInDrawerIdOfSimilarReq,
    setCurrentOpenInDrawerIdOfSimilarReq,
  ] = useState<string>('')

  const [similarRequests, setSimilarRequests] = useState<
    GenerateSimilarQuestionsRequestItem[]
  >([])

  const setCurrentOpenIdHandler = (id: string) => {
    setCurrentOpenInDrawerIdOfSimilarReq(id)
  }

  const closeSimilarQuestionDrawer = () => {
    setCurrentOpenInDrawerIdOfSimilarReq('')
  }

  const {
    eng: {
      views: {
        similarQuestions: { title, heading },
      },
    },
  } = content

  const answerDataToPass: AnswerItem | undefined = answersData.find(
    answerItem => {
      return answerItem.id === currentOpenAnswer
    }
  )

  if (answerDataToPass) {
    return (
      <AnswerPreviewContexProvider {...answerDataToPass}>
        <MainContentContainer maxWidth="xl">
          <Box sx={{ mb: theme.spacing(4) }}>
            <UniversalButton
              stylingType={6}
              onClick={onCloseAnswer}
              label="< Return to list"
            />
          </Box>
          <QuestionHeading />
          <UncheckedAnswerSection />
          <SimilarQuestionsSectionInUncheckedAnswer
            requestId={answerDataToPass.request.id}
            onSetSimilarRequests={setSimilarRequests}
            onSetCurrenOpenSimilarQuestion={setCurrentOpenIdHandler}
          />
          {currentOpenInDrawerIdOfSimilarReq !== '' && (
            <SimilarQuestionRightDrawer
              isOpen={currentOpenInDrawerIdOfSimilarReq !== ''}
              onCloseDrawer={closeSimilarQuestionDrawer}
              dataTodisplay={
                similarRequests.find(
                  item => currentOpenInDrawerIdOfSimilarReq === item.id
                )!
              }
            />
          )}
        </MainContentContainer>
      </AnswerPreviewContexProvider>
    )
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }
}

export default ReviewUncheckedAnswer
