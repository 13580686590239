import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  ButtonProps,
  Chip,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { ThemeContext } from '@emotion/react'
import TextOnDark from '../../ui/Typographies/TextOnDark'
import { useLocation } from 'react-router-dom'
import GetHelpBox from './GetHelpBox'

interface Props {
  positionProperties: {
    position: 'fixed' | 'relative' | 'absolute'
    bottom?: string
    right?: string
    left?: string
  }
  onGetHelpPillClick: () => void
}

const GetHelpPill: React.FC<Props> = ({
  positionProperties,
  onGetHelpPillClick,
}) => {
  const theme = useTheme()
  return (
    <Button
      onClick={onGetHelpPillClick}
      sx={{
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(3),
        alignItems: 'center',
        borderRadius: '40px',
        ...positionProperties,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: theme.palette.tertiary.light,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={'12px'}
      >
        <HelpIcon sx={{ fill: 'white' }} />
        <TextOnDark sx={{ m: 0, fontWeight: 500 }}>Get Help</TextOnDark>
      </Stack>
    </Button>
  )
}

export default GetHelpPill
