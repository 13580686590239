import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { convertDateToNiceFormat } from '../../../../../utils/utils'

const RequestedQuestionItem: React.FC<{
  id: string
  question: string
  created_at: string
  updated_at: string
}> = ({ question, created_at }) => {
  const theme = useTheme()
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!
  return (
    <>
      <Box
        sx={{
          borderRight: `1px solid ${borderColor}`,
        }}
      >
        <Typography>{question}</Typography>
      </Box>
      <Box sx={{ justifyItems: 'center' }}>
        <Typography>{convertDateToNiceFormat(created_at)}</Typography>
      </Box>
    </>
  )
}

export default RequestedQuestionItem
