import React from 'react'
import { Box, TextField, TextFieldProps, Typography } from '@mui/material'

interface Props {
  id: string
  title: string
  explanation?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  textFildProps?: TextFieldProps
}

const TextInput: React.FC<Props> = ({
  id,
  title,
  explanation,
  value,
  onChange,
  textFildProps,
}) => {
  return (
    <Box id={id}>
      <Typography component={'h3'} variant="h6" sx={{ m: 0 }}>
        {title}
      </Typography>
      {explanation && (
        <Typography variant="body2" sx={{ m: 0 }}>
          {explanation}
        </Typography>
      )}
      <TextField
        id={`input_${id}`}
        variant="outlined"
        fullWidth
        size="small"
        label={title}
        value={value ? value : ''}
        onChange={onChange}
        {...textFildProps}
      />
    </Box>
  )
}

export default TextInput
