import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FileAccess, FileDetails } from '../types/interfaces'
import type { RootState } from '../store/store'

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/files/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['File'],
  endpoints: build => ({
    getAccessToFile: build.mutation<FileAccess, FileDetails['id']>({
      query: fileId => ({
        url: `/${fileId}`,
        method: 'GET',
      }),
      invalidatesTags: ['File'],
    }),
  }),
})

export const { useGetAccessToFileMutation } = fileApi
