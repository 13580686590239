import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const LoadingInsideComponent: React.FC = () => {
  return (
    <Box
      component={'article'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '350px',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default LoadingInsideComponent
