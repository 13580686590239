import React, { useContext, useState } from 'react'
import { Box, Typography, useTheme, Link, Chip } from '@mui/material'
import { FileDetails, SupportiveEvidence } from '../../../../types/interfaces'
import { useAppSelector } from '../../../../hooks/hooks'
import { useLocation } from 'react-router-dom'
import { OpenInNew } from '@mui/icons-material'
import { SupportiveEvidenceContext } from '../../../../contexts/SupportiveEvidenceContext/supportive-evidence-contex'
import {
  checkIsItPdfFile,
  processFileNameFromUrl,
} from '../../../../utils/utils'
import UniversalCheckBoxButton from '../../../ui/Buttons/UniversalCheckBoxButton'
import PDFpreview from '../../FoundEvidenceSection/EvidenceItem/PDFpreview'
import ConfirmRemovalEvidenceDialog from '../../FoundEvidenceSection/ConfirmRemovalEvidenceDialog/ConfirmRemovalEvidenceDialog'
import { IRemovalEvidenceDialogState } from '../../FoundEvidenceSection/EvidenceTable/FoundEvidenceTable'

interface Props extends SupportiveEvidence {
  index: number
  removalDialogState: IRemovalEvidenceDialogState
  onClickRemoveEvidenceCheckbox: () => void
}

const SuppEviInAnsGenItem: React.FC<Props> = props => {
  const theme = useTheme()
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let evidenceHeading: JSX.Element = <></>
  let headingContent = ''

  const location = useLocation()
  const { pathname } = location
  const { role } = useAppSelector(state => state.user)
  const {
    state: {
      isPdfPreviewShown,
      isErrorInPreviewPdfFromCompanysServer,
      isErrorInDisplayingPdfScreenshot,
    },
    actions: {
      onClickEvidenceHeading,
      onShowPdfPreview,
      onErrorInPreviewPdfFromCompanysServer,
      onErrorInDisplayingPdfScreenshot,
    },
  } = useContext(SupportiveEvidenceContext)

  let supportiveEvidenceSentence = ''
  let supportiveEvidenceHtmlContent = ''
  const isSimilarQuestions = pathname === '/similar-questions'

  let pageNumber = props.page_number
  let labelType = ''
  let isItPdfFile = false
  let evidenceUrl: string | null = null
  let evidenceFileDetails: FileDetails | null = null
  let pdfsScreenshotRedirectItem: JSX.Element = <></>

  if (props.evidence_chunk) {
    const { evidence, text, type } = props.evidence_chunk
    if (evidence) {
      const { category, url, file, id } = evidence
      supportiveEvidenceSentence = text
      if (url || file) {
        if (url) {
          isItPdfFile = checkIsItPdfFile(url)
        } else if (file) {
          isItPdfFile = checkIsItPdfFile(file.path)
        }
      }
      labelType = `${type[0]}${type.slice(-type.length + 1).toLowerCase()}`

      if (category) {
        headingContent = category
      } else if (file && file.friendly_name) {
        headingContent = file.friendly_name
      } else if (file && file.original_file_name) {
        headingContent = file.original_file_name
      } else if (file && file.path) {
        headingContent = processFileNameFromUrl(file.path)
      } else if (url) {
        headingContent = url
      } else {
        headingContent = id
      }

      if (url) {
        pdfsScreenshotRedirectItem = (
          <Link
            href={pageNumber ? `${url}#page=${pageNumber}` : url}
            target="_blank"
          >
            <img
              style={{
                maxWidth: '300px',
                cursor: 'pointer',
                border: `1px solid ${theme.palette.grey[400]}`,
                boxShadow: `0px 5px 21px -10px rgba(147, 147, 152, 1)`,
              }}
              onError={onErrorInDisplayingPdfScreenshot}
              src={props.image!}
              alt="Evidence Preview"
              loading="lazy"
            />
          </Link>
        )
        evidenceUrl = url
        evidenceHeading = (
          <Link
            href={pageNumber ? `${url}#page=${pageNumber}` : url}
            target="_blank"
            sx={{
              fontSize: '20px',
              lineHeight: '25px',
              wordBreak: 'break-all',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {headingContent} <OpenInNew sx={{ ml: '10px' }} />
          </Link>
        )
      } else if (file) {
        pdfsScreenshotRedirectItem = (
          <img
            onClick={onClickEvidenceHeading}
            onError={onErrorInDisplayingPdfScreenshot}
            style={{
              maxWidth: '300px',
              cursor: 'pointer',
              border: `1px solid ${borderColor}`,
              boxShadow: `0px 5px 21px -10px rgba(147, 147, 152, 1)`,
            }}
            src={props.image!}
            alt="Evidence Preview"
            loading="lazy"
          />
        )
        evidenceFileDetails = file
        evidenceHeading = (
          <Typography
            onClick={onClickEvidenceHeading}
            component={'h3'}
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 400,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {headingContent}
            <OpenInNew sx={{ ml: '10px' }} />
          </Typography>
        )
      } else {
        evidenceHeading = <Typography>{headingContent}</Typography>
        pdfsScreenshotRedirectItem = (
          <img
            onError={onErrorInDisplayingPdfScreenshot}
            style={{ maxWidth: '300px', cursor: 'pointer' }}
            src={props.image!}
            alt="Evidence Preview"
            loading="lazy"
          />
        )
      }
    }
  }

  const isViewer = role === 'VIEWER'

  return (
    <>
      <Box
        sx={{
          ':not(:last-child)': { borderBottom: `1px solid ${borderColor}` },
        }}
      >
        <Box
          sx={{
            borderBottom: `1px solid ${borderColor}`,
            display: 'grid',
            gridTemplateColumns: '7.44fr 1.14fr 2fr',
            '>div': {
              px: theme.spacing(3),
              py: theme.spacing(1),
              display: 'flex',
              alignItems: 'flex',
            },
            '>div:not(:last-child)': {
              borderRight: `1px solid ${borderColor}`,
            },
          }}
        >
          <Box>{evidenceHeading}</Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Chip
              size="small"
              sx={{
                backgroundColor:
                  labelType === 'Private'
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
              }}
              label={labelType}
            />
          </Box>
          {pageNumber !== null && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography>Page: {pageNumber} </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns:
              isItPdfFile && isPdfPreviewShown ? '1fr 330px' : '1fr',
          }}
        >
          <Box
            sx={{
              px: theme.spacing(3),
              py: theme.spacing(2),
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            {supportiveEvidenceSentence && (
              <Typography>{supportiveEvidenceSentence}</Typography>
            )}
            {!isViewer && !isSimilarQuestions && (
              <Box sx={{ mt: theme.spacing(1) }}>
                <UniversalCheckBoxButton
                  isChecked={props.removalDialogState.isDialogOpen}
                  onClick={props.onClickRemoveEvidenceCheckbox}
                  label="Remove this evidence. It is not relevant to the question."
                />
              </Box>
            )}
          </Box>
          {isItPdfFile && (
            <Box
              sx={{
                py: '20px',
                display:
                  isPdfPreviewShown && !isErrorInDisplayingPdfScreenshot
                    ? 'flex'
                    : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.secondary.main,
                borderLeft: `1px solid ${borderColor}`,
              }}
            >
              {!isErrorInPreviewPdfFromCompanysServer ? (
                <PDFpreview
                  onPreviewShow={onShowPdfPreview}
                  onLoadError={onErrorInPreviewPdfFromCompanysServer}
                  fileUrl={
                    evidenceUrl
                      ? evidenceUrl
                      : evidenceFileDetails
                      ? evidenceFileDetails.path
                      : ''
                  }
                  isUrlConfidential={evidenceFileDetails ? true : false}
                  pageNumber={pageNumber ? pageNumber : 0}
                  evidenceId={props.id}
                  fileId={evidenceFileDetails ? evidenceFileDetails.id : null}
                />
              ) : (
                pdfsScreenshotRedirectItem
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default SuppEviInAnsGenItem
