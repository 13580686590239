import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  FormHelperText,
  ListItemText,
  Checkbox,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

interface Props {
  label?: string
  options: { value: string; label: string }[]
  helper?: string
  sx?: SxProps
  onChange?: (newValue: string[]) => void
  currentValue: string[]
  isRequired?: boolean
  hideLabel?: boolean
  defaultValue?: string[]
  isDisabled?: boolean
}

const SelectMulInput: React.FC<Props> = ({
  label,
  options,
  sx,
  helper,
  onChange,
  currentValue,
  isRequired,
  hideLabel,
  defaultValue,
  isDisabled,
}) => {
  let mapping = {}

  options.forEach(option => (mapping[option.value] = option.label))

  const optionsJsxElements = options.map(({ label, value }, index) => {
    return (
      <MenuItem key={`${index}_${label}`} value={value}>
        <Checkbox checked={currentValue.indexOf(value) > -1} />
        <ListItemText primary={label} />
      </MenuItem>
    )
  })

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    if (onChange) {
      onChange(event.target.value as string[])
    }
  }
  return (
    <FormControl disabled={isDisabled} sx={{ div: { marginBottom: 0 }, ...sx }}>
      {!hideLabel && (
        <InputLabel size="small" id={`mul-select-label-${label}`}>
          {label}
        </InputLabel>
      )}
      <Select<string[]>
        labelId={`mul-select-label-${label}`}
        id={`mul-select-label-${label}`}
        multiple
        value={currentValue as string[]}
        label={hideLabel ? '' : label}
        onChange={handleChange}
        size="small"
        renderValue={selected => selected.map(s => mapping[s]).join(', ')}
        required={isRequired ? true : false}
        defaultValue={defaultValue ? defaultValue : []}
      >
        {optionsJsxElements}
      </Select>
      {helper && (
        <FormHelperText sx={{ m: 0, marginBottom: 1 }}>{helper}</FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectMulInput
