import { AskQuestionActions } from './askQuestionInterfaces'
import { SelectChangeEvent } from '@mui/material'

export const initialAskQuestionActions: AskQuestionActions = {
  onInputChange: (newValue: React.ChangeEvent<HTMLInputElement>) => {},
  onAskQuestionFormSubmit: (e: React.SyntheticEvent) => {},
  onCloseBlockedDialog: () => {},
  onOpenBlockedDialog: () => {},
  onSetIsMetric: (newValue: boolean) => {},
  onSetUnit: (eventFromSelect: SelectChangeEvent) => {},
  onCloseErrorNotification: () => {},
}
