import React, { useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { useGetSurveysQuery } from '../../../services/surveys'
import { checkIfItIsSpecificFileNameToTriggerProgressBar } from '../../../utils/utils'
import { appActions } from '../../../store/App/app-slice'
import { DashBoardData } from '../../../types/interfaces'
import DashboardMainItem from '../DashboradMainItem/DashboardMainItem'
import DashboardSecondaryItem from '../DashboardSecondaryItem/DashboardSecondaryItem'

interface Props {
  dasboardData: DashBoardData | undefined
  isEvidenceListsEmpty?: boolean
}

export interface DasboardMainSubItem {
  title: string
  quantity: number
}

export interface DashboardMainItemInterface {
  title: string
  mainNumber: number
  mainNumberText: string
  url: string
  subItems: DasboardMainSubItem[]
}

export interface DasboardSecondaryItemSubItem {
  label: string
  quantity: number
  link: string
  items: DashBoardData['requests_in_progress']
}

export interface DashboardSecondaryItemInterface {
  title: string
  subItems: DasboardSecondaryItemSubItem[]
  firstCellBackgroundColor: string
}

const DashboardItems: React.FC<Props> = ({
  dasboardData,
  isEvidenceListsEmpty,
}) => {
  const { username, companyName, role, isAskQuestionModeBlocked, userId } =
    useAppSelector(state => state.user)
  const { isDemoMode } = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()
  const {
    data: surveysData,
    isLoading: isGetAllQuestionairesLoading,
    isError: isGetAllQuestionairesError,
    error: getAllQuestionairesError,
  } = useGetSurveysQuery()

  const theme = useTheme()

  const isViewer = role === 'VIEWER'

  useEffect(() => {
    if (surveysData && companyName) {
      surveysData.forEach(survey => {
        const { file } = survey
        if (file) {
          const isDemoItem = checkIfItIsSpecificFileNameToTriggerProgressBar(
            companyName,
            file.original_file_name
          )
          if (isDemoItem) {
            dispatch(appActions.switchOnDemoMode())
          }
        }
      })
    }
  }, [surveysData, companyName])

  const PublishedWithChangesIconJSX = PublishedWithChangesIcon
  const LibraryAddCheckIconJSX = LibraryAddCheckIcon

  let dashboardMainItems: DashboardMainItemInterface[] = []

  if (role === 'ADMIN' || role === 'EDITOR' || role === 'STUFF') {
    dashboardMainItems = [
      {
        title: 'Answers',
        mainNumber: dasboardData!.responses_approved,
        mainNumberText: 'Approved answers',
        url: `/answers-library`,
        subItems: [
          {
            title: 'Needs review',
            quantity: dasboardData!.responses_unreviewed,
          },
          {
            title: 'Rejected / Regenerating ',
            quantity:
              dasboardData!.responses_rejected_checked +
              dasboardData!.responses_rejected_reviewed,
          },
        ],
      },
      {
        title: 'Surveys',
        mainNumber: isDemoMode
          ? 0
          : dasboardData!.surveys_ready!.length +
            dasboardData!.surveys_processing!.length,
        mainNumberText: 'Completed surveys',
        url: '/surveys',
        subItems: [
          {
            title: 'Ready',
            quantity: isDemoMode
              ? dasboardData!.surveys_ready!.length - 1
              : dasboardData!.surveys_ready!.length,
          },
          {
            title: 'In processing',
            quantity: isDemoMode ? 0 : dasboardData!.surveys_processing!.length,
          },
          // {
          //   title: 'Expired',
          //   quantity: dasboardData!.surveys_processing!.length,
          // },
        ],
      },
      {
        title: 'Evidence',
        mainNumber:
          dasboardData!.public_evidences!.length +
          dasboardData!.private_evidences!.length,
        mainNumberText: 'Evidence documents',
        url: '/evidence-library',
        subItems: [
          {
            title: 'Public documents',
            quantity: dasboardData!.public_evidences!.length,
          },
          {
            title: 'Private documents',
            quantity: dasboardData!.private_evidences!.length,
          },
        ],
      },
    ]
  } else if (role === 'CONTRIBUTOR') {
    dashboardMainItems = [
      {
        title: 'Answers',
        mainNumber: dasboardData!.responses_approved,
        mainNumberText: 'Approved answers',
        url: `/answers-library`,
        subItems: [
          {
            title: 'Needs review',
            quantity: dasboardData!.responses_unreviewed,
          },
          {
            title: 'Rejected / Regenerating ',
            quantity:
              dasboardData!.responses_rejected_checked +
              dasboardData!.responses_rejected_reviewed,
          },
        ],
      },
      {
        title: 'Surveys',
        mainNumber: isDemoMode
          ? 0
          : dasboardData!.surveys_ready!.length +
            dasboardData!.surveys_processing!.length,
        mainNumberText: 'Completed surveys',
        url: '/surveys',
        subItems: [
          {
            title: 'Ready',
            quantity: isDemoMode
              ? dasboardData!.surveys_ready!.length - 1
              : dasboardData!.surveys_ready!.length,
          },
          {
            title: 'In processing',
            quantity: isDemoMode ? 0 : dasboardData!.surveys_processing!.length,
          },
          // {
          //   title: 'Expired',
          //   quantity: dasboardData!.surveys_processing!.length,
          // },
        ],
      },
      {
        title: 'Evidence',
        mainNumber:
          dasboardData!.public_evidences!.length +
          dasboardData!.private_evidences!.length,
        mainNumberText: 'Evidence documents',
        url: '/evidence-library',
        subItems: [
          {
            title: 'Public documents',
            quantity: dasboardData!.public_evidences!.length,
          },
          {
            title: 'Private documents',
            quantity: dasboardData!.private_evidences!.length,
          },
        ],
      },
    ]
  } else {
    dashboardMainItems = [
      {
        title: 'Answers',
        mainNumber: dasboardData!.responses_approved,
        mainNumberText: 'Approved answers',
        url: `/answers-library`,
        subItems: [],
      },
      {
        title: 'Surveys',
        mainNumber: isDemoMode
          ? 0
          : dasboardData!.surveys_ready!.length +
            dasboardData!.surveys_processing!.length,
        mainNumberText: 'Completed surveys',
        url: '/surveys',
        subItems: [],
      },
      {
        title: 'Evidence',
        mainNumber:
          dasboardData!.public_evidences!.length +
          dasboardData!.private_evidences!.length,
        mainNumberText: 'Evidence documents',
        url: '/evidence-library',
        subItems: [],
      },
    ]
  }

  let dahboardSecondaryItems: DashboardSecondaryItemInterface[] = []

  if (role === 'ADMIN' || role === 'EDITOR' || role === 'STUFF') {
    dahboardSecondaryItems = [
      {
        title: 'Answers to assign for review',
        firstCellBackgroundColor: theme.palette.tertiary.main,
        subItems: [
          {
            label: `Unassigned answers`,
            quantity: dasboardData!.responses_unassigned,
            link: '/answers-library/null/null/null/CHECKED/null/null/null/null',
            items: [],
          },
          {
            label: `Assigned awaiting my approval`,
            quantity: dasboardData!.responses_reviewed,
            link: '/answers-library/null/null/null/REVIEWED/null/null/null/null',
            items: [],
          },
        ],
      },
      {
        title: `Answers assigned to me for review/approval`,
        firstCellBackgroundColor: theme.palette.secondary.contrastText,
        subItems: [
          {
            label: `Answers for surveys`,
            quantity: dasboardData!.answers_for_surveys,
            link: `/answers-library/null/null/2/CHECKED_ASSIGNED/${userId}/null/null/null`,
            items: [],
          },
          {
            label: `Answers for questions I have asked`,
            quantity: dasboardData!.answers_for_user_questions,
            link: `/answers-library/null/null/1/CHECKED_ASSIGNED/${userId}/null/null/null`,
            items: [],
          },
          {
            label: `Answers for questions requested by others`,
            quantity: dasboardData!.answers_for_others_questions,
            link: '/answers-library/null/null/1/CHECKED_ASSIGNED/null/null/null/null',
            items: [],
          },
        ],
      },
      {
        title: 'My questions',
        firstCellBackgroundColor: theme.palette.secondary.main,
        subItems: [
          {
            label: `Complete / Questions with approved answers`,
            quantity: dasboardData!.requests_completed,
            link: '/answers-library/null/null/1/APPROVED/null/null/null/null',
            items: [],
          },
          {
            label: `In processing`,
            quantity: dasboardData!.requests_in_progress.length,
            link: '',
            items: dasboardData!.requests_in_progress,
          },
        ],
      },
    ]
  } else if (role === 'CONTRIBUTOR') {
    dahboardSecondaryItems = [
      {
        title: `Answers assigned to me for review`,
        firstCellBackgroundColor: theme.palette.secondary.contrastText,
        subItems: [
          {
            label: `Answers for surveys`,
            quantity: dasboardData!.answers_for_surveys,
            link: `/answers-library/null/null/2/CHECKED_ASSIGNED/${userId}/null/null/null`,
            items: [],
          },
          {
            label: `Answers for questions I have asked`,
            quantity: dasboardData!.answers_for_user_questions,
            link: `/answers-library/null/null/1/CHECKED_ASSIGNED/${userId}/null/null/null`,
            items: [],
          },
          {
            label: `Answers for questions requested by others`,
            quantity: dasboardData!.answers_for_others_questions,
            link: '/answers-library/null/null/1/CHECKED_ASSIGNED/null/null/null/null',
            items: [],
          },
        ],
      },
      {
        title: 'My questions',
        firstCellBackgroundColor: theme.palette.secondary.main,
        subItems: [
          {
            label: `Complete / Questions with approved answers`,
            quantity: dasboardData!.requests_completed,
            link: '/answers-library/null/null/1/APPROVED/null/null/null/null',
            items: [],
          },
          {
            label: `In processing`,
            quantity: dasboardData!.requests_in_progress.length,
            link: '',
            items: dasboardData!.requests_in_progress,
          },
        ],
      },
    ]
  } else {
    dahboardSecondaryItems = [
      {
        title: 'My questions',
        firstCellBackgroundColor: theme.palette.secondary.main,
        subItems: [
          {
            label: `Complete / Questions with approved answers`,
            quantity: dasboardData!.requests_completed,
            link: '/answers-library/null/null/1/APPROVED/null/null/null/null',
            items: [],
          },
          {
            label: `In processing`,
            quantity: dasboardData!.requests_in_progress.length,
            link: '',
            items: dasboardData!.requests_in_progress,
          },
        ],
      },
    ]
  }

  const dashboardMainJsxItems = dashboardMainItems.map(
    (dashboardItem, index) => {
      const { title } = dashboardItem
      return <DashboardMainItem key={`${title}_${index}`} {...dashboardItem} />
    }
  )

  const dashboardSecondaryJsxItems = dahboardSecondaryItems.map(
    (dashboardItem, index) => {
      const { title } = dashboardItem
      return (
        <DashboardSecondaryItem key={`${index}_${title}`} {...dashboardItem} />
      )
    }
  )

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: theme.spacing(4),
        }}
      >
        {/* {dashBoardJSXelements} */}
        {dashboardMainJsxItems}
      </Box>
      <Box>{dashboardSecondaryJsxItems}</Box>
    </>
  )
}
export default DashboardItems
