import React from 'react'
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const TermsAndConditions: React.FC = () => {
  const theme = useTheme()

  const {
    eng: {
      views: {
        termsAndConditions: { heading },
      },
    },
  } = content

  const listItems: string[] = [
    'it is, or becomes, generally available to the public other than as a direct or indirect result of the information being disclosed to us in breach of these terms;',
    'it was available to us on a non-confidential basis prior to disclosure by you or your Organisation or Another Representative;',
    'it was, is, or becomes, available to us on a non-confidential basis from a person who is not under any confidentiality obligation in respect of that information;',
    'it was lawfully in our possession before the information was obtained from you or your Organisation or Another Representative;',
    'you or your Organisation or Another Representative expressly agree in writing that the information is not confidential.',
  ]

  const JSXListElements = listItems.map((listItemText, index) => {
    return (
      <ListItem
        key={listItemText[0] + index}
        sx={{ span: { color: theme.palette.primary.dark } }}
      >
        <ListItemIcon>
          <FiberManualRecordIcon
            fontSize="small"
            sx={{ color: theme.palette.primary.dark }}
          />
        </ListItemIcon>

        <ListItemText sx={{ m: 0, span: { m: 0 } }} primary={listItemText} />
      </ListItem>
    )
  })

  return (
    <>
      <MainContentContainer maxWidth="md">
        <Box component={'article'}>
          <Typography
            sx={{ my: theme.spacing(4) }}
            component={'h1'}
            variant={'h4'}
          >
            {heading}
          </Typography>
          <Typography sx={{ my: theme.spacing(4), fontWeight: 'bold' }}>
            Please read these terms carefully.
          </Typography>
          <Typography sx={{ my: theme.spacing(4), fontWeight: 500 }}>
            We recommend that you print or otherwise keep a copy of these terms
            in a form that you can store and see them for future reference.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              EthicsAnswer is provided and operated by EthicsGrade Limited (we,
              us).{' '}
            </Typography>
            We are a company registered in England and Wales under company
            number 13749368 and have our registered office at Llwyncalennig
            Alltwalis Road, Alltwalis, Carmarthen, Wales, SA32 7DZ. You can
            contact us by writing to us at support&#64;ethicsanswer&#46;com.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              EthicsAnswer is a prediction engine that provides an answer to any
              Environmental sustainability, Social justice or corporate
              Governance (ESG) question on any company for any stakeholder.{' '}
            </Typography>
            Our customers or their representatives can simply copy and paste
            into the engine questions from any survey, questionnaire, or adhoc
            source, and let EthicsAnswer generate answers using its unique
            AI-based platform.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              In these terms, you warrant that you are a representative of an
              EthicsAnswer customer (your Organisation){' '}
            </Typography>
            that wants to use EthicsAnswer to receive answers{' ('}
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Answers
            </Typography>
            {') '}relating to your Organisation who is EthicsAnswer's customer
            {' ('} the
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              {' '}
              Subject Organisation
            </Typography>
            {`)`}.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              {' '}
              These terms tell you how we use the confidential information you
              or someone else representing your Organisation (Another
              Representative) give us when using EthicsAnswer,{' '}
            </Typography>
            and other important information. These terms only relate to the
            supply of the data by you or your Organisation or Another
            Representative and the use of the Answers.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Our payment terms{' '}
            </Typography>
            and information about fees for using EthicsAnswer can be found here{' '}
            <Link href="https://ethicsanswer.com/pricing">
              www.ethicsanswer.com/pricing
            </Link>{' '}
            and your and your Organisation's use of EthicsAnswer is subject to
            your Organisation's payments of our fees under that document.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Confidential Information{' '}
            </Typography>
            - To generate the most accurate answers using EthicsAnswer, you may
            provide us with non-public information about the Subject
            Organisation, such as their internal policies and procedures (the{' '}
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Confidential Information
            </Typography>
            ). EthicsAnswer digests the Confidential Information together with
            information in the public domain (the{' '}
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Non-Confidential Information
            </Typography>
            ) to generate Answers.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              The Confidential Information, and any Answers generated for you,
              remain visible only to you and us.
            </Typography>
            This data is also stored on our server in an aggregated,
            non-identifying form to train EthicsAnswer using machine learning,
            which helps EthicsAnswer to improve its future performance. We may
            also store this data to be able to generate additional similar
            answers to similar questions for you and Your Organisation and
            Another Representative in the future. One further use is for
            analysis on what you or Another Representative has provided - such
            as the fact that the Subject Organisation has a policy on something
            - but not the content of the data provided such as the policy itself
            - for disclosure to third parties. Together with generating Answers,
            we will use your Confidential Information (and the Answers) only in
            these ways (the{' '}
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Purpose
            </Typography>
            ).
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Non-Confidential Information{' '}
            </Typography>
            - We may use Non-Confidential Information in any way and for any
            purpose. We may also use aggregated data that does not identify you,
            or the Subject Organisation, in any way and for any purpose.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Use of Confidential Information{' '}
            </Typography>
            - We will use your Confidential Information strictly for the
            Purpose. We will not disclose or provide access to any Confidential
            Information to any other person or party (other than Another
            Representative) without your or your Organisation's or Another
            Representative's express prior written consent.
          </Typography>
          <Typography sx={{ mt: theme.spacing(4), mb: 0 }}>
            However,{' '}
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              these restrictions shall not apply to any part of your
              Confidential Information if we can show that:
            </Typography>
          </Typography>
          <List dense={true} sx={{ m: 0, p: 0 }}>
            {JSXListElements}
          </List>
          <Typography sx={{ my: theme.spacing(4) }}>
            You may copy Answers to your clipboard, amend them and use them
            elsewhere as you see fit. However,{' '}
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              {' '}
              we make no warranty, promise, statement or representation as to
              the accuracy or completeness of the Answers{' '}
            </Typography>
            or their fitness for any purpose or of the rights of any nature in
            the Confidential Information you provide, and you should take care
            accordingly. The Answers are intended as a tool, but we cannot
            guarantee their accuracy or completeness, and you should check the
            Answers carefully before using them. You may want to provide
            EthicsAnswer with further data in order to benefit from even better
            Answers.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              You, or any person who provides us with Confidential Information
              on your behalf, warrant that you are authorised to provide that
              information on behalf of the Subject Organisation.
            </Typography>
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              We will apply no lower level of security measures and degree of
              care to the Confidential Information as we apply to our own
              confidential information,{' '}
            </Typography>
            which in any event shall involve using at least reasonable skill and
            care from unauthorised disclosure, copying or use.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              You reserve all rights in the Confidential Information.{' '}
            </Typography>
            As between you and us, unless otherwise agreed in writing, all
            rights of any nature in relation to any of your Confidential
            Information remain vested in you or your Organisation. No licence is
            granted to us other than a non-exclusive licence to use your
            Confidential Information strictly for the Purpose subject to the
            restrictions set out in these terms.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              We shall inform your Organisation in writing upon becoming aware
              of any improper or wrongful or unauthorised use of the
              Confidential Information,{' '}
            </Typography>
            and we shall co-operate with you in every reasonable way to help you
            regain possession of the Confidential Information and prevent its
            further use.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              We limit our liability, to the greater of £100 or the amount paid
              or payable to us for each particular Answer provided,{' '}
            </Typography>
            in relation to any loss or damage, whether in contract, tort,
            negligence, breach of statutory duty, misrepresentation, restitution
            or otherwise, and whether or not foreseeable, arising under or in
            connection with the use of or inability to use EthicsAnswer; or the
            use of or reliance on any Answer it generates. We do not in any
            event exclude or limit in any way our liability to you where it
            would be unlawful to do so, including (without limitation) for death
            or personal injury caused by our negligence, or for fraud or
            fraudulent misrepresentation.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              We are not responsible for events outside our control.{' '}
            </Typography>
            If our provision of EthicsAnswer or support for EthicsAnswer is
            delayed by an event outside our control, then we shall take steps as
            soon as possible to minimise the effect of the delay. However, we
            shall not be liable for delays caused by the event but if there is a
            risk of substantial delay then you may contact us to receive a
            refund for any Answers you have paid for but not received.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Severability -{' '}
            </Typography>
            If any provision or part-provision of these terms is or becomes
            invalid, illegal or unenforceable, it shall be deemed deleted, but
            that shall not affect the validity and enforceability of the rest of
            these terms.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Third Party Rights -{' '}
            </Typography>
            These terms do not give rise to any rights under the Contracts
            (Rights of Third Parties) Act 1999 to enforce any of these terms.
            Your rights and ours to rescind or vary these terms are not subject
            to the consent of any other person.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Governing Law -{' '}
            </Typography>
            These terms and any dispute or claim arising out of or in connection
            with them or their subject matter or formation (including without
            limitation non-contractual disputes or claims) shall be governed by
            and construed in accordance with the law of England and Wales.
          </Typography>
          <Typography sx={{ my: theme.spacing(4) }}>
            <Typography component={'span'} sx={{ fontWeight: 500 }}>
              Jurisdiction -{' '}
            </Typography>
            It is a matter for the exclusive jurisdiction of the courts of
            England and Wales to settle any dispute or claim arising out of or
            in connection with these terms or its subject matter or formation.
          </Typography>
        </Box>
      </MainContentContainer>
    </>
  )
}

export default TermsAndConditions
