import React from 'react'
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material'

interface Props {
  label: string
}

const BulletItem: React.FC<Props> = ({ label }) => {
  const theme = useTheme()
  const separatedText = label
    .split('\n')
    .map((splittedText: string) => splittedText.trim())

  const bulletTexts = separatedText.map((textInLine, index) => (
    <ListItemText
      key={`${index}_${textInLine[0]}`}
      primary={textInLine}
      primaryTypographyProps={{ m: 0 }}
      sx={{ m: 0 }}
    />
  ))

  return (
    <ListItem
      sx={{
        p: 0,
        my: theme.spacing(1),
        dispaly: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <ListItemIcon
        sx={{
          m: 0,
          ml: theme.spacing(1),
          minWidth: '25px',
          mt: '7px',
        }}
      >
        <FiberManualRecord
          fontSize="small"
          sx={{ color: theme.palette.primary.dark, fontSize: '10px' }}
        />
      </ListItemIcon>
      <Box>{bulletTexts}</Box>
    </ListItem>
  )
}

export default BulletItem
