import { createContext } from 'react'

import { AnswersLibraryContextSchema } from './answersLibraryInterfaces'
import { initialAnswersLibraryState } from './initialAnswersLibraryState'
import { initialAnswersLibraryActions } from './initialAnswersLibraryActions'

const AnswersLibraryInitialContext: AnswersLibraryContextSchema = {
  state: initialAnswersLibraryState,
  bulkQueryResults: { isLoadingAssignReviewer: false },
  queryResults: {
    isFetching: false,
  },
  actions: initialAnswersLibraryActions,
}

export const AnswersLibraryContext = createContext(AnswersLibraryInitialContext)
