import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ChartData, VisualisationsData } from '../types/interfaces'
import type { RootState } from '../store/store'

interface VisualisationsGenerateBody {
  survey_ids?: string[] | null
  survey_audience_types?: string[] | null
}

export const visualisationsApi = createApi({
  reducerPath: 'visualisationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/visualisations`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: [`Visualisations`],
  endpoints: build => ({
    getVisualisations: build.query<VisualisationsData, void>({
      query: () => ({
        url: `/`,
      }),
      providesTags: ['Visualisations'],
    }),
    generateVisualisation: build.mutation<string, VisualisationsGenerateBody>({
      query: body => ({
        url: `/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Visualisations'],
    }),
  }),
})

export const { useGetVisualisationsQuery, useGenerateVisualisationMutation } =
  visualisationsApi
