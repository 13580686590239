import { createContext } from 'react'
import { SupportiveEvidenceContextSchema } from './supportiveEvidenceInterfaces'
import { initialSupportiveEvidenceState } from './initialSupportiveEvidenceState'
import { initialSupportiveEvidenceActions } from './initialSupportiveEvidenceActions'

const supportiveEvidenceInitialContext: SupportiveEvidenceContextSchema = {
  state: initialSupportiveEvidenceState,
  results: {
    deleteSupportiveEvidence: {
      isError: false,
      isLoading: false,
      isSuccess: false,
    },
  },
  actions: initialSupportiveEvidenceActions,
}

export const SupportiveEvidenceContext = createContext(
  supportiveEvidenceInitialContext
)
