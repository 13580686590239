import React from 'react'
import { CircularProgress, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../ui/MainContentContainer/MainContentContainer'
import content from '../../assets/content/content'

const LoadingViewForGenerateQuestion: React.FC = () => {
  const {
    eng: {
      views: {
        loadingViewForGenerateQuestion: { title, introduction, additionalInfo },
      },
    },
  } = content
  const theme = useTheme()
  return (
    <>
      <MainContentContainer
        maxWidth="md"
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" component={'h3'}>
          {title}
        </Typography>
        <Typography>{introduction}</Typography>
        <Typography>{additionalInfo}</Typography>
        <CircularProgress sx={{ marginTop: theme.spacing(2) }} size={70} />
      </MainContentContainer>
    </>
  )
}

export default LoadingViewForGenerateQuestion
