import { CompanyMembersContext } from '../../../contexts/CompanyMembersContext/companyMembers-context'
import { Box, Dialog, DialogTitle, IconButton, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { Close } from '@mui/icons-material'

const AddMemberDialog: React.FC = () => {
  const {
    state: { isModalOpen },
    actions: { renderAddMemberForm, onCloseModal },
  } = useContext(CompanyMembersContext)

  const theme = useTheme()

  return (
    <Dialog open={isModalOpen} onClose={onCloseModal} maxWidth="md" fullWidth>
      <Box
        sx={{
          boxSizing: 'border-box',
          minHeight: '370px',

          paddingX: theme.spacing(4),
          paddingY: theme.spacing(2.5),
        }}
      >
        <Box sx={{ textAlign: 'right' }}>
          <IconButton onClick={onCloseModal}>
            <Close />
          </IconButton>
        </Box>
        <DialogTitle sx={{ paddingLeft: 0, m: 0 }}>Add Member</DialogTitle>
        {renderAddMemberForm()}
      </Box>
    </Dialog>
  )
}

export default AddMemberDialog
