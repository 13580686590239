import React, { useContext, useEffect } from 'react'
import { Box, Drawer } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import LoadingInsideComponent from '../components/ui/LoadingInsideComponent/LoadingInsideComponent'
import AnswersLibraryTabel from '../components/AnswersLibrary/AnswersLibraryTabel/AnswersLibraryTabel'
import { AnswersLibraryContext } from '../contexts/AnswersLibraryContext/answers-library-context'
import AnswersLibraryFilters from '../components/AnswersLibrary/AnswersLibraryFilters/AnswersLibraryFilters'
import { useAppDispatch } from '../hooks/hooks'
import { appActions } from '../store/App/app-slice'

const {
  eng: {
    views: {
      answersLibrary: { heading },
    },
  },
} = content

const AnswersLibrary: React.FC = () => {
  const {
    state: { isLoadingInSavedAnswers },
  } = useContext(AnswersLibraryContext)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Answers library'))
  }, [])

  if (isLoadingInSavedAnswers) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <LoadingInsideComponent />
      </Box>
    )
  }

  return (
    <MainContentContainer
      maxWidth="xl"
      sx={{
        alignItems: 'start',
        display: 'flex',
      }}
    >
      <Drawer />
      <AnswersLibraryFilters />
      <AnswersLibraryTabel />
    </MainContentContainer>
  )
}

export default AnswersLibrary
