import React, { useContext } from 'react'
import { AnswerPreviewContext } from '../../contexts/AnswerPreview/answer-preview-contex'
import { Box, SxProps, useTheme } from '@mui/material'
import { AnswerPreviewDisplayTypes } from '../../types/types'
import SupportiveEvidenceSection from './SupportiveEvidenceSection/SupportiveEvidenceSection'
import ApprovalSection from './ApprovalSection/ApprovalSection'
import HeadingAndAnswerSection from './HeadingAndAnswerSection/HeadingAndAnswerSection'
import { useLocation, useNavigate } from 'react-router-dom'
import StatusSection from './StatusSection/StatusSection'
import UniversalButton from '../ui/Buttons/UniversalButton'
import { useAppSelector } from '../../hooks/hooks'
import { SimilarQuestionsContext } from '../../contexts/SimilarQuestionsContext/similar-questions-context'
import SuccessDialog from './SuccessDialog/SuccessDialog'

interface Props {
  displayType: AnswerPreviewDisplayTypes
}
const AnswerPreview: React.FC<Props> = ({ displayType }) => {
  const {
    state: { isEditMode },
    answerData: {
      id,
      questionHeading,
      supportiveEvidence,
      status,
      answerText,
      originalResponse,
      assignedUser,
    },
  } = useContext(AnswerPreviewContext)

  const {
    state: {
      isViewerRequestAnswerApprovalLoading,
      isViewerRequestAnswerApprovalSent,
    },
    actions: { onClickRequestApproveAnswerButton },
  } = useContext(SimilarQuestionsContext)

  const { role, isSuperUser, userId } = useAppSelector(state => state.user)
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location
  const isViewer = role === 'VIEWER'

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const cellPagination: SxProps = {
    px: theme.spacing(2.5),
    py: theme.spacing(2),
  }

  const boxTitleStyling: SxProps = {
    ...cellPagination,
    borderRight: `1px solid ${borderColor}`,
    display: 'flex',
    alignItems: 'center',
  }

  const isSimilarQuestions = pathname === '/similar-questions'

  let canUserSeeSupportiveEvidence = userId === assignedUser?.id
  if (role === 'ADMIN' || role === 'STUFF') {
    canUserSeeSupportiveEvidence = true
  } else if (
    role === 'EDITOR' &&
    (status === 'REVIEWED' || status === 'APPROVED')
  ) {
    canUserSeeSupportiveEvidence = true
  }

  let isShownApprovalSection = false

  let isRegenerating = status === 'REJECTED_CHECKED'

  let isApproved = status === 'APPROVED'

  if (displayType === 'answerUncheckedReview') {
    isShownApprovalSection = false
  } else if (isSuperUser && !isEditMode) {
    isShownApprovalSection = true
  } else if (!isRegenerating && !isEditMode) {
    isShownApprovalSection = true
  }

  const supportiveEvidenceToDisplay = supportiveEvidence.filter(supEvi => {
    const { evidence } = supEvi
    return evidence !== null
  })

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        borderBottom: 'none',
        height: '100%',
      }}
    >
      <HeadingAndAnswerSection
        heading={questionHeading}
        answerText={answerText}
        originalResponse={originalResponse}
        boxTitleStyling={boxTitleStyling}
        cellPagination={cellPagination}
      />

      {isSimilarQuestions ? (
        <StatusSection
          borderColor={borderColor}
          boxTitleStyling={boxTitleStyling}
        />
      ) : (
        isShownApprovalSection && (
          <ApprovalSection
            borderColor={borderColor}
            boxTitleStyling={boxTitleStyling}
          />
        )
      )}
      {!isRegenerating && canUserSeeSupportiveEvidence && (
        <SupportiveEvidenceSection
          cellPagination={cellPagination}
          borderColor={borderColor}
          boxTitleStyling={boxTitleStyling}
          supportiveEvidence={supportiveEvidenceToDisplay}
        />
      )}
      {isSimilarQuestions && !isViewer && !isRegenerating && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: theme.spacing(4),
          }}
        >
          <UniversalButton
            label="Go to Answers Library to modify or approve the answer"
            onClick={() =>
              navigate(
                `/answers-library/null/null/null/null/null/null/null/${id}`
              )
            }
          />
        </Box>
      )}
      {displayType === 'similarQuestions' &&
        isViewer &&
        !isApproved &&
        !isViewerRequestAnswerApprovalSent && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: theme.spacing(4),
            }}
          >
            <UniversalButton
              loading={isViewerRequestAnswerApprovalLoading}
              label="Request approval and access to this response"
              onClick={onClickRequestApproveAnswerButton}
            />
          </Box>
        )}
      {isSimilarQuestions && <SuccessDialog />}
    </Box>
  )
}

export default AnswerPreview
