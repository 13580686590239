import React from 'react'
import { CompanyMembersActions } from './companyMembersInterfaces'

export const initialCompanyMembersActions: CompanyMembersActions = {
  onUserRoleChange: () => {},
  renderAddMemberForm: () => <></>,
  onOpenModal: () => {},
  onCloseModal: () => {},
  onClickDeleteUserRole: () => {},
}
