import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  IGeneratedResponse,
  AskRequest,
  GeneratedResponse,
  Unit,
  AnswerItem,
  GenerateSimilarQuestionsRequestItem,
} from '../types/interfaces'
import type { RootState } from '../store/store'
import {
  AnswerLengthType,
  ResponseTypeRegardingSupportiveEvidenceType,
} from '../types/types'

interface IGetGeneratedResp {
  username: string
  is_metric: boolean
  unit: string
  q_heading: string
}

interface CreateRequestBody {
  question: string
  is_metric: boolean
  unit: Unit
  prompt?: string
  based_on_type?: ResponseTypeRegardingSupportiveEvidenceType
  response_length?: AnswerLengthType
  survey_id?: string
  survey_name?: string
  survey_question_order?: number
  survey_question_number?: string
}

export const generateApi = createApi({
  reducerPath: 'generateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/generate`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['Request', 'Response', 'Responses'],
  endpoints: build => ({
    createRequest: build.mutation<AskRequest, CreateRequestBody>({
      query: body => ({
        url: `/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Request'],
    }),
    askForAnswerApproval: build.mutation<string, AskRequest['request_id']>({
      query: requestId => ({
        url: `/request_approval/${requestId}`,
        method: 'POST',
      }),
    }),
    generateNewResponse: build.mutation<
      GeneratedResponse,
      AskRequest['request_id']
    >({
      query: requestId => ({
        url: `/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Response'],
    }),
    generateNewResponseIncludingPrivateEvidence: build.mutation<
      {
        id: string
        text: string
        request_id: string
      },
      AskRequest['request_id']
    >({
      query: requestId => ({
        url: `/include_private/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Response'],
    }),
    getSimilarRequests: build.mutation<
      GenerateSimilarQuestionsRequestItem[],
      AskRequest['request_id']
    >({
      query: requestId => ({
        url: `/similar_requests/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Response'],
    }),
    regenerateResponse: build.mutation<AnswerItem, AskRequest['request_id']>({
      query: requestId => ({
        url: `/regenerate/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Responses'],
    }),
  }),
})

export const {
  useCreateRequestMutation,
  useAskForAnswerApprovalMutation,
  useGenerateNewResponseMutation,
  useGenerateNewResponseIncludingPrivateEvidenceMutation,
  useGetSimilarRequestsMutation,
  useRegenerateResponseMutation,
} = generateApi
