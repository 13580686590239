import React from 'react'
import MainContentContainer from '../../ui/MainContentContainer/MainContentContainer'
import ViewTitle from '../../ui/ViewTitle/ViewTitle'
import { Box, Typography, useTheme } from '@mui/material'
import MembersTable from '../../members/MembersTable/MembersTable'

const NoAccessToThisView: React.FC = () => {
  const theme = useTheme()
  return (
    <MainContentContainer
      maxWidth="lg"
      sx={{
        alignItems: 'start',
        display: 'flex',
      }}
    >
      <ViewTitle label={'This Section is Blocked'} />
      <Box sx={{ my: theme.spacing(4) }}>
        <Typography>
          We're sorry, but it seems like you do not have the necessary
          permissions to access this view. This section is restricted to users
          with specific roles, and your current role is set to "VIEWER."
        </Typography>
        <Typography>
          If you believe you should have access to this view, please reach out
          to the owner of the app or your administrator and request an upgrade
          to your role. They will be able to review your request and adjust your
          permissions accordingly.
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: 400 }}>List of users:</Typography>
      <MembersTable />
    </MainContentContainer>
  )
}

export default NoAccessToThisView
