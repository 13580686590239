import React, { useContext } from 'react'
import {
  Box,
  SxProps,
  Typography,
  useTheme,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'

import { AnswerPreviewContext } from '../../../contexts/AnswerPreview/answer-preview-contex'

import RejectDialog from './RejectDialog/RejectDialog'
import { useAppSelector } from '../../../hooks/hooks'
import { AnswerStatus, AnswerStatusLabel } from '../../../types/interfaces'

interface Props {
  borderColor: string
  boxTitleStyling: SxProps
}

const displayCleanStatus = (
  status: AnswerStatus,
  assignedUserId: string
): AnswerStatusLabel => {
  const newStatus = `${
    status.charAt(0).toUpperCase() +
    status.replace(/_/g, ' ').slice(1).toLowerCase()
  }`
  if (status === 'CHECKED' && assignedUserId) {
    return 'Unreviewed'
  } else if (status === 'CHECKED') {
    return 'Unassigned'
  }
  return newStatus as AnswerStatusLabel
}

const ApprovalSection: React.FC<Props> = ({ borderColor, boxTitleStyling }) => {
  const theme = useTheme()

  const {
    state: { selectReviewerValue, reviewers, isEditorAssignedAsContributor },
    actions: { onUpdateReviewer, onUpdateStatus },
    answerData: { status, assignedUser },
  } = useContext(AnswerPreviewContext)

  const { role } = useAppSelector(state => state.user)

  const aproveBoxStyling: SxProps = {
    borderRight: `1px solid ${borderColor}`,
    px: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  }

  let JsxBoxElements: JSX.Element[] = []

  let canUserChangeStatus = false
  let canUserChangeReviewer = false

  if (
    (role === 'CONTRIBUTOR' &&
      (status === 'CHECKED' || status === 'REJECTED_REVIEWED')) ||
    role === 'ADMIN' ||
    role === 'STUFF'
  ) {
    canUserChangeStatus = true
  } else if (role === 'EDITOR' && isEditorAssignedAsContributor) {
    canUserChangeStatus = true
  } else if (
    role === 'EDITOR' &&
    (status === 'REVIEWED' || status === 'APPROVED')
  ) {
    canUserChangeStatus = true
  }

  if (
    (role === 'EDITOR' && status === 'CHECKED') ||
    role === 'ADMIN' ||
    role === 'STUFF'
  ) {
    canUserChangeReviewer = true
  } else if (role === 'EDITOR' && assignedUser === null) {
    canUserChangeReviewer = true
  }

  const isApproved = status === 'APPROVED'

  let selectReviewersOptions: JSX.Element[] = []

  let selectStatusJSXItem: JSX.Element = <span></span>
  let selectStatusOptions: JSX.Element[] = []

  if (canUserChangeStatus) {
    if (role === 'STUFF') {
      selectStatusOptions = [
        <MenuItem value={'APPROVED'}>Approved</MenuItem>,
        <MenuItem value={'REVIEWED'}>Reviewed</MenuItem>,
        <MenuItem value={'CHECKED'}>Unreviewed</MenuItem>,
        <MenuItem value={'UNCHECKED'}>Unchecked</MenuItem>,
        <MenuItem value={'REJECTED_REVIEWED'}>Rejected by Editor</MenuItem>,
        <MenuItem value={'REJECTED_CHECKED'}>Rejected by Contributor</MenuItem>,
      ]
    } else if (role === 'ADMIN') {
      selectStatusOptions = [
        <MenuItem value={'APPROVED'}>Approved</MenuItem>,
        <MenuItem value={'REVIEWED'}>Reviewed</MenuItem>,
        <MenuItem value={'CHECKED'}>Unreviewed</MenuItem>,
        <MenuItem value={'REJECTED_REVIEWED'}>Rejected by Editor</MenuItem>,
        <MenuItem value={'REJECTED_CHECKED'}>Rejected by Contributor</MenuItem>,
      ]
    } else if (role === 'EDITOR' && isEditorAssignedAsContributor) {
      selectStatusOptions = [
        <MenuItem value={'APPROVED'}>Approved</MenuItem>,
        <MenuItem value={'REVIEWED'}>Reviewed</MenuItem>,
        <MenuItem value={'CHECKED'}>Unreviewed</MenuItem>,
        <MenuItem value={'REJECTED_REVIEWED'}>Rejected by Editor</MenuItem>,
        <MenuItem value={'REJECTED_CHECKED'}>Rejected by Contributor</MenuItem>,
      ]
    } else if (role === 'EDITOR') {
      selectStatusOptions = [
        <MenuItem value={'APPROVED'}>Approved</MenuItem>,
        <MenuItem value={'REVIEWED'}>Reviewed</MenuItem>,
        <MenuItem disabled={true} value={'CHECKED'}>
          Unreviewed
        </MenuItem>,
        <MenuItem value={'REJECTED_REVIEWED'}>Rejected by Editor</MenuItem>,
      ]
    } else if (role === 'CONTRIBUTOR' && status !== 'REJECTED_REVIEWED') {
      selectStatusOptions = [
        <MenuItem value={'REVIEWED'}>Reviewed</MenuItem>,
        <MenuItem value={'CHECKED'}>Unreviewed</MenuItem>,
        <MenuItem value={'REJECTED_REVIEWED'}>Rejected by Editor</MenuItem>,
        <MenuItem value={'REJECTED_CHECKED'}>Rejected by Contributor</MenuItem>,
      ]
    } else if (role === 'CONTRIBUTOR' && status === 'REJECTED_REVIEWED') {
      selectStatusOptions = [
        <MenuItem value={'REVIEWED'}>Reviewed</MenuItem>,
        <MenuItem value={'REJECTED_REVIEWED'}>Rejected by Editor</MenuItem>,
        <MenuItem value={'REJECTED_CHECKED'}>Rejected by Contributor</MenuItem>,
      ]
    }

    selectStatusJSXItem = (
      <Box sx={aproveBoxStyling} key={'reviewer'}>
        <Typography>Update status:</Typography>
        <FormControl sx={{ ml: theme.spacing(2) }}>
          <Select
            sx={{ bgcolor: 'white' }}
            labelId="reviewer"
            id="select-reviewer"
            size="small"
            value={status}
            onChange={onUpdateStatus}
          >
            {selectStatusOptions}
          </Select>
        </FormControl>
      </Box>
    )
  }

  if (reviewers !== null) {
    selectReviewersOptions = reviewers.map(reviewer => {
      const { first_name, last_name, id } = reviewer
      return (
        <MenuItem key={id} value={id}>
          {`${first_name} ${last_name}`}
        </MenuItem>
      )
    })
  }

  let selectReviewerJsxItem = canUserChangeReviewer ? (
    <Box sx={aproveBoxStyling} key={'reviewer'}>
      <Typography>Reviewer:</Typography>
      <FormControl sx={{ ml: theme.spacing(2) }}>
        <Select
          sx={{ bgcolor: 'white' }}
          labelId="reviewer"
          id="select-reviewer"
          size="small"
          value={selectReviewerValue}
          onChange={onUpdateReviewer}
        >
          <MenuItem value={'unassigned'}>Unassigned</MenuItem>
          {selectReviewersOptions}
        </Select>
      </FormControl>
    </Box>
  ) : assignedUser ? (
    <Box sx={aproveBoxStyling} key={'approve_by_name'}>
      <Typography>{`Reviewer: ${assignedUser!.first_name} ${
        assignedUser!.last_name
      }`}</Typography>
    </Box>
  ) : (
    <></>
  )

  let selectStatusSection = canUserChangeStatus ? (
    selectStatusJSXItem
  ) : (
    <Box sx={{ ...aproveBoxStyling, borderRight: 'none' }} key={'status'}>
      <Typography>
        Status:{' '}
        {displayCleanStatus(status, assignedUser ? assignedUser.id : '')}
      </Typography>
    </Box>
  )

  JsxBoxElements = [selectReviewerJsxItem, selectStatusSection]

  return (
    <>
      <RejectDialog />
      <Box
        id="approvalRow"
        sx={{
          borderBottom: `1px solid ${borderColor}`,
          display: 'grid',
          gridTemplateColumns: '130px 1fr',
        }}
      >
        <Box id="leftColumn" sx={aproveBoxStyling}>
          <Typography variant="h6" component={'h4'}>
            Approval
          </Typography>
        </Box>
        <Box
          id="rightColumn"
          sx={{
            backgroundColor: isApproved
              ? theme.palette.secondary.contrastText
              : theme.palette.primary.light,
            display: 'grid',
            gridTemplateColumns: `1fr 1fr`,
          }}
        >
          {JsxBoxElements}
        </Box>
      </Box>
    </>
  )
}

export default ApprovalSection
