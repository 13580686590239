import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
  Alert,
  Collapse,
  IconButton,
  AlertTitle,
  Link,
} from '@mui/material'
import content from '../../../assets/content/content'
import { useUpdateUserDataDetailsMutation } from '../../../services/users'
import { useAppDispatch } from '../../../hooks/hooks'
import { userActions } from '../../../store/User/user-slice'
import { LoadingButton } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation } from 'react-router-dom'
import { IHubspotForm, User } from '../../../types/interfaces'
import SuccessAlert from '../../ui/Allerts/SuccessAlert/SuccessAlert'
import { useTriggerHubspotProfileDetailsCompletedMutation } from '../../../services/hubSpotUserTracking'
import { useAuth0 } from '@auth0/auth0-react'

const createFieldObject = (
  id: string,
  label: string,
  isRequired: boolean,
  defaultValue: string | null,
  type?: HTMLInputElement['type']
) => {
  return {
    id,
    label,
    isRequired,
    defaultValue: defaultValue ? defaultValue : '',
    type: type ? type : 'text',
  }
}

const createCheckBoxdObject = (id: string, label: string, value: string) => {
  return {
    id,
    label,
    value,
  }
}

const AccountProfileForm: React.FC<User> = ({
  first_name,
  last_name,
  declared_company_name,
  declared_company_website,
  title,
  office_location,
  professional_profile_link,
  telephone_number,
  role_at_company,
  other_role_at_company,
}) => {
  const theme = useTheme()
  const { user } = useAuth0()

  const [updateUserDetailsData, { isLoading, isSuccess, isError, reset }] =
    useUpdateUserDataDetailsMutation()
  const [registerUserProfileDetailsCompletedInHubSpot] =
    useTriggerHubspotProfileDetailsCompletedMutation()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const isPresurvey = location.pathname === '/'
  const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')
  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const {
    eng: {
      components: {
        forms: {
          accountProfileForm: {
            personalPartTitle,
            rolePartTitle,
            radioButtonsLabel,
            consent,
            termsAndConditions,
            errorTitleAfterSubmission,
            errorAfterSubmission,
            success,
          },
        },
      },
      buttons: { formSubmit, update },
    },
  } = content

  const processTermsAndConditionContent = (content: string) => {
    let jsxTermsAndConditionWithLink: JSX.Element
    const stringToChangeForLink = 'the terms and conditions of use'
    const startIndex = content.indexOf(stringToChangeForLink)
    const firstPart = content.slice(0, startIndex)
    const secondPart = content.slice(
      startIndex + stringToChangeForLink.length,
      content.length
    )

    jsxTermsAndConditionWithLink = (
      <>
        <Typography component={'span'}>{firstPart}</Typography>
        <Link
          sx={{ display: 'inline-block' }}
          href="/terms-and-conditions"
          target="_blank"
        >
          {stringToChangeForLink}
        </Link>
        <Typography sx={{ display: 'inline' }}>{secondPart}</Typography>
      </>
    )
    return jsxTermsAndConditionWithLink
  }

  const personalInputsData = [
    createFieldObject('firstName', 'First name', true, first_name),
    createFieldObject('lastName', 'Last name', true, last_name),
    createFieldObject('company', 'Company', true, declared_company_name),
    createFieldObject('title', 'Title / Position', true, title),
    createFieldObject('phoneNumber', 'Phone number', false, telephone_number),
    createFieldObject(
      'officeLocalization',
      'Your office location',
      true,
      office_location
    ),
    createFieldObject(
      'website',
      'Company website',
      true,
      declared_company_website,
      'text'
    ),
    createFieldObject(
      'linkedInUrl',
      'Professional online profile (e.g. Linkedin, company profile page)',
      true,
      professional_profile_link,
      'text'
    ),
  ]

  const personalInputsJsxElements = personalInputsData.map(inputDataItem => {
    const { id, isRequired, label, type, defaultValue } = inputDataItem
    return (
      <TextField
        id={id}
        key={id}
        label={label}
        type={type}
        required={isRequired}
        variant="outlined"
        fullWidth
        defaultValue={defaultValue}
        inputProps={{
          sx: {
            backgroundColor: theme.palette.primary.contrastText,
            borderRadius: '5px',
          },
        }}
        sx={{
          marginTop: theme.spacing(1),
          maxWidth: '70%',
        }}
      />
    )
  })

  const roleAtCompanyInputsData = [
    createCheckBoxdObject(
      'corporateGovernance',
      'Corporate governance',
      'CORPORATE_GOVERNANCE'
    ),
    createCheckBoxdObject('esg', 'ESG', 'ESG'),
    createCheckBoxdObject(
      'investorRelations',
      'Investor relations',
      'INVESTOR_RELATIONS'
    ),
    createCheckBoxdObject(
      'digitalTransformation',
      'Digital transformation',
      'DIGITAL_TRANSFORMATION'
    ),
    createCheckBoxdObject('automation', 'Automation', 'AUTOMATION'),
    createCheckBoxdObject('data', 'Data', 'DATA'),
    createCheckBoxdObject('innovation', 'Innovation', 'INNOVATION'),
    createCheckBoxdObject('risk', 'Risk', 'RISK'),
    createCheckBoxdObject('other', 'Other', 'OTHER'),
  ]

  const [isCheckboxGroupValid, setIsCheckboxGroupValid] = useState(true)
  const [isOtherChecked, setIsOtherChecked] = useState(false)

  useEffect(() => {
    const checkIsOtherChecked = document
      .getElementById('other')
      ?.querySelector('input')?.checked

    if (checkIsOtherChecked !== undefined) {
      setIsOtherChecked(checkIsOtherChecked)
    }
  }, [])

  const roleAtCompanyJsxElements = roleAtCompanyInputsData.map(
    (inputDataItem, index) => {
      const { id, label, value } = inputDataItem

      const handleCheckboxChange = () => {
        setIsCheckboxGroupValid(true)
        const checkIsOtherChecked = document
          .getElementById('other')
          ?.querySelector('input')?.checked

        if (checkIsOtherChecked !== undefined) {
          setIsOtherChecked(checkIsOtherChecked)
        }
      }

      const isDefaultChecked = role_at_company?.find(role => role === value)
      if (index === roleAtCompanyInputsData.length - 1) {
        return (
          <Box
            key={`${id}_${index}`}
            sx={{
              display: 'flex',
              p: 0,
              m: 0,
              alignItems: 'flex-start',
            }}
          >
            <FormControlLabel
              id={id}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  defaultChecked={isDefaultChecked ? true : false}
                />
              }
              label={label}
              value={value}
            />
            {isOtherChecked && (
              <TextField
                margin="none"
                size="small"
                id="customRole"
                variant="outlined"
                label={'Role:'}
                type="text"
                defaultValue={
                  other_role_at_company ? other_role_at_company : ''
                }
                inputProps={{
                  sx: {
                    backgroundColor: theme.palette.primary.contrastText,
                    borderRadius: '5px',
                  },
                }}
                sx={{
                  '&:div': {
                    m: 0,
                  },
                }}
              />
            )}
          </Box>
        )
      }

      return (
        <FormControlLabel
          key={`${id}_${index}`}
          id={id}
          control={
            <Checkbox
              onChange={handleCheckboxChange}
              defaultChecked={isDefaultChecked ? true : false}
            />
          }
          label={label}
          value={value}
        />
      )
    }
  )

  const onFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const checkedRolesAtCompany: string[] = []

    const isAtLeastOneCheckboxChecked = roleAtCompanyInputsData.some(
      inputDataItem =>
        document.getElementById(inputDataItem.id)?.querySelector('input')
          ?.checked
    )

    roleAtCompanyInputsData.forEach(inputDataItem => {
      const currentInputElement = document
        .getElementById(inputDataItem.id)
        ?.querySelector('input')

      if (currentInputElement?.checked) {
        checkedRolesAtCompany.push(inputDataItem.value)
      }
    })

    setIsCheckboxGroupValid(isAtLeastOneCheckboxChecked)

    if (isAtLeastOneCheckboxChecked) {
      interface IEditProfileFormValues {
        firstName: { value: string }
        lastName: { value: string }
        company: { value: string }
        title: { value: string }
        officeLocalization: { value: string }
        website: { value: string }
        linkedInUrl: { value: string }
        customRole: { value: string }
        phoneNumber: { value: string }
      }

      interface IPreSurveyProfileFormValues extends IEditProfileFormValues {
        representative: { value: boolean }
        termsAndConditions: { value: boolean }
        comunication: { value: boolean }
      }

      if (isPresurvey) {
        const target = e.target as typeof e.target & IPreSurveyProfileFormValues
        let userEmail = ''
        if (user) {
          const { email } = user
          userEmail = email ? email : ''
        }

        const hubspotSubmitFormData: IHubspotForm = {
          fields: [
            { name: 'email', value: userEmail },
            { name: 'firstname', value: target.firstName.value },
            { name: 'lastname', value: target.lastName.value },
            { name: 'company', value: target.company.value },
            {
              name: 'where_is_your_office_located_',
              value: target.officeLocalization.value,
            },
            {
              name: 'please_confirm_that_you_are_an_authorised_representative_of_your_company_________to_be_providing_us',
              value: 'Yes',
            },
          ],
        }

        updateUserDetailsData({
          first_name: target.firstName.value,
          last_name: target.lastName.value,
          declared_company_name: target.company.value,
          office_location: target.officeLocalization.value,
          professional_profile_link: target.linkedInUrl.value,
          role_at_company: checkedRolesAtCompany,
          telephone_number: target.phoneNumber.value,
          other_role_at_company: target.customRole
            ? target.customRole.value
            : '',
          title: target.title.value,
          declared_company_website: target.website.value,
          is_user_details_completed: true,
          is_terms_and_conditions_accepted: target.termsAndConditions.value,
          is_authorised_representative_consent_collected:
            target.representative.value,
        })
          .unwrap()
          .then(() => {
            dispatch(userActions.setProfileDetailsCollected(true))
            isProduction &&
              registerUserProfileDetailsCompletedInHubSpot(
                hubspotSubmitFormData
              )
          })
          .catch(rejected => console.error(rejected))
      } else {
        const target = e.target as typeof e.target & IEditProfileFormValues

        updateUserDetailsData({
          first_name: target.firstName.value,
          last_name: target.lastName.value,
          declared_company_name: target.company.value,
          office_location: target.officeLocalization.value,
          professional_profile_link: target.linkedInUrl.value,
          role_at_company: checkedRolesAtCompany,
          telephone_number: target.phoneNumber.value,
          other_role_at_company: target.customRole
            ? target.customRole.value
            : '',
          title: target.title.value,
          declared_company_website: target.website.value,
          is_terms_and_conditions_accepted: true,
          is_user_details_completed: true,
          is_authorised_representative_consent_collected: true,
          //below line doesn't exist in front-end
        })
          .unwrap()
          .then(() => {
            dispatch(userActions.setProfileDetailsCollected(true))
          })
          .catch(rejected => console.error(rejected))
      }
    } else {
      console.log('Validation error: At least one checkbox should be checked')
    }
  }

  return (
    <Box
      component={'form'}
      onSubmit={onFormSubmit}
      sx={{
        '>section': {
          display: 'grid',
          gridTemplateColumns: '1fr 5fr',
          border: `1px solid ${borderColor}`,
          borderTop: 0,
          '&:not(:last-of-type)': {
            borderTop: `1px solid ${borderColor}`,
          },
        },
      }}
    >
      <Box component={'section'} id="personalInformation">
        <Box
          sx={{
            textAlign: 'center',
            borderRight: `1px solid ${borderColor}`,
            p: theme.spacing(1),
          }}
        >
          <Typography component={'h4'} variant="h6">
            {personalPartTitle}
          </Typography>
        </Box>
        <Box
          id="personalInputs"
          component={'article'}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            px: theme.spacing(5),
            py: theme.spacing(1.5),
          }}
        >
          {personalInputsJsxElements}
        </Box>
      </Box>
      <Box
        component={'section'}
        id="roleAtCompany"
        sx={{
          borderTop: 0,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            borderRight: `1px solid ${borderColor}`,
            p: theme.spacing(1),
          }}
        >
          <Typography component={'h4'} variant="h6">
            {rolePartTitle}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            px: theme.spacing(5),
            py: theme.spacing(1.5),
          }}
        >
          <Typography>{radioButtonsLabel}</Typography>
          <FormControl
            id="roleAtCompanyCheckboxGroup"
            required
            component="fieldset"
            sx={{ m: 0 }}
            margin="none"
            variant="standard"
            error={!isCheckboxGroupValid}
          >
            <FormGroup>{roleAtCompanyJsxElements}</FormGroup>
            {!isCheckboxGroupValid && (
              <FormHelperText
                sx={{
                  p: { color: `${theme.palette.primary.main} !important` },
                }}
              >
                Select at least one option.
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
      {isPresurvey && (
        <Box
          sx={{
            border: `1px solid ${borderColor}`,
            borderTop: 0,
            px: theme.spacing(5),
            py: theme.spacing(2),
          }}
        >
          <Box id="authorisedConsent">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox id={'representative'} sx={{ height: '30px' }} />
                }
                label={consent}
                required={true}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  span: { margin: 0, input: { margin: 0 } },
                  'span:last-child': {
                    display: 'none',
                  },
                  'span:nth-of-type(2)': {
                    mt: theme.spacing(0.5),
                  },
                }}
              />
            </FormGroup>
          </Box>
          <Box id="termsAndConditionsBox" sx={{ mt: theme.spacing(2) }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox id={'termsAndConditions'} sx={{ height: '30px' }} />
                }
                label={processTermsAndConditionContent(termsAndConditions)}
                required={true}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  span: { margin: 0, input: { margin: 0 } },
                  'span:last-child': {
                    display: 'none',
                  },
                  'span:nth-of-type(2)': {
                    mt: theme.spacing(0.5),
                  },
                }}
              />
            </FormGroup>
          </Box>
        </Box>
      )}
      <Stack
        justifyContent={'center'}
        marginTop={theme.spacing(4)}
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Collapse in={isError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  reset()
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>{errorTitleAfterSubmission}</AlertTitle>
            {errorAfterSubmission}
          </Alert>
        </Collapse>
        {!isPresurvey && isSuccess && (
          <SuccessAlert alertContent={success} isSuccess={isSuccess} />
        )}
        <LoadingButton variant="contained" type="submit" loading={isLoading}>
          {isPresurvey ? formSubmit : update}
        </LoadingButton>
      </Stack>
    </Box>
  )
}

export default AccountProfileForm
