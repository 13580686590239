import React, { useContext } from 'react'
import { Box, Drawer, Typography, useTheme } from '@mui/material'
import AnswerPreviewContexProvider from '../../../../contexts/AnswerPreview/AnswerPreviewContexProvider'
import AnswerPreview from '../../../AnswerPreview/AnswerPreview'
import UniversalButton from '../../../ui/Buttons/UniversalButton'
import {
  GenerateSimilarQuestionsRequestItem,
  SimilarRequest,
} from '../../../../types/interfaces'
import { convertDateToNiceFormat } from '../../../../utils/utils'
import { SimilarQuestionsContext } from '../../../../contexts/SimilarQuestionsContext/similar-questions-context'

interface Props {
  dataTodisplay?: GenerateSimilarQuestionsRequestItem
  isOpen?: boolean
  onCloseDrawer?: () => void
}

const SimilarQuestionRightDrawer: React.FC<Props> = ({
  dataTodisplay,
  isOpen,
  onCloseDrawer,
}) => {
  const {
    state: { currentOpenSimilarQuestion, similarRequests },
    actions: { onClickCloseRightDrawer },
  } = useContext(SimilarQuestionsContext)

  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let answerDataToPass:
    | SimilarRequest
    | undefined
    | GenerateSimilarQuestionsRequestItem = undefined

  if (similarRequests.length > 0) {
    answerDataToPass = similarRequests.find(similarRequestItem => {
      return similarRequestItem.id === currentOpenSimilarQuestion
    })
  } else {
    answerDataToPass = dataTodisplay
  }

  let topRowJsxItem = (
    <Box
      id="closeRow"
      sx={{
        border: `1px solid ${borderColor}`,
        py: theme.spacing(0.5),
        px: theme.spacing(2),
        borderBottom: 'none',
      }}
    >
      <UniversalButton
        onClick={onCloseDrawer ? onCloseDrawer : onClickCloseRightDrawer}
        label="Close"
        stylingType={6}
      />
    </Box>
  )

  if (answerDataToPass) {
    const { updated_at, status } = answerDataToPass.response
    if (status === 'APPROVED') {
      const updatedDateInNiceFormat = convertDateToNiceFormat(updated_at)
      topRowJsxItem = (
        <Box
          id="closeRow"
          sx={{
            border: `1px solid ${borderColor}`,

            borderBottom: 'none',
            display: 'grid',
            gridTemplateColumns: '2.4fr 1fr',
            alignItems: 'stretch',
          }}
        >
          <Box
            sx={{
              borderRight: `1px solid ${borderColor}`,
              py: theme.spacing(0.5),
              px: theme.spacing(2),
            }}
          >
            <UniversalButton
              onClick={onCloseDrawer ? onCloseDrawer : onClickCloseRightDrawer}
              label="Close"
              stylingType={6}
            />
          </Box>
          <Box
            sx={{
              py: theme.spacing(0.5),
              px: theme.spacing(2),
            }}
          >
            <Typography>{`Last Updated: ${updatedDateInNiceFormat}`}</Typography>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Drawer
      anchor={'right'}
      open={
        isOpen !== undefined
          ? isOpen === true
            ? true
            : false
          : currentOpenSimilarQuestion
          ? true
          : false
      }
      onClose={onCloseDrawer ? onCloseDrawer : onClickCloseRightDrawer}
    >
      <Box
        sx={{
          height: '100%',
          width: '1200px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {topRowJsxItem}
        {answerDataToPass && (
          <AnswerPreviewContexProvider {...answerDataToPass.response}>
            <AnswerPreview displayType="similarQuestions" />
          </AnswerPreviewContexProvider>
        )}
      </Box>
    </Drawer>
  )
}

export default SimilarQuestionRightDrawer
