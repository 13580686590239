import React from 'react'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import ApprovedLabel from '../../../../../ui/Labels/ApprovedLabel'
import ToReviewLabel from '../../../../../ui/Labels/ToReviewLabel'
import RegeneratingLabel from '../../../../../ui/Labels/RegeneratingLabel'
import { ArrowForwardIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import AnswersLabel from '../../../../../ui/Labels/AnswersLabel'
import { ExtendedAnswerStatus } from '../../../../../../types/interfaces'
import StatusLabel from '../../../../../ui/Labels/StatusLabel'

interface Props {
  borderStyle: string
  type: ExtendedAnswerStatus
  quantity: number
  link: string
}

const ReviewItem: React.FC<Props> = ({ borderStyle, type, quantity, link }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  if (quantity === 0) {
    return null
  }

  return (
    <Box
      id="approvedRow"
      sx={{
        display: 'grid',
        gridTemplateColumns: '3fr 1fr 1fr',
        '>div': {
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'center',
        },
        '>div:not(:last-child)': {
          borderRight: borderStyle,
        },
      }}
    >
      <Box sx={{ px: theme.spacing(2) }}>
        <StatusLabel status={type} />
      </Box>
      <Box sx={{ px: theme.spacing(1) }}>
        <Typography textAlign={'right'}>{quantity}</Typography>
      </Box>
      <Box sx={{}}>
        <IconButton onClick={() => navigate(link)}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ReviewItem
