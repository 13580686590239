import React, { useContext } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import UniversalButton from '../../../../ui/Buttons/UniversalButton'
import { Download } from '@mui/icons-material'
import { SeasonalSurvey } from '../../../../../types/interfaces'
import { UploadSurveyContext } from '../../../../../contexts/UploadSurveyContext/uploadSurvey-context'
import { SurveysContext } from '../../../../../contexts/SurveysContext/surveys-context'

const SeasonalSurveyRow: React.FC<SeasonalSurvey> = ({ id, name, status }) => {
  const theme = useTheme()
  const {
    queryResult: { isLoadingInCreateQuestionnaire },
    actions: { onClickGenerateSeasonalSurvey },
  } = useContext(UploadSurveyContext)
  const {
    state: { idOfSeasonalSurveyModalOpen },
    actions: { onSetIdOfSeasonalSurveyModalOpen },
  } = useContext(SurveysContext)

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let statusItem = <span></span>

  if (status === null) {
    statusItem = (
      <UniversalButton
        loading={isLoadingInCreateQuestionnaire}
        label="Generate Answers"
        // onClick={() => onClickGenerateSeasonalSurvey(name, id)}
        onClick={() => onSetIdOfSeasonalSurveyModalOpen(id)}
      />
    )
  } else if (status === 'Ready') {
    // statusItem = (
    //   <Typography
    //     variant="body1"
    //     sx={{
    //       m: 0,
    //       p: 0,
    //       cursor: 'pointer',
    //       color: theme.palette.primary.main,
    //       lineHeight: '24px',
    //       fontWeight: 400,
    //       display: 'flex',
    //       alignItems: 'center',
    //     }}
    //   >
    //     Download Answers <Download sx={{ color: `rgba(0, 0, 0, 0.54)` }} />
    //   </Typography>
    // )
    statusItem = <Typography variant="body1">Ready to download</Typography>
  } else {
    statusItem = (
      <Typography sx={{ textAlign: 'center' }}>Processing</Typography>
    )
  }

  return (
    <Box
      sx={{
        '>div': {
          px: theme.spacing(3),
          borderRight: `1px solid ${borderColor}`,
          display: 'flex',
          ':last-child': {
            borderRight: 'none',
          },
        },
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
      }}
    >
      <Box sx={{ alignItems: 'center' }}>
        <Typography>{name}</Typography>
      </Box>
      <Box sx={{ justifyContent: 'center' }}>{statusItem}</Box>
    </Box>
  )
}

export default SeasonalSurveyRow
