import React, { useContext } from 'react'
import {
  Box,
  DialogContentText,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { Close } from '@mui/icons-material'
import content from '../../../../assets/content/content'
import { UploadEvidenceContext } from '../../../../contexts/UploadEvidenceContext/uploadEvidence-context'
import DialogPublicContent from './content/DialogPublicContent'
import DialogPrivateContent from './content/DialogPrivateContent'
import UniversalDialog from '../../../ui/UniversalDialog/UniversalDialog'
import ChooseTypeOfEvidenceContent from './content/ChooseTypeOfEvidenceContent'
import { EvidenceLibraryContext } from '../../../../contexts/EvidenceLibraryContext/evidence-library-context'

const DialogAddEvidence: React.FC = () => {
  const theme = useTheme()
  const {
    eng: {
      components: { addDocumentDialog },
    },
  } = content

  const { info } = addDocumentDialog

  const {
    state: { currEvidenceType, isAddEvidenceModalOpen },
    actions: { onClickCloseAddOrUploadModalButton },
  } = useContext(UploadEvidenceContext)

  const {
    actions: { onClickCloseUploadMode },
  } = useContext(EvidenceLibraryContext)

  let dialogContent: JSX.Element

  switch (currEvidenceType) {
    case 'PRIVATE':
      dialogContent = <DialogPrivateContent />
      break
    case 'PUBLIC':
      dialogContent = <DialogPublicContent />
      break
    default:
      dialogContent = <ChooseTypeOfEvidenceContent />
  }

  return (
    <UniversalDialog
      isDialogOpen={isAddEvidenceModalOpen}
      onCloseDialog={onClickCloseUploadMode}
    >
      {dialogContent}
    </UniversalDialog>
  )
}

export default DialogAddEvidence
