import { SimilarQuestionsState } from './similarQuestionsInterfaces'

export const initialSimilarQuestionsState: SimilarQuestionsState = {
  questionHeading: '',
  isMetric: false,
  unit: null,
  similarRequests: [],
  requestId: '',
  isLoadingInGenerateNewResponse: false,
  isErrorInGenerateNewResponse: false,
  newRequestId: '',
  currentOpenSimilarQuestion: null,
  isViewerRequestAnswerApprovalSent: false,
  isViewerRequestAnswerApprovalLoading: false,
  isViewerRequestAnswerApprovalModalOpen: false,
  isViewerRequestNewAnswerSent: false,
  // responseId: null,
  // responseInputValue: '',

  // isSuccessInSendFeedback: false,
  // isLoadingSendFeedback: false,
  // isErrorInSendFeedback: false,

  // isSuccessInUpdateResponse: false,
  // isLoadingInUpdateResponse: false,
  // isErrorInUpdateResponse: false,
}
