import React, { useContext } from 'react'
import {
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox,
  Stack,
  useTheme,
} from '@mui/material'
import { CdpSmeSeasonalSurveyDetailsContext } from '../../../../../../contexts/CdpSmeSeasonalSurveyDetails/cdpSmeSeasonalSurveyDetails-context'
import UniversalButton from '../../../../../ui/Buttons/UniversalButton'
import { UploadSurveyContext } from '../../../../../../contexts/UploadSurveyContext/uploadSurvey-context'

interface Props {}

const CdpSmeDialogContent: React.FC<Props> = () => {
  const {
    state: { questions, currentQuestion, surveyId, surveyName },
    actions: { onChangeCheckedAnswer, onSetCurrentQuestion },
  } = useContext(CdpSmeSeasonalSurveyDetailsContext)

  const {
    actions: { onClickGenerateSeasonalSurvey },
    queryResult: { isLoadingInCreateQuestionnaire },
  } = useContext(UploadSurveyContext)

  const theme = useTheme()

  const currentQuestionItem = questions[currentQuestion]

  const isCurrentQuestionValid = questions[currentQuestion].answers.find(
    answer => answer.isChoosen === true
  )

  const checkBoxItems = currentQuestionItem.answers.map((answerItem, index) => {
    const { label, id, isChoosen } = answerItem
    return (
      <FormControlLabel
        key={`${answerItem.label}_${index}`}
        label={label}
        control={
          <Checkbox
            onChange={() => onChangeCheckedAnswer(id)}
            checked={isChoosen}
          />
        }
      ></FormControlLabel>
    )
  })

  let additionalInfo = ''

  questions.forEach(question => {
    const { heading, answers } = question

    additionalInfo = additionalInfo + heading + ' '

    answers.forEach(answer => {
      if (answer.isChoosen === true) {
        additionalInfo = additionalInfo + answer.label + ' '
      }
    })
  })

  return (
    <Box sx={{ p: theme.spacing(2) }}>
      <Typography component={'h3'} variant="h6">
        {currentQuestionItem.heading}
      </Typography>
      <FormGroup>{checkBoxItems}</FormGroup>
      <Stack
        sx={{ mt: theme.spacing(2) }}
        direction={'row'}
        justifyContent={'center'}
      >
        {currentQuestion === 0 ? (
          <UniversalButton
            label="Next"
            disabled={!isCurrentQuestionValid}
            onClick={() => onSetCurrentQuestion(currentQuestion + 1)}
          />
        ) : (
          <UniversalButton
            loading={isLoadingInCreateQuestionnaire}
            disabled={!isCurrentQuestionValid}
            label="Submit"
            onClick={() =>
              onClickGenerateSeasonalSurvey(
                surveyName,
                surveyId,
                additionalInfo
              )
            }
          />
        )}
      </Stack>
    </Box>
  )
}

export default CdpSmeDialogContent
