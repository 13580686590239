import { Box, Stack, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import UniversalButton from '../../ui/Buttons/UniversalButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../hooks/hooks'
import { responsesViewActions } from '../../../store/ResponsesView/ResponsesView-slice'
import { GeneratedResponseContext } from '../../../contexts/GeneratedResponseContext/generated-response-context'
import { SimilarQuestionsContext } from '../../../contexts/SimilarQuestionsContext/similar-questions-context'
import { paragraphGenerator } from '../../../utils/paragraphGenerator'
import { AnswerPreviewContext } from '../../../contexts/AnswerPreview/answer-preview-contex'

interface Props {
  onClickRegenerate?: () => void
}

const QuestionHeading: React.FC<Props> = ({ onClickRegenerate }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const maxWidth = theme.breakpoints.values.md + 'px'

  const {
    state: { questionHeading: headingGeneratedResponse },
  } = useContext(GeneratedResponseContext)

  const {
    state: { questionHeading: headingSimilarQuestions },
  } = useContext(SimilarQuestionsContext)

  const {
    answerData: { questionHeading },
  } = useContext(AnswerPreviewContext)

  const isSimilarQuestionView = location.pathname === '/similar-questions'

  const isStuffReviewview = location.pathname.indexOf('review') !== -1

  const onClickAskNewQuestionButtonHandler = () => {
    dispatch(responsesViewActions.resetResponses())
    navigate('/ask-question')
  }

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let headingContent = ''

  if (headingGeneratedResponse) {
    headingContent = headingGeneratedResponse
  } else if (headingSimilarQuestions) {
    headingContent = headingSimilarQuestions
  } else if (questionHeading) {
    headingContent = questionHeading
  }

  let headingJSXitems = paragraphGenerator({
    text: headingContent,
    component: 'h3',
    variant: 'h4',
    sx: { m: theme.spacing(1) },
  })

  return (
    <Box sx={{ padding: 0, margin: 'auto', marginBottom: 0 }}>
      <Box
        component={'article'}
        sx={{
          bgcolor: theme.palette.secondary.main,
          px: theme.spacing(2.5),
          py: theme.spacing(2),
          border: `1px solid ${borderColor}`,
        }}
      >
        {headingJSXitems}
        {!isStuffReviewview && (
          <Stack
            direction={'row'}
            spacing={theme.spacing(3)}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <UniversalButton
              stylingType={1}
              endIcon={<ArrowForwardIcon />}
              label="Ask a New Question"
              onClick={onClickAskNewQuestionButtonHandler}
            />
          </Stack>
        )}
      </Box>
    </Box>
  )
}

export default QuestionHeading
