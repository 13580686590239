import React from 'react'
import { Box, Typography, useTheme, Link } from '@mui/material'
import { Link as LinkRouter } from 'react-router-dom'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import { calculateProcessingDays, processMonth } from '../utils/utils'
import { useAppSelector } from '../hooks/hooks'

const LoadSurveySuccess: React.FC = () => {
  const theme = useTheme()
  const { isDemoMode } = useAppSelector(state => state.app)

  const {
    config: { supportEmail },
    eng: {
      views: {
        loadSurveySuccess: { heading },
      },
    },
  } = content

  const today: Date = new Date()

  const processingDays: number = 3

  const endDate: Date = calculateProcessingDays(today, processingDays)

  const endDateOfProcessingInNiceFormat = `${endDate.getFullYear()}-${processMonth(
    endDate.getMonth()
  )}-${endDate.getDate()}`

  return (
    <MainContentContainer
      maxWidth="lg"
      sx={{
        alignItems: 'start',
        display: 'flex',
        paddingTop: '5%',
        paddingBottom: '3%',
      }}
    >
      <Box component={'article'}>
        <Typography
          component={'h1'}
          variant={'h3'}
          sx={{ marginY: theme.spacing(3) }}
        >
          {heading}
        </Typography>
        <Typography sx={{ marginBottom: theme.spacing(3) }}>
          Thank you for submitting your survey; it has been successfully
          uploaded and is now in the queue for analysis.
        </Typography>
        {!isDemoMode && (
          <Typography sx={{ marginY: theme.spacing(3) }}>
            Your survey will be completed for you no later than{' '}
            <Typography
              sx={{ fontWeight: 'bold' }}
              component={'span'}
            >{` ${endDateOfProcessingInNiceFormat}`}</Typography>
            .
          </Typography>
        )}
        <Typography sx={{ marginY: theme.spacing(3) }}>
          Once the survey is complete, we will promptly notify you via email.
          You can also monitor the processing status of your survey by visiting
          the{' '}
          <Link component={LinkRouter} to={'/surveys'}>
            "Surveys"
          </Link>{' '}
          section.
        </Typography>
        <Typography sx={{ marginY: theme.spacing(3) }}>
          Should our analysts have any questions during the evaluation, one of
          our team members will reach out to you promptly.
        </Typography>
        <Typography sx={{ marginY: theme.spacing(3) }}>
          For immediate assistance, please feel free to contact us at{' '}
          <Link href={`mailto:${supportEmail}`}> {`${supportEmail}`}</Link>
        </Typography>
        <Typography sx={{ marginY: theme.spacing(3) }}>
          We appreciate your cooperation and look forward to providing you with
          valuable insights from your survey.
        </Typography>
      </Box>
    </MainContentContainer>
  )
}

export default LoadSurveySuccess
