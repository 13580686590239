import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  AnswerItem,
  IAnswerComment,
  IGeneratedResponse,
  IMyAnswer,
  UpdateAnswerItem,
} from '../types/interfaces'
import type { RootState } from '../store/store'

export const responsesApi = createApi({
  reducerPath: 'responsesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/responses`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['Responses'],
  endpoints: build => ({
    getAnswers: build.query<AnswerItem[], void>({
      query: () => ({
        url: `/`,
      }),
      providesTags: ['Responses'],
    }),
    sendFeedbackRegResponse: build.mutation<
      AnswerItem,
      {
        responseId: AnswerItem['id']
        body: Partial<AnswerItem>
      }
    >({
      query: ({ responseId, body }) => ({
        url: `/${responseId}`,
        method: 'PATCH',
        body: {
          ...body,
        },
      }),
      invalidatesTags: ['Responses'],
    }),
    updateAnswer: build.mutation<
      AnswerItem,
      {
        responseId: AnswerItem['id']
        body: UpdateAnswerItem
      }
    >({
      query: ({ responseId, body }) => ({
        url: `/${responseId}`,
        method: 'PATCH',
        body: {
          ...body,
        } as UpdateAnswerItem,
      }),
      invalidatesTags: ['Responses'],
    }),

    deleteResponse: build.mutation<
      AnswerItem,
      {
        responseId: AnswerItem['id']
      }
    >({
      query: ({ responseId }) => ({
        url: `/${responseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Responses'],
    }),
    bulkReviewerAssigment: build.mutation<
      AnswerItem[],
      {
        body: {
          user_id: string
          response_ids: string[]
        }
      }
    >({
      query: ({ body }) => ({
        url: `/bulk`,
        method: 'POST',
        body: {
          ...body,
        },
      }),
      invalidatesTags: ['Responses'],
    }),
    duplicateAnswer: build.mutation<
      AnswerItem,
      {
        resId: string
        targetedId: string
      }
    >({
      query: ({ resId, targetedId }) => ({
        url: `/duplicate/${resId}`,
        method: 'POST',
        params: {
          response_id: targetedId,
        },
      }),
      invalidatesTags: ['Responses'],
    }),
  }),
})

export const {
  useGetAnswersQuery,
  useUpdateAnswerMutation,
  useSendFeedbackRegResponseMutation,
  useDeleteResponseMutation,
  useBulkReviewerAssigmentMutation,
  useDuplicateAnswerMutation,
} = responsesApi
