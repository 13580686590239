import React, { useState } from 'react'
import { Box, IconButton, SxProps, Typography, useTheme } from '@mui/material'
import { DashboardSecondaryItemInterface } from '../DashboardItems/DashboardItems'
import { ArrowForwardIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { link } from 'fs'
import RequestedQuestionsRightDrawer from '../DashboardItem/RequestedQuestionsRightDrawer/RequestedQuestionsRightDrawer'
import { DashBoardData } from '../../../types/interfaces'

const DashboardSecondaryItem: React.FC<DashboardSecondaryItemInterface> = ({
  title,
  subItems,
  firstCellBackgroundColor,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [
    isDrawerWithRequestedQuestionsOpen,
    setIsDrawerWithRequestedQuestionsOpen,
  ] = useState(false)
  const [drawerItems, setDrawerItems] = useState<
    DashBoardData['requests_in_progress']
  >([])

  const onClickArrowWithQuestionsInsideButtonHandler = () => {
    setIsDrawerWithRequestedQuestionsOpen(true)
  }

  const onClickCloseDrawerButton = () => {
    setIsDrawerWithRequestedQuestionsOpen(false)
  }

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  const subItemsJsx = subItems.map((item, index) => {
    const { label, quantity, items, link } = item

    const onClickArrowHandler = () => {
      if (link) {
        navigate(link)
      } else {
        setDrawerItems(items)
        onClickArrowWithQuestionsInsideButtonHandler()
      }
    }

    const cellPadding: SxProps = {
      paddingX: theme.spacing(3),
    }

    return (
      <Box sx={{ display: 'flex' }} key={`${index}_${label}`}>
        <Box
          sx={{
            width: '100%',
            borderRight: `1px solid ${borderColor}`,
            ...cellPadding,
          }}
        >
          <Typography>{label}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            borderRight: `1px solid ${borderColor}`,
            width: '80px',
            justifyContent: 'flex-end',
            paddingX: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography>{quantity}</Typography>
        </Box>
        <Box
          sx={{
            width: '40px',
            display: 'flex',
            flexDirection: 'center',
            alignItems: 'center',
            paddingX: theme.spacing(0.2),
          }}
        >
          <IconButton onClick={onClickArrowHandler}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
    )
  })

  return (
    <>
      {isDrawerWithRequestedQuestionsOpen && (
        <RequestedQuestionsRightDrawer
          isDrawerOpen={isDrawerWithRequestedQuestionsOpen}
          onCloseDrawer={onClickCloseDrawerButton}
          requestedQuestionsData={drawerItems}
        />
      )}
      <Box sx={{ my: theme.spacing(6) }}>
        <Typography component={'h4'} variant="h4">
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            border: `1px solid ${borderColor}`,
            maxWidth: '70%',
            marginTop: theme.spacing(4),
          }}
        >
          <Box
            sx={{
              width: '16px',
              borderRight: `1px solid ${borderColor}`,
              backgroundColor: firstCellBackgroundColor,
            }}
          ></Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              '& > :not(:last-child)': {
                borderBottom: `1px solid ${borderColor}`,
              },
            }}
          >
            {subItemsJsx}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DashboardSecondaryItem
