import React, { useContext, useEffect, useState } from 'react'
import { CompanyUserState, UserRole } from '../../../types/interfaces'
import SelectUniInput from '../../ui/inputs/SelectUniInput'
import { CompanyMembersContext } from '../../../contexts/CompanyMembersContext/companyMembers-context'
import { useAppSelector } from '../../../hooks/hooks'

interface Props {
  currentValue: UserRole
  memberId: CompanyUserState['id']
  defaultValue?: string
}

const RoleSelectInput: React.FC<Props> = ({
  currentValue,
  memberId,
  defaultValue,
}) => {
  const {
    actions: { onUserRoleChange },
    queryResults: { isErrorInUpdateRole },
  } = useContext(CompanyMembersContext)
  const [memberRole, setMemberRole] = useState<UserRole>(
    currentValue ? currentValue : 'VIEWER'
  )

  const { role } = useAppSelector(state => state.user)

  useEffect(() => {
    if (currentValue !== role) {
      setMemberRole(currentValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInUpdateRole])

  const onSelectChangeHandler = (newValue: string) => {
    setMemberRole(newValue as UserRole)
    onUserRoleChange(newValue as UserRole, memberId)
  }

  let options: { label: string; value: UserRole }[] = [
    { label: 'Editor', value: 'EDITOR' },
    { label: 'Contributor', value: 'CONTRIBUTOR' },
    { label: 'Viewer', value: 'VIEWER' },
  ]

  if (role === 'STUFF') {
    options = [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Editor', value: 'EDITOR' },
      { label: 'Contributor', value: 'CONTRIBUTOR' },
      { label: 'Viewer', value: 'VIEWER' },
    ]
  }

  return (
    <SelectUniInput
      sx={{ marginTop: 1, div: { marginTop: 0 } }}
      defaultValue={defaultValue}
      hideLabel={true}
      label="Role"
      options={options}
      currentValue={memberRole}
      onChange={onSelectChangeHandler}
    />
  )
}

export default RoleSelectInput
