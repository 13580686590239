import { LoadingButton } from '@mui/lab'
import { Button, ButtonProps, SxProps, useTheme } from '@mui/material'
import React from 'react'

interface Props extends ButtonProps {
  stylingType?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  label?: string
  component?: React.ElementType
  children?: React.ReactNode
  loading?: boolean
  sx?: SxProps
  to?: string
}

const UniversalButton: React.FC<Props> = ({
  stylingType,
  startIcon,
  endIcon,
  label,
  type = 'button',
  size = 'medium',
  href,
  onClick,
  children,
  component,
  disabled,
  loading,
  sx,
  to,
}) => {
  const theme = useTheme()

  //default pink action button
  let styling: SxProps = {
    boxShadow: 1,
    bgcolor: theme.palette.primary.main,
    color: theme.palette.primary.dark,
    ':hover': {
      bgcolor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.dark,
    },
    ':disabled': {
      bgcolor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      boxShadow: 'none',
    },
  }

  let buttonProps: Props = {
    startIcon,
    endIcon,
    type,
    onClick,
    size,
    href,
    component,
    disabled,
    to,
  }

  switch (stylingType) {
    //inherit button with dark border
    case 1:
      styling = {
        ...styling,
        bgcolor: 'inherit',
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
        borderStyle: 'solid',
        borderWidth: '2px',
        minWidth: '104px',
        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 2:
      styling = {
        ...styling,
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
        borderStyle: 'solid',
        borderWidth: '2px',
        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 3:
      buttonProps = {
        ...buttonProps,
        variant: 'contained',
      }
      styling = {
        ...styling,
        bgcolor: theme.palette.grey[300],
        color: theme.palette.grey[900],

        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 4:
      buttonProps = {
        ...buttonProps,
        variant: 'contained',
      }
      styling = {
        ...styling,
        bgcolor: theme.palette.grey[600],
        color: theme.palette.primary.contrastText,

        ':hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.dark,
        },
      }
      break
    case 5:
      buttonProps = {
        ...buttonProps,
        variant: 'contained',
      }
      break
    case 6:
      buttonProps = {
        ...buttonProps,
        variant: 'contained',
      }
      styling = {
        ...styling,
        textTransform: 'none',
        backgroundColor: 'inherit',
        color: theme.palette.primary.main,
        border: 'none',
        boxShadow: 'none',
        p: 0,
        borderRadius: '0px',
        minWidth: 'unset',
        ':hover': {
          textTransform: 'none',
          textDecoration: 'underline',
          backgroundColor: 'inherit',
          color: theme.palette.primary.main,
          border: 'none',
          boxShadow: 'none',
          p: 0,
          minWidth: 'unset',
        },
      }
      break
    case 7:
      buttonProps = {
        ...buttonProps,
        variant: 'outlined',
      }
      styling = {
        ...styling,
        backgroundColor: 'inherit',
        color: theme.palette.primary.main,
        boxShadow: 'none',
        m: 0,
        borderRadius: '0px',
        minWidth: 'unset',
        ':hover': {
          backgroundColor: 'inherit',
          color: theme.palette.primary.main,
          boxShadow: 'none',
          minWidth: 'unset',
        },
      }
      break
    case 8:
      buttonProps = {
        ...buttonProps,
        variant: 'outlined',
      }
      styling = {
        ...styling,
        backgroundColor: 'inherit',
        color: theme.palette.tertiary.light,
        borderColor: theme.palette.tertiary.light,
        boxShadow: 'none',
        m: 0,
        borderRadius: '0px',
        textWrap: 'nowrap',
        ':hover': {
          backgroundColor: theme.palette.tertiary.light,
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.tertiary.light,
          boxShadow: 'none',
          minWidth: 'unset',
        },
      }
      break
  }

  if (loading !== undefined) {
    return (
      <LoadingButton
        {...buttonProps}
        loading={loading}
        sx={{ ...styling, ...sx }}
      >
        {label}
      </LoadingButton>
    )
  }
  return (
    <Button {...buttonProps} sx={{ ...styling, ...sx }}>
      {label}
      {children}
    </Button>
  )
}

export default UniversalButton
