import React from 'react'
import { Button } from '@mui/material'
import content from '../../../assets/content/content'
import { useAuth0 } from '@auth0/auth0-react'
import ReactGA from 'react-ga4'
import { useTriggerClickLogInButtonMutation } from '../../../services/hubSpotUserTracking'

const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')

interface Props {}

const {
  eng: {
    buttons: { login },
  },
} = content

const LoginButton: React.FC<Props> = () => {
  const { loginWithRedirect } = useAuth0()
  const [triggerClickButtonToHubspot] = useTriggerClickLogInButtonMutation()

  const logInHandler = async () => {
    isProduction &&
      ReactGA.event({
        category: 'Authorization',
        action: 'User clicked Log in Button',
        label: 'User clicked Log in Button Label', // optional
        value: 0, // optional, must be a number
        nonInteraction: true, // optional, true/false
      })
    isProduction && triggerClickButtonToHubspot()
    await loginWithRedirect({
      authorizationParams: { redirect_uri: window.location.origin },
    })
  }

  return (
    <Button
      onClick={logInHandler}
      size="large"
      variant="contained"
      sx={{ minWidth: '104px' }}
    >
      {login}
    </Button>
  )
}

export default LoginButton
