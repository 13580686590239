import React, { useContext } from 'react'
import { Box, Drawer, Typography, useTheme } from '@mui/material'
import { AnswersLibraryContext } from '../../../../contexts/AnswersLibraryContext/answers-library-context'
import AnswerPreviewContexProvider from '../../../../contexts/AnswerPreview/AnswerPreviewContexProvider'
import AnswerPreview from '../../../AnswerPreview/AnswerPreview'
import UniversalButton from '../../../ui/Buttons/UniversalButton'
import { AnswerItem, DashBoardData } from '../../../../types/interfaces'
import { convertDateToNiceFormat } from '../../../../utils/utils'
import RequestedQuestionItem from './RequestedQuestionItem/RequestedQuestionItem'

interface Props {
  requestedQuestionsData: DashBoardData['requests_in_progress']
  isDrawerOpen: boolean
  onCloseDrawer: () => void
}

const RequestedQuestionsRightDrawer: React.FC<Props> = ({
  requestedQuestionsData,
  onCloseDrawer,
  isDrawerOpen,
}) => {
  const theme = useTheme()

  const borderColor =
    theme.components?.MuiTableCell?.styleOverrides?.root!['borderColor']!

  let topRowJsxItem = (
    <Box
      id="closeRow"
      sx={{
        border: `1px solid ${borderColor}`,
        py: theme.spacing(0.5),
        px: theme.spacing(2),
        borderBottom: 'none',
      }}
    >
      <UniversalButton onClick={onCloseDrawer} label="Close" stylingType={6} />
    </Box>
  )

  return (
    <Drawer anchor={'right'} open={isDrawerOpen} onClose={onCloseDrawer}>
      <Box
        sx={{
          height: '100%',
          width: '1200px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {topRowJsxItem}
        <Box
          sx={{
            border: `1px solid ${borderColor}`,
            px: theme.spacing(3),
            py: theme.spacing(2),
          }}
        >
          <Typography component={'h3'} variant="h4">
            Requested answers to be approved by Editor in progress
          </Typography>
          <Typography>
            The following are questions you or others at your organization have
            requested to be approved by the Editor. Once the Editor approves
            them you will see the answers, and they will be on the list of
            “Unreviewed Requested answers”.
          </Typography>
        </Box>
        <Box
          sx={{
            border: `1px solid ${borderColor}`,
            borderTop: 0,
            display: 'grid',
            gridTemplateColumns: '3fr 1fr',
            '>div:nth-of-type(-n+2)': { bgcolor: theme.palette.grey[100] },
            '>div': {
              px: theme.spacing(3),
            },
            '>div:not(:nth-of-type(-n+2))': {
              borderBottom: `1px solid ${borderColor}`,
            },
            // '>div:odd': {
            //
            // },
            'div:nth-of-type(odd)': {
              borderRight: `1px solid ${borderColor}`,
            },
            'div:nth-of-type(even)': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            sx={{
              borderRight: `1px solid ${borderColor}`,
            }}
          >
            <Typography variant="body2">Question</Typography>
          </Box>
          <Box>
            <Typography variant="body2">Request date</Typography>
          </Box>
          {requestedQuestionsData.map(requestedQuestion => (
            <RequestedQuestionItem
              key={requestedQuestion.id}
              {...requestedQuestion}
            />
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}

export default RequestedQuestionsRightDrawer
