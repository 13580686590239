import React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useTheme } from '@mui/material'

interface Props {
  label: string
  id?: string
  onChange: (newValue: string) => void
  value: string
}

const DateInput: React.FC<Props> = ({ label, id, onChange, value }) => {
  const theme = useTheme()
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={`${label}`}
        onChange={newValue => onChange(newValue as string)}
        value={value}
        sx={{
          width: '48%',
          input: { p: 1 },
          label: { marginTop: 1.4, lineHeight: 0.8 },
          color: theme.palette.primary.dark,
        }}
      />
    </LocalizationProvider>
  )
}

export default DateInput
