import { CdpSmeSeasonalSurveyDetailsState } from './cdpSmeSeasonalSurveyDetailsInterfaces'

const initialCdpSmeSeasonalSurveyDetailsState: CdpSmeSeasonalSurveyDetailsState =
  {
    surveyId: '',
    surveyName: '',
    currentQuestion: 0,
    questions: [
      {
        heading: `Which questions apply to your organization?`,
        answers: [
          {
            label: 'CDP core and sector questions only',
            isChoosen: false,
            id: 'CDP_core',
          },
          {
            label:
              'Supply chain questions – in addition to CDP core and sector questions. These apply if you are responding to a disclosure request from a customer',
            isChoosen: false,
            id: 'Supply_chain_questions',
          },
        ],
      },
      {
        heading: `Which environmental issues would you like guidance on?`,
        answers: [
          {
            label: 'Climate Change',
            isChoosen: false,
            id: 'Climate_Change',
          },
          {
            label: 'Water',
            isChoosen: false,
            id: 'Water',
          },
          {
            label: 'Forests',
            isChoosen: false,
            id: 'Forests',
          },
        ],
      },
    ],
  }

export default initialCdpSmeSeasonalSurveyDetailsState
