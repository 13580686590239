import React, { useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import UniversalButton from '../ui/Buttons/UniversalButton'
import {
  useGetAccessToCompletedQuestionnaireMutation,
  useGetAccessToCompletedAnnotatedQuestionnaireMutation,
} from '../../services/surveys'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { appActions } from '../../store/App/app-slice'
import { useGetAccessToFileMutation } from '../../services/file'

interface Props {
  label: string
  fileId: string
  filePath: string
}

const MySurveyItemLink: React.FC<Props> = ({ label, fileId, filePath }) => {
  const dispatch = useAppDispatch()

  // const [
  //   getAccessToCompleteQuestionnaire,
  // {
  //   isError: isErrorInGetReadyQuestionnairePreview,
  //   isSuccess: isSuccessInGetReadyQuestionnairePreview,
  //   isLoading: isLoadingInGetReadyQuestionnairePreview,
  //   data: questionnaireReadyPreviewData,
  //   reset: resetToGetReadyQuestionnairePreview,
  // },
  // ] = useGetAccessToCompletedQuestionnaireMutation()

  // const [
  //   getAccessToCompleteAnnotatedQuestionnaire,
  //   {
  //     isError: isErrorInGetReadyAnnotatedQuestionnairePreview,
  //     isSuccess: isSuccessInGetReadyAnnotatedQuestionnairePreview,
  //     isLoading: isLoadingInGetReadyAnnotatedQuestionnairePreview,
  //     data: questionnaireReadyAnnotatedPreviewData,
  //     reset: resetToGetReadyAnnotatedQuestionnairePreview,
  //   },
  // ] = useGetAccessToCompletedAnnotatedQuestionnaireMutation()

  const [
    getAccessToSurveyFile,
    {
      isError: isErrorInGetAccessToSurveyFile,
      isSuccess: isSuccessInGetAccessSurveyFile,
      isLoading: isLoadingInGetAccessSurveyFile,
      data: dataToAccessSurveyFile,
      reset: resetGetAccessToSurveyFile,
    },
  ] = useGetAccessToFileMutation()

  let isPdfFile: boolean = false

  if (filePath.lastIndexOf('.pdf') !== -1) {
    isPdfFile = true
  }

  // useEffect(() => {
  //   if (isErrorInGetReadyQuestionnairePreview) {
  //     resetToGetReadyQuestionnairePreview()
  //   }
  // }, [isErrorInGetReadyQuestionnairePreview])

  useEffect(() => {
    if (isSuccessInGetAccessSurveyFile && dataToAccessSurveyFile) {
      const { url } = dataToAccessSurveyFile
      window.open(url, isPdfFile ? '_blank' : '_self', 'noreferrer')
    }
  }, [isSuccessInGetAccessSurveyFile])

  const onClickButtonHandler = () => {
    getAccessToSurveyFile(fileId)
  }

  if (isErrorInGetAccessToSurveyFile) {
    dispatch(
      appActions.showSnackbarNotification({
        label: `Sorry, we couldn't access our database. Please try again later, if the problem persists, please contact us.`,
        type: 'error',
      })
    )
  }

  if (isLoadingInGetAccessSurveyFile) {
    return (
      <Box sx={{ minWidth: '102px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <UniversalButton
      onClick={onClickButtonHandler}
      label={label}
      stylingType={6}
      sx={{
        fontSize: '20px',
        fontWeight: 300,
        m: 0,
        p: 0,
        lineHeight: '20px',
        wordWrap: 'normal',
        minWidth: '120px',
        ':hover': {
          minWidth: '120px',
          backgroundColor: 'inherit',
          textDecoration: 'underline',
          boxShadow: 'none',
        },
      }}
    />
  )
}

export default MySurveyItemLink
