import React, { useEffect, useRef, useState } from 'react'
import { Backdrop, Dialog, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'
import GetHelpBox from './GetHelpBox'
import GetHelpPill from './GetHelpPill'

const GetHelpSection: React.FC = () => {
  const theme = useTheme()

  const location = useLocation()

  const elementRef = useRef<HTMLDivElement | null>(null)
  const [isFooterVisible, setIsFooterVisible] = useState(false)
  const [isGetHelpOpen, setIsGetHelpOpen] = useState(false)

  useEffect(() => {
    // Add event listener to handle scroll events
    const handleScroll = () => {
      const element = document.getElementById('footer') // Replace with your actual element ID
      if (element) {
        const rect = element.getBoundingClientRect()
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0
        setIsFooterVisible(isVisible)
      }
    }

    // Attach the event listener
    window.addEventListener('scroll', handleScroll)
    handleScroll()

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [location.pathname])

  const openHelpBoxHandler = () => {
    setIsGetHelpOpen(true)
  }

  const closeHelpBoxHandler = () => {
    setIsGetHelpOpen(false)
  }

  let positionProperties: {
    position: 'fixed' | 'relative' | 'absolute'
    bottom?: string
    right?: string
    left?: string
  } = {
    position: 'fixed',
    bottom: '20px',
    right: '3%',
  }

  if (isFooterVisible) {
    positionProperties = {
      position: 'absolute',
      bottom: '140%',
      right: '3%',
    }
  }

  if (isGetHelpOpen) {
    return (
      <>
        <Backdrop open={isGetHelpOpen} onClick={closeHelpBoxHandler} />
        <GetHelpBox
          onCloseHelpBoxClick={closeHelpBoxHandler}
          positionProperties={positionProperties}
        />
      </>
    )
  }

  return (
    <GetHelpPill
      onGetHelpPillClick={openHelpBoxHandler}
      positionProperties={positionProperties}
    />
  )
}

export default GetHelpSection
