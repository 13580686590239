import { AnswerPreviewActions } from './answerPreviewContextInterfaces'

export const initialAnswerPreviewActions: AnswerPreviewActions = {
  onResponseChange: (newValue: string) => {},
  onClickSaveAnswer: () => {},
  onClickDiscardChangesButton: () => {},
  onClickDeleteFromMyAnswers: () => {},
  onClickEditMode: (inputInitailValue: string) => {},
  switchOffEditMode: () => {},
  onChangeAddingMissingEvidenceStep: () => {},
  onChangeMissingEvidenceSentenceInput: () => {},
  onChangePageRangeInput: () => {},
  onSelectMissingEvidence: () => {},
  onUpdateReviewer: () => {},
  onClickAproveAnswer: () => {},
  onClickCloseAddMissingEvidenceModal: () => {},
  onClickCloseRejectModal: () => {},
  onClickOpenAddMissingEvidenceModal: () => {},
  onClickOpenRejectModal: () => {},
  onSubmitRejectFeedback: () => {},
  onClickCancelButtonInAddMissingEvidenceDialog: () => {},
  onSubmitCreateMissingEvidence: () => {},
}
