import React from 'react'
import { IconButton, SvgIconTypeMap, Icon, useTheme } from '@mui/material'
import { Box, Typography } from '@mui/material'
import UniversalButton from '../ui/Buttons/UniversalButton'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useNavigate } from 'react-router-dom'

interface Props {
  title: string
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  heading?: string
  content: string
  path?: string
  buttonLabel?: string
}

const StepItem: React.FC<Props> = ({
  title,
  heading,
  icon,
  path,
  buttonLabel,
  content,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const navigateHandler = () => {
    path && navigate(path)
  }

  const pinkColor = theme.palette.primary.main
  return (
    <Box
      sx={{
        border: `1px solid ${pinkColor}`,
        display: 'grid',
        padding: '16px 16px 32px 16px',
        justifyItems: `center`,
      }}
    >
      <Typography component="h3" variant="h4" textAlign={'center'}>
        {title}
      </Typography>
      <IconButton
        onClick={navigateHandler}
        sx={{
          minHeight: '116px',
          minWidth: '116px',
          backgroundColor: theme.palette.secondary.contrastText,
        }}
      >
        <Icon component={icon} color="primary" sx={{ fontSize: '80px' }} />
      </IconButton>
      {heading && (
        <Typography component={'h4'} variant="h6" textAlign={'center'}>
          {heading}
        </Typography>
      )}

      <Typography textAlign={'center'}>{content}</Typography>
      {buttonLabel && (
        <Box
          sx={{
            alignItems: 'flex-end',
            display: 'flex',
          }}
        >
          <UniversalButton label={buttonLabel} onClick={navigateHandler} />
        </Box>
      )}
    </Box>
  )
}

export default StepItem
