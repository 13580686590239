import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Evidence } from '../../types/interfaces'

interface EvidenceListViewState {
  currentLoadingEvidenceItems: Evidence[]
}

const initialState: EvidenceListViewState = {
  currentLoadingEvidenceItems: [],
}

export const evidenceListSlice = createSlice({
  name: 'EvidenceListView',
  initialState,
  reducers: {
    addLoadingEvidenceToEvidenceTable: (
      state,
      action: PayloadAction<Evidence>
    ) => {
      state.currentLoadingEvidenceItems =
        state.currentLoadingEvidenceItems.concat(action.payload)
    },
    deletLoadingEvidenceFromEvidenceTableHandler: (
      state,
      action: PayloadAction<Evidence['id']>
    ) => {
      state.currentLoadingEvidenceItems =
        state.currentLoadingEvidenceItems.filter(
          eviInTable => action.payload !== eviInTable.id
        )
    },
    resetEvidenceToEvidenceTable: state => {
      state.currentLoadingEvidenceItems = []
    },
  },
})

export const evidenceListActions = evidenceListSlice.actions

export default evidenceListSlice.reducer
