import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import ErrorView from './ErrorView'
import Auth0Loading from './Loading'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'

import Loading from './Loading'
import { userActions } from '../store/User/user-slice'
import DashboardItems from '../components/dashboard/DashboardItems/DashboardItems'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import { useGetDashboardDataQuery } from '../services/dashboard'
import { useLocation } from 'react-router-dom'
import { appActions } from '../store/App/app-slice'

const Home: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const {
    data: dashboardData,
    isSuccess: isSuccessDashboardData,
    isLoading: isLoadingDashboardData,
    isError: isErrorDashboardData,
    error: errorDashboardData,
    refetch,
    isFetching,
  } = useGetDashboardDataQuery()

  const { dashboardRequireRefetch } = useAppSelector(state => state.app)
  const dispatchStore = useAppDispatch()

  const {
    eng: {
      views: {
        home: { title },
      },
    },
  } = content

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Dashboard'))
  }, [])

  useEffect(() => {
    if (dashboardRequireRefetch) {
      refetch()
    }
  }, [dashboardRequireRefetch])

  useEffect(() => {
    if (isSuccessDashboardData) {
      dispatchStore(appActions.setDashboardNotRequireRefetch())
    }
  }, [refetch, isSuccessDashboardData])

  useEffect(() => {
    if (
      dashboardData &&
      (dashboardData.private_evidences.length > 0 ||
        dashboardData.public_evidences.length > 0)
    ) {
      dispatch(userActions.setEvidenceProvided())
    } else {
      dispatch(userActions.setEvidenceNotProvided())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData])

  // @ts-ignore: Unreachable code error
  // var _hsq = (window._hsq = window._hsq || [])

  // useEffect(() => {
  //   _hsq.push(['setPath', '/ask-question'])
  //   _hsq.push(['trackPageView'])
  // }, [])

  let mainContentJxs: JSX.Element

  if (isErrorDashboardData) {
    return <ErrorView errorData={errorDashboardData} />
  }

  if (isLoadingDashboardData || isFetching) {
    return <Loading />
  }

  if (
    dashboardData &&
    (dashboardData.public_evidences.length > 0 ||
      dashboardData.private_evidences.length > 0)
  ) {
    mainContentJxs = (
      <DashboardItems
        dasboardData={dashboardData}
        isEvidenceListsEmpty={false}
      />
    )
  } else {
    mainContentJxs = (
      <DashboardItems
        dasboardData={dashboardData}
        isEvidenceListsEmpty={true}
      />
    )
  }

  return (
    <MainContentContainer
      maxWidth="xl"
      sx={{
        alignItems: 'start',
        display: 'flex',
        paddingBottom: '3%',
      }}
    >
      <Box component={'article'}>{mainContentJxs}</Box>
    </MainContentContainer>
  )
}

export default Home
