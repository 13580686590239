import { Evidence } from '../../types/interfaces'
import {
  UploadEvidenceActions,
  UploadEvidenceState,
} from './uploadEvidenceInterfaces'

const initialUploadEvidenceActions: UploadEvidenceActions = {
  onClickContinueInChooseEvidenceType: (
    demandedTabType: 'PUBLIC' | 'PRIVATE'
  ) => {},
  onClickAddOrUploadDocumentButton: () => {},
  onSetCurrentUploadedFileData: () => {},
  onResetCurrentUploadedFileData: () => {},
  onClickCloseAddOrUploadModalButton: () => {},
  onSubmitAddEvidenceForm: () => {},
}

export default initialUploadEvidenceActions
