import React from 'react'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ManageHistoryIcon from '@mui/icons-material/ManageHistory'
import RestoreIcon from '@mui/icons-material/Restore'
import { AnswerStatus, ExtendedAnswerStatus } from '../../../types/interfaces'

interface Props {
  status: ExtendedAnswerStatus
}

const StatusIcon: React.FC<Props> = ({ status }) => {
  const sharedStyling = {
    width: '24px',
    height: '24px',
    m: 0,
  }

  switch (status) {
    case 'UNCHECKED':
      return <PriorityHighIcon sx={{ fill: '#903636', ...sharedStyling }} />
    case 'CHECKED':
      return <HelpOutlineIcon sx={{ fill: '#3FAD0A', ...sharedStyling }} />
    case 'CHECKED_ASSIGNED':
      return <AssignmentIndIcon sx={{ fill: '#9E9E9E', ...sharedStyling }} />
    case 'REVIEWED':
      return (
        <AssignmentTurnedInIcon sx={{ fill: '#FFA771', ...sharedStyling }} />
      )
    case 'APPROVED':
      return <CheckCircleIcon sx={{ fill: '#3FAD0A', ...sharedStyling }} />
    case 'REJECTED_CHECKED':
      return <ManageHistoryIcon sx={{ fill: '#143509', ...sharedStyling }} />
    case 'REJECTED_REVIEWED':
      return <RestoreIcon sx={{ fill: '#FF0000', ...sharedStyling }} />
    default:
      return null
  }
}

export default StatusIcon
