import { UploadSurveyState } from './uploadSurveyInterfaces'

const initialUploadSurveyState: UploadSurveyState = {
  dialogCurrentPage: 0,
  fileTitle: '',
  surveyType: '',
  surveySource: '',
  surveyAudience: '',
  customInternalDepartment: '',
  comment: '',
  evidenceDepth: '',
  answerLength: 'DETAILED',
  evidenceType: '',
  surveyDeadline: '',
  surveyFocus: '',
}

export default initialUploadSurveyState
