import React from 'react'
import { Box, useTheme } from '@mui/material'
import content from '../../../assets/content/content'
import StepItem from '../StepItem'

interface Props {}

const EvidenceProvidedView: React.FC<Props> = () => {
  const theme = useTheme()
  const {
    eng: {
      views: {
        getStarted: { evidenceProvidedElements },
      },
    },
  } = content

  const stepsElementsJsx = evidenceProvidedElements.map((stepItem, index) => (
    <StepItem key={`${stepItem.title}_${index}`} {...stepItem} />
  ))
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(2),
        width: '100%',
        m: 0,
        boxSizing: 'border-box',
      }}
    >
      {stepsElementsJsx}
    </Box>
  )
}

export default EvidenceProvidedView
