import { Box, SxProps, Typography, TypographyVariant } from '@mui/material'

import React, { ElementType } from 'react'

export const paragraphGenerator = ({
  text,
  component = 'p',
  variant = 'body1',
  sx,
}: {
  text: string
  variant?: TypographyVariant
  sx?: SxProps
  component?: ElementType<any>
}): JSX.Element => {
  if (text) {
    const paragraphs = text.split('\n').map((line, index) => (
      <Typography
        component={component}
        key={`${index}_${line[0]}`}
        variant={variant}
        sx={{ ...sx }}
      >
        {line}
      </Typography>
    ))

    return <Box>{paragraphs}</Box>
  }

  return <span></span>
}
