import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
  useTheme,
} from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import { useGetUserDetailsQuery } from '../services/users'
import Auth0Loading from './Loading'
import ErrorView from './ErrorView'
import ReactGA from 'react-ga4'
import { useTriggerWaitingForAuthorisationViewAccessedMutation } from '../services/hubSpotUserTracking'
import { useAppSelector } from '../hooks/hooks'

const WaitingForAuthorisation: React.FC = () => {
  const theme = useTheme()

  const { isLoading, data, isError, error, refetch } = useGetUserDetailsQuery()
  const { userEmail } = useAppSelector(state => state.user)

  const [registerAccessToWaitingForAuthorisationView] =
    useTriggerWaitingForAuthorisationViewAccessedMutation()

  const [isAuthorisationStatusShown, setIsAuthorisationStatusShown] =
    useState(false)

  const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')

  const showAuthorisationStatusHandler = () => {
    refetch()
    setIsAuthorisationStatusShown(true)
  }

  const hideAuthorisationStatusHandler = () => {
    setIsAuthorisationStatusShown(false)
  }

  useEffect(() => {
    if (isAuthorisationStatusShown) {
      const timer = setTimeout(() => {
        hideAuthorisationStatusHandler()
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [isAuthorisationStatusShown])

  useEffect(() => {
    if (data && isProduction && userEmail) {
      registerAccessToWaitingForAuthorisationView({
        fields: [{ name: 'email', value: userEmail }],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isProduction])

  useEffect(() => {
    isProduction &&
      ReactGA.send({
        hitType: 'pageview',
        page: '/waiting-for-authorisation',
        title: 'Waiting for authorisation',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    eng: {
      views: {
        waitingForAuthorisation: { introduction, paragraphs, email },
      },
      buttons: { checkAuthorisationStatus },
    },
  } = content

  const paragraphsJsxElements = paragraphs.map((paragraph, index) => {
    if (paragraph.includes('assistance')) {
      return (
        <span key={index + paragraph[0]}>
          <Typography sx={{ display: 'inline' }}>{paragraph} </Typography>
          <Link
            href={`mailto:${email}`}
            sx={{ display: 'inline', fontSize: '20px' }}
          >
            {email}
          </Link>
        </span>
      )
    }
    return <Typography key={index + paragraph[0]}>{paragraph}</Typography>
  })

  if (isLoading) {
    return <Auth0Loading />
  }

  if (isError) {
    return <ErrorView errorData={error} />
  }

  if (data) {
    return (
      <MainContentContainer maxWidth="lg">
        <Box component={'article'} sx={{ mt: '10%' }}>
          <Typography
            component={'h2'}
            variant={'h4'}
            sx={{ mt: theme.spacing(3), fontWeight: 400 }}
          >
            {introduction}
          </Typography>
          <Box sx={{ mt: theme.spacing(5) }}>{paragraphsJsxElements}</Box>
        </Box>
        <Button variant="contained" onClick={showAuthorisationStatusHandler}>
          {checkAuthorisationStatus}
        </Button>
        {isAuthorisationStatusShown && (
          <Typography>
            Please be patient, we still have been working on it!
          </Typography>
        )}
      </MainContentContainer>
    )
  }

  return (
    <Box>
      <CircularProgress />
    </Box>
  )
}

export default WaitingForAuthorisation
