import React, { useEffect } from 'react'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'
import AccountProfileForm from '../components/forms/AccountProfileForm/AccountProfileForm'
import { useGetUserDetailsQuery } from '../services/users'
import { Navigate } from 'react-router-dom'
import Auth0Loading from './Loading'
import ErrorView from './ErrorView'
import ReactGA from 'react-ga4'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { appActions } from '../store/App/app-slice'

interface Props {}

const isProduction = !(process.env.REACT_APP_IS_DEVELOPMENT! === 'true')
const EditProfile: React.FC<Props> = () => {
  useEffect(() => {
    isProduction &&
      ReactGA.send({
        hitType: 'pageview',
        page: '/edit-profile',
        title: 'Edit profile view',
      })
  }, [])

  const { isLoading, data, isError, error } = useGetUserDetailsQuery()
  const { userEmail } = useAppSelector(state => state.user)

  const dispatch = useAppDispatch()
  const theme = useTheme()

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Account Profile'))
  }, [])

  const {
    eng: {
      views: {
        accountProfile: { heading, introduction },
      },
    },
  } = content

  // @ts-ignore: Unreachable code error
  var _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', '/edit-profile'])
  _hsq.push(['trackPageView'])

  if (isLoading) {
    return <Auth0Loading />
  }

  if (isError) {
    return <ErrorView errorData={error} />
  }

  if (data) {
    const { is_user_details_completed } = data

    if (!is_user_details_completed) {
      return <Navigate to="/" />
    }

    if (userEmail) {
      _hsq.push([
        'identify',
        {
          email: userEmail,
        },
      ])
    }

    return (
      <MainContentContainer maxWidth="xl">
        <Box component={'article'} sx={{ mb: theme.spacing(2.5) }}>
          <Typography>{introduction}</Typography>
        </Box>
        <AccountProfileForm {...data} />
      </MainContentContainer>
    )
  }

  return <CircularProgress />
}

export default EditProfile
