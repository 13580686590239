import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import Loading from '../../views/Loading'

const RedirectToAuth0SignUpLogIn: React.FC<{ isSignUp?: boolean }> = ({
  isSignUp,
}) => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: isSignUp ? 'signup' : 'login',
        redirect_uri: window.location.origin,
      },
    })
  }, [])

  return <Loading />
}

export default RedirectToAuth0SignUpLogIn
