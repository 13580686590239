import React, { useEffect } from 'react'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import content from '../assets/content/content'

import GenerateResponseForm from '../components/forms/GenerateResponseForm/GenerateResponseForm'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'
import { useAppDispatch } from '../hooks/hooks'
import { appActions } from '../store/App/app-slice'

const AskQuestion: React.FC = () => {
  const {
    eng: {
      views: {
        askQuestion: { heading },
      },
    },
  } = content

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Ask a question'))
  }, [])

  return (
    <MainContentContainer
      maxWidth="xl"
      sx={{
        alignItems: 'start',
        display: 'flex',
      }}
    >
      <GenerateResponseForm />
    </MainContentContainer>
  )
}
export default AskQuestion
