import React, { useEffect } from 'react'
import MainContentContainer from '../components/ui/MainContentContainer/MainContentContainer'
import QuestionHeading from '../components/questionResponse/QuestionHeading/QuestionHeading'
import MyResponseSection from '../components/questionResponse/MyResponseSection/MyResponseSection'
import ResponseRecomendationSection from '../components/questionResponse/ResponseRecomendation/ResponseRecomendationSection'
import ViewTitle from '../components/ui/ViewTitle/ViewTitle'

import FoundEvidenceSection from '../components/questionResponse/FoundEvidenceSection/FoundEvidenceSection'
import { ScrollRestoration } from 'react-router-dom'
import GeneratedAnswerSection from '../components/questionResponse/GeneratedAnswerSection/GeneratedAnswerSection'
import SupportiveEvidenceInAnswerGeneratedSection from '../components/questionResponse/SupportiveEvidenceInAnswerGeneratedSection/SupportiveEvidenceInAnswerGeneratedSection'
import SaveAnswerSection from '../components/questionResponse/SaveAnswerSection/SaveAnswerSection'
import { useAppDispatch } from '../hooks/hooks'
import { appActions } from '../store/App/app-slice'

const GeneratedResponse: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(appActions.setCurrentViewTitle('Review Answer'))
  }, [])

  return (
    <MainContentContainer maxWidth="xl">
      <ScrollRestoration />
      <QuestionHeading />
      <GeneratedAnswerSection />
      <SupportiveEvidenceInAnswerGeneratedSection />
      <SaveAnswerSection />

      {/* <MyResponseSection />
      <ResponseRecomendationSection />
      <FoundEvidenceSection /> */}
    </MainContentContainer>
  )
}

export default GeneratedResponse
