import { SupportiveEvidenceActions } from './supportiveEvidenceInterfaces'

export const initialSupportiveEvidenceActions: SupportiveEvidenceActions = {
  onToggleExtedned: () => {},
  onClickEvidenceHeading: () => {},
  onClickRemoveEvidenceCheckbox: () => {},
  onClickRemoveSupportiveEvidenceAprovalButton: () => {},
  onErrorInPreviewPdfFromCompanysServer: () => {},
  onShowPdfPreview: () => {},
  onErrorInDisplayingPdfScreenshot: () => {},
}
