import React, { useEffect } from 'react'
import { Alert, AlertTitle, Box, Collapse, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  isError: boolean
  alertContent: string
}

const ErrorAlert: React.FC<Props> = ({ isError, alertContent }) => {
  const [open, setOpen] = React.useState(isError)

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false)
    }, 6000)

    return () => clearTimeout(timer)
  }, [isError])

  if (!isError) {
    return null
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Error</AlertTitle>
          {alertContent}
        </Alert>
      </Collapse>
    </Box>
  )
}

export default ErrorAlert
