import { createContext } from 'react'
import { SurveysContextSchema } from './SurveysInterfaces'
import initialSurveysState from './initialSurveysState'
import initialSurveysActions from './initialSurveysActions'

const SurveysInitialContext: SurveysContextSchema = {
  state: initialSurveysState,
  actions: initialSurveysActions,
}

export const SurveysContext = createContext(SurveysInitialContext)
