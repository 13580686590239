import { createTheme, responsiveFontSizes } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary']
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
  }
}

let theme = createTheme({
  palette: {
    primary: {
      contrastText: '#FFFFFF',
      light: '#FFEBE2',
      main: '#56CD11',
      dark: '#122F08',
    },
    secondary: {
      contrastText: '#DEF8B8',
      light: '#3FAD0A',
      main: '#EEEEEE',
      dark: 'rgba(0, 0, 4, 0.54)',
    },
    tertiary: {
      contrastText: '',
      light: '#D9580E',
      main: '#FFDAC6',
      dark: '',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#122F08',
          borderColor: '#e0e0e0',
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: '#122F08',
      marginTop: 10,
      marginBottom: 10,
    },
    h2: {
      fontSize: '60px',
      fontWeight: 300,
    },
    h4: {
      fontSize: '34px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '24px',
      fontWeight: 300,
    },
    body1: {
      fontSize: '20px',
      fontWeight: 300,
      lineHeight: '24px',
    },
    body2: {
      fontSize: '16px',
      fontWeight: 400,
    },
    button: {
      margin: 0,
    },
  },
})

export default theme
