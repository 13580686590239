import React, { useEffect } from 'react'
import { Delete } from '@mui/icons-material'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { useDeleteEvidenceLinkMutation } from '../../../../services/evidences'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { appActions } from '../../../../store/App/app-slice'

interface Props {
  id: string
  showDelEviSuccess?: () => void
  showDelEviError?: (label: string) => void
}

const DeleteEvidenceIcon: React.FC<Props> = ({
  id,
  showDelEviSuccess,
  showDelEviError,
}) => {
  const { role } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const [
    deleteEvidenceReq,
    { isError: isErrorInDeleteEvidence, isLoading, isSuccess },
  ] = useDeleteEvidenceLinkMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        appActions.showSnackbarNotification({
          type: 'success',
          label: `Evidence succesful deleted`,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, showDelEviSuccess])

  useEffect(() => {
    if (isErrorInDeleteEvidence) {
      dispatch(
        appActions.showSnackbarNotification({
          type: 'error',
          label: `Sorry we couldn't delete evidence, please try again!`,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorInDeleteEvidence])

  const isViever = role === 'VIEWER'

  if (isLoading) {
    return <CircularProgress />
  }

  const iconJsx = (
    <IconButton
      disabled={isViever ? true : false}
      onClick={() => deleteEvidenceReq(id)}
    >
      <Delete />
    </IconButton>
  )

  if (isViever) {
    return (
      <Tooltip
        title={`As a "VIEWER," you don't have permission to delete items.`}
      >
        <span>{iconJsx}</span>
      </Tooltip>
    )
  }

  return iconJsx
}

export default DeleteEvidenceIcon
